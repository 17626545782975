import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalTransactionOriginCode extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Transaction Origin Code</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                TransactionOriginCode is used to track the origin of the transaction. Each integrator is assigned to their respective code, that they should include, when transaction is submitted. If an integrator has more than one place within application from which the request can be originated to the gateway, the integrator can append additional value after the assigned transaction origin code (using : as separator).
<br/>
 
<br/>
For example, an integrator (SuperSoft) has two places in its software that send transactions to the gateway. One of them is originated by POS (point of sale), the other one is originated by back-end billing process. Transaction origin codes for these transactions should look similar to this:
<br/>

<br/>
<div class="info">SuperSoft:pos<br/>
SuperSoft:recurring</div>

<br/>
The maximum length of the transaction origin code must not exceed 35 characters (including assigned value and separator).<br/>                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

