import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalStoredCredentials extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Stored Credentials</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                There can be cases when a payment card is going to be used for recurring/installment transactions, which can be processed either in real-time or as batch files. Some card brands require identification of an original authorization when recurring transactions are processed. The idea of that is when a cardholder was accepting the agreement, an initial transaction was processed either as card-present or with CSC included, and subsequent recurring transactions, which are processed as card-not-present without CSC, are assumed to be a continuation of the initial or previous transaction. This is needed to manage risk and fraud, resulting in fewer chargebacks and improved cardholder experience. 
<br/>

<br/>
To process a recurring/installment transaction, follow the steps below:
<br/>
<ol style={{marginLeft: "25px", color: "inherit !important"}} >
<li>Submit the sale/sale-auth API request with the <em>transactionCategoryType=I</em> or <em>transactionCategoryType=R</em> and <em>transactionModeType=S</em> or <em>transactionModeType=O</em> included in the request. If it is a card-not-present transaction, we recommend submitting the <em>csc</em> or the <em>accountData</em> field as well, as this ensures that the transaction was initiated by a customer and is not fraudulent.
</li>
<li>After the transaction is submitted, the gateway returns the API response with the processor-generated ID of the recurring/installment transaction. The ID is returned in the <em>networkTransactionId</em> field. This value must be used in every transaction associated with the first one to indicate that a transaction is a continuation of a recurring sequence and follows a recurring/installment transaction that has already been processed by the system before. Note that each networkTransactionId value is associated with a certain sequence of recurring/installment transactions and not with an account number of a cardholder.
</li>
<li>Submit the received <em>networkTransactionID</em> field value in the <em>originalNetworkTransactionID</em> field within the subsequent recurring/installment transactions.
</li>
<li>If you want to start a new sequence of recurring/installment transactions, you have to submit a new sale/sale-auth API request <em>without</em> the originalNetworkTransactionID field included, as described in step 1.
</li>
<li>If the first recurring/installment transaction is scheduled for processing in the future, you can reserve the <em>networkTransactionId</em> field value in advance without funds withdrawal from a cardholder. To do this, submit the account-verification API request with the <em>csc</em> or <em>accountData</em> field value included. This transaction will be considered the first in the sequence of recurring/installment transactions. After the transaction is processed, the <em>networkTransactionId</em> field value is received in the account-verification response. This ID can be used in subsequent transactions associated with this recurring sequence, as described above. 
</li>
</ol>

<br/>
In the table below you can see, which fields are required to submit in the request and which are returned in the response when using the stored credentials mechanism:
<br/>
<div class="table-responsive"><table class="newtable newtablenorowspan pull-left" style={{marginTop: "5px", tableLayout: "fixed"}} ><tbody><tr>
<th rowspan="3" style={{fontWeight: "bold", textAlign: "center"}} >Transaction Sequence
</th>
<th rowspan="3" style={{fontWeight: "bold", textAlign: "center"}}>Transaction <br/> Category Type
</th>
<th colspan="3" style={{fontWeight: "bold", textAlign: "center"}}>Transaction Mode Type
</th>
<th rowspan="3" style={{fontWeight: "bold", textAlign: "center"}}>Network <br/> Transaction ID
</th>
<th rowspan="3" style={{fontWeight: "bold", textAlign: "center"}}>Original Network <br/> Transaction ID			
</th>
</tr><tr>
<th rowspan="2" style={{fontWeight: "bold", textAlign: "center", width: "10px"}}>Card-present
</th>
<th colspan="2" style={{fontWeight: "bold", textAlign: "center", width: "10px"}}>Card-not-present*
</th>
</tr><tr>
<th style={{fontWeight: "bold", textAlign: "center"}}>Card-On-File/<br/>Cardholder Initiated
</th>
<th style={{fontWeight: "bold", textAlign: "center"}}>Card-On-File/<br/>Merchant Initiated
</th>
</tr><tr>
<td>First transaction (request)
</td>
<td>x
</td>
<td>x
</td>
<td>
</td>
<td>
</td>
<td>
</td>
<td>
</td>
</tr><tr>
<td>First transaction (response)
</td>
<td>
</td>
<td>
</td>
<td>
</td>
<td>
</td>
<td>x
</td>
<td>
</td>
</tr><tr>
<td>Subsequent transactions (request)
</td>
<td>x
</td>
<td>
</td>
<td>x
</td>
<td>x
</td>
<td>
</td>
<td>x
</td>
</tr><tr>
<td>Subsequent transactions (response)
</td>
<td>
</td>
<td>
</td>
<td>
</td>
<td>
</td>
<td>x
</td>
<td>
</td>
</tr><tr>
</tr></tbody></table> 
<br/>
<div style={{display: "inline-block"}} >
<strong>*</strong> Note that the transaction mode type for each subsequent transaction can be either Card-On-File / Cardholder Initiated or Card-On-File / Merchant Initiated.
<br/>

<br/>
</div>                </div> </div>




                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

