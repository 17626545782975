import React from 'react'
import Navbar from '../../Components/NavBar/Navbar'
import { LuArrowRightCircle } from 'react-icons/lu';
import './Welcome.css';
import Footer from '../../Components/Footer/Footer';

export default class Welcome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }
    render() {
        return (
            <>
                <Navbar />

                <div className='anchoMaximo' style={{ paddingTop: '120px', display: 'block', margin: "auto", width: "95%" }}>
                    <div className="row" >
                        <div className="col-xs-12 col-sm-12">
                            <h1 className="sectiontitle">Welcome to the on-line help system</h1>
                        </div>



                        <div className="col-xs-12 col-sm-12">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    Here you can find complete documentation for Transxations Gateway, including integration documents, administration guides and application tutorials.  Please use links below to access the information you are interesed in.
                                    <div className="prettyline" ></div>
                                    <b>Note: the site gets updated as works progress, we are constantly adding new data.</b>
                                </div></div>
                        </div>



                        <div class="col-xs-12 col-sm-12">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h3 class="panel-title widget-title"><img src="/images/usage-integration.png" /> Usage &amp; Integration</h3>
                                </div>
                                <div class="panel-body">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <h3 class="widget-title">APIs:</h3>
                                            <ul class="list-arrow">

                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Processing</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/billing-api">Billing</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Management</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Webhooks</a></a></h3>

                                            </ul>
                                        </div>
                                        <div class="col-sm-6 choose-us">
                                            <h3 class="widget-title">Manuals:</h3>

                                            <ul class="list-arrow">

                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Terminology</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">User Guides</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">User Interface Reference</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">How-To Tutorials</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Lessons</a></a></h3>
                                            </ul>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-xs-12 col-sm-12">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h3 class="panel-title widget-title"><img src="/images/administration-maintenance.png" /> Administration &amp; Maintenance</h3>
                                </div>
                                <div class="panel-body">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h3 class="widget-title">Data Dictionaries:</h3>
                                            <ul class="list-arrow">

                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">UniCore</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">UniCharge</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">UniBill</a></a></h3>

                                            </ul>
                                        </div>
                                        <div class="col-sm-4">
                                            <h3 class="widget-title">Build Information:</h3>
                                            <ul class="list-arrow">

                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Properties Definition</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Resources (Directory Structure) Definition</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Zip and Country Codes</a></a></h3>

                                            </ul>
                                        </div>
                                        <div class="col-sm-5 choose-us">
                                            <a name="reference_materials"></a>
                                            <h3 class="widget-title">Reference Materials:</h3>
                                            <ul class="list-arrow">

                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Supported Providers (Banks and Networks)</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Transxations Codes &amp; Code Mappings</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Integrated 3rd Party Libraries</a></a></h3>
                                                <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a href="/processing-api">Diagrams and Documents</a></a></h3>


                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>



                </div>


                <Footer />

            </>
        )
    }
}

