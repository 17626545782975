import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINWarningCode extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Warning Code</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                Warning code is a letter code that indicates additional conditions, which have occurred during transaction processing. However, such conditions are not dramatic enough to affect processing one way or another. Warning code can be received as a part of the API response, within <em>warningCode</em> field. At this point, it is preliminary used to notify a user about an unsuccessful reversal of a transaction or any errors related to a descriptor. 

                                <br/>
                                There are three cases when a transaction should be reversed after successful authorization because certain business condition does not match:
                                <br/>
                                <ul type="circle" style={{marginLeft: "10px", color: "#5688c9"}}>
                                <li>Submitter does not support partial authorization, therefore, isPartialAuthorization flag is set to false. The expected behavior is that in case when partial approval is received, the transaction should be reversed and the submitter should receive a decline and not a partial approval;
                                </li>
                                <li>Post-processing filter gets triggered and requires a reversal of the transaction. For example, post-processing filter requires reversal of any transactions where AVS codes have not matched;
                                </li>
                                <li>External fraud protection tool similar to post-processing filter requires reversal of the transaction.
                                </li>
                                </ul>

                                <br/>
                                In these cases the system executes void. If void has been approved, <em>A03/A06: Void Posted</em> response is received. If void has been declined, <em>A11: Approval (Reversal failed)</em> message, as well as a respective warning code is returned within a response. 

                                <br/>
                                Follow <a href="/codes/your_gateway/system_codes#Warning_Codes">this</a> link to learn more about possible warning codes.                 
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

