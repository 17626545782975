import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINInterchangeRateQuealification extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Interchange Rate Qualification</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                            Based on the fields present in a transaction, transaction may qualify for different interchange rates. Among the fields that commonly affect qualification for Card Not Present transactions (those that do not involve track data) are CSC code and address fields (street, city, state, ZIP code). Presence of these fields reduces possibility of fraud and therefore improves the interchange rate, that the transaction qualifies for, as well as a greater chance of winning a dispute by merchant in case of chargeback. Providing these fields is highly recommended and among address fields, at least, ZIP code should always be provided.
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

