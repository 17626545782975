import React from 'react'
import { LuArrowRightCircle } from 'react-icons/lu';
import './Objects.css'


import ModalMessageFormats from '../../../Components/Modals/Billing-api/Objects/ModalMessageFormats';
import ModalMessageFormatsMode from '../../../Components/Modals/Billing-api/Objects/ModalMessageFormatsMode';


export default class SQLBALANCINGSUMMARY  extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showmodal: "",

        }
    }


    async componentDidMount() {

      
    }
 


    render() {
        return (
            <>
             



                <div className='options-container'>
                <pre class="mysql" style={{fontFamily: "monospace"}} ><span style={{color: "#808080", fontStyle:"italic"}}>-- Create Table Statement for 'Balancing Summary'</span> <br/>
<a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=DROP"><span style={{color: "#990099", fontWeight: "bold"}} >DROP</span></a> <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=TABLE"><span style={{color: "#990099", fontWeight: "bold"}}>TABLE</span></a> <a href="http://dev.mysql.com/doc/refman/%35%2E%31/en/control-flow-functions.html"><span style={{color: "#009900"}}>IF</span></a> <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=EXISTS"><span style={{color: "#990099", fontWeight: "bold"}}>EXISTS</span></a> <span style={{color: "#008000"}}>`BALANCING<span style={{color: "#008080", fontWeight: "bold"}}>_</span>SUMMARY`</span><span style={{color: "#000033"}}>;</span><br/>
<a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=CREATE"><span style={{color: "#990099", fontWeight: "bold"}}>CREATE</span></a> <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=TABLE"><span style={{color: "#990099", fontWeight: "bold"}}>TABLE</span></a> <span style={{color: "#008000"}}>`BALANCING<span style={{color: "#008080", fontWeight: "bold"}}>_</span>SUMMARY`</span> <span style={{color: "#FF00FF"}}>(</span><br/>
	<div className='ps-5'>
    ID <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=BIGINT"><span style={{color: "#999900", fontWeight: "bold"}}>bigint</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>20</span><span style={{color: "#FF00FF"}}>)</span> <a href="http://dev.mysql.com/doc/refman/%35%2E%31/en/non-typed-operators.html"><span style={{color: "#CC0099", fontWeight: "bold"}}>NOT</span></a> <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=NULL"><span style={{color: "#9900FF", fontWeight: "bold"}}>NULL</span></a><span style={{color: "#000033"}}>,</span><br/>
	MERCHANT_ACCOUNT_CODE <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=INT"><span style={{color: "#999900", fontWeight: "bold"}}>int</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>11</span><span style={{color: "#FF00FF"}}>)</span> <a href="http://dev.mysql.com/doc/refman/%35%2E%31/en/non-typed-operators.html"><span style={{color: "#CC0099", fontWeight: "bold"}}>NOT</span></a> <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=NULL"><span style={{color: "#9900FF", fontWeight: "bold"}}>NULL</span></a><span style={{color: "#000033"}}>,</span><br/>
	CREATE_DATE <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=DATETIME"><span style={{color: "#999900", fontWeight: "bold"}}>datetime</span></a><span style={{color: "#000033"}}>,</span><br/>
	AMOUNT <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=INT"><span style={{color: "#999900", fontWeight: "bold"}}>int</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>11</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	IS_ACTIVE <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=TINYINT"><span style={{color: "#999900", fontWeight: "bold"}}>tinyint</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>1</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	IS_IN_COLLECTIONS <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=TINYINT"><span style={{color: "#999900", fontWeight: "bold"}}>tinyint</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>1</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	AGE <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=INT"><span style={{color: "#999900", fontWeight: "bold"}}>int</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>11</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	TRANSACTION_SOURCE_CL <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=VARCHAR"><span style={{color: "#999900", fontWeight: "bold"}}>varchar</span></a><span style={{color: "#000033"}}>,</span><br/>
	PAYMENT_OPTION_TYPE <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=VARCHAR"><span style={{color: "#999900", fontWeight: "bold"}}>varchar</span></a><span style={{color: "#000033"}}>,</span><br/>
	IS_SOURCE_INVALID <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=TINYINT"><span style={{color: "#999900", fontWeight: "bold"}}>tinyint</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>1</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	IS_TARGET_INVALID <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=TINYINT"><span style={{color: "#999900", fontWeight: "bold"}}>tinyint</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>1</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	COMMISSION_STATUS_CL <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=VARCHAR"><span style={{color: "#999900", fontWeight: "bold"}}>varchar</span></a><span style={{color: "#000033"}}>,</span><br/>
	SOURCE_FK <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=BIGINT"><span style={{color: "#999900", fontWeight: "bold"}}>bigint</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>20</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	TARGET_FK <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=BIGINT"><span style={{color: "#999900", fontWeight: "bold"}}>bigint</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>20</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	AGENT_COMMISSIONS <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=BIGINT"><span style={{color: "#999900", fontWeight: "bold"}}>bigint</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>20</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	CAUSE_TRANSACTION_CODE <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=BIGINT"><span style={{color: "#999900", fontWeight: "bold"}}>bigint</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>20</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	CAUSE_TRANSACTION_CL <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=VARCHAR"><span style={{color: "#999900", fontWeight: "bold"}}>varchar</span></a><span style={{color: "#000033"}}>,</span><br/>
	CAUSE_TRANSACTION_CODE <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=BIGINT"><span style={{color: "#999900", fontWeight: "bold"}}>bigint</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>20</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	CAUSE_TRANSACTION_CL <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=VARCHAR"><span style={{color: "#999900", fontWeight: "bold"}}>varchar</span></a><span style={{color: "#000033"}}>,</span><br/>
	CAUSE_TRANSACTION_CODE <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=BIGINT"><span style={{color: "#999900", fontWeight: "bold"}}>bigint</span></a><span style={{color: "#FF00FF"}}>(</span><span style={{color: "#008080"}}>20</span><span style={{color: "#FF00FF"}}>)</span><span style={{color: "#000033"}}>,</span><br/>
	CAUSE_TRANSACTION_CL <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=VARCHAR"><span style={{color: "#999900", fontWeight: "bold"}}>varchar</span></a><span style={{color: "#000033"}}>,</span><br/>
	<a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=PRIMARY%20KEY"><span style={{color: "#990099", fontWeight: "bold"}}>PRIMARY KEY</span></a> <span style={{color: "#FF00FF"}}>(</span>ID<span style={{color: "#FF00FF"}}>)</span><br/>
    </div>
<span style={{color: "#FF00FF"}}>)</span> <a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=ENGINE"><span style={{color: "#990099", fontWeight: "bold"}}>ENGINE</span></a><span style={{color: "#CC0099"}}>=</span><a href="https://www.oracle.com/search/results?cat=mysql&amp;Ntk=SI-ALL5&amp;Ntt=INNODB"><span style={{color: "#990099", fontWeight: "bold"}}>InnoDB</span></a><span style={{color: "#000033"}}>;</span>
&nbsp;</pre>
                </div>
            </>
        )
    }
}

