import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINCrossReferenceFieldsName extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Cross-reference Fields</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                The system provides an ability to specify values that can be used for cross-referencing of entities between the gateway and a submitter’s system. The fields that can be provided by an integrated external system are listed below:
                                <br/>

                                <br/>
                                <div>
                                <div style={{display: "inline-block"}}>
                                <div class="table-responsive"><table class="newtable pull-left" style={{marginTop: "0px"}}><tbody><tr>
                                <th style={{fontWeight: "bold"}}>Entity
                                </th>
                                <th style={{fontWeight: "bold"}}>Field Name
                                </th>
                                <th style={{fontWeight: "bold"}}>Description
                                </th>
                                </tr><tr>
                                <td rowspan="2">Transaction
                                </td>
                                <td>transactionCode
                                </td>
                                <td>Identifier of the transaction within an external system that submits the transaction for processing.
                                </td>
                                </tr><tr>
                                <td>transactionInternalCode*
                                </td>
                                <td>Unique internal identifier of the transaction within an external system that submits the transaction for processing. Can be used as a supplement to a <em>transactionCode</em>.
                                </td>
                                </tr><tr>
                                <td rowspan="2">Customer
                                </td>
                                <td>customerAccountCode
                                </td>
                                <td>Identifier of a customer within the submitter’s system that submits the transaction for processing.
                                </td>
                                </tr><tr>
                                <td>customerAccountInternalCode*
                                </td>
                                <td>Unique internal identifier of a customer within the submitter’s system that submits the transaction for processing. Can be used as a supplement to a <em>customerAccountCode</em>.
                                </td>
                                </tr><tr>
                                <td>Item
                                </td>
                                <td>itemCode
                                </td>
                                <td>Identifier of a purchased product or service within an external system that submits the transaction for processing.
                                </td>
                                </tr><tr>
                                <td>Charge
                                </td>
                                <td>chargeCode
                                </td>
                                <td>Identifier of the charge within an external system that submits the transaction for processing.
                                </td>
                                </tr></tbody></table>
                                </div></div><br/>

                                <br/>
                                <em>*</em>In some cases, the submitting system is going to have two different identifiers for a given transaction: one, shown to the end user on the UI, and the other one, a system identifier (very often a database-generated artificial ID). In such cases, the user-visible ID (which is used in the business context) should be submitted in the regular fields (in transactionCode or customerAccountCode), while internal ID (artificial ID), should be sent as transactionInternalCode or customerAccountInternalCode.
                                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

