import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalReceiptRequirements extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Receipt Requirements</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                EMV processing imposes certain receipt requirements. Specific fields that are the part of the transactions must be visible on a receipt. For approved transactions, it is recommended to be included on the receipt to aid in dispute resolution. For declined transactions, this information is mandated by the processors for tracing purposes. If you want to create receipts as a part of your application, please be sure to follow the guidelines below. 
<br/>

<br/>
<h2>Fields that are recommended to be present on a receipt</h2>

<br/>
The following fields are not required but recommended to be present on a receipt of any transaction:
<br/>
<ul type="circle" >
<li>Merchant Name
</li>
<li>Merchant Address
</li>
<li>Merchant Phone is displayed in ХХХ-ХХХ-ХХХХ  format
</li>
<li>Merchant ID
</li>
<li>Terminal ID
</li>
<li>Invoice number in POS system
</li>
<li>Transaction Date
</li>
</ul>

<br/>
<h2>EMV Receipt Requirements</h2>
The following fields are required to be present on all EMV transactions receipts:
<br/>
<ul type="circle" >
<li>Transaction Type – <em>Sale</em> or <em>Credit</em>.
</li>
<li>Application Name - a chip card application name (i.e. Visa, MasterCard, etc.) used to process the EMV transaction.
</li>
<li>Total Amount – corresponds to the <em>amount</em> field.
</li>
<li>Currency indicator – corresponds to the <em>currencyCode</em> field.
</li>
<li>Account Number - corresponds to the <em>accountNumberMasked</em> field.
</li>
<li>Card Entry Method – сan be derived from the combination of <em>entryModeType</em> and <em>entryMediumType</em> fields.
</li>
<li>Approval Number - corresponds to the <em>approvalCode</em> field.
</li>
<li>Transaction Id - corresponds to the <em>transactionId</em> field.
</li>
<li>Verification Method – <em>Signature</em>, <em>PIN</em> or <em>PIN Bypassed</em> (optional, present for approved transactions).
</li>
<li>Mode - corresponds to the <em>processingMode</em> field (online or offline).
</li>
<li>PNN - corresponds to the <em>paymentNetworkName</em> field.
</li>
<li>Specific EMV tags (see table below).
</li>
</ul>

<br/>
A receipt can be obtained in two ways:
<br/>
<ul type="circle">
<li>if you are using the standard terminal solution that comes with the gateway, you can print receipt on your terminal. See <span class="note-doc" type="note" data="Terminals_Technical_Standards">integration notes</span> to check whether your terminal supports receipt printing or not. 
</li>
<li>if you are using your own terminal solution or terminals that do not support receipt printing, you can obtain a receipt by e-mail. These receipts can also be downloaded in the gateway.
</li>
<li>if you are using your own application for receipt printing, please stick rigidly to the guidelines.
</li>
</ul>

<br/>
For <em>Sale</em> and <em>Credit</em> transactions, <em>PNN</em> and <em>Account Type</em> fields take the default value of <em>Interac</em> in cases when the value of <em>transaction ID</em> is returned in <em>stanValue</em> field in transaction response.

<br/>
Receipt examples are listed in the table below:
<br/>

<br/>
<div style={{display: "inline-block"}} >
<div class="table-responsive"><table class="newtable pull-left" style={{marginTop: "0px", width: "600px"}} ><tbody><tr>
<th style={{fontWeight: "bold", width: "55%"}} >Transaction Type
</th>
<th style={{fontWeight: "bold", width: "15%"}} >Merchant Copy
</th>
<th style={{fontWeight: "bold", width: "15%"}} >Customer Copy
</th>
<th style={{fontWeight: "bold", width: "15%"}} >Email Copy
</th>
</tr><tr>
<td>EMV sale transaction (approved)
</td>
<td><span class="screenshot" type="screenshot" data="/images/merchant_sale_emv_approved.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/customer_sale_emv_approved.png">Example</span>
</td>
<td rowspan="5" align="center"><a href="http://unipaygateway.info/images/pdf/email_receipt.pdf">Example</a> <a href="http://unipaygateway.info/images/pdf/email_receipt_interac.pdf">Example*</a> 
</td>
</tr><tr>
<td>EMV sale with tips (approved)
</td>
<td><span class="screenshot" type="screenshot" data="/images/merchant_sale_emv_tips_approved.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/customer_sale_emv_tips_approved.png">Example</span>
</td>
</tr><tr>
<td>EMV sale with items (approved)
</td>
<td><span class="screenshot" type="screenshot" data="/images/merchant_emv_with_items.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/customer_emv_with_items.png">Example</span>
</td>
</tr><tr>
<td>EMV sale with fee included (approved)
</td>
<td><span class="screenshot" type="screenshot" data="/images/merchant_emv_with_fee.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/customer_emv_with_fee.png">Example</span>
</td>
</tr><tr>
<td>Contactless sale transaction (approved)
</td>
<td><span class="screenshot" type="screenshot" data="/images/merchant_contactless_approved.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/customer_contactless_approved.png">Example</span>
</td>
</tr><tr>
<td>Credit transaction (approved)
</td>
<td><span class="screenshot" type="screenshot" data="/images/merchant_credit_approved.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/customer_credit_approved.png">Example</span>
</td>
</tr><tr>
<td>Refund transaction (approved)
</td>
<td><span class="screenshot" type="screenshot" data="/images/merchant_refund_approved.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/customer_refund_approved.png">Example</span>
</td>
</tr><tr>
<td>EMV sale transaction (declined)
</td>
<td><span class="screenshot" type="screenshot" data="/images/merchant_emv_online_declined.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/customer_emv_online_declined.png">Example</span>
</td>
</tr><tr>
<td>Split transaction (declined)
</td>
<td><span class="screenshot" type="screenshot" data="/images/merchant_split_declined.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/customer_split_declined.png">Example</span>
</td>
</tr><tr>
<td>Split transaction with items <br/> paid with two different cards (approved)
</td>
<td><span class="screenshot" type="screenshot" data="/images/note_terminal_API_split_merchant_approve_ticket_cash.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/note_terminal_API_split_customer_approve_ticket_cash.png">Example</span>
</td>
</tr><tr>
<td>Split transaction with items <br/>paid with card and cash (approved)
</td>
<td><span class="screenshot" type="screenshot" data="/images/note_terminal_API_split_merchant_approve_ticket_card.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/note_terminal_API_split_customer_approve_ticket_card.png">Example</span>
</td>
</tr><tr>
<td>Split transaction with items (declined)
</td>
<td><span class="screenshot" type="screenshot" data="/images/note_terminal_API_merchant_decline_1.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/note_terminal_API_customer_decline.png">Example</span>
</td>
</tr><tr>
<td>Split transaction <br/> paid with two different cards (approved)
</td>
<td><span class="screenshot" type="screenshot" data="/images/without_ticket_1_merchant.png">Example</span> <br/> <span class="screenshot" type="screenshot" data="/images/without_ticket_2_card_merchant.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/without_ticket_1_customer.png">Example</span> <br/> <span class="screenshot" type="screenshot" data="/images/without_ticket_2_card_customer.png">Example</span>
</td>
</tr><tr>
<td>Split transaction <br/>paid with card and cash  (approved)
</td>
<td><span class="screenshot" type="screenshot" data="/images/without_ticket_1_merchant.png">Example</span> <br/> <span class="screenshot" type="screenshot" data="/images/split_cash_merchant.png">Example</span>
</td>
<td><span class="screenshot" type="screenshot" data="/images/without_ticket_1_customer.png">Example</span> <br/> <span class="screenshot" type="screenshot" data="/images/split_cash_customer.png">Example</span>
</td>
</tr></tbody></table>
</div></div>
<br/>

<br/>
 * for <em>Interac</em> card only

<br/>

<br/>
<h2>Tags To Be Included in a Receipt</h2>

<br/>
EMV transaction fields listed below must be present on all online transactions.
<br/>

<br/>
<div  style={{display: "inline-block"}}>
<div class="table-responsive"><table class="newtable newtablenorowspan pull-left" style={{tableLayout: "fixed", marginTop: "0px"}} ><tbody><tr>
<td style={{fontWeight: "bold", width: "10%"}} > Tag
</td>
<td style={{fontWeight: "bold", width: "10%"}} > Tag Name
</td>
<td style={{fontWeight: "bold", width: "50%"}} > Description
</td>
</tr><tr>
<td>
</td>
<td align="center">Mode
</td>
<td>Processig Mode (online or offline) (corresponds to the field value <em>processingMode</em>)
</td>
</tr><tr>
<td>
</td>
<td align="center">PNN
</td>
<td>Payment Network Name (corresponds to the field value <em>paymentNetworkName</em>)
</td>
</tr><tr>
<td>
</td>
<td align="center">APP
</td>
<td>Application Name for a chip card, which is used for EMV transactions
</td>
</tr><tr>
<td align="center">4F
</td>
<td align="center">AID
</td>
<td>Application Identifier
</td>
</tr><tr>
<td align="center">95
</td>
<td align="center">TVR
</td>
<td>Terminal Verification Results
</td>
</tr><tr>
<td align="center">9F10
</td>
<td align="center">IAD
</td>
<td>Issuer Application Data
</td>
</tr><tr>
<td align="center">9B
</td>
<td align="center">TSI
</td>
<td>Transaction Status Indicator
</td>
</tr><tr>
<td align="center">8A
</td>
<td align="center">ARC
</td>
<td>Application Response Code
</td>
</tr></tbody></table>
</div>
<br/>
<div style={{display: "inline-block", marginTop: "20px"}}>
Usually these tags are displayed on a receipt one tag per line listing a tag name followed by colon (":") followed by the value of the tag.
<br/>
To simplify the process for integrators, there is a field in the Sale response - receiptTagData - that will contain all of the tags that need to be rendered on a receipt in the order in which they need to appear. Two formats of the field are supported:
<br/>

<br/>
1) [tag1]:[tag-value1];[tag2]:[tag-value2];[tag3]:[tag-value3];[tag4]:[tag-value4];[tag5]:[tag-value5];[tag6]:[tag-value6].
<br/>
2) [tag1]–[tag -name1]:[tag-value1];[tag2]–[tag-name2]:[tag-value2];[tag3]–[tag-name3]:[tag-value3];[tag4]–[tag-name4]:[tag-value4];[tag5]–[tag-name5]:[tag-value5];[tag6]–[tag-name6]:[tag-value6].
<br/>

<br/>
So, for example, 4F:A0000000041010;95:0000008000;9F10: 0110A04009240000000000000000000000FF;9B:;91:B2C567CDE15E4C290012;8A:01  
<br/>

<br/>
or 4F-AID:A0000000041010;95-TVR:0000008000;9F10-IAD:0110A04009240000000000000000000000FF;9B-TSI:;91:B2C567CDE15E4C290012;8A-ARC:01 will be rendered as follows:
<br/>

<br/>
<div class="info">
receiptTagData=
<br/>
Mode: Online;
<br/>
PNN:Visa Credit;
<br/>
APP:VISA CREDIT;
<br/>
AID:A0000000041010;
<br/>
TVR:0000008000;
<br/>
IAD:0110A04009240000000000000000000000FF;
<br/>
TSI:;
<br/>
91: B2C567CDE15E4C290012;
<br/>
ARC:01
<br/>
</div>

<br/>
The Mode,PNN,APP fields are formed at the terminal.
<br/>

<br/>
Please, be advised that most of the transactions will always have pointed values in the receipt tag data field. In case of EMV offline declines, larger set of tags will be included. For EMV offline decline transactions tags from Tag columns are used for receiptTagData (started from 8.1 release)
<br/>

<br/>
<div style={{display: "inline-block"}}>
<div class="table-responsive"><table class="newtable newtablenorowspan pull-left" style={{tableLayout: "fixed"}} ><tbody><tr>
<td style={{fontWeight: "bold", width: "20%"}}> Tag
</td>
<td style={{fontWeight: "bold", width: "20%"}}> Tag Name
</td>
<td style={{fontWeight: "bold", width: "50%"}}> Description
</td>
</tr><tr>
<td align="center">5F2A
</td>
<td align="center">Tag 5F2A
</td>
<td>Transaction Currency Code
</td>
</tr><tr>
<td align="center">5F34
</td>
<td align="center">Tag 5F34
</td>
<td>PAN Sequence Number (Blank if not present)
</td>
</tr><tr>
<td align="center">82
</td>
<td align="center">Tag 82
</td>
<td>Application Interchange Profile
</td>
</tr><tr>
<td align="center">95
</td>
<td align="center">Tag 95
</td>
<td>Terminal Verification Results
</td>
</tr><tr>
<td align="center">9A
</td>
<td align="center">Tag 9A
</td>
<td>Transaction Date
</td>
</tr><tr>
<td align="center">9C
</td>
<td align="center">Tag 9C
</td>
<td>Transaction Time
</td>
</tr><tr>
<td align="center">9F02
</td>
<td align="center">Tag 9F02
</td>
<td>Amount Authorized (Printed with decimal point)
</td>
</tr><tr>
<td align="center">9F03
</td>
<td align="center">Tag 9F03
</td>
<td>Other Amount (Printed with decimal point)
</td>
</tr><tr>
<td align="center">9F07
</td>
<td align="center">Tag 9F07
</td>
<td>Application Usage Control
</td>
</tr><tr>
<td align="center">9F0D
</td>
<td align="center">Tag 9F0D
</td>
<td>Issuer Action Code – Default
</td>
</tr><tr>
<td align="center">9F0E
</td>
<td align="center">Tag 9F0E
</td>
<td>Issuer Action Code – Denial
</td>
</tr><tr>
<td align="center">9F0F
</td>
<td align="center">Tag 9F0F
</td>
<td>Issuer Action Code – Online
</td>
</tr><tr>
<td align="center">9F12
</td>
<td align="center">Tag 9F12
</td>
<td>Application Preferred Name (Blank if in unprintable format)
</td>
</tr><tr>
<td align="center">9F1A
</td>
<td align="center">Tag 9F1A
</td>
<td>Terminal Country Code
</td>
</tr><tr>
<td align="center">9F26
</td>
<td align="center">Tag 9F26
</td>
<td>Application Cryptogram
</td>
</tr><tr>
<td align="center">9F27
</td>
<td align="center">Tag 9F27
</td>
<td>Cryptogram Information Data
</td>
</tr><tr>
<td align="center">9F34
</td>
<td align="center">Tag 9F34
</td>
<td>CVM Results
</td>
</tr><tr>
<td align="center">9F36
</td>
<td align="center">Tag 9F36
</td>
<td>Application Transaction Data
</td>
</tr><tr>
<td align="center">9F37
</td>
<td align="center">Tag 9F37
</td>
<td>Unpredictable Number
</td>
</tr></tbody></table>
</div>
</div>
</div>
                </div>
            </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

