import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalBillingApi.css';


export default class ModalAccountActivityClassifier extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }
    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    render() {
        return (

            <>
                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Account Activity Classifier</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "20%"}}></colgroup>
                                        <colgroup style={{width: "70%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>Value</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                    <tr>
                                <td>RA</td>
                                <td>Represents fee, applied to the customer's account because access to restricted digital assets (e.g. paperless copies of contracts) was granted.</td>
                            </tr>
                                                    <tr>
                                <td>AA</td>
                                <td>Represents automatic write off of the existing debt due to the age of this debt.</td>
                            </tr>
                                                    <tr>
                                <td>RX</td>
                                <td>Represents a reversal of a previously posted credit.</td>
                            </tr>
                                                    <tr>
                                <td>AX</td>
                                <td>Represents a reversal of a previously posted refund.</td>
                            </tr>
                                                    <tr>
                                <td>AG</td>
                                <td>Represents a credit card chargeback of a previously processed payment or claim.</td>
                            </tr>
                                                    <tr>
                                <td>AS</td>
                                <td>Represents a reversal of a previously posted chargeback.</td>
                            </tr>
                                                    <tr>
                                <td>AC</td>
                                <td>Represents a batch payment, collected on the customer's account. It is conceptually similar to real-time payment, but has a likelihood of subsequent decline within three-day period (on average) after its effective date (due to batch processing and reprocessing). See [t:Transaction Reprocessing] for additional information.</td>
                            </tr>
                                                    <tr>
                                <td>RO</td>
                                <td>Represents fee, applied to an account when it is sent to the 3rd party collections for the first time.</td>
                            </tr>
                                                    <tr>
                                <td>RC</td>
                                <td>Represents a transaction opposite to an invoice (reduction of customer's debt to a merchant).</td>
                            </tr>
                                                    <tr>
                                <td>AD</td>
                                <td>Represents a decline of payment, either real-time or batch.</td>
                            </tr>
                                                    <tr>
                                <td>RF</td>
                                <td>Represents generic fee, applied to the customer's account.</td>
                            </tr>
                                                    <tr>
                                <td>RI</td>
                                <td>Represents an invoice (recording of customer's debt to a merchant).</td>
                            </tr>
                                                    <tr>
                                <td>RL</td>
                                <td>Represents fee, applied because of a late payment.</td>
                            </tr>
                                                    <tr>
                                <td>AP</td>
                                <td>Represents a real-time payment, collected on the customer's account.</td>
                            </tr>
                                                    <tr>
                                <td>AR</td>
                                <td>Represents partial or full reversal of a previous payment.</td>
                            </tr>
                                                    <tr>
                                <td>RN</td>
                                <td>Represents fee, applied to an account, when it is reinstated from 3rd party collections back to regular billing.</td>
                            </tr>
                                                    <tr>
                                <td>RR</td>
                                <td>Represents full reversal of an existing invoice.</td>
                            </tr>
                                                    <tr>
                                <td>AV</td>
                                <td>Represents void of an existing payment.</td>
                            </tr>
                                                </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

