import React from 'react'
import { LuArrowRightCircle } from 'react-icons/lu';
import './Objects.css'


import ModalMessageFormats from '../../../Components/Modals/Billing-api/Objects/ModalMessageFormats';
import ModalMessageFormatsMode from '../../../Components/Modals/Billing-api/Objects/ModalMessageFormatsMode';
import ModalValidationPatternsTypes from '../../../Components/Modals/Billing-api/Objects/ModalValidationPatternsTypes';
import ModalPaymentOptionClassifier from '../../../Components/Modals/Billing-api/Objects/ModalPaymentOptionClassifier';
import ModalBillingCycleClassifier from '../../../Components/Modals/Billing-api/Objects/ModalBillingCycleClassifier';
import ModalAssetTransactionClassifier from '../../../Components/Modals/Billing-api/Objects/ModalAssetTransactionClassifier';
import ModalAccountActivityClassifier from '../../../Components/Modals/Billing-api/Objects/ModalAccountActivityClassifier';
import ModalPaymentPlanClassifier from '../../../Components/Modals/Billing-api/Objects/ModalPaymentPlanClassifier';
import ModalSubscriptionAdjustmentClassifier from '../../../Components/Modals/Billing-api/Objects/ModalSubscriptionAdjustmentClassifier';
import ModalPaymentPlanStatus from '../../../Components/Modals/Billing-api/Objects/ModalPaymentPlanStatus';


export default class Objects extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showmodal: "",

        }
    }


    async componentDidMount() {

      
    }
    seleccionarModal(titulomodal){
        this.setState({ showmodal: titulomodal})
    }

    cambiarOpcionContenido(option){
        setTimeout(() => {
            this.props.cambiarOpcionContenido(option);
            
        }, 200);
    }

    renderizarContenidos(){
        switch (this.props.opcioncontenido) {
            case "Allocation":
                return(
                    <div className="row">
                        
                        <div id="">
                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h1>Allocation</h1>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-2 col-sm-2"><strong>Type: &nbsp;</strong></div>
                                <div class="col-xs-2 col-sm-2"><strong>Abstract: &nbsp;</strong>no</div>
                                <div class="col-xs-2 col-sm-2"><strong>Status: &nbsp;</strong>Active</div>
                                <div class="col-xs-6 col-sm-6"><strong>Java Class: &nbsp;</strong><a href="/objects/1647">BalancingSummary</a></div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            Represents allocations of funds across transactions (for example between payments and invoices).                </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h2>Available Fields:</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table my-table">
                                            <colgroup style={{width: "3%"}} ></colgroup>
                                            <colgroup style={{width: "15%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "40%"}}></colgroup>
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Pattern</th>
                                                <th>Format</th>
                                                <th>Mode</th>
                                                <th>Usage</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                                <tr>
                                                <td>
                                                                                1                        </td>
                                                <td>sourceId</td>
                                                <td>Long<a href="/objects/1647#fldSource_FK" style={{float: "right"}} ><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                                                <td>
                                                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                                                </td>
                                                <td></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats")}  type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                                        <td>Reference to a Transaction that the Allocation is associated with.<div class="notatabla"><strong>Note:</strong> The balance of this source transaction was used to offset the balance of a target transaction. <br/> <br/> <strong><em>Typical example:</em></strong> source transaction is a payment, target transaction is an invoice.</div></td>
                                            </tr>
                                                                <tr>
                                                <td>
                                                                                2                        </td>
                                                <td>targetId</td>
                                                <td>Long<a href="/objects/1647#fldTarget_FK" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                                                <td>
                                                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                                                </td>
                                                <td></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                                        <td>Reference to a Transaction that the Allocation is associated with.<div class="notatabla"><strong>Note:</strong> The balance of this target transaction was offset by the source transaction. <br/> <br/> <strong><em>Typical example:</em></strong> source transaction is a payment, target transaction is an invoice.</div></td>
                                            </tr>
                                                                <tr>
                                                <td>
                                                                                3                        </td>
                                                <td>createDate</td>
                                                <td>Datetime<a href="/objects/1647#fldCreate_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                                                <td>
                                                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                                                </td>
                                                <td></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                                        <td>Date when the Allocation was created.<div class="notatabla"><strong>Note:</strong> Date format:  <strong><em>yyyy-MM-dd</em></strong>.</div></td>
                                            </tr>
                                                                <tr>
                                                <td>
                                                                                4                        </td>
                                                <td>isActive</td>
                                                <td>Boolean<a href="/objects/1647#fldIs_Active" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                                                <td>
                                                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                                                </td>
                                                <td></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                                        <td>Status of the Allocation.<div class="notatabla"><strong>Note:</strong> Values: true (active) or false (inactive).</div></td>
                                            </tr>
                                                                <tr>
                                                <td>
                                                                                5                        </td>
                                                <td>amount</td>
                                                <td>Integer<a href="/objects/1647#fldAmount" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                                                <td>
                                                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                                                </td>
                                                <td></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                                        <td>Amount (in cents) charged during the transaction processing.</td>
                                            </tr>
                                                                <tr>
                                                <td>
                                                                                6                        </td>
                                                <td>targetTransactionType</td>
                                                <td>String<a href="/objects/1647#fldTarget_FK" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                                                <td>
                                                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                                                </td>
                                                <td></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                                        <td>Type of a target transaction that the Allocation is associated with.<div class="notatabla"><strong>Note:</strong> See <em>targetId</em> for detail.</div></td>
                                            </tr>
                                                                <tr>
                                                <td>
                                                                                7                        </td>
                                                <td>causeTransactionId</td>
                                                <td>Long<a href="/objects/1647#fldCause_Transaction_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                                                <td>
                                                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                                                </td>
                                                <td></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                                        <td>Reference to a Transaction that the Allocation is affected by.<div class="notatabla"><strong>Note:</strong> Sometimes, the allocation may change due to business circumstances. This field indicates which transaction caused the changes.</div></td>
                                            </tr>
                                                                <tr>
                                                <td>
                                                                                8                        </td>
                                                <td>causeTransactionType</td>
                                                <td>String(2)<a href="/objects/1647#fldCause_Transaction_Cl" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                                                <td>
                                                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                                                </td>
                                                <td></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                                        <td>Type of a transaction that the Allocation is affected by.<div class="notatabla"><strong>Note:</strong> See <em>causeTransactionId</em> for detail.</div></td>
                                            </tr>
                                                                <tr>
                                                <td>
                                                    <a id="ident" name="ident"></a>                            9                        </td>
                                                <td>id</td>
                                                <td>Long<a href="/objects/1647#fldId" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                                                <td>
                                                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                                                </td>
                                                <td></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                                                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                                        <td>Unique Allocation ID generated by the system.</td>
                                            </tr>
                                                                </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            
                                <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h3 class="panel-title">XML Sample:</h3>
                                        </div>
                                        <div class="panel-body">
                                            <pre class="xml" style={{fontFamily: "monospace"}}>
                                                <span style={{color: "#009900"}} >
                                                    <span style={{color: "#009900", fontWeight: "bold"}} >&lt;allocation </span>
                                                    <span style={{color: "#000066"}}>sourceId</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>targetId</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>createDate</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>isActive</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>amount</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>targetTransactionType</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>causeTransactionId</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>causeTransactionType</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>id</span>=<span style={{color: "#ff0000"}}>""</span> <span style={{color: "#009900", fontWeight: "bold"}}>/&gt;</span></span></pre>                </div>
                                    </div>
                                </div>
                            </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">JSON Sample:</h3>
                    </div>
                    <div class="panel-body">
                        <pre class="javascript" style={{fontFamily: "monospace"}}><span style={{color: "#009900"}}>{"{"}</span><br/>
    <span style={{color: "#3366CC"}}>"sourceId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"targetId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"createDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"isActive"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"amount"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"targetTransactionType"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"causeTransactionId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"causeTransactionType"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"id"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><br/>
    <span style={{color: "#009900"}}>{"}"}</span></pre>               
    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">XURL Sample:</h3>
                    </div>
                    <div class="panel-body">
                        ?sourceId=&amp;targetId=&amp;createDate=&amp;isActive=&amp;amount=&amp;targetTransactionType=&amp;causeTransactionId=&amp;causeTransactionType=&amp;id=&amp;<hr/><strong>sourceId</strong>=<em></em><br/><strong>targetId</strong>=<em></em><br/><strong>createDate</strong>=<em></em><br/><strong>isActive</strong>=<em></em><br/><strong>amount</strong>=<em></em><br/><strong>targetTransactionType</strong>=<em></em><br/><strong>causeTransactionId</strong>=<em></em><br/><strong>causeTransactionType</strong>=<em></em><br/><strong>id</strong>=<em></em><br/>                </div>
                </div>
            </div>
        </div>

        <div id="test-popup" class="popup-container white-popup mfp-hide">
        </div>




            
            
            
            
        </div>
                    </div>
    
                )
 
            case "Customer":
                return(
                    <div className="row">
                    <div id="">
                    <div class="row">
                        <a id="billing-anchor" name="billing-anchor"></a>
                        <div class="col-xs-12 col-sm-12">
                            <h1>Customer</h1>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-2 col-sm-2"><strong>Type: &nbsp;</strong></div>
                        <div class="col-xs-2 col-sm-2"><strong>Abstract: &nbsp;</strong>no</div>
                        <div class="col-xs-2 col-sm-2"><strong>Status: &nbsp;</strong>Active</div>
                        <div class="col-xs-6 col-sm-6"><strong>Java Class: &nbsp;</strong><a href="/objects/1765">CustomerAccount</a></div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12">
                            <div class="panel panel-default">
                                <div class="panel-body">
                                Represents information about the customer.                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <a id="billing-anchor" name="billing-anchor"></a>
                        <div class="col-xs-12 col-sm-12">
                            <h2>Available Fields:</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12">
                            <div class="table-responsive">
                                <table class="table my-table">
                                    <colgroup style={{width: "3%"}} ></colgroup>
                                    <colgroup style={{width: "15%"}}></colgroup>
                                    <colgroup style={{width: "10%"}}></colgroup>
                                    <colgroup style={{width: "10%"}}></colgroup>
                                    <colgroup style={{width: "10%"}}></colgroup>
                                    <colgroup style={{width: "5%"}}></colgroup>
                                    <colgroup style={{width: "5%"}}></colgroup>
                                    <colgroup style={{width: "40%"}}></colgroup>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Pattern</th>
                                        <th>Format</th>
                                        <th>Mode</th>
                                        <th>Usage</th>
                                        <th>Description</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                    <td>
                                                    1                        </td>
                    <td>code</td>
                    <td>String(60)<a href="/objects/1764#fldReference_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>CODE</span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Unique Customer ID supplied by external/user's system.<div class="notatabla"><strong>Note:</strong> Optional secondary identifier. Typically this field is a reference to an ID in your own system to cross-reference it within our system. If not provided, the system will use the object 'id' generated at the time of object creation.</div> <div  class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/terminology/reference_field_type">Reference Field Type</a>.</em> </div></td>
                </tr>
                                    <tr>
                    <td>
                                                    2                        </td>
                    <td>accountId</td>
                    <td>Integer<a href="/objects/1764#fldMerchant_Account_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                            <td>Merchant account associated with Customer.<div class="notatabla"><strong>Note:</strong> If your API user is associated to more than one merchant account or group of merchant accounts, you can specify the merchant account ID for the ~find operation to reduce the data set in the API response. <br/><br/> Your API credentials may be associated with more than one accountId.</div><br/></td>
                </tr>
                                    <tr>
                    <td>
                                                    3                        </td>
                    <td>firstName</td>
                    <td>String(125)<a href="/objects/1764#fldFirst_Name" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NAME</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                            <td>First name of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                                                    4                        </td>
                    <td>lastName</td>
                    <td>String(125)<a href="/objects/1764#fldLast_Name" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NAME</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                            <td>Last name of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                                                    5                        </td>
                    <td>isActive</td>
                    <td>Boolean<a href="/objects/1765#fldIs_Active" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Status of the Customer.<div class="notatabla"><strong>Note:</strong> Values: true (active) or false (inactive).</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    6                        </td>
                    <td>city</td>
                    <td>String(50)<a href="/objects/1882#fldCity" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>ALPHANUMERIC_EXTENDED</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>City of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                                                    7                        </td>
                    <td>countryCode</td>
                    <td>String(2)<a href="/objects/1882#fldCountry_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>ALPHA</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Country code of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                                                    8                        </td>
                    <td>state</td>
                    <td>String(3)<a href="/objects/1882#fldState" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>State of the Customer.<div class="notatabla"><strong>Note:</strong> State value format is subject to the associated country code in the Customer object.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    9                        </td>
                    <td>zipCode</td>
                    <td>String(10)<a href="/objects/1882#fldZIP_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>ZIP/postal code of the Customer.<div class="notatabla"><strong>Note:</strong> ZipCode value format is subject to the associated country code in the Customer object.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    10                        </td>
                    <td>phone</td>
                    <td>String</td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NUMERIC</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                            <td>Phone number of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                                                    11                        </td>
                    <td>street1</td>
                    <td>String(128)<a href="/objects/1882#fldStreet_1" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>STREET</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Street address of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                                                    12                        </td>
                    <td>street2</td>
                    <td>String(128)<a href="/objects/1882#fldStreet_2" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>STREET</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Secondary Street address of the Customer.<div class="notatabla"><strong>Note:</strong> Additional street data such as appartment or suite number.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    13                        </td>
                    <td>organizationName</td>
                    <td>String(255)<a href="/objects/1764#fldOrganization_Name" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NAME_BUSINESS</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Name of the associated organization for the Customer.<div class="notatabla"><strong>Note:</strong> OrganizationName is a user provided freeform field and does not represent any other data structure or object.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    14                        </td>
                    <td>email</td>
                    <td>String(100)<a href="/objects/1884#fldEmail" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>EMAIL</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Email address of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                                                    15                        </td>
                    <td>holderName</td>
                    <td>String(500)<a href="/objects/1764#fldHolder_Name" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Combined values of the firstName and lastName in the Customer.<div class="notatabla"><strong>Note:</strong> This value is a concatenation of the firstName and lastName values provided at the time of Customer creation or modification.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    16                        </td>
                    <td>createDate</td>
                    <td>Datetime<a href="/objects/1764#fldCreate_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Date when the Customer was created.<div class="notatabla"><strong>Note:</strong> Date format:  <strong><em>yyyy-MM-dd</em></strong>.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    17                        </td>
                    <td>address</td>
                    <td>String(321)<a href="/objects/1882#fldAddress" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Combined address field of the Customer.<div class="notatabla"><strong>Note:</strong> The value is a comma separated concatenation of the street1, street2, city, state, zipCode, countryCode field values.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    18                        </td>
                    <td>balance</td>
                    <td>Integer</td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Current balance of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                        <a id="ident" name="ident"></a>                            19                        </td>
                    <td>id</td>
                    <td>Long<a href="/objects/1765#fldId" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Unique Customer ID generated by the system. <div  class="infotabla"> <em>For more information see term: <a href="https://unipaygateway.info/terminology/customer">Customer</a>.</em> </div> </td>
                </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    
                        <div class="row">
                        <div class="col-xs-12 col-sm-12">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h3 class="panel-title">XML Sample:</h3>
                                </div>
                                <div class="panel-body">
                                    <pre class="xml" style={{fontFamily: "monospace"}}>
                                        <span style={{color: "#009900"}} >
                                        <span style={{color: "#009900", fontWeight: "bold"}} >&lt;customer </span><br/>
                                            <div className='ps-3'>
                                                <span style={{color: "#000066"}}>code</span>=<span style={{color: "#ff0000"}}>""</span><br/>
                                                <span style={{color: "#000066"}}>accountId</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>firstName</span>=<span style={{color: "#ff0000"}}>"John Smith"</span>  <br/>
                                                <span style={{color: "#000066"}}>lastName</span>=<span style={{color: "#ff0000"}}>"John Smith"</span>  <br/>
                                                <span style={{color: "#000066"}}>isActive</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                <span style={{color: "#000066"}}>city</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                <span style={{color: "#000066"}}>countryCode</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                <span style={{color: "#000066"}}>state</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                <span style={{color: "#000066"}}>zipCode</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>phone</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>street1</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>street2</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>organizationName</span>=<span style={{color: "#ff0000"}}>"UnitedThinkers"</span> <br/>
                                                <span style={{color: "#000066"}}>email</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>holderName</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>createDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>address</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>balance</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>id</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                            </div>
                                            <span style={{color: "#009900", fontWeight: "bold"}}>/&gt;</span>
                                        </span>
                                    </pre>                
                                </div>
                            </div>
                        </div>
                    </div>

    <div class="row">
    <div class="col-xs-12 col-sm-12">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">JSON Sample:</h3>
            </div>
            <div class="panel-body">
                <pre class="javascript" style={{fontFamily: "monospace"}}><span style={{color: "#009900"}}>{"{"}</span>
                    <div className='ps-3'>
                        <span style={{color: "#3366CC"}}>"code"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"accountId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"firstName"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>"John Smith"</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"lastName"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>"John Smith"</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"isActive"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"city"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"countryCode"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"state"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"zipCode"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"phone"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"street1"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"street2"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"organizationName"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>"UnitedThinkers"</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"email"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"holderName"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"createDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"address"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"balance"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"id"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><br/>
                    </div>
                    <span style={{color: "#009900"}}>{"}"}</span>
                </pre>               
    </div>
        </div>
    </div>
    </div>

    <div class="row">
    <div class="col-xs-12 col-sm-12">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">XURL Sample:</h3>
            </div>
            <div class="panel-body text-break">
            ?code=&accountId=&firstName=John+Smith&lastName=John+Smith&isActive=&city=&countryCode=&state=&zipCode=&phone=&street1=&street2=&organizationName=UnitedThinkers&email=&phone=&holderName=&createDate=&address=&balance=&id=&<hr/>
                <strong>code</strong>=<em></em><br/>
                <strong>accountId</strong>=<em></em><br/>
                <strong>firstName</strong>=<em>John Smith</em><br/>
                <strong>lastName</strong>=<em>John Smith</em><br/>
                <strong>isActive</strong>=<em></em><br/>
                <strong>city</strong>=<em></em><br/>
                <strong>countryCode</strong>=<em></em><br/>
                <strong>state</strong>=<em></em><br/>
                <strong>zipCode</strong>=<em></em><br/>               
                <strong>phone</strong>=<em></em><br/>               
                <strong>street1</strong>=<em></em><br/>               
                <strong>street2</strong>=<em></em><br/>               
                <strong>organizationName</strong>=<em>UnitedThinkers</em><br/>               
                <strong>email</strong>=<em></em><br/>               
                <strong>phone</strong>=<em></em><br/>               
                <strong>holderName</strong>=<em></em><br/>               
                <strong>createDate</strong>=<em></em><br/>               
                <strong>address</strong>=<em></em><br/>               
                <strong>balance</strong>=<em></em><br/>               
                <strong>id</strong>=<em></em><br/>               
                </div>
        </div>
    </div>
    </div>

    <div id="test-popup" class="popup-container white-popup mfp-hide">
    </div>








    </div>
                        
                    </div>

                )
 
            case "PaymentOption":
                return(
                <div className="row">
                    <div id="">
                    <div class="row">
                        <a id="billing-anchor" name="billing-anchor"></a>
                        <div class="col-xs-12 col-sm-12">
                            <h1>PaymentOption</h1>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-2 col-sm-2"><strong>Type: &nbsp;</strong></div>
                        <div class="col-xs-2 col-sm-2"><strong>Abstract: &nbsp;</strong>no</div>
                        <div class="col-xs-2 col-sm-2"><strong>Status: &nbsp;</strong>Active</div>
                        <div class="col-xs-6 col-sm-6"><strong>Java Class: &nbsp;</strong><a href="/objects/1780">PaymentOption</a></div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12">
                            <div class="panel panel-default">
                                <div class="panel-body">
                                Payment option information associated with the merchant.              </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <a id="billing-anchor" name="billing-anchor"></a>
                        <div class="col-xs-12 col-sm-12">
                            <h2>Available Fields:</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12">
                            <div class="table-responsive">
                                <table class="table my-table">
                                    <colgroup style={{width: "3%"}} ></colgroup>
                                    <colgroup style={{width: "15%"}}></colgroup>
                                    <colgroup style={{width: "10%"}}></colgroup>
                                    <colgroup style={{width: "10%"}}></colgroup>
                                    <colgroup style={{width: "10%"}}></colgroup>
                                    <colgroup style={{width: "5%"}}></colgroup>
                                    <colgroup style={{width: "5%"}}></colgroup>
                                    <colgroup style={{width: "40%"}}></colgroup>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Pattern</th>
                                        <th>Format</th>
                                        <th>Mode</th>
                                        <th>Usage</th>
                                        <th>Description</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                <tr>
                <td>
                                                1                        </td>
                <td>type</td>
                <td><span data="1839" onClick={() => this.seleccionarModal("Payment Option Classifier")} type="unibill-enum" style={{color: "#0066B3"}}>Enum</span></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Type of a Payment Option.</td>
            </tr>
                                <tr>
                <td>
                                                2                        </td>
                <td>isActive</td>
                <td>Boolean<a href="/objects/1780#fldIs_Active" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Status of the PaymentOption.<div class="notatabla"><strong>Note:</strong> Values: true (active) or false (inactive).</div></td>
            </tr>
                                <tr>
                <td>
                                                3                        </td>
                <td>csc</td>
                <td>String<a href="/objects/1780#fldCVV2" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NUMERIC</span>
                </td>
                <td></td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>CVV2 security code of the credit card used for the transaction processing.</td>
            </tr>
                                <tr>
                <td>
                                                4                        </td>
                <td>street1</td>
                <td>String(128)<a href="/objects/1780#fldStreet1" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>STREET</span>
                </td>
                <td></td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Street address of the Customer.</td>
            </tr>
                                <tr>
                <td>
                                                5                        </td>
                <td>street2</td>
                <td>String(128)<a href="/objects/1780#fldStreet2" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>STREET</span>
                </td>
                <td></td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Secondary street address of the Customer.<div class="notatabla"><strong>Note:</strong> Additional street data such as appartment or suite number.</div></td>
            </tr>
                                <tr>
                <td>
                                                6                        </td>
                <td>city</td>
                <td>String(50)<a href="/objects/1780#fldCity" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>ALPHANUMERIC_EXTENDED</span>
                </td>
                <td></td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>City of the Customer.</td>
            </tr>
                                <tr>
                <td>
                                                7                        </td>
                <td>state</td>
                <td>String(3)<a href="/objects/1780#fldState" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>State of the Customer.<div class="notatabla"><strong>Note:</strong> State value format is subject to the associated country code in the Customer object.</div></td>
            </tr>
                                <tr>
                <td>
                                                8                        </td>
                <td>zipCode</td>
                <td>String(15)<a href="/objects/1780#fldZIP_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>ZIP/postal code of the Customer.<div class="notatabla"><strong>Note:</strong> ZipCode value format is subject to the associated country code in the Customer object.</div></td>
            </tr>
                                <tr>
                <td>
                                                9                        </td>
                <td>customerId</td>
                <td>Long<a href="/objects/1780#fldCustomer_Account_FK" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Identifier of a Customer that a PaymentOption is associated with.</td>
            </tr>
                                <tr>
                <td>
                                                10                        </td>
                <td>code</td>
                <td>String(60)<a href="/objects/1780#fldReference_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>CODE</span>
                </td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Unique PaymentOption ID supplied by external/user's system.<div class="notatabla"><strong>Note:</strong> Optional secondary identifier. Typically this field is a reference to an ID in your own system to cross-reference it within our system. If not provided, the system will use the object 'id' generated at the time of object creation.</div> <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/terminology/reference_field_type">Reference Field Type</a>.</em> </div></td>
            </tr>
                                <tr>
                <td>
                                                11                        </td>
                <td>accountId</td>
                <td>Integer<a href="/objects/1780#fldMerchant_Account_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Merchant account associated with PaymentOption.<div class="notatabla"><strong>Note:</strong> If your API user is associated to more than one merchant account or group of merchant accounts, you can specify the merchant account ID for the ~find operation to reduce the data set in the API response. <br/> <br/> Your API credentials may be associated with more than one accountId.</div></td>
            </tr>
                                <tr>
                <td>
                                                12                        </td>
                <td>cardType</td>
                <td>String<a href="/objects/1780#fldCard_Type" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Type of the card associated with payment option.</td>
            </tr>
                                <tr>
                <td>
                                                13                        </td>
                <td>countryCode</td>
                <td>String(3)<a href="/objects/1780#fldCountry_CL" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>ALPHA</span>
                </td>
                <td></td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Country code of the Customer.</td>
            </tr>
                                <tr>
                <td>
                                                14                        </td>
                <td>token</td>
                <td>String(50)<a href="/objects/1780#fldToken" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Unique random characters generated by the system for identifying and securely transmitting sensitive data.</td>
            </tr>
                                <tr>
                <td>
                                                15                        </td>
                <td>accountAccessory</td>
                <td>String(80)<a href="/objects/1780#fldAccessory" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NUMERIC</span>
                </td>
                <td></td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                        <td>Card expiration date or bank routing number.</td>
            </tr>
                                <tr>
                <td>
                                                16                        </td>
                <td>holderName</td>
                <td>String(150)<a href="/objects/1780#fldHolder_Name" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NAME</span>
                </td>
                <td></td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                        <td>Combined values fo the firsetName and lastName in the Customer.</td>
            </tr>
                                <tr>
                <td>
                                                17                        </td>
                <td>accountNumber</td>
                <td>String(80)<a href="/objects/1780#fldNumber" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                        <td>Number of a payment card or bank account.</td>
            </tr>
                                <tr>
                <td>
                                                18                        </td>
                <td>address</td>
                <td>String<a href="/objects/1780#fldAddress" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Combined address field of the customer.<div class="notatabla"><strong>Note:</strong> The value is a comma separated concatenation of the street1, street2, city, state, zipCode, countryCode field values.</div></td>
            </tr>
                                <tr>
                <td>
                                                19                        </td>
                <td>createDate</td>
                <td>Datetime<a href="/objects/1780#fldCreate_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Date when the PaymentOption was created.<div class="notatabla"><strong>Note:</strong> In API request, the date must be submitted in <strong><em>yyyy-MM-dd</em></strong> format. In API response, the date is returned in <strong><em>yyyy-MM-dd HH:mm:ss</em></strong> format.</div></td>
            </tr>
                                <tr>
                <td>
                    <a id="ident" name="ident"></a>                            20                        </td>
                <td>id</td>
                <td>Long<a href="/objects/1780#fldId" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Unique PaymentOption ID generated by the system. <div class="infotabla"> <em>For more information see term: <a href="https://unipaygateway.info/terminology/payment_option">Payment Option</a>.</em> </div></td>
            </tr>
                                <tr>
                <td>
                                                21                        </td>
                <td>tokenCode</td>
                <td>String(512)<a href="/objects/1780#fldToken_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                <td>
                    <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                </td>
                <td></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                        <td>Code of the token associated with the particular payment option.</td>
            </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    
                        <div class="row">
                        <div class="col-xs-12 col-sm-12">
                            <div class="panel panel-default">
                                <div class="panel-heading" >
                                    <h3 class="panel-title">XML Sample:</h3>
                                </div>
                                <div class="panel-body">
                                    <pre class="xml" style={{fontFamily: "monospace"}}>
                                        <span style={{color: "#009900"}} >
                                        <span style={{color: "#009900", fontWeight: "bold"}} >&lt;payment-option </span><br/>
                                            <div className='ps-3'>
                                                <span style={{color: "#000066"}}>type</span>=<span style={{color: "#ff0000"}}>""</span><br/>
                                                <span style={{color: "#000066"}}>isActive</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                <span style={{color: "#000066"}}>csc</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                <span style={{color: "#000066"}}>street1</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>street2</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>city</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                <span style={{color: "#000066"}}>state</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                <span style={{color: "#000066"}}>zipCode</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>customerId</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>code</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>accountId</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>cardType</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>countryCode</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>token</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>accountAccessory</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>holderName</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>accountNumber</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>address</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>createDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>id</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>tokenCode</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                            </div>
                                            <span style={{color: "#009900", fontWeight: "bold"}}>/&gt;</span>
                                        </span>
                                    </pre>                
                                </div>
                            </div>
                        </div>
                    </div>

<div class="row">
    <div class="col-xs-12 col-sm-12">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">JSON Sample:</h3>
            </div>
            <div class="panel-body">
                <pre class="javascript" style={{fontFamily: "monospace"}}><span style={{color: "#009900"}}>{"{"}</span>
                    <div className='ps-3'>
                        <span style={{color: "#3366CC"}}>"type"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"isActive"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"csc"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"street1"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"street2"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"city"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"state"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"zipCode"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"customerId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"code"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"accountId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"cardType"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"countryCode"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"token"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"accountAccessory"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"holderName"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"accountNumber"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"address"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"createDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"id"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                        <span style={{color: "#3366CC"}}>"tokenCode"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><br/>
                    </div>
                    <span style={{color: "#009900"}}>{"}"}</span>
                </pre>               
</div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-sm-12">
        <div class="panel panel-default">
            <div class="panel-heading">
                <h3 class="panel-title">XURL Sample:</h3>
            </div>
            <div class="panel-body text-break">
            ?type=&isActive=&csc=&street1=&street2=&city=&state=&zipCode=&customerId=&code=&accountId=&cardType=&countryCode=&token=&accountAccessory=&holderName=&accountNumber=&address=&createDate=&id=&tokenCode=&<hr/>
                <strong>type</strong>=<em></em><br/>
                <strong>isActive</strong>=<em></em><br/>
                <strong>csc</strong>=<em></em><br/>
                <strong>street1</strong>=<em></em><br/>
                <strong>street2</strong>=<em></em><br/>
                <strong>city</strong>=<em></em><br/>
                <strong>state</strong>=<em></em><br/>
                <strong>zipCode</strong>=<em></em><br/>               
                <strong>customerId</strong>=<em></em><br/>               
                <strong>code</strong>=<em></em><br/>               
                <strong>accountId</strong>=<em></em><br/>                              
                <strong>cardType</strong>=<em></em><br/>               
                <strong>countryCode</strong>=<em></em><br/>               
                <strong>token</strong>=<em></em><br/>               
                <strong>accountAccessory</strong>=<em></em><br/>               
                <strong>holderName</strong>=<em></em><br/>               
                <strong>accountNumber</strong>=<em></em><br/>               
                <strong>address</strong>=<em></em><br/>               
                <strong>createDate</strong>=<em></em><br/>               
                <strong>id</strong>=<em></em><br/>               
                <strong>tokenCode</strong>=<em></em><br/>               
            </div>
        </div>
    </div>
</div>

<div id="test-popup" class="popup-container white-popup mfp-hide">
</div>




    
    
    
    
</div>
                    
                </div>

                )
 
            case "Plan":
                return(
                    <div className="row">
                        
                        <div id="">
                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h1>Plan</h1>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-2 col-sm-2"><strong>Type: &nbsp;</strong></div>
                                <div class="col-xs-2 col-sm-2"><strong>Abstract: &nbsp;</strong>no</div>
                                <div class="col-xs-2 col-sm-2"><strong>Status: &nbsp;</strong>Active</div>
                                <div class="col-xs-6 col-sm-6"><strong>Java Class: &nbsp;</strong><a href="/objects/4227">Plan</a></div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                        Represents information about the plan.               </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h2>Available Fields:</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table my-table">
                                            <colgroup style={{width: "3%"}} ></colgroup>
                                            <colgroup style={{width: "15%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "40%"}}></colgroup>
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Pattern</th>
                                                <th>Format</th>
                                                <th>Mode</th>
                                                <th>Usage</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                        <tr>
                        <td>
                                                        1                        </td>
                        <td>code</td>
                        <td>String(60)<a href="/objects/4227#fldRef_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>CODE</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Unique Plan ID supplied by external/user's system.<div class="notatabla"><strong>Note:</strong> Optional secondary identifier. Typically this field is a reference to an ID in your own system to cross-reference it within our system. If not provided, the system will use the object 'id' generated at the time of object creation.</div> <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/terminology/reference_field_type">Reference Field Type</a>.</em> </div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        2                        </td>
                        <td>accountId</td>
                        <td>Integer<a href="/objects/4227#fldMerchant_Account_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                                <td>Merchant account associated with Plan.<div class="notatabla"><strong>Note:</strong> If your API user is associated to more than one merchant account or group of merchant accounts, you can specify the merchant account ID for the ~find operation to reduce the data set in the API response. <br/> <br/> Your API credentials may be associated with more than one accountId.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        3                        </td>
                        <td>name</td>
                        <td>String(255)<a href="/objects/4227#fldName" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NAME</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                                <td>Name of the Plan.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        4                        </td>
                        <td>amount</td>
                        <td>Integer<a href="/objects/4227#fldAmount" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                                <td>Amount (in cents) of the Plan.<div class="notatabla"><strong>Note:</strong> Amount charged every time billing occurs for this plan.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        5                        </td>
                        <td>billingCycleType</td>
                        <td><span data="4228" type="unibill-enum" onClick={() => this.seleccionarModal("Billing Cycle Classifier")} style={{color: "#0066B3"}}>Enum</span></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                                <td>Type of a billing cycle used. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/objects/4228">Billing Cycle Classifier</a>.</em> </div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        6                        </td>
                        <td>isActive</td>
                        <td>Boolean<a href="/objects/4227#fldIs_Active" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                                <td>Status of the Plan.<div class="notatabla"><strong>Note:</strong> Values: true (active) or false (inactive).</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        7                        </td>
                        <td>taxAmount</td>
                        <td>Integer<a href="/objects/4227#fldTax_Amount" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Tax amount (in cents) associated with the transaction processing.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        8                        </td>
                        <td>createDate</td>
                        <td>Datetime<a href="/objects/4227#fldCreate_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Date when the Plan was created.</td>
                    </tr>
                                        <tr>
                        <td>
                            <a id="ident" name="ident"></a>                            9                        </td>
                        <td>id</td>
                        <td>Long<a href="/objects/4227#fldId" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Unique Plan ID generated by the system.</td>
                    </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            
                                <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h3 class="panel-title">XML Sample:</h3>
                                        </div>
                                        <div class="panel-body">
                                            <pre class="xml" style={{fontFamily: "monospace"}}>
                                                <span style={{color: "#009900"}} >
                                                    <span style={{color: "#009900", fontWeight: "bold"}} >&lt;plan  </span>
                                                    <span style={{color: "#000066"}}>code</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>accountId</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>name</span>=<span style={{color: "#ff0000"}}>"John Smith"</span>  <span style={{color: "#000066"}}>amount</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>billingCycleType</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>isActive</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>taxAmount</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>createDate</span>=<span style={{color: "#ff0000"}}>""</span>  <span style={{color: "#000066"}}>id</span>=<span style={{color: "#ff0000"}}>""</span> <span style={{color: "#009900", fontWeight: "bold"}}>/&gt;</span></span></pre>                </div>
                                    </div>
                                </div>
                            </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">JSON Sample:</h3>
                    </div>
                    <div class="panel-body">
                        <pre class="javascript" style={{fontFamily: "monospace"}}><span style={{color: "#009900"}}>{"{"}</span><br/>
    <span style={{color: "#3366CC"}}>"code"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"accountId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"name"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>"John Smith"</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"amount"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"billingCycleType"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"isActive"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"taxAmount"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"createDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
    <span style={{color: "#3366CC"}}>"id"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><br/>
    <span style={{color: "#009900"}}>{"}"}</span></pre>               
    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">XURL Sample:</h3>
                    </div>
                    <div class="panel-body">
                    ?code=&accountId=&name=John+Smith&amount=&billingCycleType=&isActive=&taxAmount=&createDate=&id=&<hr/>
                    <strong>code</strong>=<em></em><br/>
                    <strong>accountId</strong>=<em></em><br/>
                    <strong>name</strong>=<em>John Smith</em><br/>
                    <strong>amount</strong>=<em></em><br/>
                    <strong>billingCycleType</strong>=<em></em><br/>
                    <strong>isActive</strong>=<em></em><br/>
                    <strong>taxAmount</strong>=<em></em><br/>
                    <strong>createDate</strong>=<em></em><br/>
                    <strong>id</strong>=<em></em><br/>       
                             </div>
                </div>
            </div>
        </div>

        <div id="test-popup" class="popup-container white-popup mfp-hide">
        </div>




            
            
            
            
        </div>
                    </div>
    
                )
 
            case "ProcessingInfo":
                return(
                    <div className="row">
                        
                        <div id="">
                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h1>ProcessingInfo</h1>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-2 col-sm-2"><strong>Type: &nbsp;</strong></div>
                                <div class="col-xs-2 col-sm-2"><strong>Abstract: &nbsp;</strong>no</div>
                                <div class="col-xs-2 col-sm-2"><strong>Status: &nbsp;</strong>Active</div>
                                <div class="col-xs-6 col-sm-6"><strong>Java Class: &nbsp;</strong><a href="/objects/1760">CaptureInfo</a></div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                        Information associated with transaction processing within internal and external systems.              </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h2>Available Fields:</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table my-table">
                                            <colgroup style={{width: "3%"}} ></colgroup>
                                            <colgroup style={{width: "15%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "40%"}}></colgroup>
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Pattern</th>
                                                <th>Format</th>
                                                <th>Mode</th>
                                                <th>Usage</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                    <tr>
                    <td>
                                                    1                        </td>
                    <td>accountAccessory</td>
                    <td>String<a href="/objects/1760#fldAccessory" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Card expiration date or bank routing number.<div class="notatabla"><strong>Note:</strong> This field is required even if it is used with a token.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    2                        </td>
                    <td>accountNumber</td>
                    <td>String<a href="/objects/1760#fldAccount_Number" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Number of a payment card or bank account.</td>
                </tr>
                                    <tr>
                    <td>
                                                    3                        </td>
                    <td>address</td>
                    <td>String<a href="/objects/1760#fldAddress" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Combined address field of the Customer object.<div class="notatabla"><strong>Note:</strong> The value is a comma separated concatenation of the street1, street2, city, state, zipCode, countryCode field values.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    4                        </td>
                    <td>approvalCode</td>
                    <td>String(60)<a href="/objects/1760#fldApproval_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Code of the approval status of the transaction provided by the payment provider.</td>
                </tr>
                                    <tr>
                    <td>
                                                    5                        </td>
                    <td>city</td>
                    <td>String(50)<a href="/objects/1760#fldCity" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>City of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                                                    6                        </td>
                    <td>createDate</td>
                    <td>Datetime<a href="/objects/1760#fldCreate_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Date when the ProcessingInfo was created.<div class="notatabla"><strong>Note:</strong> In API request, the date must be submitted in <strong><em>yyyy-MM-dd</em></strong> format. In API response, the date is returned in <strong><em>yyyy-MM-dd HH:mm:ss</em></strong> format.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    7                        </td>
                    <td>email</td>
                    <td>String(100)<a href="/objects/1760#fldEmail" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Email address of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                                                    8                        </td>
                    <td>gatewayTransactionId</td>
                    <td>String(20)<a href="/objects/1760#fldReference_Number" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Identifier of a Transaction (Processing) that the current transaction is associated with.</td>
                </tr>
                                    <tr>
                    <td>
                                                    9                        </td>
                    <td>holderName</td>
                    <td>String(150)<a href="/objects/1760#fldHolder_Name" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Combined values fo the firsetName and lastName in the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                        <a id="ident" name="ident"></a>                            10                        </td>
                    <td>id</td>
                    <td>Long<a href="/objects/1760#fldId" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Unique ProcesingInfo ID generated by the system.</td>
                </tr>
                                    <tr>
                    <td>
                                                    11                        </td>
                    <td>phone</td>
                    <td>String(20)<a href="/objects/1760#fldPhone" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Phone number of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                                                    12                        </td>
                    <td>responseCode</td>
                    <td>String(3)<a href="/objects/1760#fldResponse_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Code providing an explanation of the outcome of the request. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/codes/your_gateway">UniPay Codes &amp; Code Mapping</a>.</em> </div></td>
                </tr>
                                    <tr>
                    <td>
                                                    13                        </td>
                    <td>responseDate</td>
                    <td>Datetime<a href="/objects/1760#fldResponse_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Date  when the response to the transaction was received.</td>
                </tr>
                                    <tr>
                    <td>
                                                    14                        </td>
                    <td>responseMessage</td>
                    <td>String(255)<a href="/objects/1760#fldResponse_Message" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Response details associated with a processing request, generated by the gateway.</td>
                </tr>
                                    <tr>
                    <td>
                                                    15                        </td>
                    <td>state</td>
                    <td>String(3)<a href="/objects/1760#fldState" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>State of the Customer.<div class="notatabla"><strong>Note:</strong> State value format is subject to the associated country code in the Customer object.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    16                        </td>
                    <td>street</td>
                    <td>String(128)<a href="/objects/1760#fldStreet" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Street address of the Customer.</td>
                </tr>
                                    <tr>
                    <td>
                                                    17                        </td>
                    <td>token</td>
                    <td>String(512)<a href="/objects/1760#fldToken_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Unique random characters generated by the system for identifying and securely transmitting sensitive data.</td>
                </tr>
                                    <tr>
                    <td>
                                                    18                        </td>
                    <td>zipCode</td>
                    <td>String(15)<a href="/objects/1760#fldZIP_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>ZIP/postal code of the Customer.<div class="notatabla"><strong>Note:</strong> ZipCode value format is subject to the associated country code in the Customer object.</div></td>
                </tr>
                                    </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            
                                <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h3 class="panel-title">XML Sample:</h3>
                                        </div>
                                        <div class="panel-body">
                                        <pre class="xml" style={{fontFamily: "monospace"}}>
                                            <span style={{color: "#009900"}} >
                                            <span style={{color: "#009900", fontWeight: "bold"}} >&lt;processing-info</span><br/>
                                                <div className='ps-3'>
                                                    <span style={{color: "#000066"}}>accountAccessory</span>=<span style={{color: "#ff0000"}}>""</span><br/>
                                                    <span style={{color: "#000066"}}>accountNumber</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>address</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>approvalCode</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>city</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>createDate</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>email</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>gatewayTransactionId</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>holderName</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>id</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>phone</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>responseCode</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>responseDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>responseMessage</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>state</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>street</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>token</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>zipCode</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                </div>
                                                <span style={{color: "#009900", fontWeight: "bold"}}>/&gt;</span>
                                            </span>
                                        </pre>                
                                        </div>
                                    </div>
                                </div>
                            </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">JSON Sample:</h3>
                    </div>
                    <div class="panel-body">
                        <pre class="javascript" style={{fontFamily: "monospace"}}><span style={{color: "#009900"}}>{"{"}</span><br/>
                        <div className='ps-3'>
                    <span style={{color: "#3366CC"}}>"accountAccessory"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"accountNumber"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"address"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"approvalCode"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"city"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"createDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"email"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"gatewayTransactionId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"holderName"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"id"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"phone"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"responseCode"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"responseDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"responseMessage"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"state"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"street"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"token"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"zipCode"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><br/>
                    
                </div>
    <span style={{color: "#009900"}}>{"}"}</span></pre>               
    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">XURL Sample:</h3>
                    </div>
                    <div class="panel-body">
                    ?accountAccessory=&accountNumber=&address=&approvalCode=&city=&createDate=&email=&gatewayTransactionId=&holderName=&id=&phone=&responseCode=&responseDate=&responseMessage=&state=&street=&token=&zipCode=&<hr/>
                    <strong>accountAccessory</strong>=<em></em><br/>
                    <strong>accountNumber</strong>=<em></em><br/>
                    <strong>address</strong>=<em></em><br/>
                    <strong>approvalCode</strong>=<em></em><br/>
                    <strong>city</strong>=<em></em><br/>
                    <strong>createDate</strong>=<em></em><br/>
                    <strong>email</strong>=<em></em><br/>
                    <strong>gatewayTransactionId</strong>=<em></em><br/>       
                    <strong>holderName</strong>=<em></em><br/>       
                    <strong>id</strong>=<em></em><br/>       
                    <strong>phone</strong>=<em></em><br/>       
                    <strong>responseCode</strong>=<em></em><br/>       
                    <strong>responseDate</strong>=<em></em><br/>       
                    <strong>responseMessage</strong>=<em></em><br/>       
                    <strong>state</strong>=<em></em><br/>       
                    <strong>street</strong>=<em></em><br/>       
                    <strong>token</strong>=<em></em><br/>       
                    <strong>zipCode</strong>=<em></em><br/>       
                                </div>
                </div>
            </div>
        </div>

        <div id="test-popup" class="popup-container white-popup mfp-hide">
        </div>
        </div>
                    </div>
    
                )
            
            case "ProcessingRequest":
                return(
                    <div className="row">
                        
                        <div id="">
                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h1>ProcessingRequest</h1>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-2 col-sm-2"><strong>Type: &nbsp;</strong></div>
                                <div class="col-xs-2 col-sm-2"><strong>Abstract: &nbsp;</strong>no</div>
                                <div class="col-xs-2 col-sm-2"><strong>Status: &nbsp;</strong>Active</div>
                                <div class="col-xs-6 col-sm-6"><strong>Java Class: &nbsp;</strong><a href="/objects/1644">AssetTransaction</a></div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                        Represents information included in processing request sent to a processor.              </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h2>Available Fields:</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table my-table">
                                            <colgroup style={{width: "3%"}} ></colgroup>
                                            <colgroup style={{width: "15%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "40%"}}></colgroup>
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Pattern</th>
                                                <th>Format</th>
                                                <th>Mode</th>
                                                <th>Usage</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                        <tr>
                        <td>
                                                        1                        </td>
                        <td>accountData</td>
                        <td>String<a href="/objects/1760#fldTrack_Data" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Track/EMV data or manually keyed number of a payment card.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        2                        </td>
                        <td>accountAccessory</td>
                        <td>String<a href="/objects/1760#fldAccessory" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NUMERIC</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Card expiration date or bank routing number.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        3                        </td>
                        <td>accountNumber</td>
                        <td>String<a href="/objects/1760#fldAccount_Number" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Number of a payment card or bank account. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/processing-api/realtime/operations#conditions_Account_Data_is_used">Conditions determined by account data</a>.</em> </div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        4                        </td>
                        <td>token</td>
                        <td>String(512)<a href="/objects/1760#fldToken_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>TOKEN</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Unique random characters generated by the system for identifying and securely transmitting sensitive data.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        5                        </td>
                        <td>csc</td>
                        <td>String<a href="/objects/1760#fldCVV2" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NUMERIC</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>CVV2 security code of the credit card used for the transaction processing.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        6                        </td>
                        <td>street</td>
                        <td>String(128)<a href="/objects/1760#fldStreet" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>STREET</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Street address of the Customer.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        7                        </td>
                        <td>city</td>
                        <td>String(50)<a href="/objects/1760#fldCity" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>ALPHANUMERIC_EXTENDED</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>City of the Customer.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        8                        </td>
                        <td>state</td>
                        <td>String(3)<a href="/objects/1760#fldState" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>State of the Customer.<div class="notatabla"><strong>Note:</strong> State value format is subject to the associated country code in the Customer object.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        9                        </td>
                        <td>zipCode</td>
                        <td>String(15)<a href="/objects/1760#fldZIP_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>ZIP/postal code of the Customer.<div class="notatabla"><strong>Note:</strong> ZipCode value format is subject to the associated country code in the Customer object.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        10                        </td>
                        <td>phone</td>
                        <td>String(20)<a href="/objects/1760#fldPhone" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NUMERIC</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Phone number of the Customer.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        11                        </td>
                        <td>email</td>
                        <td>String(100)<a href="/objects/1760#fldEmail" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>EMAIL</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Email address of the Customer.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        12                        </td>
                        <td>type</td>
                        <td><span data="1794" onClick={() => this.seleccionarModal("Account Activity Classifier")} type="unibill-enum" style={{color: "#0066B3"}}>Enum</span></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Type of a ProcessingRequest. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/objects/1794">Account Activity Classifier</a>.</em> </div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        13                        </td>
                        <td>accountType</td>
                        <td><span data="1795" onClick={() => this.seleccionarModal("Asset Transaction Classifier")} type="unibill-enum" style={{color: "#0066B3"}}>Enum</span></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Type of a payment method used. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/objects/1795">Asset Transaction Classifier</a>.</em> </div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        14                        </td>
                        <td>creatorCode</td>
                        <td>String(60)<a href="/objects/1635#fldRef_Creator_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>CODE</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Identifier of a user  (provided by an integrator) initiating the request.<div class="notatabla"><strong>Note:</strong> This field typically references a user within your system whose actions resulted in this request. Defaults to your API's userName if no value is provided.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        15                        </td>
                        <td>amount</td>
                        <td>Integer<a href="/objects/1635#fldAmount" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                                <td>Amount (in cents) of the ProcessingRequest.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        16                        </td>
                        <td>note</td>
                        <td>String(255)<a href="/objects/1635#fldNote" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>TEXT</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Any additional details associated with a ProcessingRequest.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17                        </td>
                        <td>accountId</td>
                        <td>Integer<a href="/objects/1632#fldMerchant_Account_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Merchant account associated with ProcessingRequest.<div class="notatabla"><strong>Note:</strong> If your API user is associated to more than one merchant account or group of merchant accounts, you can specify the merchant account ID for the ~find operation to reduce the data set in the API response. <br/> <br/> Your API credentials may be associated with more than one accountId.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        18                        </td>
                        <td>countryCode</td>
                        <td>String(3)<a href="/objects/1760#fldCountry_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>ALPHA</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Country code of the Customer.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        19                        </td>
                        <td>code</td>
                        <td>String</td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>CODE</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Unique ProcessingRequest ID supplied by external/user's system.<div class="notatabla"><strong>Note:</strong> Optional secondary identifier. Typically this field is a reference to an ID in your own system to cross-reference it within our system. If not provided, the system will use the object 'id' generated at the time of object creation.</div> <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/terminology/reference_field_type">Reference Field Type</a>.</em> </div></td>
                    </tr>
                                        <tr>
                        <td>
                            <a id="ident" name="ident"></a>                            20                        </td>
                        <td>id</td>
                        <td>Long<a href="/objects/1644#fldId" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Unique ProcessingRequest ID generated by the system.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        21                        </td>
                        <td>feeAmount</td>
                        <td>Integer<a href="/objects/1635#fldFee_Amount" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Fee amount (in cents) associated with the transaction processing.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        22                        </td>
                        <td>holderName</td>
                        <td>String(150)<a href="/objects/1760#fldHolder_Name" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NAME</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Combined values of the firstName and lastName in the Customer.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        23                        </td>
                        <td>taxAmount</td>
                        <td>Integer<a href="/objects/1635#fldTax_Amount" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Tax amount (in cents) associated with the transaction processing.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        24                        </td>
                        <td>dueDate</td>
                        <td>Date<a href="/objects/1635#fldDue_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Date when the ProcessingRequest is expected to be completed.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        25                        </td>
                        <td>invoiceList</td>
                        <td>String(250)<a href="/objects/1635#fldInvoice_List" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NUMERIC</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>List of invoice references that are expected to be covered by this transaction.</td>
                    </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            
                                <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h3 class="panel-title">XML Sample:</h3>
                                        </div>
                                        <div class="panel-body">
                                        <pre class="xml" style={{fontFamily: "monospace"}}>
                                            <span style={{color: "#009900"}} >
                                            <span style={{color: "#009900", fontWeight: "bold"}} >&lt;processing-request</span><br/>
                                                <div className='ps-3'>
                                                    <span style={{color: "#000066"}}>accountData</span>=<span style={{color: "#ff0000"}}>""</span><br/>
                                                    <span style={{color: "#000066"}}>accountAccessory</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>accountNumber</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>token</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>csc</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>street</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>city</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>state</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>zipCode</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>phone</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>email</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>type</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>accountType</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>creatorCode</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>amount</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>note</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>accountId</span>=<span style={{color: "#ff0000"}}>"2001"</span> <br/>
                                                    <span style={{color: "#000066"}}>countryCode</span>=<span style={{color: "#ff0000"}}>"US"</span> <br/>
                                                    <span style={{color: "#000066"}}>code</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>id</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>feeAmount</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>holderName</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>taxAmount</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>dueDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>invoiceList</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                </div>
                                                <span style={{color: "#009900", fontWeight: "bold"}}>/&gt;</span>
                                            </span>
                                        </pre>                
                                        </div>
                                    </div>
                                </div>
                            </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">JSON Sample:</h3>
                    </div>
                    <div class="panel-body">
                        <pre class="javascript" style={{fontFamily: "monospace"}}><span style={{color: "#009900"}}>{"{"}</span><br/>
                        <div className='ps-3'>
                    <span style={{color: "#3366CC"}}>"accountData"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"accountAccessory"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"accountNumber"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"token"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"csc"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"street"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"city"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"state"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"zipCode"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"phone"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"email"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"type"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"accountType"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"creatorCode"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"amount"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"note"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"accountId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#CC0000"}}>2001</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"countryCode"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>"US"</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"code"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"id"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"feeAmount"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"holderName"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"taxAmount"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"dueDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"invoiceList"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><br/>
                    
                </div>
    <span style={{color: "#009900"}}>{"}"}</span></pre>               
    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">XURL Sample:</h3>
                    </div>
                    <div class="panel-body">
                    ?accountData=&accountAccessory=&accountNumber=&token=&csc=&street=&city=&state=&zipCode=&phone=&email=&type=&accountType=&creatorCode=&amount=&note=&accountId=2001&countryCode=US&code=&id=&feeAmount=&holderName=&taxAmount=&dueDate=&invoiceList=&<hr/>
                    <strong>accountData</strong>=<em></em><br/>
                    <strong>accountAccessory</strong>=<em></em><br/>
                    <strong>accountNumber</strong>=<em></em><br/>
                    <strong>token</strong>=<em></em><br/>
                    <strong>csc</strong>=<em></em><br/>
                    <strong>street</strong>=<em></em><br/>
                    <strong>city</strong>=<em></em><br/>
                    <strong>state</strong>=<em></em><br/>       
                    <strong>zipCode</strong>=<em></em><br/>       
                    <strong>phone</strong>=<em></em><br/>       
                    <strong>email</strong>=<em></em><br/>       
                    <strong>type</strong>=<em></em><br/>       
                    <strong>accountType</strong>=<em></em><br/>       
                    <strong>creatorCode</strong>=<em></em><br/>       
                    <strong>amount</strong>=<em></em><br/>       
                    <strong>note</strong>=<em></em><br/>       
                    <strong>accountId</strong>=<em>2001</em><br/>       
                    <strong>countryCode</strong>=<em>US</em><br/>       
                    <strong>code</strong>=<em></em><br/>       
                    <strong>id</strong>=<em></em><br/>       
                    <strong>feeAmount</strong>=<em></em><br/>       
                    <strong>holderName</strong>=<em></em><br/>       
                    <strong>taxAmount</strong>=<em></em><br/>       
                    <strong>dueDate</strong>=<em></em><br/>       
                    <strong>invoiceList</strong>=<em></em><br/>       
                                </div>
                </div>
            </div>
        </div>

        <div id="test-popup" class="popup-container white-popup mfp-hide">
        </div>
        </div>
                    </div>
    
                )
            case "Subscription":
                return(
                    <div className="row">
                        
                        <div id="">
                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h1>Subscription</h1>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-2 col-sm-2"><strong>Type: &nbsp;</strong></div>
                                <div class="col-xs-2 col-sm-2"><strong>Abstract: &nbsp;</strong>no</div>
                                <div class="col-xs-2 col-sm-2"><strong>Status: &nbsp;</strong>Active</div>
                                <div class="col-xs-6 col-sm-6"><strong>Java Class: &nbsp;</strong><a href="/objects/1781">PaymentPlan</a></div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                        Information about the subscription transaction.              </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h2>Available Fields:</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table my-table">
                                            <colgroup style={{width: "3%"}} ></colgroup>
                                            <colgroup style={{width: "15%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "40%"}}></colgroup>
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Pattern</th>
                                                <th>Format</th>
                                                <th>Mode</th>
                                                <th>Usage</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                    <tr>
                    <td>
                                                    1                        </td>
                    <td>accountId</td>
                    <td>Integer<a href="/objects/1781#fldMerchant_Account_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Merchant account associated with Subscription.<div class="notatabla"><strong>Note:</strong> If your API user is associated to more than one merchant account or group of merchant accounts, you can specify the merchant account ID for the ~find operation to reduce the data set in the API response. <br/> <br/> Your API credentials may be associated with more than one accountId.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    2                        </td>
                    <td>amount</td>
                    <td>Integer<a href="/objects/1781#fldAmount" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Amount (in cents) of the Subscription.<div class="notatabla"><strong>Note:</strong> Amount charged every time billing occurs for this plan.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    3                        </td>
                    <td>customerId</td>
                    <td>Long<a href="/objects/1781#fldCustomer_Account_FK" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Identifier of a Customer that a Subscription is associated with.</td>
                </tr>
                                    <tr>
                    <td>
                                                    4                        </td>
                    <td>length</td>
                    <td>Integer<a href="/objects/1781#fldLength" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Number of times that a Subscription will generate an invoice.</td>
                </tr>
                                    <tr>
                    <td>
                                                    5                        </td>
                    <td>nextBillingDate</td>
                    <td>Date<a href="/objects/1650#fldNext_Billing_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Date when the next billing occured.</td>
                </tr>
                                    <tr>
                    <td>
                                                    6                        </td>
                    <td>note</td>
                    <td>String(255)<a href="/objects/1781#fldNote" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>TEXT</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Any additional details associated with a Subscription.</td>
                </tr>
                                    <tr>
                    <td>
                                                    7                        </td>
                    <td>paymentOptionId</td>
                    <td><a style={{color: "#0066B3"}} onClick={() => this.cambiarOpcionContenido("PaymentOption")}>Reference</a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                            <td>Reference to a PaymentOption that a Subscription is associated with.</td>
                </tr>
                                    <tr>
                    <td>
                                                    8                        </td>
                    <td>type</td>
                    <td><span data="1797" onClick={() => this.seleccionarModal("Payment Plan Classifier")} type="unibill-enum" style={{color: "#0066B3"}}>Enum</span></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Type of a Subscription.<div class="notatabla"><strong>Note:</strong> If this value is set to <strong><em>F - Fixed</em></strong>, you must use the length field to specify the duration of the subscription.</div> <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/objects/1797"> Payment Plan Classifier</a>.</em> </div></td>
                </tr>
                                    <tr>
                    <td>
                                                    9                        </td>
                    <td>pendingAdjustmentType</td>
                    <td><span data="4229" onClick={() => this.seleccionarModal("Subscription Adjustment Classifier")} type="unibill-enum" style={{color: "#0066B3"}}>Enum</span></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Type of a pending adjustment for the Subscription.</td>
                </tr>
                                    <tr>
                    <td>
                                                    10                        </td>
                    <td>firstInvoicingDate</td>
                    <td>Date</td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Date when the <a href="https://unipaygateway.info/terminology/invoice">invoicing</a> will occur for the first time. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/guide_content?id=37#Invoicing">integration notes</a>.</em> </div></td>
                </tr>
                                    <tr>
                    <td>
                                                    11                        </td>
                    <td>nextInvoicingDate</td>
                    <td>Date</td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Date when the <a href="https://unipaygateway.info/terminology/invoice">invoicing</a> will occur for the next time. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/guide_content?id=37#Invoicing">integration notes</a>.</em> </div></td>
                </tr>
                                    <tr>
                    <td>
                                                    12                        </td>
                    <td>taxAmount</td>
                    <td>Integer<a href="/objects/1781#fld" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Tax amount (in cents) associated with the transaction processing.</td>
                </tr>
                                    <tr>
                    <td>
                                                    13                        </td>
                    <td>planName</td>
                    <td>String(255)<a href="/objects/4227#fldName" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Name of the plan.</td>
                </tr>
                                    <tr>
                    <td>
                                                    14                        </td>
                    <td>billingCycleType</td>
                    <td><span data="4228" onClick={() => this.seleccionarModal("Billing Cycle Classifier")} type="unibill-enum" style={{color: "#0066B3"}}>Enum</span></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Type of billing cycle used. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/objects/4228">Billing Cycle Classifier</a>.</em> </div></td>
                </tr>
                                    <tr>
                    <td>
                                                    15                        </td>
                    <td>code</td>
                    <td>String(60)<a href="/objects/1781#fldReference_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>CODE</span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Unique Subscription ID supplied by external/user's system.<div class="notatabla"><strong>Note:</strong> Optional secondary identifier. Typically this field is a reference to an ID in your own system to cross-reference it within our system. If not provided, the system will use the object 'id' generated at the time of object creation.</div> <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/terminology/reference_field_type">Reference Field Type</a>.</em> </div></td>
                </tr>
                                    <tr>
                    <td>
                                                    16                        </td>
                    <td>createDate</td>
                    <td>Datetime<a href="/objects/1781#fldCreate_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Date when the Subscription was created.<div class="notatabla"><strong>Note:</strong> Date format:  <strong><em>yyyy-MM-dd</em></strong>.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    17                        </td>
                    <td>firstBillingDate</td>
                    <td>Date<a href="/objects/1781#fldFirst_Billing_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                            <td>Date when the first billing occured. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/guide_content?id=37#Billing">integration notes</a>.</em> </div></td>
                </tr>
                                    <tr>
                    <td>
                        <a id="ident" name="ident"></a>                            18                        </td>
                    <td>id</td>
                    <td>Long<a href="/objects/1781#fldId" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Unique Subscription ID generated by the system.</td>
                </tr>
                                    <tr>
                    <td>
                                                    19                        </td>
                    <td>lastBillingDate</td>
                    <td>Date<a href="/objects/1781#fldLast_Invoicing_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Date when the last billing occured.</td>
                </tr>
                                    <tr>
                    <td>
                                                    20                        </td>
                    <td>lastUpdateDate</td>
                    <td>Datetime<a href="/objects/1781#fldLast_Update_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Date when the last update was made to the Subscription.</td>
                </tr>
                                    <tr>
                    <td>
                                                    21                        </td>
                    <td>pendingAdjustmentEffectiveDate</td>
                    <td>Date<a href="/objects/1781#fldPending_Adjustment_Effective_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Date when the pending adjustment for the Subscription become effective.</td>
                </tr>
                                    <tr>
                    <td>
                                                    22                        </td>
                    <td>pendingAdjustmentLength</td>
                    <td>Integer<a href="/objects/1781#fldPending_Adjustment_Length" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Number of times that the pending adjustment for the Subscription will generate an invoice.</td>
                </tr>
                                    <tr>
                    <td>
                                                    23                        </td>
                    <td>pendingAdjustmentNote</td>
                    <td>String(255)<a href="/objects/1781#fldPending_Adjustment_Note" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                    <td>
                        <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>TEXT</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                            <td>Any additional details associated with the pending adjustment for the Subscription.</td>
                </tr>
                                    <tr>
                    <td>
                                                    24                        </td>
                    <td>planId</td>
                    <td><a style={{color: "#0066B3"}} onClick={() => this.cambiarOpcionContenido("Plan")} >Reference</a></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Reference to a Plan that a Subscription is associated with.<div class="notatabla"><strong>Note:</strong> Reference to the Plan that the Customer is subscribing.</div></td>
                </tr>
                                    <tr>
                    <td>
                                                    25                        </td>
                    <td>status</td>
                    <td><span data="2614" onClick={() => this.seleccionarModal("Payment Plan Status")} type="unibill-enum" style={{color: "#0066B3"}}>Enum</span></td>
                    <td>
                        <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                    </td>
                    <td></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                    <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                            <td>Status of the record in the system. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/objects/2614">Payment Plan Status</a>.</em> </div></td>
                </tr>
                                    </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            
                                <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h3 class="panel-title">XML Sample:</h3>
                                        </div>
                                        <div class="panel-body">
                                        <pre class="xml" style={{fontFamily: "monospace"}}>
                                            <span style={{color: "#009900"}} >
                                            <span style={{color: "#009900", fontWeight: "bold"}} >&lt;subscription</span><br/>
                                                <div className='ps-3'>
                                                    <span style={{color: "#000066"}}>accountId</span>=<span style={{color: "#ff0000"}}>""</span><br/>
                                                    <span style={{color: "#000066"}}>amount</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>customerId</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>length</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>nextBillingDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>note</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>paymentOptionId</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>type</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>pendingAdjustmentType</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>firstInvoicingDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>nextInvoicingDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>taxAmount</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>planName</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>billingCycleType</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>code</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>createDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>firstBillingDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>id</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>lastBillingDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>lastUpdateDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>pendingAdjustmentEffectiveDate</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>pendingAdjustmentLength</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>pendingAdjustmentNote</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>planId</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>status</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                </div>
                                                <span style={{color: "#009900", fontWeight: "bold"}}>/&gt;</span>
                                            </span>
                                        </pre>                
                                        </div>
                                    </div>
                                </div>
                            </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">JSON Sample:</h3>
                    </div>
                    <div class="panel-body">
                        <pre class="javascript" style={{fontFamily: "monospace"}}><span style={{color: "#009900"}}>{"{"}</span><br/>
                        <div className='ps-3'>
                    <span style={{color: "#3366CC"}}>"accountId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"amount"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"customerId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"length"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"nextBillingDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"note"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"paymentOptionId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"type"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"pendingAdjustmentType"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"firstInvoicingDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"nextInvoicingDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"taxAmount"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"planName"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"billingCycleType"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"code"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"createDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"firstBillingDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#CC0000"}}></span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"id"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"lastBillingDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"lastUpdateDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"pendingAdjustmentEffectiveDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"pendingAdjustmentLength"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"pendingAdjustmentNote"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"planId"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"status"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><br/>
                    
                </div>
    <span style={{color: "#009900"}}>{"}"}</span></pre>               
    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">XURL Sample:</h3>
                    </div>
                    <div class="panel-body">
                    ?accountId=&amount=&customerId=&length=&nextBillingDate=&note=&paymentOptionId=&type=&pendingAdjustmentType=&firstInvoicingDate=&nextInvoicingDate=&taxAmount=&planName=&billingCycleType=&code=&createDate=&firstBillingDate=&id=&lastBillingDate=&lastUpdateDate=&pendingAdjustmentEffectiveDate=&pendingAdjustmentLength=&pendingAdjustmentNote=&planId=&status=&<hr/>
                    <strong>accountId</strong>=<em></em><br/>
                    <strong>amount</strong>=<em></em><br/>
                    <strong>customerId</strong>=<em></em><br/>
                    <strong>length</strong>=<em></em><br/>
                    <strong>nextBillingDate</strong>=<em></em><br/>
                    <strong>note</strong>=<em></em><br/>
                    <strong>paymentOptionId</strong>=<em></em><br/>
                    <strong>type</strong>=<em></em><br/>       
                    <strong>pendingAdjustmentType</strong>=<em></em><br/>       
                    <strong>firstInvoicingDate</strong>=<em></em><br/>       
                    <strong>nextInvoicingDate</strong>=<em></em><br/>       
                    <strong>taxAmount</strong>=<em></em><br/>       
                    <strong>planName</strong>=<em></em><br/>       
                    <strong>billingCycleType</strong>=<em></em><br/>       
                    <strong>code</strong>=<em></em><br/>       
                    <strong>createDate</strong>=<em></em><br/>       
                    <strong>firstBillingDate</strong>=<em></em><br/>       
                    <strong>id</strong>=<em></em><br/>       
                    <strong>code</strong>=<em></em><br/>       
                    <strong>id</strong>=<em></em><br/>       
                    <strong>lastBillingDate</strong>=<em></em><br/>       
                    <strong>lastUpdateDate</strong>=<em></em><br/>       
                    <strong>pendingAdjustmentEffectiveDate</strong>=<em></em><br/>       
                    <strong>pendingAdjustmentLength</strong>=<em></em><br/>       
                    <strong>pendingAdjustmentNote</strong>=<em></em><br/>       
                    <strong>planId</strong>=<em></em><br/>       
                    <strong>status</strong>=<em></em><br/>       
                   </div>
                </div>
            </div>
        </div>

        <div id="test-popup" class="popup-container white-popup mfp-hide">
        </div>
        </div>
                    </div>
    
                )

            case "SubscriptionAdjustment":
                return(
                    <div className="row">
                        
                        <div id="">
                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h1>SubscriptionAdjustment</h1>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-2 col-sm-2"><strong>Type: &nbsp;</strong></div>
                                <div class="col-xs-2 col-sm-2"><strong>Abstract: &nbsp;</strong>no</div>
                                <div class="col-xs-2 col-sm-2"><strong>Status: &nbsp;</strong>Active</div>
                                <div class="col-xs-6 col-sm-6"><strong>Java Class: &nbsp;</strong><a href="/objects/1641">Adjustment</a></div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                        Adjustment applied to a subscription transaction.             </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                    <h2>Available Fields:</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="table-responsive">
                                        <table class="table my-table">
                                            <colgroup style={{width: "3%"}} ></colgroup>
                                            <colgroup style={{width: "15%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "10%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "5%"}}></colgroup>
                                            <colgroup style={{width: "40%"}}></colgroup>
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Pattern</th>
                                                <th>Format</th>
                                                <th>Mode</th>
                                                <th>Usage</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                        <tr>
                        <td>
                            <a id="ident" name="ident"></a>                            1                        </td>
                        <td>id</td>
                        <td>Long<a href="/objects/1641#fldId" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Unique SubscriptionAdjustment ID generated by the system.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        2                        </td>
                        <td>effectiveDate</td>
                        <td>Date<a href="/objects/1641#fldAdjustment_Begin_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Date when this SubscriptionAdjustment becomes effective. <div class="notatabla"><strong>Note:</strong> <strong><em>For example</em></strong>, when freeze begins.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        3                        </td>
                        <td>endDate</td>
                        <td>Date<a href="/objects/1641#fldAdjustment_End_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Date when the SubscriptionAdjustment period ends.<div class="notatabla"><strong>Note:</strong> Used for SubscriptionAdjustment actions: <br/>- Freeze;<br/>- Pause. <br/> <br/> <strong><em>For example</em></strong>, when freeze ends.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        4                        </td>
                        <td>note</td>
                        <td>String(255)<a href="/objects/1641#fldNotes" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Any additional details associated with a SubscriptionAdjustment.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        5                        </td>
                        <td>type</td>
                        <td>String(1)<a href="/objects/1641#fldAdjustment_CL" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Type of a SubscriptionAdjustment.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        6                        </td>
                        <td>length</td>
                        <td>Integer<a href="/objects/1641#fldLength" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Number of times that a SubscriptionAdjustment will generate an invoice.</td>
                    </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            
                                <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h3 class="panel-title">XML Sample:</h3>
                                        </div>
                                        <div class="panel-body">
                                        <pre class="xml" style={{fontFamily: "monospace"}}>
                                            <span style={{color: "#009900"}} >
                                            <span style={{color: "#009900", fontWeight: "bold"}} >&lt;subscription-adjustment</span><br/>
                                                <div className='ps-3'>
                                                    <span style={{color: "#000066"}}>id</span>=<span style={{color: "#ff0000"}}>""</span><br/>
                                                    <span style={{color: "#000066"}}>effectiveDate</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>endDate</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                    <span style={{color: "#000066"}}>note</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>type</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                    <span style={{color: "#000066"}}>length</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                </div>
                                                <span style={{color: "#009900", fontWeight: "bold"}}>/&gt;</span>
                                            </span>
                                        </pre>                
                                        </div>
                                    </div>
                                </div>
                            </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">JSON Sample:</h3>
                    </div>
                    <div class="panel-body">
                        <pre class="javascript" style={{fontFamily: "monospace"}}><span style={{color: "#009900"}}>{"{"}</span><br/>
                        <div className='ps-3'>
                    <span style={{color: "#3366CC"}}>"id"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"effectiveDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"endDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"note"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"type"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"note"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                    <span style={{color: "#3366CC"}}>"length"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}></span><br/>
                   
                    
                </div>
    <span style={{color: "#009900"}}>{"}"}</span></pre>               
    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">XURL Sample:</h3>
                    </div>
                    <div class="panel-body">
                    ?id=&effectiveDate=&endDate=&note=&type=&length=&<hr/>
                    <strong>id</strong>=<em></em><br/>
                    <strong>effectiveDate</strong>=<em></em><br/>
                    <strong>endDate</strong>=<em></em><br/>
                    <strong>note</strong>=<em></em><br/>
                    <strong>type</strong>=<em></em><br/>
                    <strong>length</strong>=<em></em><br/>
                    </div>
                </div>
            </div>
        </div>

        <div id="test-popup" class="popup-container white-popup mfp-hide">
        </div>
        </div>
                    </div>
    
                )
            
            case "Transaction":
            return(
                <div className="row">
                    
                    <div id="">
                        <div class="row">
                            <a id="billing-anchor" name="billing-anchor"></a>
                            <div class="col-xs-12 col-sm-12">
                                <h1>Transaction</h1>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-2 col-sm-2"><strong>Type: &nbsp;</strong></div>
                            <div class="col-xs-2 col-sm-2"><strong>Abstract: &nbsp;</strong>no</div>
                            <div class="col-xs-2 col-sm-2"><strong>Status: &nbsp;</strong>Active</div>
                            <div class="col-xs-6 col-sm-6"><strong>Java Class: &nbsp;</strong><a href="/objects/1635">AccountTransaction
</a></div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                    <div class="panel-body">
                                    Represents a transaction associated with a particular merchant.           </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <a id="billing-anchor" name="billing-anchor"></a>
                            <div class="col-xs-12 col-sm-12">
                                <h2>Available Fields:</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "3%"}} ></colgroup>
                                        <colgroup style={{width: "15%"}}></colgroup>
                                        <colgroup style={{width: "10%"}}></colgroup>
                                        <colgroup style={{width: "10%"}}></colgroup>
                                        <colgroup style={{width: "10%"}}></colgroup>
                                        <colgroup style={{width: "5%"}}></colgroup>
                                        <colgroup style={{width: "5%"}}></colgroup>
                                        <colgroup style={{width: "40%"}}></colgroup>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Pattern</th>
                                            <th>Format</th>
                                            <th>Mode</th>
                                            <th>Usage</th>
                                            <th>Description</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                        <td>
                                                        1                        </td>
                        <td>accountId</td>
                        <td>Integer<a href="/objects/1632#fldMerchant_Account_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Merchant account associated with Transaction.<div class="notatabla"><strong>Note:</strong> If your API user is associated to more than one merchant account or group of merchant accounts, you can specify the merchant account ID for the ~find operation to reduce the data set in the API response. <br/> <br/>Your API credentials may be associated with more than one accountId.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        2                        </td>
                        <td>customerId</td>
                        <td>Long<a href="/objects/1632#fldCustomer_Account_FK" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Identifier of a Customer that a Transaction is associated with.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        3                        </td>
                        <td>dueDate</td>
                        <td>Date<a href="/objects/1635#fldDue_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Date when the Transaction is expected to be completed.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        4                        </td>
                        <td>type</td>
                        <td><span data="1794" onClick={() => this.seleccionarModal("Account Activity Classifier")} type="unibill-enum" style={{color: "#0066B3"}}>Enum</span></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                                <td>Type of a Transaction.<div class="notatabla"><strong>Note:</strong> Optional secondary identifier. Typically this field is a reference to an ID in your own system to cross-reference it within our system. If not provided, the system will use the object 'id' generated at the time of object creation.</div> <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/objects/1794">Account Activity Classifier</a>.</em> </div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        5                        </td>
                        <td>accountType</td>
                        <td><span data="1795" onClick={() => this.seleccionarModal("Asset Transaction Classifier")}  type="unibill-enum" style={{color: "#0066B3"}}>Enum</span></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Type of a payment method used. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/objects/1795">Asset Transaction Classifier</a>.</em> </div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        6                        </td>
                        <td>creatorCode</td>
                        <td>String(60)<a href="/objects/1635#fldRef_Creator_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>CODE</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Identifier of a user  (provided by an integrator) initiating the request.<div class="notatabla"><strong>Note:</strong> This field typically references a user within your system whose actions resulted in this request. Defaults to your API's userName if no value is provided.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        7                        </td>
                        <td>amount</td>
                        <td>Integer<a href="/objects/1635#fldAmount" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>R</span></td>
                                                <td>Amount (in cents) of the Transaction.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        8                        </td>
                        <td>accountNumber</td>
                        <td>String(19)<a href="/objects/1644#fldMasked_Account_Number" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Number of a payment card or bank account.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        9                        </td>
                        <td>note</td>
                        <td>String(255)<a href="/objects/1635#fldNote" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>TEXT</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Any additional details associated with a Transaction.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        10                        </td>
                        <td>code</td>
                        <td>String(60)<a href="/objects/1632#fldReference_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>CODE</span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>C</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Unique Transaction ID supplied by external/user's system.<div class="notatabla"><strong>Note:</strong> Optional secondary identifier. Typically this field is a reference to an ID in your own system to cross-reference it within our system. If not provided, the system will use the object 'id' generated at the time of object creation.</div> <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/terminology/reference_field_type">Reference Field Type</a>.</em> </div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        11                        </td>
                        <td>feeAmount</td>
                        <td>Integer<a href="/objects/1635#fldFee_Amount" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Fee amount (in cents) associated with the transaction processing.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        12                        </td>
                        <td>taxAmount</td>
                        <td>Integer<a href="/objects/1635#fldTax_Amount" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Tax amount (in cents) associated with the transaction processing.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        13                        </td>
                        <td>adjustmentId</td>
                        <td>Long<a href="/objects/1635#fldAdjustment_Transaction_FK" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Identifier of an Adjustment that a Transaction is associated with.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        14                        </td>
                        <td>balance</td>
                        <td>Integer<a href="/objects/1635#fldBalance" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Current balance of the Transaction.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        15                        </td>
                        <td>createDate</td>
                        <td>Datetime<a href="/objects/1632#fldCreate_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Date when the Transaction was created.<div class="notatabla"><strong>Note:</strong> Date format:  <strong><em>yyyy-MM-dd</em></strong>.</div></td>
                    </tr>
                                        <tr>
                        <td>
                            <a id="ident" name="ident"></a>                            16                        </td>
                        <td>id</td>
                        <td>Long<a href="/objects/1632#fldId" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Unique Transaction ID generated by the system.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17                        </td>
                        <td>processingInfo</td>
                        <td><a href="/billing-api/v01/components/253">ProcessingInfo</a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Information associated with a processing of the transaction.<div class="notatabla"><strong>Note:</strong> This field only is included in a transaction record if the transaction has been processed.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.1                        </td>
                        <td><span  style={{paddingLeft: "10%"}}>accountAccessory</span></td>
                        <td>String<a href="/objects/1760#fldAccessory" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Card expiration date or bank routing number.<div class="notatabla"><strong>Note:</strong> This field is required even if it is used with a token.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.2                        </td>
                        <td><span style={{paddingLeft: "10%"}}>accountNumber</span></td>
                        <td>String<a href="/objects/1760#fldAccount_Number" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Number of a payment card or bank account.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.3                        </td>
                        <td><span style={{paddingLeft: "10%"}}>address</span></td>
                        <td>String<a href="/objects/1760#fldAddress" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Combined address field of the Customer object.<div class="notatabla"><strong>Note:</strong> The value is a comma separated concatenation of the street1, street2, city, state, zipCode, countryCode field values.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.4                        </td>
                        <td><span style={{paddingLeft: "10%"}}>approvalCode</span></td>
                        <td>String(60)<a href="/objects/1760#fldApproval_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Code of the approval status of the transaction provided by the payment provider.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.5                        </td>
                        <td><span style={{paddingLeft: "10%"}}>city</span></td>
                        <td>String(50)<a href="/objects/1760#fldCity" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>City of the Customer.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.6                        </td>
                        <td><span style={{paddingLeft: "10%"}}>createDate</span></td>
                        <td>Datetime<a href="/objects/1760#fldCreate_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Date when the ProcessingInfo was created.<div class="notatabla"><strong>Note:</strong> In API request, the date must be submitted in <strong><em>yyyy-MM-dd</em></strong> format. In API response, the date is returned in <strong><em>yyyy-MM-dd HH:mm:ss</em></strong> format.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.7                        </td>
                        <td><span style={{paddingLeft: "10%"}}>email</span></td>
                        <td>String(100)<a href="/objects/1760#fldEmail" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Email address of the Customer.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.8                        </td>
                        <td><span style={{paddingLeft: "10%"}}>gatewayTransactionId</span></td>
                        <td>String(20)<a href="/objects/1760#fldReference_Number" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Identifier of a Transaction (Processing) that the current transaction is associated with.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.9                        </td>
                        <td><span style={{paddingLeft: "10%"}}>holderName</span></td>
                        <td>String(150)<a href="/objects/1760#fldHolder_Name" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Combined values fo the firsetName and lastName in the Customer.</td>
                    </tr>
                                        <tr>
                        <td>
                            <a id="ident" name="ident"></a>                            17.10                        </td>
                        <td><span style={{paddingLeft: "10%"}}>id</span></td>
                        <td>Long<a href="/objects/1760#fldId" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Unique ProcesingInfo ID generated by the system.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.11                        </td>
                        <td><span style={{paddingLeft: "10%"}}>phone</span></td>
                        <td>String(20)<a href="/objects/1760#fldPhone" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Phone number of the Customer.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.12                        </td>
                        <td><span style={{paddingLeft: "10%"}}>responseCode</span></td>
                        <td>String(3)<a href="/objects/1760#fldResponse_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Code providing an explanation of the outcome of the request. <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/codes/your_gateway">UniPay Codes &amp; Code Mapping</a>.</em> </div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.13                        </td>
                        <td><span style={{paddingLeft: "10%"}}>responseDate</span></td>
                        <td>Datetime<a href="/objects/1760#fldResponse_Date" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Date  when the response to the transaction was received.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.14                        </td>
                        <td><span style={{paddingLeft: "10%"}}>responseMessage</span></td>
                        <td>String(255)<a href="/objects/1760#fldResponse_Message" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Response details associated with a processing request, generated by the gateway.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.15                        </td>
                        <td><span style={{paddingLeft: "10%"}}>state</span></td>
                        <td>String(3)<a href="/objects/1760#fldState" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>State of the Customer.<div class="notatabla"><strong>Note:</strong> State value format is subject to the associated country code in the Customer object.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.16                        </td>
                        <td><span style={{paddingLeft: "10%"}}>street</span></td>
                        <td>String(128)<a href="/objects/1760#fldStreet" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Street address of the Customer.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.17                        </td>
                        <td><span style={{paddingLeft: "10%"}}>token</span></td>
                        <td>String(512)<a href="/objects/1760#fldToken_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Unique random characters generated by the system for identifying and securely transmitting sensitive data.</td>
                    </tr>
                                        <tr>
                        <td>
                                                        17.18                        </td>
                        <td><span style={{paddingLeft: "10%"}}>zipCode</span></td>
                        <td>String(15)<a href="/objects/1760#fldZIP_Code" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>ZIP/postal code of the Customer.<div class="notatabla"><strong>Note:</strong> ZipCode value format is subject to the associated country code in the Customer object.</div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        18                        </td>
                        <td>subscriptionId</td>
                        <td>Long<a href="/objects/1635#fldPayment_Plan_FK" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" data="Pattern" type="pattern" style={{color: "#0066B3"}}></span>
                        </td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats Mode")} type="unibill-mode" style={{color: "#0066B3"}}>R</span></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>Identifier of a Subscription that a Transaction is associated with.<div class="notatabla">Used for transactions of type: <strong><em>RI</em></strong> - Invoice. <br/><strong>Note:</strong> when the Invoice transaction is created based on a subscription.</div> <div class="infotabla"> <em>For more information see <a href="https://unipaygateway.info/objects/1794">Account Activity Classifier</a>.</em> </div></td>
                    </tr>
                                        <tr>
                        <td>
                                                        19                        </td>
                        <td>invoiceList</td>
                        <td>String(250)<a href="/objects/1635#fldInvoice_List" style={{float: "right"}}><img src="/images/Assets/database_link.png" alt="DataBaseLink"/></a></td>
                        <td>
                            <span data-mfp-src="#test-popup" onClick={() => this.seleccionarModal("Validation Patterns Types")} type="pattern" style={{color: "#0066B3"}}>NUMERIC</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td><span onClick={() => this.seleccionarModal("Message Formats")} type="unibill-usage" style={{color: "#0066B3"}}>O</span></td>
                                                <td>List of invoice references that are expected to be covered by this transaction.</td>
                    </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        
                            <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h3 class="panel-title">XML Sample:</h3>
                                    </div>
                                    <div class="panel-body">
                                    <pre class="xml" style={{fontFamily: "monospace"}}>
                                        <span style={{color: "#009900"}} >
                                        <span style={{color: "#009900", fontWeight: "bold"}} >&lt;subscription-adjustment</span><br/>
                                            <div className='ps-3'>
                                                <span style={{color: "#000066"}}>id</span>=<span style={{color: "#ff0000"}}>""</span><br/>
                                                <span style={{color: "#000066"}}>effectiveDate</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                <span style={{color: "#000066"}}>endDate</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                                <span style={{color: "#000066"}}>note</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>type</span>=<span style={{color: "#ff0000"}}>""</span> <br/>
                                                <span style={{color: "#000066"}}>length</span>=<span style={{color: "#ff0000"}}>""</span>  <br/>
                                            </div>
                                            <span style={{color: "#009900", fontWeight: "bold"}}>/&gt;</span>
                                        </span>
                                    </pre>                
                                    </div>
                                </div>
                            </div>
                        </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3 class="panel-title">JSON Sample:</h3>
                </div>
                <div class="panel-body">
                    <pre class="javascript" style={{fontFamily: "monospace"}}><span style={{color: "#009900"}}>{"{"}</span><br/>
                    <div className='ps-3'>
                <span style={{color: "#3366CC"}}>"id"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                <span style={{color: "#3366CC"}}>"effectiveDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                <span style={{color: "#3366CC"}}>"endDate"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                <span style={{color: "#3366CC"}}>"note"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                <span style={{color: "#3366CC"}}>"type"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                <span style={{color: "#3366CC"}}>"note"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}>,</span><br/>
                <span style={{color: "#3366CC"}}>"length"</span><span style={{color: "#339933"}}>:</span> <span style={{color: "#3366CC"}}>""</span><span style={{color: "#339933"}}></span><br/>
                
                
            </div>
<span style={{color: "#009900"}}>{"}"}</span></pre>               
</div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3 class="panel-title">XURL Sample:</h3>
                </div>
                <div class="panel-body">
                ?id=&effectiveDate=&endDate=&note=&type=&length=&<hr/>
                <strong>id</strong>=<em></em><br/>
                <strong>effectiveDate</strong>=<em></em><br/>
                <strong>endDate</strong>=<em></em><br/>
                <strong>note</strong>=<em></em><br/>
                <strong>type</strong>=<em></em><br/>
                <strong>length</strong>=<em></em><br/>
                </div>
            </div>
        </div>
    </div>

    <div id="test-popup" class="popup-container white-popup mfp-hide">
    </div>
    </div>
                </div>

            )
            
        
         default:
             return null;
        }
     }
    
     cerrarModal = (accion) => {
        this.setState({ showmodal: "" })
    
    }


    render() {
        return (
            <>
                {
                /** Modales Usage */
                (this.state.showmodal == "Message Formats") ? 
                (<ModalMessageFormats tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
                : (this.state.showmodal == "Message Formats Mode") ? 
                (<ModalMessageFormatsMode tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
                : (this.state.showmodal == "Validation Patterns Types") ? 
                (<ModalValidationPatternsTypes tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
                : (this.state.showmodal == "Payment Option Classifier") ? 
                (<ModalPaymentOptionClassifier tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
                : (this.state.showmodal == "Billing Cycle Classifier") ? 
                (<ModalBillingCycleClassifier tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
                : (this.state.showmodal == "Account Activity Classifier") ? 
                (<ModalAccountActivityClassifier tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />)
                : (this.state.showmodal == "Asset Transaction Classifier") ? 
                (<ModalAssetTransactionClassifier tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />)
                : (this.state.showmodal == "Payment Plan Classifier") ? 
                (<ModalPaymentPlanClassifier tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />)
                : (this.state.showmodal == "Subscription Adjustment Classifier") ? 
                (<ModalSubscriptionAdjustmentClassifier tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />)
                : (this.state.showmodal == "Payment Plan Status") ? 
                (<ModalPaymentPlanStatus tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />)
                
                : null
                
              
                }



                <div className=''>
                    <div className="row pt-3">
                        <div className="col-12">
                            {this.renderizarContenidos()}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

