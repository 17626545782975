import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalClientHost extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Client Host</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                To eliminate potential fraud and to help with the resolution of suspected duplicate transactions, the gateway can track two host/IP addresses (request host and client host).
<br/>

<br/>
Request host is the host or IP-address of the external system submitting directly to the gateway.
<br/>

<br/>
Client host is the host or IP-address of a client that originated the transaction through some action (such as on-line purchase) in the external submitting system (which then issued direct request to the gateway).
<br/>

<br/>
When transaction is processed, the host/IP address of the submitting system (request host) is automatically captured; in addition, the integrators of web-based solutions are encouraged to include the host/IP address of the client into their system (using clientHost field), which, in turn, results in the gateway request.
<br/>

<br/>
For example, a customer from IP-address 211.167.76.181 connects to ShoppingCart.com (which communicates with the payment gateway). When the request is sent, the gateway automatically captures IP of the ShoppingCart.com, and ShoppingCart.com software is encouraged to include the IP address 211.167.76.181 in the gateway request as part of the clientHost field.<br/>                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

