import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalDriverLicenseState extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Driver License State</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "15%"}}></colgroup>
                                        <colgroup style={{width: "85%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>Value</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                            <td width="100px">CA</td>
                            <td>California<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">AL</td>
                            <td>Alabama<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">AK</td>
                            <td>Alaska<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">AZ</td>
                            <td>Arizona<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">CT</td>
                            <td>Connecticut<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">CO</td>
                            <td>Colorado<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">GA</td>
                            <td>Georgia<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">FL</td>
                            <td>Florida<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">DE</td>
                            <td>Delaware<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">AR</td>
                            <td>Arkansas<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">IL</td>
                            <td>Illinois<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">HI</td>
                            <td>Hawaii<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">ID</td>
                            <td>Idaho<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">IN</td>
                            <td>Indiana<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">KS</td>
                            <td>Kansas<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">KY</td>
                            <td>Commonwealth of Kentucky<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">IA</td>
                            <td>Iowa<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">ME</td>
                            <td>Maine<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">LA</td>
                            <td>Louisiana<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">MN</td>
                            <td>Minnesota<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">MI</td>
                            <td>Michigan<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">MO</td>
                            <td>Missouri<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">MD</td>
                            <td>Maryland<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">MA</td>
                            <td>Massachusetts<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">MS</td>
                            <td>Mississippi<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">MT</td>
                            <td>Montana<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">NV</td>
                            <td>Nevada<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">NH</td>
                            <td>New Hampshire<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">NJ</td>
                            <td>New Jersey<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">NE</td>
                            <td>Nebraska<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">NY</td>
                            <td>New York<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">NC</td>
                            <td>North Carolina<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">OK</td>
                            <td>Oklahoma<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">OH</td>
                            <td>Ohio<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">ND</td>
                            <td>North Dakota<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">PA</td>
                            <td>Commonwealth of Pennsylvania<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">OR</td>
                            <td>Oregon<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">NM</td>
                            <td>New Mexico<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">SD</td>
                            <td>South Dakota<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">TN</td>
                            <td>Tennessee<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">RI</td>
                            <td>Rhode Island<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">SC</td>
                            <td>South Carolina<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">VA</td>
                            <td>Commonwealth of Virginia<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">VT</td>
                            <td>Vermont<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">UT</td>
                            <td>Utah<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">TX</td>
                            <td>Texas<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">WV</td>
                            <td>West Virginia<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">WA</td>
                            <td>Washington<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">WI</td>
                            <td>Wisconsin<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">DC</td>
                            <td>Washington DC<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">WY</td>
                            <td>Wyoming<br/></td>
                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

