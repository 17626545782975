import React from 'react'
import { LuArrowRightCircle } from 'react-icons/lu';
import Navbar from '../../../Components/NavBar/Navbar';
import { getDatos } from '../../../Services/DatosLocalees';
import './suportFileFormat.css'
import parse from 'html-react-parser';
import { HiOutlineMinusSm } from 'react-icons/hi';
import ModalConTablas from '../../../Components/Modals/Batch/ModalConTablas';
import ModalReferenceFieldType from '../../../Components/Modals/Batch/ModalReferenceFieldType';
import ModalProcessorRules from '../../../Components/Modals/Batch/ModalProcessorRules';
import ModalINLegacyFieldsNames from '../../../Components/Modals/Batch/ModalINLegacyFieldsNames';
import ModalINDirectDebit from '../../../Components/Modals/Batch/ModalINDirectDebit';
import ModalINBankInformationSubscription from '../../../Components/Modals/Batch/ModalINBankInformationSubscription';
import ModalINServiceFees from '../../../Components/Modals/Batch/ModalINServiceFees';
import ModalINEffectiveDate from '../../../Components/Modals/Batch/ModalINEffectiveDate';
import ModalINTransactionGropus from '../../../Components/Modals/Batch/ModalINTransactionGroups';
import ModalINDynamicDescriptor from '../../../Components/Modals/Batch/ModalINDynamicDescriptor';
import ModalINStoredCredentials from '../../../Components/Modals/Batch/ModalINStoredCredentials';
import ModalINSplitPayments from '../../../Components/Modals/Batch/ModalINSplitPayments';
import ModalINCrossReferenceFieldsName from '../../../Components/Modals/Batch/ModalINCrossReferenceFieldsName';
import ModalINInterchangeRateQuealification from '../../../Components/Modals/Batch/ModalINInterchangeRateQualification';
import ModalINHolderNameNormalizationRules from '../../../Components/Modals/Batch/ModalINHolderNameNormalizationRules';
import ModalINPaymentAggregation from '../../../Components/Modals/Batch/ModalINPaymentAggregation';
import ModalINRetryFee from '../../../Components/Modals/Batch/ModalINRetryFee';
import ModalINRSequenceNumber from '../../../Components/Modals/Batch/ModalINSequenceNumber';
import ModalINRecordID from '../../../Components/Modals/Batch/ModalINRecordID';
import ModalINWarningCode from '../../../Components/Modals/Batch/ModalINWarningCode';
import ModalINProviderResponseData from '../../../Components/Modals/Batch/ModalINProviderResponseData';
import ModalINSplitAmountFormat from '../../../Components/Modals/Batch/ModalINSplitAmountFormat';
import ModalINRetryProcess from '../../../Components/Modals/Batch/ModalINRetryProcess';
import ModalProcessorRulesAmex from '../../../Components/Modals/Batch/ModalProcessorRulesAmex';
import ModalINNoticesofChange from '../../../Components/Modals/Batch/ModalINNoticesofChange';
import ModalNoProcessorRules from '../../../Components/Modals/Batch/ModalNoProcessorRules';
import ModalINPartialSettlement from '../../../Components/Modals/Batch/ModalINPartialSettlement';
import ModalINBatchTokenization from '../../../Components/Modals/Batch/ModalINBatchTokenization';
import ModalTokenization from '../../../Components/Modals/Batch/ModalTokenization';
import ModalINBatchEncryption from '../../../Components/Modals/Batch/ModalINBatchEncryption';

export default class SuportFileFormat extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      bandera: props.bandera,
      contenido: [],
      titulo: "",

      tablasporseccion:[],

      arrayDatos: [
        {
          titulo: "Supported File Formats",
          subdatos: [
            {//////////////Processing////////////////////////////
              titulo: "Processing",
              notas: "",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Shipping Address Information *" },
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Token is used" },
                  { nombre: "Account Number is used" },
                ],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Processing Request Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: []
              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Account  Information" },
                  { nombre: "Transaction  Information" },
                  { nombre: " Cross-References" },
                  { nombre: "Response Codes Information" },
                  { nombre: "Account Update Information" },
                  { nombre: "Aggregation Information" },
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Token  is used" },
                  { nombre: "Account Number  is used" },
                ],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Processing Response Header Record" },
                  { nombre: "Legacy Systems" },
                ],
                subtitulo4: "",
                arraytitulo4: []
              }],

              tablas: [
                {
                  titulo: "Processing File Format (Request)",
                  tabla: {
                    titulotabla: "Account Information",
                    id: "Account Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                         "1",
                         "accountId",
                         "Reference",
                         "",
                         "",
                         "R",
                         "Reference to an account associated with the transaction included in the batch file.",
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                        vinculoType: "ProcessingAccountIdReference",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "2",
                         "accountType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Type of an account used in the transaction. This value doesn't affect the cost of transaction processing. It is used for validation purposes only.",
                        ],
                        vinculoType: "ProcessignAccountTypeEnum",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "3",
                         "accountNumber",
                         "String(20)",
                         "",
                         "",
                         "C",
                         "Number of a payment card or bank account.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "4",
                         "accountAccessory *",
                         "String(14)",
                         "",
                         "",
                         "R",
                         "Payment card's expiration date or bank account's routing number. See ",
                        ],
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "integrationNotesAccountAccessory",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "5",
                         "token",
                         "String(40)",
                         "",
                         "",
                         "C",
                         "Token associated with a payment card or bank account. Can be used instead of a payment card or bank account number in further transaction processing.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "6",
                         "holderType",
                         "Enum",
                         "",
                         "default - P",
                         "O",
                         "Type of a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessignHolderTypeEnum",
                      },
                      {
                        row: [ 
                         "7",
                         "holderName",
                         "String(100)",
                         "",
                         "",
                         "O",
                         "Name of a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "8",
                         "holderBirthdate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "O",
                         "Birthdate of a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "9",
                         "subscriptionCode ",
                         "Long",
                         "",
                         "",
                         "O",
                         "Code of the mandate. See ",
                        ],
                        descripcion1: "Note: This field requires",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "integrationNotesSubscriptionCode",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "subscriptionCodeName",
                        vinculoUsage: "Message Formats",
                        vinculoName: "subscriptionCodeName"
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Transaction Information",
                    id: "Transaction Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                         "10",
                         "amount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R",
                         "Total amount of the transaction that includes taxes and fees (if present).",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "11",
                         "feeAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of a service fee included in the total amount. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "TransactionInformacionINFeeAmount",
                      },
                      {
                        row: [ 
                         "12",
                         "taxAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Tax amount charged on the order/transaction. Included in the total amount.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "13",
                         "transactionDate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "R",
                         "Date of the transaction within the submiter's system. Cosindered as effective date for processing purposes. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "TransactionInformacionINtransactionDate",
                      },
                      {
                        row: [ 
                         "14",
                         "transactionType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Type of the transaction.",
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "TransactionInformationTransactionTypeEnum",
                      },
                      {
                        row: [ 
                         "15",
                         "transactionGroupCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Identifier of the logic group, to which the transaction belongs. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "TransactionInformacionINtransactionGroupCode",
                      },
                      {
                        row: [ 
                         "16",
                         "transactionIndustryType",
                         "Enum",
                         "",
                         "default - RE",
                         "O",
                         "Industry type of the transaction.",
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "TransactionInformationTransactionIndustryTypeEnum",
                      },
                      {
                        row: [ 
                         "17",
                         "rebillAttemptCount",
                         "Integer",
                         "",
                         "default - 0",
                         "O",
                         "Number of the attempts made to collect an amount of the transaction. Used when the current transaction is a reattempt of a previously declined transaction (with the same accountNumber).",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "18",
                         "descriptor",
                         "String(25)",
                         "",
                         "",
                         "O",
                         "Dynamic descriptor of the transaction that is shown on customer statements and can be customized to simplify understanding of charge for a cardholder. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "TransactionInformacionINdescriptor",
                      },
                      {
                        row: [ 
                         "19",
                         "memo",
                         "String(255)",
                         "",
                         "",
                         "O",
                         "Additional information about the transaction.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "70 of 255 characters are reserved for system use and only 185 characters can be used within the API request.",
                        
                      },
                      {
                        row: [ 
                         "20",
                         "originalNetworkTransactionId *",
                         "String(30)",
                         "",
                         "",
                         "O",
                         "Identifier of the first transaction in a sequence of payments. Provided by a payment network. Used for installment and recurring payments only. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "TransactionInformacionINoriginalNetworkTransactionId",
                      },
                      {
                        row: [ 
                         "21",
                         "sequenceNumber",
                         "Integer",
                         "",
                         "greater than 0; less than or equal to sequenceCount",
                         "O",
                         "Sequential number of the payment (e.g. 2nd of 12 payments). Used for installment and recurring payments only.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "22",
                         "sequenceCount",
                         "Integer",
                         "",
                         "greater than 0",
                         "O",
                         "Total number of payments in an installment sequence (e.g. 12 payments). Used for installment payments only.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                         "23",
                         "splitSchemaId",
                         "Reference",
                         "",
                         "",
                         "O",
                         "Reference to a split schema associated with the transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "TransactionInformacionSplitSchemaIdReference",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "TransactionInformacionINsplitSchemaId",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Cross-References",
                    id: "Cross-References",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                         "24",
                         "originalTransactionId",
                         "Long",
                         "",
                         "",
                         "O",
                         "Identifier of the original sale transaction. Used for refund transactions only.",
                        ],
                        descripcion2: "Formerly called originalReferenceNumber. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "CrossReferenceINoriginalTransactionId",
                      },
                      {
                        row: [ 
                         "25",
                         "transactionCode",
                         "String(20)",
                         "",
                         "",
                         "R",
                         "Identifier of the transaction supplied by a submitter. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "26","transactionInternalCode","String(20)","","","O","Internal identifier of the transaction within a submitter's system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionInternalCode",
                      },
                      {
                        row: [ 
                          "27","customerAccountCode","String(20)","","","O","Identifier of a customer, associated with the transaction, within a submitter's system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINcustomerAccountCode",
                      },
                      {
                        row: [ 
                          "28","customerAccountInternalCode","String(20)","","","O","Internal identifier of a customer, associated with the transaction, within a submitter's system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINcustomerAccountInternalCode",
                      },
                      {
                        row: [ 
                          "29","itemCode","String(20)","","","O","Identifier of a purchased product or service within a submitter’s system."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Billing Address Information",
                    id: "Billing Address Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "30","street","String(128)","","","O","Street of a billing address associated with a payment card or bank account holder. See "
                        ],
                        descripcion1: "Note: This field requires ",
                        vinculoUsage: "Message Formats",
                        vinculoName: "BillingAddressInfStreet",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "BillingAddressInfINstreet",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "BillingAddressInfStreet",
                      },
                      {
                        row: [ 
                          "31","city","String(50)","","","O","City of a billing address associated with a payment card or bank account holder. See "
                        ],
                        descripcion1: "Note: This field requires ",
                        vinculoUsage: "Message Formats",
                        vinculoName: "BillingAddressInfStreet",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "BillingAddressInfINstreet",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "BillingAddressInfStreet",
                      },
                      {
                        row: [ 
                          "32","countryCode","String(2)","","default - US","O","Country of a billing address associated with a payment card or bank account holder."
                        ],
                        descripcion1: "Note: This field requires ",
                        vinculoUsage: "Message Formats",
                        vinculoName: "BillingAddressInfStreet",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "BillingAddressInfStreet",
                      },
                      {
                        row: [ 
                          "33","state","String(2)","","","O","State of a billing address associated with a payment card or bank account holder. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "BillingAddressInfINstreet",
                      },
                      {
                        row: [ 
                          "34","zipCode *","String(15)","","alphanumeric characters only, no spaces or dashes, no special characters","O","ZIP code of a billing address associated with a payment card or bank account holder. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "BillingAddressInfINstreet",
                      },
                      {
                        row: [ 
                          "35","phone","String(20)","","digits only, no special characters","O","Phone number associated with a payment card or bank account holder."
                        ],
                        descripcion1: "Note: This field requires ",
                        vinculoUsage: "Message Formats",
                        vinculoName: "BillingAddressInfStreet",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "BillingAddressInfStreet",
                      },
                      {
                        row: [ 
                          "36","email","String(100)","","","O","Email associated with a payment card or bank account holder."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Shipping Address Information *",
                    id: "Shipping Address Information *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "37","shippingName","String(100)","","","R2","Name of an order recipient."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "38","shippingStreet","String(128)","","","R2","Street of a shipping address associated with an order recipient."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "39","shippingCity","String(50)","","","R2","City of a shipping address associated with an order recipient."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "40","shippingCountryCode","String(2)","","","O","Country code of a shipping address associated with an order recipient."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "41","shippingState","String(2)","","","R2","State of a shipping address associated with an order recipient."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "42","shippingZipCode","String(15)","","","R2","ZIP code of a shipping address associated with an order recipient."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "Conditions (Processing File Format Request)",
                  tabla: {
                    titulotabla: "Token is used",
                    id: "Token is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","token","R"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                      {
                        row: [ 
                          "2","accountNumber","N"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Number is used",
                    id: "Account Number is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","token","N"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                      {
                        row: [ 
                          "2","accountNumber","R"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                    ]
                  }
                },
                {
                  titulo: "Processing File Format (Response)",
                  tabla: {
                    titulotabla: "Account  Information",
                    id: "Account  Information",
                    headers:[
                      "#",
                      "Name",
                      "Type",
                      "Pattern",
                      "Format",
                      "Usage",
                      "Description",
                     ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","E","accountType used in the transaction. Formerly called merchantAccountCode. See integration notes for more information."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","accountType","Enum","","","E","accountType used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessignAccountTypeEnum",
                      },
                      {
                        row: [ 
                          "3","accountNumberMasked","String(20)","","","C","accountAccessory used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "4","accountAccessory","String(14)","","","E","accountAccessory used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "5","token","String(40)","","","C","Token associated with a payment card or bank account. Present if tokenization service is enabled."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "6","holderName","String(100)","","","E","Normalized holderName value used in the transaction. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingINholderName",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Transaction  Information",
                    id: "Transaction  Information",
                    headers:[
                      "#",
                      "Name",
                      "Type",
                      "Pattern",
                      "Format",
                      "Usage",
                      "Description",
                     ],
                    body: [
                      {
                        row: [ 
                          "7","amount","Integer","","amount in cents","R","Total authorized amount."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "8","processedAmount","Integer","","","O","Amount of the transaction that was processed. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseINprocessedAmount",
                      },
                      {
                        row: [ 
                          "9","retryFeeAmount","Integer","","not negative; amount in cents","O","Amount of service fee, charged when a reattempt of a previously declined transaction is made. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseINretryFeeAmount",
                      },
                      {
                        row: [ 
                          "10","transactionDate","Date","","yyyyMMdd","E","transactionDate used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "11","originalTransactionDate","Date","","yyyyMMdd","R","Date of the original transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "12","providerTransactionId","String(50)","","","O","Identifier of the transaction in the processor's system. Provided by the "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "processor",
                        seemoreinformationcom0: ".",
                        modalname0: "ProcessingResponseINproviderTransactionId", // PENDIENTE lleva a UniPay Terminology
                      },
                      {
                        row: [ 
                          "13","expectedEffectiveDate","Date","","yyyyMMdd","O","Date when the transaction is expected to be processed. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "TransactionInformacionINtransactionDate",
                      },
                      {
                        row: [ 
                          "14","transactionType","Enum","","","E","transactionType used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingResponsetransactionTypeEnum",
                      },
                      {
                        row: [ 
                          "15","approvalCode","String(100)","","","O","Authorization number given by a cardholder's bank (ex processorCode). Often shown on a credit card receipt."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "16","sequenceNumber","String(1)","","chargebacks and reversals only","O","Sequential number of a received chargeback or reversal. Used to avoid misinterpretation of a new chargeback/reversal as a duplicate of a previously processed one. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseSequenceNumber",
                      },
                      {
                        row: [ 
                          "17","isRebillEnabled","Boolean","","1 - true, 0 - false","R","Indicates whether the transaction is in rebill. If set to true, additional response(s) will be received for this transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "18","networkTransactionId *","String(30)","","","O","Identifier of the first transaction in a sequence of payments. Provided by a payment network. Used for installment and recurring payments only. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "TransactionInformacionINoriginalNetworkTransactionId",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: " Cross-References",
                    id: " Cross-References",
                    headers:[
                      "#",
                      "Name",
                      "Type",
                      "Pattern",
                      "Format",
                      "Usage",
                      "Description",
                     ],
                    body: [
                      {
                        row: [ 
                          "19","submissionId","Long","","","R","Identifier of the batch associated with the the transaction request."
                        ],
                        descripcion2: "Formerly called submissionCode. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "CrossReferenceINoriginalTransactionId",
                      },
                      {
                        row: [ 
                          "20","transactionId","Long","","","R","Unique identifier of the transaction. Generated by the gateway. See "
                        ],
                        descripcion2: "Formerly called referenceNumber. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCrossReferenceINtransactionId",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "CrossReferenceINoriginalTransactionId",
                      },
                      {
                        row: [ 
                          "21","originalTransactionId","Long","","returns, chargebacks, reversals only","R","Identifier of the original sale transaction. Used for refund transactions only."
                        ],
                        descripcion2: "Formerly called originalReferenceNumber. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "CrossReferenceINoriginalTransactionId",
                      },
                      {
                        row: [ 
                          "22","transactionCode","String(20)","","","E","transactionCode used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "23","originalTransactionCode","String(20)","","","R","Value of the transactionCode field associated with the original transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "24","providerTransactionCode","String(25)","","","R","Identifier of the transaction in a processor's system. Generated by the gateway."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "25","transactionInternalCode","String(20)","","","E","transactionInternalCode used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Response Codes Information",
                    id: "Response Codes Information",
                    headers:[
                      "#",
                      "Name",
                      "Type",
                      "Pattern",
                      "Format",
                      "Usage",
                      "Description",
                     ],
                    body: [
                      {
                        row: [ 
                          "26","warningCode","String(1)","","","O","Code warning that non-criticial issue with the transaction is present. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoINwarningCode",
                      },
                      {
                        row: [ 
                          "27","responseCode","String(5)","","","R","Response code returned by the gateway. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoResponseCode", // PENDIENTE envia a your gateway
                      },
                      {
                        row: [ 
                          "28","responseMessage","String(255)","","","R","Response message returned by the gateway. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoResponseCode", // PENDIENTE envia a your gateway
                      },
                      {
                        row: [ 
                          "29","providerResponseCode","String(20)","","","O","Response code returned by an underlying processor/bank. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoINproviderResponseCode",
                      },
                      {
                        row: [ 
                          "30","providerResponseMessage","String(260)","","","O","Response message returned by an underlying processor/bank. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoINproviderResponseCode",
                      },
                      {
                        row: [ 
                          "31","avsResponseCode","String(5)","","","O","AVS response code returned by the gateway (if address information has been provided). See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoResponseCode", // PENDIENTE envia a your gateway
                      },
                      {
                        row: [ 
                          "32","providerAvsResponseCode","String(20)","","","O","AVS response code returned by an underlying processor/bank. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoINproviderResponseCode",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Update Information",
                    id: "Account Update Information",
                    headers:[
                      "#",
                      "Name",
                      "Type",
                      "Pattern",
                      "Format",
                      "Usage",
                      "Description",
                     ],
                    body: [
                      {
                        row: [ 
                          "33","accountNumberUpdated","String(20)","","","O","Updated number of a payment card or bank account. Present if provided by an account updater."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "34","accountAccessoryUpdated","String(14)","","","O","Updated payment card's expiration date or bank account's routing number. Present if provided by an account updater."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "35","accountTypeUpdated","Enum","","","O","Updated type of an account used in the transaction. Present if provided by an account updater."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessignAccountTypeEnum",
                      },
                      {
                        row: [ 
                          "36","holderNameUpdated","String(100)","","","O","Updated name of a payment card or bank account holder. Present if provided by an account updater."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "37","tokenUpdated","String(40)","","","O","Updated token generated based on the updated account information, that has been provided by an account updater."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Aggregation Information",
                    id: "Aggregation Information",
                    headers:[
                      "#",
                      "Name",
                      "Type",
                      "Pattern",
                      "Format",
                      "Usage",
                      "Description",
                     ],
                    body: [
                      {
                        row: [ 
                          "38","aggregateTransactionId","Long","","","O","Identifier of the aggregated transaction. Generated by the gateway. See "
                        ],
                        descripcion2: "Formerly called aggregateReferenceNumber. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseINprocessedAmount",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "39","aggregateTransactionCode","String(20)","","","O","Identifier of the aggregated transaction supplied by a submitter. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseINprocessedAmount",
                      },
                      {
                        row: [ 
                          "40","aggregateTransactionInternalCode","String(20)","","","O","Internal identifier of the aggregated transaction within a submitter's system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseINprocessedAmount",
                      },
                    ]
                  }
                },
                {
                  titulo: "Conditions (Processing File Format Response)",
                  tabla: {
                    titulotabla: "Token  is used",
                    id: "Token  is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","token","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "2","accountNumberMasked","N"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Number  is used",
                    id: "Account Number  is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","token","N"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "2","accountNumberMasked","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                    ]
                  }
                },
                {
                  titulo: "Examples (ProcessingFileFormat)",
                  tabla: {
                    titulotabla: "Processing Request Header Record",
                    id: "Processing Request Header Record",
                    descripcion: "accountId|accountType|accountAccessory|transactionCode|transactionDate|transactionType|amount|holderName|holderType|accountNumber|taxAmount|street|city|state|zipCode|phone|email|itemCode|customerAccountCode|memo",
                    headers:[],
                    body: []
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Processing Response Header Record",
                    id: "Processing Response Header Record",
                    descripcion: "accountId|accountNumberMasked|accountAccessory|accountType|transactionType|providerTransactionCode|transactionCode|transactionInternalCode|transactionDate|submissionId|transactionId|originalTransactionId|originalTransactionCode|originalTransactionDate|isRebillEnabled|amount|holderName|responseCode|responseMessage",
                    headers:[],
                    body: []
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Legacy Systems",
                    id: "Legacy Systems",
                    descripcion: "accountId|transactionCode|transactionDate|transactionId|responseCode|responseMessage|avsResponseCode|token|accountNumberUpdated|accountAccessoryUpdated|tokenUpdated|transactionType|submissionId|originalTransactionId|isRebillEnabled|sequenceNumber|transactionInternalCode|accountTypeUpdated|holderNameUpdated|retryFeeAmount|expectedEffectiveDate|warningCode|amount|accountNumberMasked|accountAccessory|accountType|holderName|aggregateTransactionId|aggregateTransactionCode|aggregateTransactionInternalCode|approvalCode|processedAmount|providerResponseCode|providerResponseMessage|providerAvsResponseCode|originalTransactionCode|originalTransactionDate",
                    headers:[],
                    body: []
                  }
                },
              ]
            },
            {////////////////Tokenization//////////////////////////
              titulo: "Tokenization",
              notas: "",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Credit Card is used" },
                  { nombre: "ACH is used" },
                ],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Tokenization Request Header Record" },

                ],
                subtitulo4: "",
                arraytitulo4: [],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: " Format",
                arraytitulo1: [],
                subtitulo2: " ",
                arraytitulo2: [],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Tokenization Response Header Record"},
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              tablas: [
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Tokenization File Format (Request)",
                    id: "Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","R","Reference to an account associated with the transaction included in the batch file."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "TransactionInformacionSplitSchemaIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","transactionCode","String(20)","","","R","Identifier of the transaction supplied by a submitter. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "3","accountNumber","String(20)","","","R","For credit card transaction, the value is card’s account number; for ACH/eCheck transaction, the value is bank account’s number"
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "4","accountType","Enum","","","R","Type of the account. This value doesn't affect the cost of transaction processing. It is used for validation purposes only."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessignAccountTypeEnum",
                      },
                      {
                        row: [ 
                          "5","accountAccessory","String(14)","","","C","Payment card's expiration date or bank account's routing number. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "integrationNotesAccountAccessory",
                      },
                    ]
                  }
                },
                {
                  titulo: "Conditions (Tokenization File Format Request)",
                  tabla: {
                    titulotabla: "Credit Card is used",
                    id: "Credit Card is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountNumber","R"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                      {
                        row: [ 
                          "2","accountAccessory","N"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "ACH is used",
                    id: "ACH is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountNumber","R"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                      {
                        row: [ 
                          "2","accountAccessory","R"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Tokenization File Format (Response)",
                    id: " Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","R","accountId used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "TransactionInformacionSplitSchemaIdReference",
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","transactionCode","String(20)","","","R","Identifier of the transaction supplied by a submitter. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "3","accountType","Enum","","","R","Type of the account."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessignAccountTypeEnum",
                      },
                      {
                        row: [ 
                          "4","token","String(40)","","","R","Token generated for the provided payment information."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "5","responseCode","String(5)","","A01 (Approved), E03 (Transaction data integrity validation error)","R","Gateway generated response code."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "6","responseMessage","String(255)","","A01 (Approved), E03 (Transaction data integrity validation error)","R","Gateway generated response message. Corresponds to the responseCode value."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "7","providerTransactionCode","String(25)","","","O","Value identifying this transaction in the processor's system, assigned by the gateway."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "Examples (TokenizationFileFormat)",
                  tabla: {
                    titulotabla: "Tokenization Request Header Record",
                    id: "Tokenization Request Header Record",
                    descripcion: "accountId|transactionCode|accountNumber|accountType",
                    headers:[],
                    body: []
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Tokenization Response Header Record",
                    id: "Tokenization Response Header Record",
                    descripcion: "accountId|transactionCode|accountType|token|responseCode|responseMessage",
                    headers:[],
                    body: []
                  }
                },
              ]
            },
            {////////////////Parsing Error File//////////////////////////
              titulo: "Parsing Error File",
              notas: "",
              info1: [],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Error Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              tablas: [
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Parsing Error File Format (Response)",
                    id: "Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","errorCode","String(3)","","","R","Code of the error."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "2","errorMessage","String(255)","","","R","Message describing the error."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "Examples (ParsingErrorFileFormatResponse)",
                  tabla: {
                    titulotabla: "Error Header Record",
                    id: "Error Header Record",
                    descripcion: "errorCode|errorMessage",
                    headers:[],
                    body: []
                  }
                },
              ]
            },
            {////////////////Account Update//////////////////////////
              titulo: "Account Update",
              notas: "",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Account Number is used" },
                  { nombre: "Token is used" },
                ],
                subtitulo3: "Examples",
                arraytitulo3: [

                  { nombre: "Account Update Request Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: " Format",
                arraytitulo1: [],
                subtitulo2: "",
                arraytitulo2: [],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Subscriptions File Response Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              tablas: [
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Update File Format (Request)",
                    id: "Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","R","Reference to an account associated with the transaction included in the batch file."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","transactionCode","String(20)","","","O","Identifier of the transaction supplied by a submitter. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "3","accountType","Enum","","","R","Type of the account. This value doesn't affect the cost of transaction processing. It is used for validation purposes only."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessignAccountTypeEnum"
                      },
                      {
                        row: [ 
                          "4","accountNumber","String(20)","","","C","For credit card transaction, the value is card’s account number; for ACH/eCheck transaction, the value is bank account’s number."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "5","accountAccessory *","String(14)","","","R","Payment card's expiration date or bank account's routing number. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "integrationNotesAccountAccessory",
                      },
                      {
                        row: [ 
                          "6","token","String(40)","","","C","Token associated with a credit card or bank account."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "7","customerAccountCode","String(60)","","","O","Value identifying customer in submitter’s system. See "
                        ],
                        descripcion1: "Note: This field requires special handling for some processors.",
                        vinculoName: "modalProcessorRulesAmex",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "integrationNotesSubscriptionCode",
                        seemoreinformation1: "integration notes",
                        seemoreinformationcom1: " for more information.",
                        modalname1: "modalProcessorRulesAmex",
                      },
                      {
                        row: [ 
                          "8","customerAccountInternalCode","String(60)","","","O","Value identifying customer in a submitter’s system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINcustomerAccountCode",
                      },
                      {
                        row: [ 
                          "9","transactionInternalCode","String(60)","","","O","Value identifying the transaction in a submitter’s system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINcustomerAccountCode",
                      },
                    ]
                  }
                },
                {
                  titulo: "Conditions (Account Update File Format Request)",
                  tabla: {
                    titulotabla: "Account Number is used",
                    id: "Account Number is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountNumber","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "2","accountAccessory","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "3","token","N"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Token is used",
                    id: "Token is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountNumber","N"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "2","accountAccessory","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "3","token","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Update File Format (Response)",
                    id: " Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","E","accountId used in the transaction."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","transactionId","Long","","","R","Unique identifier of the transaction. Generated by the gateway. See "
                        ],
                        descripcion2: "Formerly called referenceNumber. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCrossReferenceINtransactionId",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "3","transactionCode","String(20)","","","E","Identifier of the transaction supplied by a submitter. See "
                        ],
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "4","transactionInternalCode","String(60)","","","E","Value identifying the transaction in a submitter’s system. See "
                        ],
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "5","customerAccountCode","String(60)","","","E","Value identifying customer in a submitter’s system. See "
                        ],
                        descripcion1: "Note: This field requires special handling for some processors.",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "integrationNotesSubscriptionCode",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors",
                        modalname1: "modalProcessorRulesAmex",
                        vinculoName: "modalProcessorRulesAmex"
                      },
                      {
                        row: [ 
                          "6","customerAccountInternalCode","String(60)","","","E","Value identifying customer in a submitter’s system. See "
                        ],
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINcustomerAccountCode",
                      },
                      {
                        row: [ 
                          "7","accountNumberMasked","String(20)","","","R","Masked version of the account number (credit card or bank account) associated with this transaction. The mask is formed by using the first and last digits of the account, e.g. 4********5655."
                        ],
                      },
                      {
                        row: [ 
                          "8","token","String(40)","","","O","Token associated with a credit card or bank account."
                        ],
                      },
                      {
                        row: [ 
                          "9","accountNumberUpdated *","String(20)","","","C","Updated account number, provided by an account updater. Present if an initial account number was updated and respective information has been received from an account updater."
                        ],
                      },
                      {
                        row: [ 
                          "10","accountAccessoryUpdated *","String(14)","","","C","Updated payment card's expiration date or bank account's routing number. Present if provided by an account updater."
                        ],
                      },
                      {
                        row: [ 
                          "11","tokenUpdated","String(40)","","","C","Updated token generated due to account information change, provided by an account updater. Present if an initial account number or expiration date were updated and respective information has been received from an account updater."
                        ],
                      },
                      {
                        row: [ 
                          "12","responseCode *","String(5)","","","R","Gateway generated response code. See "
                        ],
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#",
                      },
                      {
                        row: [ 
                          "13","responseMessage *","String(255)","","","R","Gateway generated response message. Corresponds to the responseCode value. See "
                        ],
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#",
                      },
                      {
                        row: [ 
                          "14","providerTransactionCode","String(25)","","","O","Value identifying this transaction in the processor's system, assigned by the gateway."
                        ],
                      },
                    ]
                  }
                },
                {
                  titulo: "Examples (AccountUpdateFileFormat)",
                  tabla: {
                    titulotabla: "Account Update Request Header Record",
                    id: "Account Update Request Header Record",
                    descripcion: "accountId|transactionCode|accountType|accountNumber|accountAccessory|customerAccountCode",
                    headers:[],
                    body: []
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Update Response Header Record",
                    id: "Account Update Response Header Record",
                    descripcion: "accountId|responseCode|responseMessage|transactionCode|accountNumberUpdated|accountAccessoryUpdated|transactionId",
                    headers:[],
                    body: []
                  }
                },
              ]
            },
            {////////////////Subscription//////////////////////////
              titulo: "Subscription",
              notas: "",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Token is used" },
                  { nombre: "Account Number is used" },

                ],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Subscriptions File Request Header Record" },

                ],
                subtitulo4: "",
                arraytitulo4: [],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: " Format",
                arraytitulo1: [],
                subtitulo2: "",
                arraytitulo2: [],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Subscriptions File Response Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              tablas: [
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Subscription File Format (Request)",
                    id: "Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","R","Reference to an account associated with the transaction included in the batch file."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",

                      },
                      {
                        row: [ 
                          "2","accountType","Enum","","","C","Type of the account. This value doesn't affect the cost of transaction processing. It is used for validation purposes only."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "SubscriptionAccountTypeEnum",
                      },
                      {
                        row: [ 
                          "3","accountNumber","String(20)","","","C","For credit card transaction, the value is card’s account number; for ACH/eCheck transaction, the value is bank account’s number."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "4","accountAccessory","String(14)","","","R","Payment card's expiration date or bank account's routing number. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "integrationNotesAccountAccessory",
                      },
                      {
                        row: [ 
                          "5","token","String(50)","","","C","Token associated with a credit card or bank account."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "6","transactionCode","String(20)","","","O","Identifier of the transaction supplied by a submitter. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "7","customerAccountCode","String(20)","","","O","Value, identifying customer in submitter’s system. See integration notes for more information."
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "8","holderName","String(100)","","","O","Name of a payment card or bank account holder."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "9","holderType","Enum","","","O","Type of cardholder."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoName: "ProcessignHolderTypeEnum",
                      },
                      {
                        row: [ 
                          "10","email","String(100)","","","R","Account holder's email."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "11","phone","String(20)","","digits only","O","Contact phone number of the account holder."
                        ],
                        descripcion1: "Note: This field requires ",
                        vinculoUsage: "Message Formats",
                        vinculoName: "subscriptionCodeName",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "subscriptionCodeName",
                      },
                      {
                        row: [ 
                          "12","street","String(128)","","","O","Street of the billing address. See "
                        ],
                        descripcion1: "Note: This field requires ",
                        vinculoUsage: "Message Formats",
                        vinculoName: "subscriptionCodeName",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "subscriptionCodeName",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "BillingAddressInfINstreet",
                      },
                      {
                        row: [ 
                          "13","city","String(50)","","","O","City of the billing address. See "
                        ],
                        descripcion1: "Note: This field requires ",
                        vinculoUsage: "Message Formats",
                        vinculoName: "subscriptionCodeName",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "subscriptionCodeName",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "BillingAddressInfINstreet",
                      },
                      {
                        row: [ 
                          "14","countryCode","String(2)","","","O","Country of the billing address."
                        ],
                        descripcion1: "Note: This field requires ",
                        vinculoUsage: "Message Formats",
                        vinculoName: "ProcessorRules2",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "ProcessorRules2",

                      },
                      {
                        row: [ 
                          "15","state","String(3)","","","O","State of the billing address. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "BillingAddressInfINstreet",
                      },
                      {
                        row: [ 
                          "16","zipCode","String(15)","","","O","ZIP code of the billing address. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "BillingAddressInfINstreet",
                      }
                    ]
                  }
                },
                {
                  titulo: "Conditions (Subscription File Format Request)",
                  tabla: {
                    titulotabla: "Token is used",
                    id: "Token is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [ 
                          "1","token","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "2","accountNumber","N"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "3","accountType","N"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Number is used",
                    id: "Account Number is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [ 
                          "1","token","N"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "2","accountNumber","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "3","accountType","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Subscription File Format (Response)",
                    id: " Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","E","accountId used in the transaction."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","accountNumberMasked","String(20)","","","R","Masked version of the account number (credit card or bank account) associated with this transaction. The mask is formed by using the first and last digits of the account, e.g. 4********5655."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "3","accountAccessoryMasked","String(14)","","","R","Masked accountAccessory used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "4","accountType","Enum","","","E","accountType used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "SubscriptionAccountTypeEnum"
                      },
                      {
                        row: [ 
                          "5","transactionCode","String(20)","","","E","transactionCode used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "6","customerAccountCode","String(20)","","","E","Value, identifying customer in submitter’s system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINcustomerAccountCode",
                      },
                      {
                        row: [ 
                          "7","transactionId","Long","","","R","Code of the subscription. See "
                        ],
                        descripcion2: "Formerly called referenceNumber. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "integrationNotesSubscriptionCode",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "8","responseCode","String(5)","","","R","Gateway generated response code. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response code",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#", //PENDIENTE
                      },
                      {
                        row: [ 
                          "9","responseMessage","String(255)","","","R","Gateway generated response message. Corresponds to the responseCode value. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response code",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#", //PENDIENTE
                      },
                      {
                        row: [ 
                          "10","providerTransactionCode","String(25)","","","O","Value identifying this transaction in the processor's system, assigned by the gateway."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "Examples (SubscriptionFileFormat)",
                  tabla: {
                    titulotabla: "Subscriptions File Request Header Record",
                    id: "Subscriptions File Request Header Record",
                    descripcion: "accountId|accountType|accountNumber|accountAccessory|transactionCode|customerAccountCode|holderName|holderType|email|phone|street|city|countryCode|state|zipCode",
                    headers:[],
                    body: []
                  }
                },
              ]
            },
            {////////////////Returns//////////////////////////
              titulo: "Returns",
              notas: "",
              info1: [],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: " General Information" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Response Codes Information" },
                  { nombre: "Account Update Information" },
                  { nombre: "Aggregation Information" },
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Token is used" },
                  { nombre: "Account Number is used" },
                ],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Returns Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              tablas: [
                {
                  titulo: "Returns File Format (Response)",
                  tabla: {
                    titulotabla: "General Information",
                    id: " General Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","submissionId","Long","","","R","Identifier of the submission (batch) that the transaction request belongs to."
                        ],
                        descripcion2: "Formerly called submissionCode. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId", 
                      },
                      {
                        row: [ 
                          "2","transactionId","Long","","","R","Unique identifier of the transaction. Generated by the gateway. See "
                        ],
                        descripcion2: "Formerly called referenceNumber. See integration notes for more information.",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCrossReferenceINtransactionId", 
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId", 
                      },
                      {
                        row: [ 
                          "3","originalTransactionId","Long","","returns, chargebacks, reversals only","R","Identifier of the original sale transaction. Used for refund transactions only."
                        ],
                        descripcion2: "Formerly called originalReferenceNumber. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId", 
                      },
                      {
                        row: [ 
                          "4","originalTransactionCode","String(20)","","","R","Transaction code of the original transaction"
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "5","originalTransactionDate","Date","","YYYYMMDD","R","Date of the original transaction"
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "6","approvalCode","String(100)","","credit card only","O","Authorization number issued by the processor (ex processorCode); often shown on a credit card receipt."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "7","isRebillEnabled","Boolean","","1 - true, 0 - false","R","Indicates whether this return was sent into a rebill queue. If true (1), additional response(s) will come later for this transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "8","token","String(22)","","","C","Token generated for the provided payment information. Always present when tokenization service is enabled."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "9","amount","Integer","","amount in cents","R","Amount of the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "10","holderName","String(100)","","","O","Name of a payment card or bank account holder."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "11","sequenceNumber","String(1)","","chargebacks and reversals only","O","Sequence number of the response, when multiple responses for the same transaction are possible. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseSequenceNumber", 
                      },
                      {
                        row: [ 
                          "12","retryFeeAmount","Integer","","not negative; amount in cents","O","Amount of service fee, applied to customer's account when reattempt is made by the system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseINretryFeeAmount", 
                      },
                      {
                        row: [ 
                          "13","expectedEffectiveDate","Date","","YYYYMMDD","O","Date on which a transaction is recorded. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "TransactionInformacionINtransactionDate", 
                      },
                      {
                        row: [ 
                          "14","processedAmount","Integer","","","O","Processed amount of the transaction. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseINprocessedAmount", 
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Information",
                    id: "Account Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "15","accountId","Reference","","","E","Reference to an account associated with the transaction included in the batch file."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId", 
                      },
                      {
                        row: [ 
                          "16","accountNumberMasked","String(20)","","","C","For credit card transaction, the value is card’s account number; for ACH/eCheck transaction, the value is bank account’s number"
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "17","accountAccessory","String(14)","","","R","Payment card's expiration date or bank account's routing number. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "integrationNotesAccountAccessory", 
                      },
                      {
                        row: [ 
                          "18","accountType","Enum","","","R","Type of the account. This value doesn't affect the cost of transaction processing. It is used for validation purposes only."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "ReturnAccountType",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Transaction Information",
                    id: "Transaction Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "19","providerTransactionId","String(50)","","","O","Identifier of the transaction in the processor's system. Provided by the "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "processor",
                        seemoreinformationcom0: ".",
                        modalname0: "#",  //PENDIENTE
                      },
                      {
                        row: [ 
                          "20","transactionCode","String(20)","","","E","transactionCode used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "21","transactionInternalCode","String(20)","","","E","transactionInternalCode used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "22","providerTransactionCode","String(25)","","","R","Value identifying this transaction in the processor's system, assigned by the gateway."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "23","transactionDate","Date","","YYYYMMDD","E","transactionDate used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "24","transactionType","Enum","","","R","Type of the transaction. See "
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "ReturnAccountType",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "TransactionInformationTransactionTypeEnum",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Response Codes Information",
                    id: "Response Codes Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "25","responseCode","String(5)","","","R","Gateway generated response code. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#",  //PENDIENTE
                      },
                      {
                        row: [ 
                          "26","responseMessage","String(255)","","","R","Gateway generated response message. Corresponds to the responseCode value. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#",  //PENDIENTE
                      },
                      {
                        row: [ 
                          "27","warningCode","String(8)","","","O","Any warnings associated with processing of the file. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoINwarningCode",
                      },
                      {
                        row: [ 
                          "28","providerResponseCode","String(20)","","","O","Response code returned by the underlying processor/bank. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoINproviderResponseCode",
                      },
                      {
                        row: [ 
                          "29","providerResponseMessage","String(260)","","","O","Response message returned by the underlying processor/bank. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoINproviderResponseCode",
                      },
                      {
                        row: [ 
                          "30","avsResponseCode","String(5)","","credit card only","O","Gateway generated AVS response code (if address information is provided). See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#",  //PENDIENTE
                      },
                      {
                        row: [ 
                          "31","providerAvsResponseCode","String(20)","","","O","AVS response code returned by the underlying processor/bank."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Update Information",
                    id: "Account Update Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "32","accountNumberUpdated","String(20)","","","O","Updated account number - if provided by account updater"
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "33","accountAccessoryUpdated","String(14)","","","O","Updated payment card's expiration date or bank account's routing number. Present if provided by an account updater."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "34","accountTypeUpdated","Enum","","","O","Updated account type - if provided by account updater"
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessignAccountTypeEnum"
                      },
                      {
                        row: [ 
                          "35","holderNameUpdated","String(100)","","","O","Updated name of a payment card or bank account holder. Present if provided by an account updater."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "36","tokenUpdated","String(22)","","","O","Updated token generated due to account information change provided by account updater or Fed Reserve"
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Aggregation Information",
                    id: "Aggregation Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "37","aggregateTransactionId","Long","","","O","Transaction ID of the aggregate transaction. See "
                        ],
                        descripcion2: "Formerly called aggregateReferenceNumber. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseINprocessedAmount",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "38","aggregateTransactionCode","String(20)","","","O","Transaction code value of the aggregate transaction. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseINprocessedAmount",
                      },
                      {
                        row: [ 
                          "39","aggregateTransactionInternalCode","String(20)","","","O","Transaction internal code value of the aggregate transaction. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ProcessingResponseINprocessedAmount",
                      },
                    ]
                  }
                },
                {
                  titulo: "Conditions (Returns File Format Response)",
                  tabla: {
                    titulotabla: "Token is used",
                    id: "Token is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountNumberMasked","N"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                      {
                        row: [ 
                          "2","token","R"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Number is used",
                    id: "Account Number is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountNumberMasked","R"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                      {
                        row: [ 
                          "2","token","N"
                        ],
                        vinculoUsage2: "MessageFormats2",
                      },
                    ]
                  }
                },
                {
                  titulo: "Examples (ReturnsFileFormatResponse)",
                  tabla: {
                    titulotabla: "Returns Header Record",
                    id: "Returns Header Record",
                    descripcion: "accountId|transactionCode|transactionDate|transactionId|responseCode|responseMessage|avsResponseCode|token|accountNumberUpdated|accountAccessoryUpdated|tokenUpdated|transactionType|submissionId|originalTransactionId|isRebillEnabled|sequenceNumber|transactionInternalCode|accountTypeUpdated|holderNameUpdated|retryFeeAmount|expectedEffectiveDate|warningCode|amount|accountNumberMasked|accountAccessory|accountType|holderName|aggregateTransactionId|aggregateTransactionCode|aggregateTransactionInternalCode|approvalCode|processedAmount|providerResponseCode|providerResponseMessage|providerAvsResponseCode|originalTransactionCode|originalTransactionDate|providerTransactionCode",
                    headers:[],
                    body: []
                  }
                },
              ]
            },
            {////////////////Returns Account Update//////////////////////////
              titulo: "Returns Account Update",
              notas: "",
              info1: [],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Transaction Information" },
                ],
                subtitulo2: "",
                arraytitulo2: [],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Returns Account Update Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              tablas: [
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Returns Account Update File Format (Response)",
                    id: "Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","E","Reference to an account associated with the transaction included in the batch file."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","transactionId","Long","","","R","Unique identifier of the transaction. Generated by the gateway. See "
                        ],
                        descripcion2: "Formerly called referenceNumber. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCrossReferenceINtransactionId",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "3","transactionCode","String(20)","","","E","Identifier of the transaction supplied by a submitter. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "4","transactionInternalCode","String(60)","","","E","Value identifying the transaction in a submitter’s system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "5","customerAccountCode","String(60)","","","E","Value identifying customer in a submitter’s system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "integrationNotesSubscriptionCode",
                      },
                      {
                        row: [ 
                          "6","customerAccountInternalCode","String(60)","","","E","Value identifying customer in a submitter’s system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "7","accountNumberMasked","String(20)","","","R","Masked version of the account number (credit card or bank account) associated with this transaction. The mask is formed by using the first and last digits of the account, e.g. 4********5655."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "8","token","String(40)","","","O","Token associated with a credit card or bank account."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "9","accountNumberUpdated","String(20)","","","C","Updated account number, provided by an account updater. Present if an initial account number was updated and respective information has been received from an account updater."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "10","accountAccessoryUpdated","String(14)","","","C","Updated payment card's expiration date or bank account's routing number. Present if provided by an account updater."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "11","tokenUpdated","String(40)","","","C","Updated token generated due to account information change, provided by an account updater. Present if an initial account number or expiration date were updated and respective information has been received from an account updater."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "12","responseCode","String(5)","","","R","Gateway generated response code. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#", //PENDIENTE
                      },
                      {
                        row: [ 
                          "13","responseMessage","String(255)","","","R","Gateway generated response message. Corresponds to the responseCode value. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#", //PENDIENTE
                      },
                      {
                        row: [ 
                          "14","providerTransactionCode","String(25)","","","O","Value identifying this transaction in the processor's system, assigned by the gateway. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#", //Pendiente el original no lo carga
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Transaction Information",
                    id: "Transaction Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "15","providerTransactionId","String(50)","","","O","Identifier of the transaction in the processor's system. Provided by the "
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoName: "ModalNoProcessorRules",
                        seemoreinformation0: "processor",
                        seemoreinformationcom0: ".",
                        modalname0: "#", //Pendiente
                      },
                    ]
                  }
                },
                {
                  titulo: "Examples (ReturnsAccountUpdateFileFormatResponse)",
                  tabla: {
                    titulotabla: "Returns Account Update Header Record",
                    id: "Returns Account Update Header Record",
                    descripcion: "accountId|responseCode|responseMessage|transactionCode|accountNumberMasked|token|accountNumberUpdated|accountAccessoryUpdated|tokenUpdated|customerAccountCode|customerAccountInternalCode|transactionInternalCode|transactionId",
                    headers:[],
                    body: []
                  }
                },
              ]
            },
            {////////////////Returns Subscription//////////////////////////
              titulo: "Returns Subscription",
              notas: "",
              info1: [],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Transaction Information" },
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Token is used" },
                  { nombre: "Account Number is used" },
                ],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Returns Subscription Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              tablas: [
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Returns Subscription File Format (Response)",
                    id: "Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","E","Reference to an account associated with the transaction included in the batch file."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","accountType","Enum","","","C","Type of the account."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "SubscriptionAccountTypeEnum"
                      },
                      {
                        row: [ 
                          "3","accountNumberMasked","String(20)","","","C","Masked version of the account number (credit card or bank account) associated with this transaction. The mask is formed by using the first and last digits of the account, e.g. 4********5655."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "4","accountAccessoryMasked","String(14)","","","R","Masked payment card's expiration date or bank account's routing number."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "5","token","String(40)","","","C","Token associated with a credit card or bank account."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "6","transactionCode","String(20)","","","R","Identifier of the transaction supplied by a submitter. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "7","transactionId","Long","","","R","Unique identifier of the transaction. Generated by the gateway. See "
                        ],
                        descripcion2: "Formerly called referenceNumber. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCrossReferenceINtransactionId",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "8","customerAccountCode","String(60)","","","O","Value identifying customer in a submitter’s system. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "integrationNotesSubscriptionCode",
                      },
                      {
                        row: [ 
                          "9","responseCode","String(5)","","","R","Gateway generated response code. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#", //PENDIENTE
                      },
                      {
                        row: [ 
                          "10","responseMessage","String(255)","","","R","Gateway generated response message. Corresponds to the responseCode value. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#", //PENDIENTE
                      },
                      {
                        row: [ 
                          "11","providerResponseCode","String(20)","","","O","Response code returned by the underlying processor/bank. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoINproviderResponseCode",
                      },
                      {
                        row: [ 
                          "12","providerResponseMessage","String(260)","","","O","Response message returned by the underlying processor/bank. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCodesInfoINproviderResponseCode",
                      },
                      {
                        row: [ 
                          "13","providerTransactionCode","String(25)","","","O","Value identifying this transaction in the processor's system, assigned by the gateway."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Transaction Information",
                    id: "Transaction Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "14","providerTransactionId","String(50)","","","O","Identifier of the transaction in the processor's system. Provided by the "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "processor",
                        seemoreinformationcom0: ".",
                        modalname0: "#", //PENDIENTE
                      },
                    ]
                  }
                },
                {
                  titulo: "Conditions (Returns Subscription File Format Response)",
                  tabla: {
                    titulotabla: "Token is used",
                    id: "Token is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountType","N"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "2","accountNumberMasked","N"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "3","token","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Number is used",
                    id: "Account Number is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountType","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "2","accountNumberMasked","R"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                      {
                        row: [ 
                          "3","token","N"
                        ],
                        vinculoUsage2: "MessageFormats2"
                      },
                    ]
                  }
                },
                {
                  titulo: "Examples (ReturnsSubscriptionFileFormatResponse)",
                  tabla: {
                    titulotabla: "Returns Subscription Header Record",
                    id: "Returns Subscription Header Record",
                    descripcion: "accountId|accountType|accountNumberMasked|accountAccessoryMasked|token|transactionCode|transactionId|customerAccountCode|responseCode|responseMessage|providerResponseCode|providerResponseMessage|providerTransactionCode",
                    headers:[],
                    body: []
                  }
                },
              ]
            },
            {////////////////Capture//////////////////////////
              titulo: "Capture",
              notas: "",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [],
                subtitulo2: "",
                arraytitulo2: [],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Capture Request Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: " Format",
                arraytitulo1: [],
                subtitulo2: "",
                arraytitulo2: [],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Capture Response Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              tablas: [
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Capture File Format (Request)",
                    id: "Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","R","Reference to an account associated with the transaction included in the batch file."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","transactionId","Reference","","","R","Identifier of the original sale-auth transaction that is going to be captured. See "
                        ],
                        descripcion2: "Formerly called referenceNumber. See ",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCrossReferenceINtransactionId",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "3","transactionCode","String(60)","","","O","Identifier of the capture transaction supplied by a submitter. See "
                        ],
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "CrossReferenceINtransactionCode",
                      },
                      {
                        row: [ 
                          "4","amount","Integer","","amount in cents","O","Amount of the transaction that is going to be captured. By default, it is equal to a total amount of the original transaction, but can be set to a lower value. See "
                        ],
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ModalINPartialSettlement",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Capture File Format (Response)",
                    id: " Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","E","accountId used in the transaction."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","transactionId","Long","","","R","Unique identifier of the capture transaction. Generated by the gateway."
                        ],
                        descripcion2: "Formerly called referenceNumber. See ",
                        vinculoUsage: "Message Formats",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "3","transactionId","Long","","","E","Identifier of the original sale-auth transaction. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "ResponseCrossReferenceINtransactionId",
                      },
                      {
                        row: [ 
                          "4","transactionCode","String(60)","","","E","transactionCode used in the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "5","providerTransactionId","String(50)","","","O","Identifier of the transaction in the processor's system. Provided by the "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "processor",
                        seemoreinformationcom0: ".",
                        modalname0: "#", //PENDIENTE
                      },
                      {
                        row: [ 
                          "6","amount","Integer","","amount in cents","R","Total authorized amount."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "7","uncapturedAmount","Integer","","amount in cents","R","Amount that has not been captured."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "8","cycleCode","Long","","if empty, no cycle was opened","R","Identifier of a retail cycle associated with the transaction."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "9","responseCode","String(5)","","","R","Response code returned by the gateway. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#", //PENDIENTE
                      },
                      {
                        row: [ 
                          "10","responseMessage","String(255)","","","R","Response message returned by the gateway. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "#", //PENDIENTE
                      },
                    ]
                  }
                },
                {
                  titulo: "Examples (CaptureFileFormat)",
                  tabla: {
                    titulotabla: "Capture Request Header Record",
                    id: "Capture Request Header Record",
                    descripcion: "accountId|transactionId|amount",
                    headers:[],
                    body: []
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Capture Response Header Record",
                    id: "Capture Response Header Record",
                    descripcion: "accountId|transactionId|transactionId|amount|uncapturedAmount|cycleCode|responseCode|responseMessage",
                    headers:[],
                    body: []
                  }
                },
              ]
            },
            {//////////////Charges////////////////////////////
              titulo: "Charges",
              notas: "",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [],
                subtitulo2: "",
                arraytitulo2: [],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Charges Request Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: " Format",
                arraytitulo1: [],
                subtitulo2: "",
                arraytitulo2: [],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Charges Response Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              tablas: [
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Charges File Format (Request)",
                    id: "Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","R","Reference to an account to which the charge is going to be applied."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","chargeCode","String(20)","","","R","Identifier of the charge supplied by a submitter."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "3","chargeType","Enum","","default - FF","R","Type of the charge."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "modalChargeType",
                      },
                      {
                        row: [ 
                          "4","amount","Integer","","amount in cents","R","Amount of the charge."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "5","description","String(255)","","","R","Description of the charge."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "6","expectedEffectiveDate","Date","","yyyyMMdd; default - current date","R","Date when the charge becomes effective and should be included in the remittance file. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "TransactionInformacionINtransactionDate",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Charges File Format (Response)",
                    id: " Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","E","accountId used in the charge."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","chargeId","Long","","","R","Unique identifier of the charge. Generated by the gateway."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "3","chargeCode","String(20)","","","E","chargeCode used in the charge."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "4","chargeType","Enum","","","E","chargeType used in the charge."
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoType: "modalChargeType",
                      },
                      {
                        row: [ 
                          "5","amount","Integer","","amount in cents","E","amount used in the charge."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "6","description","String(255)","","","E","description used in the charge."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "7","expectedEffectiveDate","Date","","yyyyMMdd; default - current date","E","expectedEffectiveDate used in the charge."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "8","responseCode","String(5)","","","R","Response code returned by the gateway. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation2: "response codes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "#", //PENDIENTE
                      },
                      {
                        row: [ 
                          "9","responseMessage","String(255)","","","R","Response message returned by the gateway. See "
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation2: "response codes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "#", //PENDIENTE
                      },
                    ]
                  }
                },
                {
                  titulo: "Examples (ChargesFileFormat)",
                  tabla: {
                    titulotabla: "Charges Request Header Record",
                    id: "Charges Request Header Record",
                    descripcion: "accountId|chargeCode|chargeType|amount|description|expectedEffectiveDate",
                    headers:[],
                    body: []
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Charges Response Header Record",
                    id: "Charges Response Header Record",
                    descripcion: "accountId|chargeId|chargeCode|chargeType|amount|description|expectedEffectiveDate|responseCode|responseMessag",
                    headers:[],
                    body: []
                  }
                },
              ]
            },
            {//////////////Charge Payments////////////////////////////
              titulo: "Charge Payments",
              notas: "",
              info1: [],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [],
                subtitulo2: "",
                arraytitulo2: [],
                subtitulo3: "Examples",
                arraytitulo3: [
                  { nombre: "Charges Payments Response Header Record" },
                ],
                subtitulo4: "",
                arraytitulo4: [],
              }],

              tablas: [
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Charges File Format (Request)",
                    id: "Format",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                          "1","accountId","Reference","","","R","Reference to an account for which the charge is set."
                        ],
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        vinculoUsage: "Message Formats",
                        vinculoType: "ProcessingAccountIdReference",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "integrationNotesAccountId",
                      },
                      {
                        row: [ 
                          "2","chargeId","Long","","","R","Unique identifier of the charge generated by the gateway."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "3","chargeCode","String(20)","","","R","Identifier of the charge supplied by a submitter."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "4","paymentAmount","Integer","","amount in cents","R","Amount of funds that has been collected."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "5","remainingAmount","Integer","","amount in cents","R","Remaining amount that has not been collected yet."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [ 
                          "6","sequenceNumber","Integer","","","R","Sequence number of the payment. Used in cases of partial payments."
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "Examples (ChargesPaymentsFileFormatResponse)",
                  tabla: {
                    titulotabla: "Charges Payments Response Header Record",
                    id: "Charges Payments Response Header Record",
                    descripcion: "accountId|chargeId|chargeCode|paymentAmount|remainingAmount|sequenceNumber",
                    headers:[],
                    body: []
                  }
                },
              ]
            },
          ]
        },
      ],

      tabladedatos: [
        {
          titulo: "R",
          descripcion: "required in request/always present in response for direct debit transactions and credit card transactions of all levels (I, II, III)."
        },
        {
          titulo: "O",
          descripcion: "optional in request/not always present in response."
        },
        {
          titulo: "C",
          descripcion: "conditional; conditions of the usage are defined below the corresponding section."
        },
        {
          titulo: "E",
          descripcion: "echo back from request; if present in request, it is present in response, if it is not present in request, it is not present in response."
        },
        {
          titulo: "R2",
          descripcion: "required in request/always present in response for credit card transactions of level II and III only; optional for direct debit and level I credit card transactions."
        },
        {
          titulo: "R3",
          descripcion: "required in request/always present in response for credit card transactions of III only; optional for direct debit and level I, II credit card transactions."
        },
        {
          titulo: "SR",
          descripcion: "required in request/always present in response for split transactions only."
        },
        {
          titulo: "I",
          descripcion: "for internal use only."
        },
        {
          titulo: "N",
          descripcion: "not used."
        },
        {
          titulo: "*",
          descripcion: "required fields in these specific sections are only required if this specific feature is used."
        },
      ]
    }
  }

  async componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const query_loc = query.get('ban');
    console.log(query_loc)
    var resp = [];

    resp = await getDatos(query_loc);

    this.setState({ bandera: query_loc })
    this.setState({ titulo: resp.tituloge })
    this.setState({ contenido: resp.datos })
  }

  pasartexto = (texto) => {
    try {
      return parse(texto);
    } catch (error) {
      return texto;
    }
  }

  obtenerDatosTablas(tituloseccion, titulosubdato){
    console.log(tituloseccion);
    console.log(titulosubdato);
     let datosseccion = this.state.arrayDatos.find(x => x.titulo == tituloseccion);
     let subdatosseccion = datosseccion.subdatos.find(x => x.titulo == titulosubdato);
     console.log(datosseccion);
     console.log(subdatosseccion);

     if(subdatosseccion.hasOwnProperty('tablas')){
      console.log(subdatosseccion.tablas);
      this.setState({ tablasporseccion: subdatosseccion.tablas })
     }
  }

  cerrarModal = (item) => {
    if (item.accion == 0) {
      this.setState({ showmodal: "" })
      /*if(item.modal=="Message Formats" || item.modal == "ProcessignAccountTypeEnum" || item.modal == "ProcessignHolderTypeEnum"){
        this.setState({ showmodal: "" })
      }*/
    } else if (item.accion == 1) {
      this.setState({ showmodal: item.modal })
      /*if(item.modal=="Message Formats" || item.modal == "ProcessignAccountTypeEnum" || item.modal == "ProcessignHolderTypeEnum"){
        this.setState({ showmodal: "" })
      }*/
    }
  }

  seleccionarModal(titulomodal){
    console.log(titulomodal);
    this.setState({ showmodal: titulomodal})
  }

  render() {
    return (
      <>
        <Navbar />

        {(this.state.showmodal == "Message Formats") 
        || (this.state.showmodal == "ProcessignAccountTypeEnum") 
        || (this.state.showmodal == "ProcessignHolderTypeEnum") 
        || (this.state.showmodal == "TransactionInformationTransactionTypeEnum") 
        || (this.state.showmodal == "TransactionInformationTransactionIndustryTypeEnum") 
        || (this.state.showmodal == "MessageFormats2") 
        || (this.state.showmodal == "ProcessingResponsetransactionTypeEnum")
        || (this.state.showmodal == "SubscriptionAccountTypeEnum")
        || (this.state.showmodal == "ReturnAccountType") 
        || (this.state.showmodal == "modalChargeType")? (
              <ModalConTablas tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
          ) : (this.state.showmodal == "ProcessingAccountIdReference") 
            || (this.state.showmodal == "TransactionInformacionSplitSchemaIdReference") ? 
            <ModalReferenceFieldType tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
          : (this.state.showmodal == "subscriptionCodeName")
            || (this.state.showmodal == "BillingAddressInfStreet")
            || (this.state.showmodal == "ProcessorRules2") ? 
            <ModalProcessorRules tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "integrationNotesAccountId")
          || (this.state.showmodal == "CrossReferenceINoriginalTransactionId") ? 
            <ModalINLegacyFieldsNames tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "integrationNotesAccountAccessory") ? 
            <ModalINDirectDebit tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "integrationNotesSubscriptionCode") ? 
            <ModalINBankInformationSubscription tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "TransactionInformacionINFeeAmount") ? 
            <ModalINServiceFees tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "TransactionInformacionINtransactionDate") ? 
            <ModalINEffectiveDate tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "TransactionInformacionINtransactionGroupCode") ? 
            <ModalINTransactionGropus tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "TransactionInformacionINdescriptor") ? 
            <ModalINDynamicDescriptor tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "TransactionInformacionINoriginalNetworkTransactionId") ? 
            <ModalINStoredCredentials tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "TransactionInformacionINsplitSchemaId") ? 
            <ModalINSplitPayments tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "CrossReferenceINtransactionCode")
          || (this.state.showmodal == "CrossReferenceINtransactionInternalCode")
          || (this.state.showmodal == "CrossReferenceINcustomerAccountCode")
          || (this.state.showmodal == "CrossReferenceINcustomerAccountInternalCode") ? 
            <ModalINCrossReferenceFieldsName tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "BillingAddressInfINstreet") ? 
            <ModalINInterchangeRateQuealification tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ProcessingINholderName") ? 
            <ModalINHolderNameNormalizationRules tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ProcessingResponseINprocessedAmount") ? 
            <ModalINPaymentAggregation tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ProcessingResponseINretryFeeAmount") ? 
            <ModalINRetryFee tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ProcessingResponseSequenceNumber") ? 
            <ModalINRSequenceNumber tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ResponseCrossReferenceINtransactionId") ? 
            <ModalINRecordID tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ResponseCodesInfoINwarningCode") ? 
            <ModalINWarningCode tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ResponseCodesInfoINproviderResponseCode") ? 
            <ModalINProviderResponseData tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ProsessingINSplitAmountFormat") ? 
            <ModalINSplitAmountFormat tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ProsessingINRetryProcess") ? 
            <ModalINRetryProcess tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "modalProcessorRulesAmex") ? 
            <ModalProcessorRulesAmex tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ModalINNoticesOfChange") ? 
            <ModalINNoticesofChange tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ModalNoProcessorRules") ? 
            <ModalNoProcessorRules tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ModalINPartialSettlement") ? 
            <ModalINPartialSettlement tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ModalINBatchTokenization") ? 
            <ModalINBatchTokenization tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ModalTokenization") ? 
            <ModalTokenization tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : (this.state.showmodal == "ModalINBatchEncryption") ? 
            <ModalINBatchEncryption tipo="full" 
              show={ this.state.showmodal != "" ? true: false}
              modal={this.state.showmodal}
              cerrarModal={this.cerrarModal} />
        : null} 

        <div className='anchoMaximo' style={{ paddingTop: '120px', display: 'block', margin: "auto", width: "95%" }}>

          <div className="row">
            <div className="col-xs-12 col-sm-3">
              <h1 style={{ fontSize: 'x-large' }}><b>{this.state.titulo}</b></h1>
            </div>

            <div className="col-xs-12 col-sm-12">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h3 className="panel-title">Version</h3>
                </div>

                <div className="panel-body">
                  <div className='row'>
                    <div className='col'>
                      <p style={{ marginBottom: '0px' }}>Current Specification Version: V9.1.4</p>
                    </div>
                    <div className='col-auto'>
                      <p style={{ marginBottom: '0px' }} ><a href="/change-history?application=C&amp;api=realtime">View
                        Change History</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.contenido.map((item, index) => (
              <div key={index}>
                <div className="row">
                  <div className="col-12">
                    <h2>{item.titulo}</h2>
                    <div className="row">
                      {item.data.map((data, index) => (
                        <div className="col-4">
                          <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                            <h3 className='lblencabezados'><a href={"#"+data.nombre.replaceAll(" ", "_")}><LuArrowRightCircle className='icoflecha' /><a >{data.nombre} </a></a>
                            {
                              data.hasOwnProperty('req') ? <b className='colorrojo'>*</b>: null
                            }
                            </h3>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="prettyline" ></div>
              </div>
            ))}
          </div>

          <div className='row'>
              <div className='col-xs-12 col-sm-12'>
                  <span className='colorrojo'>*</span> Available
                  for testing with <a href="/terminology#host_emulator" target="_blank">host emulator</a>
                  <br/><br/>
              </div>
          </div>

          <br />
          <h2 style={{ fontSize: 'x-large' }}><b>General Information</b></h2>
          <br />

          <div className="panel panel-default" id={"General_Information"}>
            <div className="panel-heading" >
              <h3 className="panel-title">General information&nbsp;&nbsp; <a data-toggle="tooltip" data-placement="right" className="mytooltip cursor_copy" title="" id="clipboardnote-general-information-connectivity-information" href="/processing-api/realtime/operations#note-general-information-connectivity-information" onclick="return false;" data-original-title="Direct link"><i className="fa fa-share-square-o"></i></a></h3>
            </div>
            <div className="panel-body">
              The purpose of this document is to provide documentation for 3rd parties to integrate with UniCharge processing gateway. It includes information on basic gateway operations, 
              <a href="#Folder_Structure">folder structure</a>, <a href="#File_Format_and_Name_Convention">file name convention</a>, file formats and tokenization.
              <br/>
              The batch transaction processing is implemented through sFTP protocol. Batches are submitted as <a href="http://en.wikipedia.org/wiki/Vertical_bar">pipe (|)</a> delimited files (one transaction per row). The protocol supports 2 operations: sale and credit and can accept both credit card and direct debit/eCheck transactions. Any sFTP enabled client can communicate with UniCharge; file upload/download can be done manually or using timer based automated process. To learn more about end-points used for file submission, review <a href="/guide_content?id=11#Users">Security Management</a> guide.                                                                                  
            </div>
          </div>


          {this.state.arrayDatos.map((itemt, index) => (
            <div key={index}>
              <h2><b className="panel-title" style={{ fontSize: 'large', paddingTop: "30px", paddingBottom: "15px" }}>{itemt.titulo}</b></h2>
              
              {itemt.subdatos.map((item, index) => (
                <div key={index}>
                  <div className="row ">
                    <div className="col-12 ">
                      <div className='borderdetallestabla'>
                        <div className="panel panel-default">
                          <div className="panel-heading" id={item.titulo.replaceAll(" ", "_")}>
                            <b  className="panel-title" style={{ fontSize: 'small' }}>{item.titulo}</b>
                          </div>
                        </div>

                        <div className="row" >
                          <div className="col-auto" >
                            {item.info1.map((itemi, index2) => (
                              <div className=" rowsinmargin">
                                <div className="row" >
                                  {/* ///////////////////////REQUEST////////////////////////////////////// */}
                                  <div className='col-12 col-sm-12 col-md-auto bordertablasub'>
                                    <div className='infotblastext' ><label onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )}>{itemi.subtitulo}</label></div>
                                  </div>

                                  {/* /////////////////////FORMAT//////////////////////////////////////// */}
                                  <div className='col-12 col-sm-12 col-md-auto bordertablasub'>
                                    {itemi.arraytitulo1.length > 0 ?
                                      <div className='infotblastext colorfuerteazul'>
                                        <div className="dropdown">
                                          <a onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className="subtablassubtitles colorfuerteazul" data="1" href={"#"+itemi.subtitulo1.replaceAll(" ", "_")}>{itemi.subtitulo1}</a>
                                          <div className="dropdown-content subtblas">
                                            {itemi.arraytitulo1.map((itema, index3) => (

                                              <a key={index3} onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className="subtablassubtitles colorfuerteazul" data="1" href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <div className='infotblastext colorfuerteazul'>
                                        <a className='infotblastext colorfuerteazul' data="1" onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} href={"#"+itemi.subtitulo1.replaceAll(" ", "_")}>{itemi.subtitulo1}</a>
                                      </div>
                                    }
                                  </div>

                                  {/* /////////////////////CONDITIONS//////////////////////////////////////// */}
                                  <div className='col-12 col-sm-12 col-md-auto bordertablasub'>
                                    {itemi.arraytitulo2.length > 0 ?
                                      <div className='infotblastext colorfuerteazul'>
                                        <div className="dropdown">
                                          <label onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className='colorfuerteazul'>{itemi.subtitulo2}</label>
                                          <div className="dropdown-content subtblas">
                                            {itemi.arraytitulo2.map((itema, index3) => (

                                              <a key={index3} onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className="subtablassubtitles colorfuerteazul" data="1" href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <div className='infotblastext colorfuerteazul'>
                                        <a className='infotblastext colorfuerteazul' data="1" onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} href={"#"+itemi.subtitulo2.replaceAll(" ", "_")}>{itemi.subtitulo2}</a>
                                      </div>
                                    }
                                  </div>

                                  {/* //////////////////////////EXAMPLES/////////////////////////////////// */}
                                  <div className='col-12 col-sm-12 col-md-auto bordertablasub'>
                                    {itemi.arraytitulo3.length > 0 ?
                                      <div className='infotblastext colorfuerteazul'>
                                        <div className="dropdown">
                                          <label onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className='colorfuerteazul'>{itemi.subtitulo3}</label>
                                          <div className="dropdown-content subtblas">
                                            {itemi.arraytitulo3.map((itema, index3) => (

                                              <a key={index3} onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className="subtablassubtitles colorfuerteazul" data="1" href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <div className='infotblastext colorfuerteazul'>
                                        <a className='infotblastext colorfuerteazul' data="1" onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} href={"#"+itemi.subtitulo3.replaceAll(" ", "_")}>{itemi.subtitulo3}</a>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            ))}

                            {item.info2.map((itemi, index2) => (
                              <div key={index2}>
                                {itemi.subtitulo != "" ?
                                  <div className=" rowsinmargin">
                                    <div className="row" >
                                      {/* ////////////////////////RESPONSE///////////////////////////////////// */}
                                      <div className='col-12 col-sm-12 col-md-auto bordertablasub'>
                                        <div className='infotblastext'><label>{itemi.subtitulo}</label></div>
                                      </div>

                                      {/* ////////////////////////FORMAT///////////////////////////////////// */}
                                      <div className='col-12 col-sm-12 col-md-auto bordertablasub'>
                                        {itemi.arraytitulo1.length > 0 ?
                                          <div className='infotblastext colorfuerteazul'>
                                            <div className="dropdown">
                                              <a className="colorfuerteazul" data="1" onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} href={"#"+itemi.subtitulo1.replaceAll(" ", "_")}>{itemi.subtitulo1}</a>
                                              <div className="dropdown-content subtblas">
                                                {itemi.arraytitulo1.map((itema, index3) => (

                                                  <a key={index3} className="subtablassubtitles colorfuerteazul" data="1" onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          <div className='infotblastext colorfuerteazul'>
                                            <a className='infotblastext colorfuerteazul' data="1" onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} href={"#"+itemi.subtitulo1.replaceAll(" ", "_")}>{itemi.subtitulo1}</a>
                                          </div>
                                        }
                                      </div>

                                      {/* /////////////////////////CONDITIONS//////////////////////////////////// */}
                                      <div className='col-12 col-sm-12 col-md-auto bordertablasub'>
                                        {itemi.arraytitulo2.length > 0 ?
                                          <div className='infotblastext colorfuerteazul'>
                                            <div className="dropdown">
                                              <label className='colorfuerteazul' onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )}>{itemi.subtitulo2}</label>
                                              <div className="dropdown-content subtblas">
                                                {itemi.arraytitulo2.map((itema, index3) => (

                                                  <a key={index3} className="subtablassubtitles colorfuerteazul" data="1" onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a> 

                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          <div className='infotblastext colorfuerteazul'>
                                            <a className='infotblastext colorfuerteazul' data="1" onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} href={"#"+itemi.subtitulo2.replaceAll(" ", "_")}>{itemi.subtitulo2}</a>
                                          </div>
                                        }
                                      </div>

                                      {/* ///////////////////////EXAMPLES////////////////////////////////////// */}
                                      <div className='col-12 col-sm-12 col-md-auto bordertablasub'>
                                        {itemi.arraytitulo3.length > 0 ?
                                          <div className='infotblastext colorfuerteazul'>
                                            <div className="dropdown">
                                              <label className='colorfuerteazul' onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )}>{itemi.subtitulo3}</label>
                                              <div className="dropdown-content subtblas">
                                                {itemi.arraytitulo3.map((itema, index3) => (

                                                    <a key={index3} className="subtablassubtitles colorfuerteazul" data="1" onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          <div className='infotblastext colorfuerteazul'>
                                            <a className='infotblastext colorfuerteazul' data="1" onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} href={"#"+itemi.subtitulo3.replaceAll(" ", "_")}>{itemi.subtitulo3}</a>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  : null}
                              </div>
                            ))}
                          </div>

                          <div className='col-5 '>
                            <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px", textAlign: "justify" }}>
                              {this.pasartexto(item.notas)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              ))}
            </div>
          ))}

          <br /><br />
          <h2 style={{ fontSize: 'x-large' }}><b>Processing</b></h2>
          <br />

          <div className="panel panel-default" id={"Folder_Structure"}>
            <div className="panel-heading" >
            <h3 class="panel-title">Folder Structure&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-folder-structure" href="/processing-api/batch/operations#note-processing-folder-structure" onclick="return false;" data-original-title="Direct link"><i class="fa fa-share-square-o"></i></a></h3>
            </div>
            <div className="panel-body">
              To be able to process batch files, each portfolio, reseller and merchant within the gateway is provided with certain folders on the FTP server, which are used for uploading and downloading of various batch files.
              <br/><br/>
              Each portfolio folder is located in the root of the FTP server. Reseller folders are located under a folder of an associated portfolio. Merchant folders are located under a folder of an associated reseller. If no reseller is assigned to the merhant, its folder is created under a folder of an associated portfolio. This approach allows making access for batch processing more convenient: FTP user that corresponds to the service user of a reseller is created with an access to the folder of the corresponding reseller. Therefore, when a new merchant is created, the reseller should not re-configure an associated FTP user to grant access to the merchant's folder.
              <br/><br/>
              Below you can find detailed information about name convention of each folder type and the structure of available sub-folders. 
              <br/><br/>
              <h2>Portfolio Folders</h2>
              The name of a portfolio folder is comprised of two parts: letter <em>p</em> and the ID of the portfolio. Each portfolio folder may contain the following sub-folders: 
              <ul type="circle" style={{ marginLeft: '10px', color:  '#5688c9'}}>
                <li><em>inbox</em> - a folder for uploading files to be processed;</li>
                <li><em>outbox</em> - a folder that contains returns, CAU returns, subscription returns, charges, charge payments files, aggregated merchant statements;</li>
                <li><em>data</em> - a folder for <a href="/processing-api/batch/notes/data-export-data-export">files exchange</a>.</li>
              </ul>
              <br/>
              Example:
              <br/>
              <pre><div class="table-responsive"><table class="pre pull-left"><tbody><tr>
              <td align="left">Portfolio ID:
              </td>
              <td align="left">100
              </td>
              </tr><tr>
              <td align="left">Inbox:
              </td>
              <td align="left">ftps://[server-name]/p100/inbox
              </td>
              </tr><tr>
              <td align="left">Outbox:
              </td>
              <td align="left">ftps://[server-name]/p100/outbox
              </td>
              </tr><tr>
              <td align="left">Data:	
              </td>
              <td align="left">ftps://[server-name]/p100/data
              </td>
              </tr></tbody></table>
              </div></pre>

              <br/>
              <h2>Reseller Folders</h2>
              The name of a reseller folder is comprised of two parts: letter <em>r</em> and the ID of the reseller. Each reseller folder may contain the following sub-folders: 
              <ul type="circle" style={{ marginLeft: '10px', colo:  '#5688c9'}}>
              <li><em>inbox</em> - a folder for uploading files to be processed;
              </li>
              <li><em>outbox</em> - a folder that contains returns, CAU returns, subscription returns, charges, charge payments files;
              </li>
              <li><em>data</em> - a folder for <a href="/processing-api/batch/notes/data-export-data-export">files exchange</a>.
              </li>
              </ul>

              <br/>
              Example:
              <br/>
              <pre>
                <div class="table-responsive"><br/>
                  <table class="pre pull-left">
                    <tbody>
                      <tr>
                        <td align="left">Reseller ID:</td>
                        <td align="left">3</td>
                      </tr>
                      <tr>
                        <td align="left">Inbox:</td>
                        <td align="left">ftps://[server-name]/p100/r3/inbox</td>
                      </tr>
                      <tr>
                        <td align="left">Outbox:</td>
                        <td align="left">ftps://[server-name]/p100/r3/outbox</td>
                      </tr>
                      <tr>
                        <td align="left">Data:</td>
                        <td align="left">ftps://[server-name]/p100/r3/data</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </pre>

              <br/>
              <h2>Merchant Folders</h2>
              The name of a merchant folder corresponds the ID of the merchant. Each merchant folder contains the following sub-folders: 
              <br/>
              <ul type="circle" style={{ marginLeft: '10px', colo:  '#5688c9'}}>
                <li><em>inbox</em> (required) - a folder for uploading files to be processed;</li>
                <li><em>outbox</em> (required) - a folder that contains processing results;</li>
                <li><em>invault</em> - a folder for uploading files for tokenization prior to processing. The folder is used when a batch file contains raw account numbers. In this case, all files are required to be submitted to the invault folder to be tokenized. After being tokenized, the files are subsequently put into the inbox folder for regular processing. See <span class="linked" style={{color: "#0066B3"}} onClick={() => this.seleccionarModal("ModalINBatchTokenization")}>integration notes</span> for more information regarding files that contain unencrypted account numbers.</li>
                <li><em>data</em> - a folder for <a href="/processing-api/batch/notes/data-export-data-export">files exchange</a>.</li>
              </ul>

              <br/>
              Be advised that sFTP folders are not created automatically. So if you assign new reseller to a merchant, you have to create new folders for this merchant manually.
              <br/><br/>

              Example:
              <br/>
              <pre>
                <div class="table-responsive">
                  <table class="pre pull-left">
                    <tbody>
                      <tr>
                        <td align="left">Merchant ID:</td>
                        <td align="left">2000</td>
                      </tr>
                      <tr>
                        <td align="left">Inbox:</td>
                        <td align="left">ftps://[server-name]/p100/r3/2000/inbox</td>
                      </tr>
                      <tr>
                        <td align="left">Invault:</td>
                        <td align="left">ftps://[server-name]/p100/r3/2000/invault</td>
                      </tr>
                      <tr>
                        <td align="left">Outbox:</td>
                        <td align="left">ftps://[server-name]/p100/r3/2000/outbox</td>
                      </tr>
                      <tr>
                        <td align="left">Data:</td>
                        <td align="left">ftps://[server-name]/p100/r3/2000/data</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </pre>

            </div>
          </div>

          <div className="panel panel-default" id={"File_Format_and_Name_Convention"}>
            <div className="panel-heading" >
              <h3 class="panel-title">File Format and Name Convention&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-file-format-and-name-convention" href="/processing-api/batch/operations#note-processing-file-format-and-name-convention" onclick="return false;" data-original-title="Direct link"><i class="fa fa-share-square-o"></i></a></h3>
            </div>
            <div className="panel-body">
              <h2>File Format</h2>
              All files, uploaded to the FTP, are required to be submitted as a single pipe-delimited text file in CSV format. To reduce the size of the file and ensure its integrity, the file should be archived by any standard ZIP client with no password protection applied. The resulting ZIP file has to be placed in <em>/inbox</em> folder for processing. For cases when the user interface is used for file uploading, a file format can be either CSV or ZIP. 
              <br/>
              If a zipped file contains any sensitive information, it can be additionally encrypted using PGP algorithm. Files are encrypted with a public key that you can request from the gateway support team. If the file is encrypted, <em>.pgp</em> is added after file extension. To learn more about batch file encryption, see <span class="linked" style={{color: "#0066B3"}} onClick={() => this.seleccionarModal("ModalINBatchEncryption")}>integration notes</span>. 
              <br/>
              After a file is uploaded to the FTP server, it gets processed by the gateway and submitted to a processor. When the response is received from the processor, it is imported to the gateway and a corresponding response file is generated. The response file, which is a ZIP archive with a compressed CSV file inside, can be downloaded from the <em>/outbox</em> folder. The response has the exact same name as the request file. 
              <br/>
              If the request file is damaged, the system generates <a href="#Parsing_Error__File">Parsing Error File</a>. If the file contains validation errors, the system generates <a href="#Validation_Error_File">Validation Error File</a>. Both files have CSV format and are placed in <em>/outbox</em> folder after being generated. Note that if an error file is generated, your request file is rejected, transactions are not processed and a response file is not received.
              <br/>

              <h2>File Name Convention</h2>
              File names consist of the following parts: 
              <br/>
              <ul type="circle" style={{ marginLeft: '10px'}}>
                <li>name of the file that can be anything that integrator desires. Can be a combination of letters, digits and dots. It is recommended to include a timestamp in yyyyMMdd or yyyyMMddHHmmss format as part of this name (for example, 20150930);</li>
                <li>suffix that corresponds to the type of a file (for example .returns, .au, .capture, etc);</li>
                <li>file extension (for example .csv, .zip, .zip.pgp).</li>
              </ul>

              <br/>
              Names of the ZIP file and included CSV file are required to match. Examples of the names can be reviewed in the table below.
              <br/>

              <div class="table-responsive">
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <table class="prettytable table my-table pull-left" style={{ marginTop: '-240px'}}>
                  <tbody>
                    <tr></tr>
                    <tr>
                      <td>Submission Date and Time (optional):</td>
                      <td>Sept 30th 2015 at 5:46 AM</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Processing_File">Request File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Request CSV (pipe) File Name:</td>
                      <td>monthly_billing_201509300546.csv</td>
                    </tr>
                    <tr>
                      <td>Request ZIP File Name:</td>
                      <td>monthly_billing_201509300546.zip</td>
                    </tr>
                    <tr>
                      <td>Request PGP File Name:</td>
                      <td>monthly_billing_201509300546.zip.pgp</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Processing_File">Response File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Response CSV (pipe) File Name:</td>
                      <td>monthly_billing_201509300546.csv</td>
                    </tr>
                    <tr>
                      <td>Response ZIP File Name:</td>
                      <td>monthly_billing_201509300546.zip</td>
                    </tr>
                    <tr>
                      <td>Response PGP File Name:</td>
                      <td>monthly_billing_201509300546.zip.pgp</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Parsing_Error__File">Parsing File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Parsing Error File Name:</td>
                      <td>monthly_billing_201509300546.error.csv</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Validation_Error_File">Validation Error File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Validation Error File Name:</td>
                      <td>monthly_billing_201509300546.validation.csv</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Account_Update_File">Account Update File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Account Update CSV (pipe) File Name: </td>
                      <td>account_update_20150930.2001.au.csv</td>
                    </tr>
                    <tr>
                      <td>Account Update ZIP File Name (by account):</td>
                      <td>account_update_20150930.2001.au.zip</td>
                    </tr>
                    <tr>
                      <td>Account Update PGP File Name:</td>
                      <td>account_update_20150930.2001.au.zip.pgp</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Subscription_File">Subscription File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Subscription CSV (pipe) File Name (by account):</td>
                      <td>subscription_20150930.2001.subscriptions.csv</td>
                    </tr>
                    <tr>
                      <td>Subscription ZIP File Name (by account):</td>
                      <td>subscription_20150930.2001.subscriptions.zip</td>
                    </tr>
                    <tr>
                      <td>Subscription PGP File Name (by account):</td>
                      <td>subscription_20150930.2001.subscriptions.zip.pgp</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Returns_File">Returns File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Returns CSV (pipe) File Name (by merchant):
        </td>
                      <td>20150930.1.2000.returns.csv
        </td>
                    </tr>
                    <tr>
                      <td>Returns CSV (pipe) File Name (by account):
        </td>
                      <td>20150930.1.2001.returns.csv
        </td>
                    </tr>
                    <tr>
                      <td>Returns ZIP File Name (by merchant):
        </td>
                      <td>20150930.1.2000.returns.zip
        </td>
                    </tr>
                    <tr>
                      <td>Returns ZIP File Name (by account):
        </td>
                      <td>20150930.1.2001.returns.zip
        </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Returns_Account_Update_File">Returns Account Update File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Returns Account Update CSV (pipe) File Name (by merchant): 
        </td>
                      <td>20150930.2000.returns.au.csv
        </td>
                    </tr>
                    <tr>
                      <td>Returns Account Update CSV (pipe) File Name (by account): 
        </td>
                      <td>20150930.2001.returns.au.csv
        </td>
                    </tr>
                    <tr>
                      <td>Returns Account Update ZIP File Name (by merchant):
        </td>
                      <td>20150930.2000.returns.au.zip
        </td>
                    </tr>
                    <tr>
                      <td>Returns Account Update ZIP File Name (by account):
        </td>
                      <td>20150930.2001.returns.au.zip
        </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Returns_Subscription_File">Returns Subscription File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Returns Subscription CSV (pipe) File Name (by merchant): 
        </td>
                      <td>20150930.2000.returns.subscriptions.csv
        </td>
                    </tr>
                    <tr>
                      <td>Returns Subscription CSV (pipe) File Name (by account): 
        </td>
                      <td>20150930.2001.returns.subscriptions.csv
        </td>
                    </tr>
                    <tr>
                      <td>Returns Subscription ZIP File Name (by merchant):
        </td>
                      <td>20150930.2000.returns.subscriptions.zip
        </td>
                    </tr>
                    <tr>
                      <td>Returns Subscription ZIP File Name (by account):
        </td>
                      <td>20150930.2001.returns.subscriptions.zip
        </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Capture_File">Capture File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Capture CSV (pipe) File Name: 
        </td>
                      <td>20150930.capture.csv
        </td>
                    </tr>
                    <tr>
                      <td>Capture ZIP File Name:
        </td>
                      <td>20150930.capture.zip
        </td>
                    </tr>
                    <tr>
                      <td>Capture PGP File Name: 
        </td>
                      <td>20150930.capture.zip.pgp
        </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Charges_File">Charges File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Charges CSV (pipe) File Name: 
        </td>
                      <td>20150930.charges.csv
        </td>
                    </tr>
                    <tr>
                      <td>Charges ZIP File Name:
        </td>
                      <td>20150930.charges.zip
        </td>
                    </tr>
                    <tr>
                      <td>Charges PGP File Name: 
        </td>
                      <td>20150930.charges.zip.pgp
        </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <strong>
                          <a href="#Charge_Payments_File">Charge Payments File</a>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Charge Payments CSV (pipe) File Name: 
        </td>
                      <td>20150930.payments.csv
        </td>
                    </tr>
                    <tr>
                      <td>Charge Payments ZIP File Name:
        </td>
                      <td>20150930.payments.zip
        </td>
                    </tr>
                    <tr>
                      <td>Charge Payments PGP File Name: 
        </td>
                      <td>20150930.payments.zip.pgp
        </td>
                    </tr> 
                  </tbody>
                </table>
              </div>

            </div>
          </div>


          <div className="panel panel-default" id={"Processing_File"}>
            <div className="panel-heading" >
            <h3 class="panel-title">Processing File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-processing-file" href="/processing-api/batch/operations#note-processing-processing-file" onclick="return false;" data-original-title="Direct link"><i class="fa fa-share-square-o"/></a></h3>
            </div>
            <div className="panel-body">
            Processing request file contains a list of transactions that need to be processed. 
            <br/><br/>
                      <h2>Request File</h2>
            To submit transactions for processing, you have to submit a corresponding request file. To review the naming convention of the file, see <span class="note-doc" type="note" data="File_Format_and_Name_Convention">integration notes</span>. 

            <br/>
                        <br/>
                          <h2>Response File</h2>
            After a request file is submitted to a processor and the respective response is received, a response file is generated. The process differs for payment card and direct debit transactions:
            <br/>
                            <ul type="circle" style={{ marginLeft: '10px', colo:  '#5688c9'}}>
                              <li>For payment card transactions, an outcome of the transaction is available in most cases in real-time. Therefore, the response included in the file reflects the final outcome of the transaction. A processed file can't be canceled.
            </li>
                              <li>For direct debit transactions, a situation is more complicated because most direct debit systems are not working in real-time. Therefore, all transactions are marked by the gateway as approved when being sent to a processor, and there is a certain period, during which returns file, containing transactions that were not processed, can be generated.  A processed file can't be canceled.
            </li>
                            </ul>
                            <br/>
            Returns, chargebacks and chargeback reversals, as well as results of rebill process (declines or approvals), can be received in two ways based on merchant's selection:
            <br/>
                                <ul type="circle" style={{ marginLeft: '10px', colo:  '#5688c9'}}>
                                  <li>As part of the response file - returns, chargebacks, reversals and rebill results will be accumulated by the system and included in the standard response file when it is generated;
            </li>
                                  <li>As a separate file - returns, chargebacks, reversals and rebill results will be placed in a separate file, which will be generated on daily basis. The file will not be generated if there are no transactions of these types for a given day. The file is placed in <em>/outbox</em> folder.
            </li>
                                </ul>
                                <br/>
            To review the format of the file, see <a href="/processing-api/batch/operations#operation-processing-batch">Processing File Format</a>.
            </div>
          </div>


          <div className="panel panel-default" id={"Tokenization_File"}>
            <div className="panel-heading" >
              <h3 class="panel-title">Tokenization File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-tokenization-file" href="/processing-api/batch/operations#note-processing-tokenization-file" onclick="return false;" data-original-title="Direct link"><i class="fa fa-share-square-o"/></a></h3>
            </div>
            <div className="panel-body">
              <h2>Tokenization File Format</h2>
              Tokenization request files are required to be submitted as a single pipe-delimited text file in CSV format. To reduce the size of the file and ensure its integrity, the file should be archived by any standard ZIP client with no password protection applied. The resulting ZIP file has to be placed in <em>/invault</em> folder for processing. 
              <br/>
              After a file is uploaded to the FTP server, it gets tokenized, processed by the gateway and submitted to a processor. When the response is received from the processor, it is imported to the gateway and a corresponding response file is generated. The response file can be downloaded from the <em>/outbox</em> folder. Response files are ZIP archives with compressed CSV files inside. Name of the response file corresponds to the name of request file. 
              <br/>
              When the request file is damaged and cannot be read or processed, a parsing error file is generated. The file contains a message that explains why the file could not be processed.
              <br/><br/>
              To review the format of the file, see <a href="/processing-api/batch/operations#operation-tokenization-batch">Tokenization File Format</a>.
              <br/>

              <h2>Tokenization File Name Convention</h2>
              File names consist of the following parts: 
              <br/>
              <ul type="circle" style={{ marginLeft: '10px', colo:  '#5688c9'}}>
                <li>name of the file that can be a combination of letters, digits and dots;</li>
                <li>suffix that corresponds to the type of a file (.tokens or .tokens.error);</li>
                <li>file extension (.csv or .zip).</li>
              </ul>
              <br/>
              Names of the ZIP file and included CSV file are required to match. Examples of the names can be reviewed in the table below.
              <br/>
              <div class="table-responsive">
                <table class="prettytable table my-table pull-left">
                  <tbody>
                    <tr></tr>
                    <tr>
                      <td>Tokenization Request ZIP File Name:</td>
                      <td>account_numbers.tokens.zip</td>
                    </tr>
                    <tr>
                      <td>Tokenization Request CSV (pipe) File Name:</td>
                      <td>account_numbers.tokens.csv</td>
                    </tr>
                    <tr>
                      <td>Tokenization Response ZIP File Name:</td>
                      <td>account_numbers.tokens.zip</td>
                    </tr>
                    <tr>
                      <td>Tokenization Response CSV (pipe) File Name:</td>
                      <td>account_numbers.tokens.csv</td>
                    </tr>
                    <tr>
                      <td>Parsing Error File Name:</td>
                      <td>account_numbers.tokens.error.csv</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="panel panel-default" id={"Parsing_Error__File"}>
            <div className="panel-heading" >
              <h3 class="panel-title">Parsing Error File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-parsing-error-file" href="/processing-api/batch/operations#note-processing-parsing-error-file" onclick="return false;" data-original-title="Direct link">
                  <i class="fa fa-share-square-o"/>
                </a>
              </h3>
            </div>
            <div className="panel-body">
              Parsing error file is generated when a request file is damaged and cannot be read or processed. For example, the file is generated when it is impossible to extract a ZIP file or read the content of the file. The file contains a message that explains why the file could not be processed. If an error file has been received, the entire request file must be re-uploaded for re-processing.
              <br/><br/>
              To review the naming convention of the file, see <span class="note-doc" type="note" data="File_Format_and_Name_Convention">integration notes</span>. To review the format of the file, see <a href="/processing-api/batch/operations#operation-parsing-error-file-batch">Parsing Error File Format</a>.
            </div>
          </div>
          
          <div className="panel panel-default" id={"Validation_Error_File"}>
            <div className="panel-heading" >
              <h3 class="panel-title">Validation Error File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-validation-error-file" href="/processing-api/batch/operations#note-processing-validation-error-file" onclick="return false;" data-original-title="Direct link">
                  <i class="fa fa-share-square-o"/>
                </a>
              </h3>
            </div>
            <div className="panel-body">
            Validation error file is generated when a request file includes some records that cannot be processed due to validation errors. The file contains a list of transactions that could not be parsed or properly persisted, with <em>errorCode</em> and <em>errorMessage</em> values associated with every transaction and explaining the specific issue with it. To review the naming convention of the file, see <span class="note-doc" type="note" data="File_Format_and_Name_Convention">integration notes</span>.

<br/>
Critical validation errors are:
<br/>
					<ul type="circle" style={{ marginLeft: '10px', colo:  '#5688c9'}}>
						<li>Invalid characters – non-numeric symbols are added into numeric fields.
</li>
						<li>Invalid date format – date format differs from yyyyMMdd for transactionDate field and from mmYY for the accountAccessory field.
</li>
						<li>Invalid token – token doesn’t follow required <a href="/processing-api/realtime/notes/concepts-token-structure--legacy-">token structure</a>.
</li>
						<li>Invalid account ID – incorrect value for the accountId field.
</li>
					</ul>
If validation errors in a Request File are non-critical, validation file is not generated. In this case, all transactions, except those that got non-critical validation errors, will be processed by a processor.
<br/>
						<br/>
If a request file contains non-critical validation errors, validation file is not generated. In this case, all transactions, except those that got non-critical validation errors, will be processed by a processor. Non-critical validation errors are the following:
<br/>
								<ul type="circle" style={{ marginLeft: '10px', colo:  '#5688c9'}}>
									<li>Incorrect card number – card number is not validated by mod 10 algorithm verification.
</li>
									<li>Invalid routing number – routing number is not well-formatted.
</li>
									<li>Required fields are missing.
</li>
								</ul>
								<br/>
In some cases, a submitter that sends a request file for processing prefers the file to be either processed completely or not processed at all if it includes any errors. To control whether a validation file should be generated, the following setting can be configured on the user interface: 
<br/>
										<ul type="circle" style={{ marginLeft: '10px', colo:  '#5688c9'}}>
											<li>All Or Fail - if there is at least one invalid transaction in the request file, validation error file is generated and the request file is not processed at all.
</li>
											<li>Any Valid - all valid transactions included in a request file are processed by a processor. Invalid transactions are placed into the validation error file and should be re-processed as a separate file after correcting all of the issues.</li>
										</ul>
            </div>
          </div>

          <div className="panel panel-default" id={"Account_Update_File"}>
            <div className="panel-heading" >
              <h3 class="panel-title">Account Update File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-account-update-file" href="/processing-api/batch/operations#note-processing-account-update-file" onclick="return false;" data-original-title="Direct link">
                  <i class="fa fa-share-square-o"/>
                </a>
              </h3>
            </div>
            <div className="panel-body">
              Account update file contains a list of payment card transactions, for which updated data need to be received. Processors do not imply any timeline requirements for the account data to be updated. It should be noted that when CAU is used, the merchant is charged a fee for each transaction checked by the processor.  
              <br/><br/>
              CAU processing runs similar to the processing of the request/response files. However, processing of an account update file on processor’s side may not start immediately after submission. It can start in 48 hours after submission or earlier depending on the time when account update transactions were submitted to the system. 
              <br/><br/>
              For CAU, aggregation is optional for all processors except Vantiv Tandem. If aggregation is used, the gateway submits the account update data for multiple merchants in a single file. In this case, you should  <a href="/guide_content?id=10#Aggregation">enable aggregation</a> within a provider profile that the account update is performed through.  If the processor receives an aggregated file, it sends an aggregated response for all merchants submitted in the request.
              <br/>
              To submit account update transactions to the system, you have to use a corresponding file. To review the naming convention of the file, see <span class="note-doc" type="note" data="File_Format_and_Name_Convention">integration notes</span>. To review the format of the file, see <a href="/processing-api/batch/operations#operation-account-update-batch">Account Update File Format</a>. 
            </div>
          </div>

          <div className="panel panel-default" id={"Subscription_File"}>
            <div className="panel-heading" >
            <h3 class="panel-title">Subscription File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-subscription-file" href="/processing-api/batch/operations#note-processing-subscription-file" onclick="return false;" data-original-title="Direct link">
                <i class="fa fa-share-square-o"/>
              </a>
            </h3>
            </div>
            <div className="panel-body">
            Subscription file is a file that contains a list of customers' bank accounts that need to be registered with the bank/processor for further transaction processing (for a mandate-based system, such as BACS or SEPA). See <span class="note-doc" type="note" data="Bank_Information_Subscription">integration notes</span> for more information.
            <br/>
            To submit subscriptions (mandates) to the system, you have to use a corresponding file. To review the naming convention of the file, see <span class="note-doc" type="note" data="File_Format_and_Name_Convention">integration notes</span>. To review the format of the file, see <a href="/processing-api/batch/operations#operation-subscription-batch">Subscription File Format</a>.
            </div>
          </div>

          <div className="panel panel-default" id={"Returns_File"}>
            <div className="panel-heading" >
              <h3 class="panel-title">Returns File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-returns-file" href="/processing-api/batch/operations#note-processing-returns-file" onclick="return false;" data-original-title="Direct link">
                  <i class="fa fa-share-square-o"/>
                </a>
              </h3>
            </div>
            <div className="panel-body">
              Returns file contains direct debit returns, chargebacks/reversals and results of the automated <a href="/processing-api/batch/notes/integration-notes-retry-process">retry process</a> (approvals/declines that occurred during the day). 
              <br/>
              By default, it is generated once per day. When a processor supports delivery of returns files several times per day, the gateway provides the ability to obtain an additional returns file. In this case, a merchant receives two returns files per day that are generated at 11AM and 8PM server time.
              <br/> <br/>
              To review the naming convention of the file, see <span class="note-doc" type="note" data="File_Format_and_Name_Convention">integration notes</span>. To review the format of the file, see <a href="/processing-api/batch/operations#operation-returns-batch">Returns File Format</a>.
            </div>
          </div>

          <div className="panel panel-default" id={"Returns_Account_Update_File"}>
            <div className="panel-heading" >
            <h3 class="panel-title">Returns Account Update File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-returns-account-update-file" href="/processing-api/batch/operations#note-processing-returns-account-update-file" onclick="return false;" data-original-title="Direct link">
                <i class="fa fa-share-square-o"/>
              </a>
            </h3>
            </div>
            <div className="panel-body">
              Returns CAU file contains a list of payment cards that got updated. It is generated once per day if any data from a processor is available. Note that this file format is available for <em>American Express only</em>. 
              <br/>
              To review the naming convention of the file, see <span class="note-doc" type="note" data="File_Format_and_Name_Convention">integration notes</span>. To review the format of the file, see <a href="/processing-api/batch/operations#operation-returns-account-update-batch">Returns Account Update File Format</a>.
            </div>
          </div>




          <div className="panel panel-default" id={"Returns_Subscription_File"}>
            <div className="panel-heading" >
            <h3 class="panel-title">Returns Subscription File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-returns-subscription-file" href="/processing-api/batch/operations#note-processing-returns-subscription-file" onclick="return false;" data-original-title="Direct link"><i class="fa fa-share-square-o"></i></a></h3>
            </div>
            <div className="panel-body">
            Returns subscription file contains subscriptions (mandates) that had been initially approved but were terminated by a processor/bank after some period of time. It is generated once per day if one or more initially approved subscriptions get declined. 
            <br/><br/>
            To review the naming convention of the file, see <span class="note-doc" type="note" data="File_Format_and_Name_Convention">integration notes</span>. To review the format of the file, see <a href="/processing-api/batch/operations#operation-returns-subscription-batch">Returns Subscription File Format</a>. 
            </div>
          </div>

          <div className="panel panel-default" id={"Capture_File"}>
            <div className="panel-heading" >
            <h3 class="panel-title">Capture File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-capture-file" href="/processing-api/batch/operations#note-processing-capture-file" onclick="return false;" data-original-title="Direct link">
                <i class="fa fa-share-square-o"/>
              </a>
            </h3>
            </div>
            <div className="panel-body">
              Capture file contains a list of real-time sale-auth transactions that are going to be captured. A format of the file allows a submitter to perform either full or partial capture. 
              <br/><br/>
              To submit sale-auth/credit-auth transactions to the system, you have to use a capture file. Note that a prior configuration has to be set via the user interface. To allow for batch capture to be enabled, make sure that <a href="https://unipaygateway.info/forms/573">Batch Capture Enabled checkbox</a> is activated in the provider profile of the processor that you use for real-time processing. 
              <br/>
              To review the naming convention of the file, see <span class="note-doc" type="note" data="File_Format_and_Name_Convention">integration notes</span>. To review the format of the file, see <a href="/processing-api/batch/operations#operation-capture-batch">Capture File Format</a>.
            </div>
          </div>

          <div className="panel panel-default" id={"Charges_File"}>
            <div className="panel-heading" >
            <h3 class="panel-title">Charges File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-charges-file" href="/processing-api/batch/operations#note-processing-charges-file" onclick="return false;" data-original-title="Direct link">
                <i class="fa fa-share-square-o"/>
              </a>
            </h3>
            </div>
            <div className="panel-body">
            Charges file contains a list of charges associated with merchants. In the charges request file, a submitter sets a specific date when the charge becomes effective and will be picked up by the remittance process. Each charge in the file is going to be applied only once. Recurring charges should be re-submitted for every occurrence.
<br/>
			<br/>
A subsequent response file informs the submitter whether the charges were accepted for processing or were rejected due to the validation issues. The charge is going to be picked up by the remittance process on or after the effective date (depending on when a process runs). The payments will be collected when an appropriate amount of funds due to the merchant is available (if money is not immediately available due to the negative balance of a merchant, it might take some time for a payment to actually be collected).
<br/>
			<br/>
To submit charges to the system, you have to use a corresponding file. To review the naming convention of the file, see <span class="note-doc" type="note" data="File_Format_and_Name_Convention">integration notes</span>. To review the format of the file, see <a href="/processing-api/batch/operations#operation-charges-batch">Charges File Format</a>.
            </div>
          </div>

          <div className="panel panel-default" id={"Charge_Payments_File"}>
            <div className="panel-heading" >
            <h3 class="panel-title">Charge Payments File&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-processing-charge-payments-file" href="/processing-api/batch/operations#note-processing-charge-payments-file" onclick="return false;" data-original-title="Direct link">
                <i class="fa fa-share-square-o"/>
              </a>
            </h3>
            </div>
            <div className="panel-body">
            Charge payments file contains a list of payments that were collected from merchants on behalf of a reseller. Each record within the file includes a reference to a charge associated with a payment, as well as the amount that was withheld from a merchant. When an account of the merchant does not have sufficient funds, additional payments associated with a charge will be collected in the future and included in the subsequent charge payments files.
<br/>
				<br/>
To review the naming convention of the file, see <span class="note-doc" type="note" data="File_Format_and_Name_Convention">integration notes</span>. To review the format of the file, see <a href="/processing-api/batch/operations#operation-charge-payments-batch">Charges Payments File Format</a>.
            </div>
          </div>

          
          <h2 style={{ fontSize: 'x-large' }}><b>Tokenization Concept</b></h2>
          <br /><br />

          <div className="panel panel-default" id={"_Tokenization"}>
            <div className="panel-heading" >
            <h3 class="panel-title">Tokenization&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-tokenization-concept-tokenization" href="/processing-api/batch/operations#note-tokenization-concept-tokenization" onclick="return false;" data-original-title="Direct link">
                <i class="fa fa-share-square-o"/>
              </a>
            </h3>
            </div>
            <div className="panel-body">
            See <a href="http://en.wikipedia.org/wiki/Tokenization_%28data_security%29">What is it? page</a> for more information about Tokenization.
            <br/>
            UniCharge provides a simple and convenient tokenization mechanism for merchants that deal with recurring payments and want to avoid storage of customer’s payment information (credit card and bank account numbers) within their own system. When a merchant uses any given credit card or bank account for the first time (as part of authorization, sale or credit operation), UniCharge will generate a unique identification number (token) associated with this payment information. If in the future, the merchant wants to issue another operation (e.g. another sale) on the same credit card or bank account, it is sufficient to pass to UniCharge just the value of the previously generated token and omit all of the account information fields.
            <br/>
                      <br/>
            </div>
          </div>

          <div className="panel panel-default" id={"Token_Structure_(Extended)"}>
            <div className="panel-heading" >
              <h3 class="panel-title">Token Structure (Extended)&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-tokenization-concept-token-structure--extended-" href="/processing-api/batch/operations#note-tokenization-concept-token-structure--extended-" onclick="return false;" data-original-title="Direct link"><i class="fa fa-share-square-o"></i></a></h3>
            </div>
            <div className="panel-body">
              As well as simple token structure, extended structure enables integrators to easily derive information needed for reporting or system maintenance from the token itself.
              <br/>

              <br/>
              For example, XVC01S0000000099990000014426594111001111.
              <br/>

              <br/>
              <div style={{ display: 'inline-block'}}>
              <div class="table-responsive">
                <table class="newtable newtablenorowspan pull-left">
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: 'bold'}}> #
                      </td>
                      <td style={{ fontWeight: 'bold'}}> Name
                      </td>
                      <td style={{ fontWeight: 'bold'}}> Type
                      </td>
                      <td style={{ fontWeight: 'bold'}}> Description
                      </td>
                    </tr>
              <tr>
              <td>1
              </td>
              <td>Token Format
              </td>
              <td>String(1)
              </td>
              <td>Identifier of the extended format (<em>always X</em>).
              </td>
              </tr><tr>
              <td>2
              </td>
              <td>Account Type
              </td>
              <td>Enum(2)
              </td>
              <td>Type of account. See <span class="popup-link" type="enum" data="Tokenization_Account_Type">possible values</span> for more information.
              </td>
              </tr><tr>
              <td>3
              </td>
              <td>Sequence Number
              </td>
              <td>String(2)
              </td>
              <td>For bank accounts only. <br/> Sequence number assosiated with the underline account number for cases when account number is not unique. For example, the same account number is used in different banks.
              </td>
              </tr><tr>
              <td>4
              </td>
              <td>Tokenization Origin
              </td>
              <td>Enum(1)
              </td>
              <td>Identifies the system, underlines system, that generates the stock. See <span class="popup-link" type="enum" data="Tokenization_Processors_Identifiers">possible values</span> for more information.
              </td>
              </tr><tr>
              <td>5
              </td>
              <td>Bank Identifier
              </td>
              <td>String(7)
              </td>
              <td>Identifier of a bank that issue the credit card or that holds a bank account.
              </td>
              </tr><tr>
              <td>6
              </td>
              <td>Reserved for Future Use
              </td>
              <td>String(1)
              </td>
              <td>
              </td>
              </tr><tr>
              <td>7
              </td>
              <td>Account Number
              </td>
              <td>String(16)
              </td>
              <td>Tokenized account number.
              </td>
              </tr><tr>
              <td>8
              </td>
              <td>First Four Digits
              </td>
              <td>String(6)
              </td>
              <td>The first four digits of credit card or bank account number (the last two digits of this value are reserved).
              </td>
              </tr><tr>
              <td>9
              </td>
              <td>Last Four Digits
              </td>
              <td>String(4)
              </td>
              <td>The last four digits of credit card number or bank account number.
              </td>
              </tr></tbody></table></div>
              </div>  
            </div>
          </div>

          <div className="panel panel-default" id={"Token_Structure_(Simple)"}>
            <div className="panel-heading" >
            <h3 class="panel-title">Token Structure (Simple)&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-tokenization-concept-token-structure--simple-" href="/processing-api/batch/operations#note-tokenization-concept-token-structure--simple-" onclick="return false;" data-original-title="Direct link">
                <i class="fa fa-share-square-o"/>
              </a>
            </h3>
            </div>
            <div className="panel-body">
            A token generated by the gateway is comprised of 3 logical parts:
<br/>
				<ul type="circle" style={{ marginLeft: '10px', colo:  '#5688c9'}}>
					<li>two-letter account type (see <span class="popup-link" type="enum" data="Tokenization_Account_Type">possible values</span> for more information);
</li>
					<li>tokenized account number;
</li>
					<li>the last 4 digits of the actual account number.
</li>
				</ul>
				<br/>
For example, a card number <em>4111111111111111</em> will be tokenized as <em>VC84632147254611111111</em>, where:
<ul type="circle" style={{ marginLeft: '10px', colo:  '#5688c9'}}>
						<li>
							<em>VC</em> is the Visa account type;
</li>
						<li>
							<em>8463214725461111</em> is a tokenized account number;
</li>
						<li>
							<em>1111</em> are the last 4 digits of the actual card number.
</li>
					</ul>
					<br/>
This structure enables integrators to easily derive information needed for reporting or system maintenance from the token itself.<br/>
            </div>
          </div>

          <div className="panel panel-default" id={"Profiling"}>
            <div className="panel-heading" >
            <h3 class="panel-title">Profiling&nbsp; <a data-toggle="tooltip" data-placement="right" class="mytooltip cursor_copy" title="" id="clipboardnote-tokenization-concept-profiling" href="/processing-api/batch/operations#note-tokenization-concept-profiling" onclick="return false;" data-original-title="Direct link">
                <i class="fa fa-share-square-o"/>
              </a>
            </h3>
            </div>
            <div className="panel-body">
            In this case token is associated with an internal account profile, which in addition to account number can contain other account information (for example, holder name, expiration date, routing number, address information). When transaction request is submitted, fields from the profile can be automatically used if they were not specified as part of the request. The disadvantage of the profiling approach is that profiles have to be updated if the customers’ information is changed, in particular the following fields:
<br/>
				<ul type="circle" style={{ marginLeft: '10px', colo:  '#5688c9'}}>
					<li>accountAccessory
</li>
					<li>holderName
</li>
					<li>isOrganization
</li>
					<li>street
</li>
					<li>city
</li>
					<li>state
</li>
					<li>zipCode
</li>
					<li>countryCode</li>
				</ul>
            </div>
          </div>

          <div className='row justify-content-start'>
            <div>
              <h3>Message Formats</h3><br />
              <h2>Usage: </h2>
            </div>                              
            <div className='col-6 align-items-start'>
              <div className='divdestablas'>
                {this.state.tabladedatos.map((itemt, index) => (
                  <div key={index} className='row nopadding rowborder'>

                    <div className='col-auto'>
                      <label style={{ fontWeight: 'bold', fontSize: "medium", width: "10px", marginTop: "5px" }}>{itemt.titulo}</label>
                    </div>

                    <div className='col-auto'>
                      <HiOutlineMinusSm style={{ fontSize: "Medium" }} />
                    </div>

                    <div className='col'>
                      <label className="txtdescripcion">{itemt.descripcion}</label>
                    </div>

                  </div>

                ))}
              </div> <br />
            </div>

            <br /><br /> <br /><br />
            {this.state.tablasporseccion.length>0 ? <div><br /><br /><h2>Component Definitions</h2></div> : null}
            {
              this.state.tablasporseccion.map((tabla, index) => (
                <div>
                   <br />
                  <div>
                    <h2>{tabla.titulo}</h2>
                    <br />
                    <h2>{tabla.tabla.titulotabla}</h2>
                  </div>
                  <div class="box">
                    <code>{tabla.tabla.descripcion}</code>
                  </div>
                  <div style={{ display: "inline-block" }} id={tabla.tabla.id.replaceAll(" ", "_")}>
                    <div class="table-responsive">
                      <table class="newtable newtablenorowspan pull-left">
                        <tbody>
                          <tr>
                            {
                              tabla.tabla.headers.map((header, index) => (
                                <th style={{ fontWeight: "bold" }}>{header}</th>
                                ))
                            }
                          </tr>
                          {
                              tabla.tabla.body.map((body, index) => (
                                <tr>
                                  {
                                    body.row.map((item, indexrow) => (
                                    <td>
                                      {
                                        indexrow==1 && body.hasOwnProperty('vinculoName') ? 
                                        <span className='mb-0'> <span className='linked' onClick={() => this.seleccionarModal(body.vinculoName)} style={{color: "#0066B3"}}>{item}</span></span> 
                                        : indexrow==2 && body.hasOwnProperty('vinculoType') ? 
                                        <span className='mb-0'> <span className='linked' onClick={() => this.seleccionarModal(body.vinculoType)} style={{color: "#0066B3"}}>{item}</span></span> 
                                        : indexrow==2 && body.hasOwnProperty('vinculoUsage2') ? 
                                        <span className='mb-0'> <span className='linked' onClick={() => this.seleccionarModal(body.vinculoUsage2)} style={{color: "#0066B3"}}>{item}</span></span>
                                        : indexrow==3 && body.hasOwnProperty('vinculoPattern') ?
                                        <span className='mb-0'> <span className='linked' onClick={() => this.seleccionarModal(body.vinculoPattern)} style={{color: "#0066B3"}}>{item}</span></span> 
                                        : indexrow==4 && body.hasOwnProperty('vinculoFormat') ?
                                        <span className='mb-0'> <span className='linked' onClick={() => this.seleccionarModal(body.vinculoFormat)} style={{color: "#0066B3"}}>{item}</span></span> 
                                        : indexrow==5 && body.hasOwnProperty('vinculoUsage') ?
                                        <span className='mb-0'> <span className='linked' onClick={() => this.seleccionarModal(body.vinculoUsage)} style={{color: "#0066B3"}}>{item}</span></span> 
                                        : item
                                      }

                                      {
                                        indexrow==6 && body.hasOwnProperty('seemoreinformation0') ?
                                        <span className='mb-0'><span className='linked' onClick={() => this.seleccionarModal(body.modalname0 )} style={{color: "#0066B3"}}>{body.seemoreinformation0}</span> {body.seemoreinformationcom0} </span>
                                        : null
                                      }
                                      {
                                        indexrow==6 && body.hasOwnProperty('descripcion1') ?  
                                        <div className='notatabla'>
                                          {body.descripcion1}
                                          {
                                            body.hasOwnProperty('seemoreinformation1') ?
                                            <span className='mb-0'> <span className='linked' onClick={() => this.seleccionarModal(body.modalname1 )} style={{color: "#0066B3"}}>{body.seemoreinformation1}</span> {body.seemoreinformationcom1} </span>
                                            : null
                                          }
                                        </div> : null
                                      }
                                      {
                                        indexrow==6 && body.hasOwnProperty('descripcion2') ?         
                                        <div className='infotabla'>
                                          {body.descripcion2}
                                          {
                                            body.hasOwnProperty('seemoreinformation2') ?
                                            <span className='mb-0'> <span className='linked' onClick={() => this.seleccionarModal(body.modalname2 )} style={{color: "#0066B3"}}>{body.seemoreinformation2}</span> {body.seemoreinformationcom2} </span>
                                            : null
                                          }
                                        </div> : null
                                      }
                                    </td>
                                    ))
                                  }
                                  
                              </tr>
                              ))
                          }
                        </tbody>
                      </table>
                    </div><br />         
                  </div><br />
                </div>
              ))
            }
          </div>
          <br /><br /><br /><br />
        </div>
      </>
    )
  }
}

