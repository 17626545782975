import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINBatchTokenization extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    seleccionarModal(titulomodal){
        
        this.setState({ show: false });
        let json = {
            accion: 1,
            modal: titulomodal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Batch Tokenization</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                            When a batch file is processed, it is recommended to use tokens instead of actual account numbers. In such cases, the file is placed into the inbox folder and processed in a regular way. However, in circumstances when unencrypted account numbers (credit card numbers, bank account numbers) are placed into a file, it is recommended to put the file in invault folder as opposed to inbox folder. 
                            <br/>

                            <br/>
                            Tokenization process is subsequently applied on all of the files in invault folder. All card numbers are converted into tokens and replaced with respective records in a request file, and the new request file (containing tokens only) is automatically placed in inbox folder for regular processing. Files will automatically be removed from invault folder after processing.
                            <br/>

                            <br/>
                            There are cases, when it is necessary to tokenize account numbers without actually charging the accounts. In such cases batch tokenization format can be used. Information about tokenization message format can be found in <span class="linked" style={{color: "#0066B3"}} onClick={() => this.seleccionarModal("ModalTokenization")}>integration notes</span>.               
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

