import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalIdentityVerification extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Identity Verification</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                In some cases, additional identity information is necessary to complete a direct debit transaction. This additional information can be supplied using four identity verifications fields: driverLicenseNumber, driverLicenseState, identityVerificationType and identityVerificationValue.
<br/>

<br/>
Fields driverLicenseNumber and driverLicenseState can be used to transmit account holder’s driver license number and driver license state (for the US) or province code (for Canada and Australia) respectively. 
<br/>

<br/>
They also can be used to provide a courtesy card ID issued for an account holder. In this case, the value of courtesy card ID must be supplied as driverLicenseNumber, while the value of driverLicenseState should be set to XX.<br/>                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

