import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class Modal3DSecureVerification extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>3D Secure Verification</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                To learn more about 3D Secure, follow <a href="https://en.wikipedia.org/wiki/3-D_Secure">this</a> link.

<br/>
3D secure authorization with MPI can be implemented in two ways:
<br/>
<ul type="circle" >
<li>Hosted payment page <em>is used</em>. In addition to the regular workflow, 3D secure authorization will be incorporated in the process if the card is enrolled and the results of 3D secure verification will be used to process the transaction. In this case, 3D secure redirects will be handled by the system. No additional actions are needed. Appropriate API configurations must be set up within the system.
</li>
<li>Hosted payment page <em>is not used</em>. In this case, processing will happen in two phases. After an API call is made, the system will verify whether 3D Secure is required. If 3D Secure is not required, then the submitter will get a regular API response - approval or decline. If 3D Secure is required, the submitter is going to receive <strong>X03</strong> response code indicating that a subsequent redirect of the cardholder is necessary. In such case, the submitter needs to use the transaction ID returned in the transaction response and execute the redirect (specifying callback URL where the final result should be posted). The redirect is going to lead the cardholder to the card issuer's website for 3D Secure verification. The result of 3D Secure verification will be returned to the gateway and the gateway will attempt to complete the transaction (complete authorization). The final result of the transaction is going to be posted to the URL supplied by the submitter within the redirect request. 
</li>
</ul>

<br/>
Please, note that there are several possible outcomes of the 3D Secure verification process. You can decide whether to complete authorization based on 3D Secure verification outcome and configure the system to act accordingly. For those cases when subsequent authorization is not desired, the transaction will be declined with <strong>E10</strong> response code. 
<br/>

<br/>
Below you can find the possible outcomes with their explanation:
<br/>
<div style={{display: "inline-block"}} >
<div class="table-responsive"><table class="newtable newtablenorowspan pull-left"><tbody><tr>
<th style={{fontWeight: "bold"}} >Status 
</th>
<th style={{fontWeight: "bold"}} >Message
</th>
</tr><tr>
<td>U or blank
</td>
<td>Unable to Authenticate. Process transaction as Normal SSL.
</td>
</tr><tr>
<td>N
</td>
<td>Failed Authentication. Reject transaction.
</td>
</tr><tr>
<td>Y
</td>
<td>Authentication Successful. Proceed to authorization.
</td>
</tr></tbody></table></div><br/>
<div >
For example, you can choose to only proceed with transaction authorization if the status of 3D Secure verification is <strong>Y</strong>. You can also attempt to authorize regardless of the status.</div><br/>                </div>
            </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

