import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalEmbeddedMode extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Embedded Mode</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                Hosted payment page can be used in two modes:
<br/>

<br/>
- <strong>standalone</strong>, when used as standalone, both header and footer will be present on every page. 
<br/>
- <strong>embedded</strong>, when used as embedded, only forms content will be visible, while header and footer will be suppressed.
<br/>

<br/>
To control which mode is applied,&nbsp;<strong>isEmbedded&nbsp;field is used</strong>. 
<br/>

<br/>
<strong>By default</strong>, the payment page is shown as a standalone page -&nbsp;<strong>isEmbedded=0</strong>. 
<br/>

<br/>
However, if it is desirable to embed the application using frame or iframe, and therefore header and footer are not needed, <strong>then isEmbedded parameter can be set to&nbsp;1</strong>. This will indicate that the content will be embedded in the page with the existing header and footer, and therefore no additional header and footer will be generated. 
<br/>

<br/>
To avoid scrollbars within a frame, <strong>its size should be 700px x 900px</strong>. <br/>                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

