import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalServiceFees extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Service Fees</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                Quite often, companies that process transactions on behalf of other organizations may require to explicitly indicate that the payment amount includes a certain part that constitutes service fees. For example, when $105 sale is processed, $5 represent service fees that the company submitter wants to withhold when deposits will get remitted to the organization on behalf of which the transaction was done (e.g. organization gets $100 and submitter keeps $5 service fee). 
                            <br/>

                            <br/>
                            In order to accommodate these needs, Sale operation provides feeAmount field, which should contain the amount of the processing fee that the submitter wants to withhold from remittance. Note: based on merchant configurations, actual processing/interchange fee will be deducted either from the amount remitted to the organization beneficiary or from the service fee amount of the submitter.
                            <br/>

                            <br/>
                            For cases, when elaborate convenience fee calculation logic is involved, it's possible to call convenience-fee operation to get the amount of convenience fee to include in a subsequent sale/sale auth call.<br/>                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

