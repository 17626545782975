import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINServiceFees extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Service Fees</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                            Quite often, companies that process transactions on behalf of other organizations may require to explicitly indicate that the payment amount includes a certain part that constitutes service fees. For example, when $105 sale is processed, $5 represent service fees that the company submitter wants to withhold when deposits will get remitted to the organization on behalf of which the transaction was done (e.g. organization gets $100 and submitter keeps $5 service fee). 
                            <br/>
                            <br/>
                            In order to accommodate these needs, Sale operation provides feeAmount field, which should contain the amount of the processing fee that the submitter wants to withhold from remittance. Note: based on merchant configurations, actual processing/interchange fee will be deducted either from the amount remitted to the organization beneficiary or from the service fee amount of the submitter.<br/>                
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

