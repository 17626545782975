import React from 'react'
import { LuArrowRightCircle } from 'react-icons/lu';
import './TypeEnumerations.css'


import ModalMessageFormats from '../../../Components/Modals/Billing-api/Objects/ModalMessageFormats';
import ModalMessageFormatsMode from '../../../Components/Modals/Billing-api/Objects/ModalMessageFormatsMode';


export default class TypeEnumerations extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showmodal: "",

        }
    }


    async componentDidMount() {

      
    }
    seleccionarModal(titulomodal){
        this.setState({ showmodal: titulomodal})
    }
    renderizarContenidos(){
        switch (this.props.opcioncontenido) {
            case "AccountActivityEnum":
                return(
                    <div className="row">
                        <div id="object-container">

        
                            <div class="row">
                            <a id="billing-anchor" name="billing-anchor"></a>
                            <div class="col-xs-12 col-sm-12">
                            <span class="label h3" style={{color: "#000000 !important"}} >Account Activity Enum</span>
                            &nbsp;&nbsp;
                            <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="/billing-api/enums/v01/283" onclick="return true;" data-original-title="Direct link">
                            <i class="fa fa-share-square-o"></i>
                            </a>
                            </div>
                            </div>

                            <div class="row" style={{paddingTop: "10px"}}>
                            <div class="col-xs-12 col-sm-12">
                            <div class="table-responsive">
                                            <table class="table my-table">
                            <colgroup style={{width: "20%"}}></colgroup>
                            <colgroup style={{width: "20%"}}></colgroup>
                            <colgroup style={{width: "60%"}}></colgroup>
                            <tbody>
                                                        <tr>
                                <td width="20%">Invoice</td>
                                <td width="20%">RI</td>
                                <td width="80%">Represents an invoice (recording of customer's debt to a merchant).</td>
                            </tr>
                                                        <tr>
                                <td width="20%">Credit</td>
                                <td width="20%">RC</td>
                                <td width="80%">Represents a transaction opposite to an invoice (reduction of customer's debt to a merchant).</td>
                            </tr>
                                                        <tr>
                                <td width="20%">Reversal</td>
                                <td width="20%">RC</td>
                                <td width="80%">Represents full reversal of an existing invoice.</td>
                            </tr>
                                                        <tr>
                                <td width="20%">Service Fee</td>
                                <td width="20%">RF</td>
                                <td width="80%">Represents generic fee, applied to the customer's account.</td>
                            </tr>
                                                        <tr>
                                <td width="20%">LateFee</td>
                                <td width="20%">RL</td>
                                <td width="80%">Represents fee, applied because of a late payment.</td>
                            </tr>
                                                        <tr>
                                <td width="20%">AccessFee</td>
                                <td width="20%">RA</td>
                                <td width="80%">Represents fee, applied to the customer's account because access to restricted digital assets (e.g. paperless copies of contracts) was granted.</td>
                            </tr>
                                                        <tr>
                                <td width="20%">Payment</td>
                                <td width="20%">AP</td>
                                <td width="80%">Represents a real-time payment, collected on the customer's account.</td>
                            </tr>
                                                        <tr>
                                <td width="20%">Void</td>
                                <td width="20%">AV</td>
                                <td width="80%">Represents void of an existing payment.</td>
                            </tr>
                                                        <tr>
                                <td width="20%">Refund</td>
                                <td width="20%">AR</td>
                                <td width="80%">Represents partial or full reversal of a previous payment.</td>
                            </tr>
                                                    </tbody>
                            </table>
                            </div>
                            </div>
                            </div>

                        </div>
                        
                    </div>
    
                )
 
            case "AdjustmentEnum":
                 return(
                     <div className="row">
                       <div id="object-container">

                                
                        <div class="row">
                        <a id="billing-anchor" name="billing-anchor"></a>
                        <div class="col-xs-12 col-sm-12">
                        <span class="label h3" style={{color: "#000000 !important"}} >Adjustment Enum</span>
                        &nbsp;&nbsp;
                        <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="/billing-api/enums/v01/283" onclick="return true;" data-original-title="Direct link">
                        <i class="fa fa-share-square-o"></i>
                        </a>
                        </div>
                        </div>

                        <div class="row" style={{paddingTop: "10px"}}>
                        <div class="col-xs-12 col-sm-12">
                        <div class="table-responsive">
                                        <table class="table my-table">
                        <colgroup style={{width: "20%"}}></colgroup>
                        <colgroup style={{width: "20%"}}></colgroup>
                        <colgroup style={{width: "60%"}}></colgroup>
                        <tbody>
                                                            <tr>
                                    <td width="20%">Cancellation</td>
                                    <td width="20%">C</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">CancellationRenewal</td>
                                    <td width="20%">R</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Freeze</td>
                                    <td width="20%">F</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Upgrade</td>
                                    <td width="20%">U</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Modification</td>
                                    <td width="20%">M</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Termination</td>
                                    <td width="20%">T</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Pause</td>
                                    <td width="20%">P</td>
                                    <td width="80%"></td>
                                </tr>
                                                        </tbody>
                        </table>
                        </div>
                        </div>
                        </div>

                        </div>
                         
                     </div>
     
                 )
 
            case "AssetTransactionEnum":
                return(
                    <div className="row">
                        <div id="object-container">

                                                            
                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                <span class="label h3" style={{color: "#000000 !important"}} >Asset Transaction Enum</span>
                                &nbsp;&nbsp;
                                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="/billing-api/enums/v01/283" onclick="return true;" data-original-title="Direct link">
                                <i class="fa fa-share-square-o"></i>
                                </a>
                                </div>
                                </div>

                                <div class="row" style={{paddingTop: "10px"}}>
                                <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                                <table class="table my-table">
                                <colgroup style={{width: "20%"}}></colgroup>
                                <colgroup style={{width: "20%"}}></colgroup>
                                <colgroup style={{width: "60%"}}></colgroup>
                                <tbody>
                                                            <tr>
                                    <td width="20%">Allowance</td>
                                    <td width="20%">L</td>
                                    <td width="80%">Indicates that the payment is based on complimentary charge.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Cach</td>
                                    <td width="20%">K</td>
                                    <td width="80%">Indicates the cash payment.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Check</td>
                                    <td width="20%">H</td>
                                    <td width="80%">Indicates the check payment.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">External</td>
                                    <td width="20%">E</td>
                                    <td width="80%">Indicates the external payment (for example, PayPal payment).</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Payment Plan</td>
                                    <td width="20%">P</td>
                                    <td width="80%">Indicates that the payment is a customer debt write-off.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Settlement</td>
                                    <td width="20%">T</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">WriteOff</td>
                                    <td width="20%">W</td>
                                    <td width="80%">Indicates a revenue offsetting transaction which means that the revenue had never been collected and was written off the books.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Amex</td>
                                    <td width="20%">AC</td>
                                    <td width="80%">Indicates that the payment is made with American Express card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Discover Credit</td>
                                    <td width="20%">DC</td>
                                    <td width="80%">Indicates that the payment is made with Discover credit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Discover Debit</td>
                                    <td width="20%">DD</td>
                                    <td width="80%">Indicates that the payment is made with Discover debit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">MasterCard Credit</td>
                                    <td width="20%">MC</td>
                                    <td width="80%">Indicates that the payment is made with MasterCard credit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">MasterCard Debit</td>
                                    <td width="20%">MD</td>
                                    <td width="80%">Indicates that the payment is made with MasterCard debit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Visa Credit</td>
                                    <td width="20%">VC</td>
                                    <td width="80%">Indicates that the payment is made with a Visa credit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Visa Debit</td>
                                    <td width="20%">VD</td>
                                    <td width="80%">Indicates that the payment is made with a Visa debit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Checking</td>
                                    <td width="20%">BC</td>
                                    <td width="80%">Indicates that the payment is made using checking account.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Savings</td>
                                    <td width="20%">BS</td>
                                    <td width="80%">Indicates that the payment is made using savings account.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Money Order</td>
                                    <td width="20%">M</td>
                                    <td width="80%">Indicates that the payment is made with money order.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">MasterCard Prepaid</td>
                                    <td width="20%">MP</td>
                                    <td width="80%">Indicates that the payment is made with MasterCard prepaid card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Visa Prepaid</td>
                                    <td width="20%">VP</td>
                                    <td width="80%">Indicates that the payment is made with a Visa prepaid card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">China UnionPay Credit</td>
                                    <td width="20%">UC</td>
                                    <td width="80%">Indicates that the payment is made with a UnionPay credit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">China UnionPay Debit</td>
                                    <td width="20%">UD</td>
                                    <td width="80%">Indicates that the payment is made with UnionPay debit card.</td>
                                </tr>
                                                        </tbody>
                                </table>
                                </div>
                                </div>
                                </div>
                            </div>
                    </div>

                )

            case "BillingCycleEnum":
                return(
                    <div className="row">
                    <div id="object-container">

                                                                                    
                        <div class="row">
                            <a id="billing-anchor" name="billing-anchor"></a>
                            <div class="col-xs-12 col-sm-12">
                            <span class="label h3" style={{color: "#000000 !important"}} >Billing Cycle Enum</span>
                            &nbsp;&nbsp;
                            <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="/billing-api/enums/v01/283" onclick="return true;" data-original-title="Direct link">
                            <i class="fa fa-share-square-o"></i>
                            </a>
                            </div>
                            </div>

                            <div class="row" style={{paddingTop: "10px"}}>
                            <div class="col-xs-12 col-sm-12">
                            <div class="table-responsive">
                                            <table class="table my-table">
                            <colgroup style={{width: "20%"}}></colgroup>
                            <colgroup style={{width: "20%"}}></colgroup>
                            <colgroup style={{width: "60%"}}></colgroup>
                            <tbody>
                                                            <tr>
                                    <td width="20%">Weekly</td>
                                    <td width="20%">W</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Monthly</td>
                                    <td width="20%">M</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Quarterly</td>
                                    <td width="20%">Q</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Semi_anually</td>
                                    <td width="20%">S</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Annually</td>
                                    <td width="20%">A</td>
                                    <td width="80%"></td>
                                </tr>
                                                        </tbody>
                            </table>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
    
                )

            case "PaymentOptionEnum":
                return(
                    <div className="row">
                        <div id="object-container">
                                                                             
                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                <span class="label h3" style={{color: "#000000 !important"}} >Payment Option Enum</span>
                                &nbsp;&nbsp;
                                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="/billing-api/enums/v01/283" onclick="return true;" data-original-title="Direct link">
                                <i class="fa fa-share-square-o"></i>
                                </a>
                                </div>
                                </div>

                                <div class="row" style={{paddingTop: "10px"}}>
                                <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                                <table class="table my-table">
                                <colgroup style={{width: "20%"}}></colgroup>
                                <colgroup style={{width: "20%"}}></colgroup>
                                <colgroup style={{width: "60%"}}></colgroup>
                                <tbody>
                                                            <tr>
                                    <td width="20%">Amex</td>
                                    <td width="20%">AC</td>
                                    <td width="80%">Represents a payment made with an American Express card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Discover Credit</td>
                                    <td width="20%">DC</td>
                                    <td width="80%">Represents a payment made with a Discover credit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Discover Debit</td>
                                    <td width="20%">DD</td>
                                    <td width="80%">Represents a payment made with a Discover debit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">MasterCard Credit</td>
                                    <td width="20%">MC</td>
                                    <td width="80%">Represents a payment made with a MasterCard credit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">MasterCard Debit</td>
                                    <td width="20%">MD</td>
                                    <td width="80%">Represents a payment made with a MasterCard debit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Visa Credit</td>
                                    <td width="20%">VC</td>
                                    <td width="80%">Represents a payment made with a Visa credit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Visa Debit</td>
                                    <td width="20%">VD</td>
                                    <td width="80%">Represents a payment made with a Visa debit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Checking</td>
                                    <td width="20%">BC</td>
                                    <td width="80%">Represents a payment made using checking account.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Savings</td>
                                    <td width="20%">BS</td>
                                    <td width="80%">Represents a payment made using savings account.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Dinners</td>
                                    <td width="20%">NC</td>
                                    <td width="80%">Represents a payment made with a Dinners card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">MasterCard Prepaid</td>
                                    <td width="20%">MP</td>
                                    <td width="80%">Represents a payment made with a MasterCard prepaid card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">MasterCard Fleet</td>
                                    <td width="20%">MF</td>
                                    <td width="80%">Represents a payment made with a MasterCard fleet card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Visa Prepaid</td>
                                    <td width="20%">VP</td>
                                    <td width="80%">Represents a payment made with a Visa prepaid card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Visa Fleet</td>
                                    <td width="20%">VS</td>
                                    <td width="80%">Represents a payment made with a Visa fleet card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">General Gift</td>
                                    <td width="20%">GG</td>
                                    <td width="80%">Represents a payment made with a general Gift card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Fleet One</td>
                                    <td width="20%">FL</td>
                                    <td width="80%">Represents a payment made with a fleet one card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Fuel Man Fleet Wide</td>
                                    <td width="20%">GF</td>
                                    <td width="80%">Represents a payment made with a fuel fleet card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Voyager</td>
                                    <td width="20%">VF</td>
                                    <td width="80%">Represents a payment made with a Voyager card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Wright Express</td>
                                    <td width="20%">WX</td>
                                    <td width="80%">Represents a payment made with a Wright Express card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Bank Card Debit</td>
                                    <td width="20%">BD</td>
                                    <td width="80%">Represents a payment made with a bank debit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Fuel Lynk</td>
                                    <td width="20%">FU</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">China UnionPay Credit</td>
                                    <td width="20%">UC</td>
                                    <td width="80%">Indicates that the payment is made with a UnionPay credit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">China UnionPay Debit</td>
                                    <td width="20%">UD</td>
                                    <td width="80%">Indicates that the payment is made with UnionPay debit card.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Food stamp</td>
                                    <td width="20%">EF</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Cash benefit</td>
                                    <td width="20%">EC</td>
                                    <td width="80%"></td>
                                </tr>
                                                        </tbody>
                                </table>
                                </div>
                                </div>
                                </div>
                            </div>
                    </div>
    
                )
            case "PaymentPlanEnum":
                return(
                    <div className="row">
                         <div id="object-container">
                                                                             
                            <div class="row">
                                <a id="billing-anchor" name="billing-anchor"></a>
                                <div class="col-xs-12 col-sm-12">
                                <span class="label h3" style={{color: "#000000 !important"}} >Payment Plan Enum</span>
                                &nbsp;&nbsp;
                                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="/billing-api/enums/v01/283" onclick="return true;" data-original-title="Direct link">
                                <i class="fa fa-share-square-o"></i>
                                </a>
                                </div>
                                </div>

                                <div class="row" style={{paddingTop: "10px"}}>
                                <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                                <table class="table my-table">
                                <colgroup style={{width: "20%"}}></colgroup>
                                <colgroup style={{width: "20%"}}></colgroup>
                                <colgroup style={{width: "60%"}}></colgroup>
                                <tbody>
                                                            <tr>
                                    <td width="20%">Fixed</td>
                                    <td width="20%">F</td>
                                    <td width="80%">Indicates that the subscription will continue for a set number of periods.</td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Perpetual</td>
                                    <td width="20%">P</td>
                                    <td width="80%">Indicates that the subscription will continue with a recurring amount until the user explicitly cancels it.</td>
                                </tr>
                                                        </tbody>
                                                                                 </table>
                                                                                 </div>
                                                                                 </div>
                                                                                 </div>
                                                                             </div>
                    </div>
    
                )
            case "SubscriptionAdjustmentEnum":
                return(
                    <div className="row">
                         <div id="object-container">
                                                                             
                                                                             <div class="row">
                                                                                 <a id="billing-anchor" name="billing-anchor"></a>
                                                                                 <div class="col-xs-12 col-sm-12">
                                                                                 <span class="label h3" style={{color: "#000000 !important"}} >Subscription Adjustment Enum</span>
                                                                                 &nbsp;&nbsp;
                                                                                 <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="/billing-api/enums/v01/283" onclick="return true;" data-original-title="Direct link">
                                                                                 <i class="fa fa-share-square-o"></i>
                                                                                 </a>
                                                                                 </div>
                                                                                 </div>
                                                 
                                                                                 <div class="row" style={{paddingTop: "10px"}}>
                                                                                 <div class="col-xs-12 col-sm-12">
                                                                                 <div class="table-responsive">
                                                                                                 <table class="table my-table">
                                                                                 <colgroup style={{width: "20%"}}></colgroup>
                                                                                 <colgroup style={{width: "20%"}}></colgroup>
                                                                                 <colgroup style={{width: "60%"}}></colgroup>
                                                                                 <tbody>
                                                            <tr>
                                    <td width="20%">Freeze</td>
                                    <td width="20%">F</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Cancel</td>
                                    <td width="20%">C</td>
                                    <td width="80%"></td>
                                </tr>
                                                            <tr>
                                    <td width="20%">Pause</td>
                                    <td width="20%">P</td>
                                    <td width="80%"></td>
                                </tr>
                                                        </tbody>
                                                                                 </table>
                                                                                 </div>
                                                                                 </div>
                                                                                 </div>
                                                                             </div>
                    </div>
    
                )
    
         default:
             return null;
        }
     }
    
     cerrarModal = (accion) => {
        this.setState({ showmodal: "" })
    
    }


    render() {
        return (
            <>
                {
                    /** Modales Usage */
                    (this.state.showmodal == "Message Formats") ? 
                    (<ModalMessageFormats tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
                    : (this.state.showmodal == "Message Formats Mode") ? 
                    (<ModalMessageFormatsMode tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 

                    : null
                
              
                }



                <div className=''>
                    <div className="row pt-3">
                        <div className="col-12">
                            {this.renderizarContenidos()}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

