import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';

export default class ModalTerminalMessage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Terminal Message</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                Terminal Message mechanism is used to pass control messages from the TMS system to the terminal within the context of a transaction. It allows to notify (within transaction response) the terminal of the need to execute a particular procedure such as invoke certain function of the TMS. There are certain processes such as terminal software update or parameter update that are running on a regular basis at the scheduled time. However, there are situations when it is necessary to initiate one of such processes sooner than scheduled. 
<br/>

<br/>
For example, due to an issue an update needs to be performed right away. One way to do this would be to deliver a terminal message in a response of the next transaction that is going to take place. The response messages then are consumed by the terminal and appropriate action takes place.
<br/>

<br/>
Terminal message consists of three control functions:
<br/>
1. <em>exec(operation-name)</em> - used to notify a terminal that particular operation needs to be executed. Usually it means that terminal needs to call a certain operation of the TMS system. Supported operations are listed below:
<ul type="circle" style={{color: "#5688c9", marginLeft:"10px"}} >
<li><a href="/processing-api/tms/operations#component_Get_Parameters__Request">get-parameters</a>
</li>
<li><a href="/processing-api/tms/operations#component_Get_Update__Request">get-update</a>
</li>
<li><a href="/processing-api/tms/operations#component_Get_Credentials_Request">get-credentials</a>
</li>
<li><a href="/processing-api/tms/operations#component_Get_Time_Request">get-time</a>
</li>
<li><a href="/processing-api/tms/operations#component_Upload_Log_Request">upload-log</a>
</li>
<li><a href="/processing-api/tms/operations#component_Log_State_Request">log-state</a>
</li>
<li><a href="/processing-api/tms/operations#component_Log_Diagnostics_Request">log-diagnostics</a>
</li>
<li><a href="/processing-api/realtime/operations#component_Merchant_Info_Request">merchant-info</a>
</li>
</ul>
For example, <em>terminalMessage=exec(get-update);exec(log-state);exec(get-parameters);</em>

<br/>
<div id="integration-notes-content-format">
2. <em>key(name, value)</em> - implies that terminal needs to replace a particular key with value specified in the parentheses. The key mechanism can be used for various reasons. One of the most common is rotation of the MAC session key, when rotation is necessary as part of the processing. Currently, three keys are available: 
<ul type="circle" >
<li>MAC - session key used for encryption of message authentication code (MAC) data block. Used for Canadian transactions only;
</li>
<li>PIN - key used for encryption of PIN value associated with customer's credit card;
</li>
<li>P2PE - key used for point-to-point encryption of EMV and track data. 
</li>
</ul>
All of these keys consists of 16 alphanumeric characters: digits and letters from A to F. 
<br/>
For example, <em>terminalMessage=key(mac,0123456789ABCDEF);key(pin,FEDCBA9876543210);key(p2pe,ABCDEF0123456789);</em>
</div>

<br/>
3. <em>message(text)</em> - used to send text messages to a terminal. To divide text in lines, pipe (|) sign is used. Note that a number of available lines on the screen is limited to five. See <span class="note-doc" type="note" data="Show_Message_Content_Field">integration notes</span> for more information. For example, if it is necessary to restart a terminal, the following message should be submitted:
<em>terminalMessage=message(Please restart|the terminal|now!);</em>

<br/>
Note that within the terminalMessage field any desirable combinations of control functions can be used.
<br/>

<br/>
This field has no importance in the context of processing. <br/>                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

