import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINTransactionGropus extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Transaction Groups</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                Transaction groups is a concept, used by batch validation process, which validates deviations in amounts between recurring batches on any two subsequent months. Merchants, that are not interested in this validation logic, should ignore the concept of the transaction group.
                                <br/><br/>
                                Transactions can be logically divided into groups. To assign a transaction to a group, it is sufficient to specify transactionGroupCode when sale or credit request is made. When incoming batch is validated and compared to the transactions processed in the previous month, transaction group is taken into account. 
                                <br/><br/>
                                For example, if a merchant sends 2 files per month (on or around 1st and on or around 15th), and the transactions sent on the 1st are marked as "1st billing", while transactions sent on the 15th are marked as "15th billing", the validation mechanism will match (and calculate deviation) current month's "1st billing" with last month's "1st billing" and current month's "15th billing" with last month's "15th billing". When deviation exceeds allowable threshold, the batch is automatically placed in review queue.					<br/>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

