import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalBankInformationSubscription extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Bank Information Subscription</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                In certain countries, in order to execute a bank transfer, prior authorization has to be obtained from a financial institution that is going to process transactions. Both BACS and SEPA systems require such preliminary mandate registration. To register a mandate, <em>subscription</em> file has to be sent. Subscription file with mandates allows a merchant to provide the information about the accounts that are going to be used subsequently for transaction processing. If mandates associated with respective accounts fail to be processed, subsequent transactions will be declined. 

<br/>
Time delay between the moment when an account is registered and when the first transaction is allowed, varies from system to system. Thus, it will be 6 days after account registration for BACS and 5 days for SEPA.   
<br/>

<br/>
When an account is registered in BACS or SEPA system, an identifier of a mandate is returned within <em>transactionId</em> field as a part of the subscription response file. For further usage, the received value must be submitted within <em>subscriptionCode</em> field as a part of subsequent real-time API requests or batch files.

<br/>
The peculiarities of request file submission and response file receiving for subscription files with mandates are the following: 


<div style={{display: "inline-block"}} > 
<div class="table-responsive"><br/><table class="newtable pull-left" ><tbody><tr>
<th style={{fontWeight: "bold"}}>
</th>
<th style={{fontWeight: "bold"}}>SEPA
</th>
<th style={{fontWeight: "bold"}}>BACS
</th>
</tr><tr>
<td><strong>Subscription request file</strong>
</td>
<td>1 business day prior to effective date
</td>
<td>6 business days prior to effective date
</td>
</tr><tr>
<td><strong>Subscription response file</strong>
</td>
<td>Same day subscription request was submitted
</td>
<td>5 business days after subscription request was submitted
</td>
</tr><tr>
<td><strong>Processing file</strong>
</td>
<td>6 business days prior to effective date
</td>
<td>1 business day prior to effective date
</td>
</tr></tbody></table>
</div>                </div>
            </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

