import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalSettlementGroup extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Settlement Group</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                The concept of the settlement group is used to accomplish "all or none" settlement behavior for a group of transactions.
<br/>

<br/>
During sale or credit process (sale-auth, sale, credit-auth, credit operations), a transaction can be assigned to a settlement group (by indicating settlement group's unique identifier as part of the operation's request message). When settlement process happens (usually at the end of the day), all transactions belonging to a group that contains at least one voided or non-captured transaction will be automatically voided.
<br/>

<br/>
The concept is convenient for the cases of split payments or split credits, when more than one transaction is needed to accomplish a specific business goal (e.g. sale with 2 credit cards involved) and it is imperative to assure that either all transactions succeed and settle or all get voided.
<br/>

<br/>
Settlement group code can be any string value. It must be the same for all transactions belonging to the "all or none" scenario. More than one group per settlement cycle is possible, but code must be unique for each group. It is highly discouraged to reuse settlement group code over time. A common approach is to use Transaction Code value of the first transaction in a group as a settlement group code. Since transaction code is a unique value for a merchant, settlement group code will not get accidentally reused.<br/>                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

