import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalPageFormat extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>

                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Page Format</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                For cases when it is needed to hide some information within the HPP, the system allows to hide not only separate fields, but also entire section.  
<br/>

<br/>
To control which sections should be available within the HPP,&nbsp;<strong>pageFormat&nbsp;field is used</strong>. 
<br/>

<br/>
The field is structured as a mask.
<br/>

<br/>
Here you can find the images indicating the structural sections of the payment page:
<br/>
- <a href="https://i.imgur.com/4LXuRyd.png" target="_blank">https://i.imgur.com/4LXuRyd.png</a> 
<br/>
- <a href="https://i.imgur.com/AHUkGMU.png" target="_blank">https://i.imgur.com/AHUkGMU.png</a>
<br/>

<br/>
The following values are available for the pages within the HPP:
<br/>

<br/>
 &nbsp;&nbsp;H - stages 
<br/>
 &nbsp;&nbsp;P - payment details 
<br/>
 &nbsp;&nbsp;B - billing details 
<br/>
 &nbsp;&nbsp;T - timer 
<br/>
 &nbsp;&nbsp;L - payment method
<br/>
 &nbsp;&nbsp;S - buttons for payment methods
<br/>
 &nbsp;&nbsp;M - merchant business name
<br/>
 &nbsp;&nbsp;W - Apple Pay as a separate payment method
<br/>

<br/>
If all pages should be available for a merchant, the value of <strong>pageFormat field</strong> should be always submitted as&nbsp;<strong>HPBT</strong>. 
<br/>

<br/>
If some pages should be hidden, a respective value should be omitted in the request. 
<br/>

<br/>
For example, if timer should be skipped, the value of <strong>pageFormat</strong> will be the following: <strong>pageFormat = HPBLSMW</strong>.<br/>                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

