import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINRetryFee extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    seleccionarModal(titulomodal){
        
        this.setState({ show: false });
        let json = {
            accion: 1,
            modal: titulomodal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Retry Fee</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                            There is a feature allowing a merchant to apply a surcharge on approvals that result from retry process (see <span class="linked" style={{color: "#0066B3"}} onClick={() => this.seleccionarModal("ProsessingINRetryProcess")}>integration notes</span> for more information on retry process). 

                            <br/>
                            If retry fee is defined, it will be added to the transaction amount when a reattempt of a previous decline is made. In case of successful decline recycling (approval), the amount of the surcharge (retry fee) will be passed back in the standard response through retryFee field. This fee is not cumulative, and will be added to the original amount only once, no matter how many reattempts are made.<br/>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

