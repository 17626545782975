import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalTerminalCapabilityModeType extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Terminal Capability Mode Type</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "15%"}}></colgroup>
                                        <colgroup style={{width: "85%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>Value</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                            <td width="100px">unknown</td>
                            <td>Terminal capability is unknown<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">unused</td>
                            <td>No terminal is used<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">manual</td>
                            <td>Transaction data can be entered manually<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">stripe</td>
                            <td>Transaction data can be entered via magnetic stripe reader (swipe technology is used)<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">barcode</td>
                            <td>Transaction data can be entered via barcode reader<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">qrcode</td>
                            <td>Transaction data can be entered via QR code reader<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">ocr</td>
                            <td>Transaction data can be entered via optical reader<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">icc</td>
                            <td>Transaction data can be entered via EMV reader<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">contactless</td>
                            <td>Transaction data can be entered via contactless payment technology<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">signature</td>
                            <td>Transaction must be approved with the customer's signature<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">rfid</td>
                            <td>Transaction data can be entered via RFID (radio frequency identification) reader<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">micr</td>
                            <td>Transaction data can be entered via MICR (magnetic ink character recognition) reader<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">mpos</td>
                            <td>Device can be used as mobile POS<br/></td>
                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

