import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalBillingApi.css';
import parse from 'html-react-parser';


export default class ModalValidationPatternsTypes extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            ALPHA: "^[\\p{L}]*$",
            ALPHANUMERIC: "^[\\p{L}0-9]*$",
            NAME_BUSINESS: "^[\\p{L}0-9-_,:;`'/~<>=@\\.\\*\\+#&\\?\\|\\^\\u0020\\(\\)\\[\\]\\{\\}]*$",
            CODE: "	[0-9A-Za-z-\\.\\u0020\\\\\\_\\+=\\)\\(\\*&^%\\$#@!<>~;]*",
            PASSWORD: "^[0-9A-Za-z-_,:;/~<>=`'&#@!%\"\\\\\\+\\.\\*\\?\\|\\^\\$\\(\\)\\[\\]\\{\\}]*$",
            DECIMAL: "^[0-9]+[\\.]?[0-9]{0,2}$",
            TEXT: "^[\\p{L}0-9-\\./\\\\\\_\\+=´`'\\^\\(\\)\\*&\\^%\\$#@!\\?\\|<>~,:;\"\\u0020]*$",
            STREET: "^[\\p{L}0-9-\\.'`,#;/\\u0020]*$",
            TOKEN: "^([B][CS]|[V][CDFPS]|[M][CDFP]|[AN][C]|[DU][CD]|BD|[E][CF]|GG|FL|WX|GF|ND)([0-9A-Za-z]{16})([0-9]{4})$|^([X]{1})([V][CDFPS]|[M][CDFP]|[AN][C]|[D][CD]|[B][CDS]|[E][CF]|[G]{2}|[F][L]|[W][X]|[G][F])([0-9]{2})([A-Z]{1})([0-9]{8})([0-9A-Za-z]{16})([0-9A-Za-z]{10})$",
            NAME: "[\\p{L}0-9-_\\.,/\\(\\)`'\\u0020]+",
            ALPHANUMERIC_EXTENDED: "^[\\p{L}0-9-\\.'`\\u0020]*$",
            ZIP_US: "^[0-9]{5}|[0-9]{9}$",
            ZIP: "^[0-9]{5}|[0-9]{9}|[A-Z]{1}[0-9]{1}[A-Z]{1} *[0-9]{1}[A-Z]{1}[0-9]{1}$",
            ZIP_US_CAN_GB: "^((([A-Z]{1,2}[0-9][0-9a-zA-Z]?)\\\\ ([0-9][a-zA-Z]{2}))|(GIR\\\\ 0AA)|(gir\\\\ 0aa))|([0-9]{5}|[0-9]{9}|[0-9a-zA-Z\\u0020]{6,7})|([a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1})$",
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Validation Patterns Types</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "25%"}}></colgroup>
                                        <colgroup style={{width: "45%"}}></colgroup>
                                        <colgroup style={{width: "30%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>Pattern</th>
                                                <th>Pattern Expression</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                        <tr><td colspan="3" style={{fontWeight: "bold"}} >Regular</td></tr>                            <tr>
                                <td>ALPHA</td>
                                <td style={{wordBreak: "break-all"}} >{this.state.ALPHA}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>ALPHANUMERIC</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.ALPHANUMERIC}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>NUMERIC</td>
                                <td style={{wordBreak: "break-all"}}>^[0-9]*$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>EMAIL</td>
                                <td style={{wordBreak: "break-all"}}>^([0-9A-Za-z-_/\+\.\:]+@[0-9A-Za-z-_/\+]+\.[0-9A-Za-z-_/\+\.]+)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>TEXT</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.TEXT}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>DESCRIPTOR</td>
                                <td style={{wordBreak: "break-all"}}>^[0-9A-Za-z-\.#\u0020]*$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>NAME_BUSINESS</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.NAME_BUSINESS}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>STREET</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.STREET}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>POSTAL_CODE</td>
                                <td style={{wordBreak: "break-all"}}>^[0-9A-Za-z]*$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>CODE</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.CODE}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>DOCUMENT_NUMBER</td>
                                <td style={{wordBreak: "break-all"}}>^[0-9A-Za-z\u0020]*$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>URL</td>
                                <td style={{wordBreak: "break-all"}}>^[0-9A-Za-z-_/\\\#@!&lt;&gt;~%&amp;=:;\|\.\+\(\)\*\^\$\?\u0020]*$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>DECIMAL</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.DECIMAL}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>NAME</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.NAME}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>BOOLEAN</td>
                                <td style={{wordBreak: "break-all"}}>^([0-1]|[true]|[false])*$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>PASSWORD</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.PASSWORD}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>FILENAME</td>
                                <td style={{wordBreak: "break-all"}}>^[0-9A-Za-z-\._#\$]*$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>PATH</td>
                                <td style={{wordBreak: "break-all"}}>^[0-9A-Za-z-_:\$/\\]*$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>USERNAME</td>
                                <td style={{wordBreak: "break-all"}}>^[0-9A-Za-z-\._:@!#\$]*$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>REFERENCE</td>
                                <td style={{wordBreak: "break-all"}}>[0-9]*|\*[0-9A-Za-z-\.\u0020\\\_\+=\)\(\*&amp;^%\$#@!&lt;&gt;~;]*</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>ALPHANUMERIC_EXTENDED</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.ALPHANUMERIC_EXTENDED}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>TOKEN</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.TOKEN}</td>
                                <td></td>
                            </tr>
                        <tr><td colspan="3" style={{fontWeight: "bold"}} >Enum</td></tr>                            <tr>
                                <td>TOKENIZATION_POLICY_TYPE_ALL</td>
                                <td style={{wordBreak: "break-all"}}>^(TO|TD|PD|DU|PO|PU)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>TOKENIZATION_POLICY_TYPE_TO_PO_PU</td>
                                <td style={{wordBreak: "break-all"}}>^(TO|PO|PU)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>AGGREGATION_FREQUENCY</td>
                                <td style={{wordBreak: "break-all"}}>^(N|Q|H|D|4|F)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>AGGREGATION_FREQUENCY_DAILY_ONLY</td>
                                <td style={{wordBreak: "break-all"}}>^(D)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>US</td>
                                <td style={{wordBreak: "break-all"}}>^(US)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>USA_CANADA</td>
                                <td style={{wordBreak: "break-all"}}>^(US|CA)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>USA_CANADA_GB</td>
                                <td style={{wordBreak: "break-all"}}>^(US|CA|GB)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>USA_CANADA_MEXICO</td>
                                <td style={{wordBreak: "break-all"}}>^(US|CA|MX)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>USD_CAD</td>
                                <td style={{wordBreak: "break-all"}}>^(CAD|USD)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>USD_CAD_GBP</td>
                                <td style={{wordBreak: "break-all"}}>^(USD|CAD|GBP)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>USA_GB</td>
                                <td style={{wordBreak: "break-all"}}>^(US|GB)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>USA_MEXICO</td>
                                <td style={{wordBreak: "break-all"}}>^(US|MX)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>USD</td>
                                <td style={{wordBreak: "break-all"}}>^(USD)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>STATE_US_CANADA</td>
                                <td style={{wordBreak: "break-all"}}>^(CA|AL|AK|AZ|AR|CO|CT|DE|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|DC|WV|WI|WY|PR|AB|BC|MB|NB|NL|NT|NS|NU|ON|PE|QC|SK|YT)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>STATE_UK</td>
                                <td style={{wordBreak: "break-all"}}>^(BKM|CAM|CMA|DBY|DEV|DOR|ESX|ESS|GLS|HAM|HRT|KEN|LAN|LEC|LIN|NFK|NYK|NTH|NTT|OXF|SOM|STS|SFK|SRY|WAR|WSX|WOR|LND|BDG|BNE|BEX|BEN|BRY|CMD|CRY|EAL|ENF|GRE|HCK|HMF|HRY|HRW|HAV|HIL|HNS|ISL|KEC|KTT|LBH|LEW|MRT|NWM|RDB|RIC|SWK|STN|TWH|WFT|WND|WSM|BNS|BIR|BOL|BRD|BUR|CLD|COV|DNC|DUD|GAT|KIR|KWL|LDS|LIV|MAN|NET|NTY|OLD|RCH|ROT|SHN|SLF|SAW|SFT|SHF|SOL|STY|SKP|SND|TAM|TRF|WKF|WLL|WGN|WRL|WLV|BAS|BDF|BBD|BPL|BMH|BRC|BNH|BST|CBF|CHE|CHW|CON|DAL|DER|DUR|ERY|HAL|HPL|HEF|IOW|IOS|KHL|LCE|LUT|MDW|MDB|MIK|NEL|NLN|NSM|NBL|NGM|PTE|PLY|POL|POR|RDG|RCC|RUT|SHR|SLG|SGC|STH|SOS|STT|STE|SWD|TFW|THR|TOB|WRT|WBK|WIL|WNM|WOK|YOR|ANT|ARD|ARM|BLA|BLY|BNB|BFS|CKF|CSR|CLR|CKT|CGV|DRY|DOW|DGN|FER|LRN|LMV|LSB|MFT|MYL|NYM|NTA|NDN|OMH|STB|ABE|ABD|ANS|AGB|CLK|DGY|DND|EAY|EDU|ELN|ERW|EDH|ELS|FAL|FIF|GLG|HLD|IVC|MLN|MRY|NAY|NLK|ORK|PKN|RFW|SCB|ZET|SAY|SLK|STG|WDU|WLN|BGW|BGE|CAY|CRF|CMN|CGN|CWY|DEN|FLN|GWN|AGY|MTY|MON|NTL|NWP|PEM|POW|RCT|SWA|TOF|VGL|WRX)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>STATE_US</td>
                                <td style={{wordBreak: "break-all"}}>^(CA|AL|AK|AZ|AR|CO|CT|DE|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|DC|WV|WI|WY|PR)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>STATE_MX</td>
                                <td style={{wordBreak: "break-all"}}>^(AG|BJ|BS|CP|CH|CI|CU|CL|DF|DG|GJ|GR|HG|JA|EM|MH|MR|NA|NL|OA|PU|QA|QR|SL|SI|SO|TA|TM|TL|VZ|YC|ZT)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>AUD</td>
                                <td style={{wordBreak: "break-all"}}>^(AUD)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>AUD_NZD</td>
                                <td style={{wordBreak: "break-all"}}>^(AUD|NZD)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>AUD_NZD_USD</td>
                                <td style={{wordBreak: "break-all"}}>^(AUD|NZD|USD)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>GBP</td>
                                <td style={{wordBreak: "break-all"}}>^(GBP)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>ZIP</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.ZIP}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>ZIP_US</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.ZIP_US}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>ZIP_US_CAN_GB</td>
                                <td style={{wordBreak: "break-all"}}>{this.state.ZIP_US_CAN_GB}</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>FULL_EISOP_COUNTRY</td>
                                <td style={{wordBreak: "break-all"}}>^(AF|AX|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AT|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BQ|BA|BW|BV|BR|IO|BN|BG|BF|BI|KH|CM|CA|CV|KY|CF|TD|CL|CN|CX|CC|CO|KM|CG|CD|CK|CR|CI|HR|CU|CW|CY|CZ|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MA|MZ|MM|NA|NR|NP|NL|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|RE|RO|RU|RW|BL|SH|KN|LC|MF|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SX|SK|SI|SB|SO|ZA|GS|SS|ES|LK|SD|SR|SJ|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|UM|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>EISOP_COUNTRY</td>
                                <td style={{wordBreak: "break-all"}}>^(AT|BE|BG|CH|CY|CZ|DE|DK|EE|ES|FI|FR|GR|HU|IE|IL|IT|LT|LU|LV|NL|NO|PL|PT|RO|SE|SI|TR|GB)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>SPINDLE_COUNTRY</td>
                                <td style={{wordBreak: "break-all"}}>^(US|CA|AU)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>STRATUS_COUNTRY</td>
                                <td style={{wordBreak: "break-all"}}>^(US|CA|GB|AT|BE|FI|FR|DE|GR|IE|IT|NL|PT|ES)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>CLEARHAUS_COUNTRY</td>
                                <td style={{wordBreak: "break-all"}}>^(AT|BE|BG|CH|CY|CZ|DE|DK|EE|ES|FI|FR|GR|HU|IE|IL|IT|LT|LU|LV|NL|NO|PL|PT|RO|SE|SI|TR|GB|RU|US|AU|CA|NZ|SA|AE|EG)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>WELLSFARGO_COUNTRY</td>
                                <td style={{wordBreak: "break-all"}}>^(GB|US|AU|CA|NZ|ES)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>TIME_ZONE</td>
                                <td style={{wordBreak: "break-all"}}>^(705|706|707|708)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>TIME_ZONE_CHAR</td>
                                <td style={{wordBreak: "break-all"}}>^(CST|EST|MST|PST)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>CLEARHAUS_CURRENCY_CODE</td>
                                <td style={{wordBreak: "break-all"}}>^(USD|EUR|GBP|DKK|NOK|SEK)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>LPB_MEDORO_CURRENCY</td>
                                <td style={{wordBreak: "break-all"}}>^(USD|RUB|EUR)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>ALL_CUR</td>
                                <td style={{wordBreak: "break-all"}}>^(USD|CAD|AUD|NZD|GBP|EUR|PTS|RUB|DKK|NOK|SEK)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>EUR</td>
                                <td style={{wordBreak: "break-all"}}>^(EUR)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>EUR_USD</td>
                                <td style={{wordBreak: "break-all"}}>^(EUR|USD)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>EUR_GBP</td>
                                <td style={{wordBreak: "break-all"}}>^(EUR|GBP)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>EUR_USD_RUB</td>
                                <td style={{wordBreak: "break-all"}}>^(EUR|USD|RUB)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>EUR_USD_CAD</td>
                                <td style={{wordBreak: "break-all"}}>^(EUR|USD|CAD)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>EUR_USD_CAD_GBP</td>
                                <td style={{wordBreak: "break-all"}}>^(EUR|USD|CAD|GBP)$</td>
                                <td></td>
                            </tr>
                                                    <tr>
                                <td>CAD</td>
                                <td style={{wordBreak: "break-all"}}>^(CAD)$</td>
                                <td></td>
                            </tr>
                                                </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

