import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import axios from "axios";


//Modal imagen
import ModalImageServiceUser from './ModalImageServiceUser';

export default class ModalRequestParameters extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            showmodalimagenservice: false,
            msgurl: "The input value is not a correct URL",
            msgusernamee: "You have not answered all required fields",
            msgpassw: "You have not answered all required fields",
            msgaccountid: "The input value was not a correct number",
            msgsplitschemaid: "The input value is incorrect",
            msgamount: "The input value was not a correct number",
            vermsgurl: false,
            vermsgusernamee: false,
            vermsgpassw: false,
            vermsgaccountid: false,
            vermsgsplitschemaid: false,
            vermsgamount: false,
            url: "",
            usernamee: "",
            passw: "",
            accountid: "",
            splitschemaid: "",
            amount: "",
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    escribir = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
        
    };

    showModalImage(){
        this.setState({ showmodalimagenservice: true})
      }


    cerrarModalImage = (accion) => {
        this.setState({  showmodalimagenservice:false })
       
    }

    
    enviarDatos = async () => {

        this.setState({ vermsgurl: false })
        this.setState({ vermsgusernamee: false })
        this.setState({ vermsgpassw: false })
        this.setState({ vermsgaccountid: false })
        this.setState({ vermsgsplitschemaid: false })
        this.setState({ vermsgamount: false })
       
        let validacionescorrectas = true
        let validatornum = new RegExp(`^[0-9]+`);

        if (this.state.url == "") {
            this.setState({ vermsgurl: true })
            validacionescorrectas = false;
        }

        if (this.state.usernamee == "") {
            this.setState({ vermsgusernamee: true })
            validacionescorrectas = false;
        }
        if (this.state.passw == "") {
            this.setState({ vermsgpassw: true })
            validacionescorrectas = false;
        }
        if (this.state.accountid == "" || !validatornum.test(this.state.accountid)) {
            this.setState({ vermsgaccountid: true })
            validacionescorrectas = false;
        }
        if (this.state.splitschemaid == "") {
            this.setState({ vermsgsplitschemaid: true })
            validacionescorrectas = false;
        }

        if (this.state.amount == "" || !validatornum.test(this.state.amount)) {
            this.setState({ vermsgamount: true })
            validacionescorrectas = false;
        }
        

        if (validacionescorrectas) {
            let json = {
                "userName": this.state.usernamee,
                "password": this.state.passw,
                "accountId": this.state.accountid,
                "a": this.state.splitschemaid,
                "amount": this.state.amount,
            }

       /*     try {
                var consulta;
                
                    consulta = await axios.post(this.state.url, json);
               
        
                var resp = consulta.data;
                return resp;
            } catch (error) {
                return -1;
            }
*/
                
        }

    }


    render() {
        return (
            <>
           { (this.state.showmodalimagenservice ) ? 
            (<ModalImageServiceUser tipo="full" show={ this.state.showmodalimagenservice } cerrarModal={this.cerrarModalImage} />) :null

            }
           
            
                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Request Parameters</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                            <table style={{width: "100%"}}>
                                <tbody><tr>
                                    <td>Use the values you were provided with in your merchant/user registration email</td>
                                    <td >
                                        <button type="button" class="btn btn-success" onClick={() => this.showModalImage()}>Screenshot</button>
                                    </td>
                                </tr>
                            </tbody></table>
                            </div>
                        </div>

                       
        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="table-responsive">
                    <table class="table my-table">
                        <colgroup style={{width: "30%"}}></colgroup>
                        <colgroup style={{width: "70%"}}></colgroup>
                        <tbody>
                        <tr>
                            <td>Portal URL (Do not include path)</td>
                            <td>
                                <input onChange={this.escribir("url")} type="text" id="url" value={this.state.url}  size="60" data-validation="url" title="Please enter your domain in the following format: https://your-domain.com. Do not include path"/>
                                {this.state.vermsgurl ? <span className="msgError letraNormal"
                                    style={{ color: "#ff3e3e" }}>{this.state.msgurl}</span> : null}
                            </td>
                        </tr>
                        <tr>
                            <td>Username</td>
                            <td>
                                <p><input onChange={this.escribir("usernamee")} type="text" id="polzovatel"  value={this.state.usernamee} data-rule-required="true" autocomplete="off" data-validation="required" title="Please enter the name of your service user provided in the registration email"/><br/></p>
                                {this.state.vermsgusernamee ? <span className="msgError letraNormal"
                                    style={{ color: "#ff3e3e" }}>{this.state.msgusernamee}</span> : null}
                            </td>
                        </tr>
                        <tr>
                            <td>Password</td>
                            <td>
                                <p><input onChange={this.escribir("passw")} type="password" id="parol"  value={this.state.passw} autocomplete="off" data-validation="required" title="Please enter the password of your service user provided in the registration email"/><br/></p>
                                {this.state.vermsgpassw ? <span className="msgError letraNormal"
                                    style={{ color: "#ff3e3e" }}>{this.state.msgpassw}</span> : null}
                            </td>
                        </tr>
                        <tr>
                            <td>Account ID</td>
                            <td>
                                <p><input onChange={this.escribir("accountid")}  value={this.state.accountid} type="text" id="mac" data-validation="number" title="Please enter an account ID which you would like to make a test transaction for"/><br/></p>
                                {this.state.vermsgaccountid ? <span className="msgError letraNormal"
                                    style={{ color: "#ff3e3e" }}>{this.state.msgaccountid}</span> : null}
                            </td>
                        </tr>
                                                                                                    <tr>
                                <td>Split Schema Id</td>
                                <td>
                                    <p><input onChange={this.escribir("splitschemaid")} value={this.state.splitschemaid} type="text" id="splitSchemaId" data-validation="custom" data-validation-regexp="^([a-zA-Z0-9\*]+)$"/><br/></p>
                                    {this.state.vermsgsplitschemaid ? <span className="msgError letraNormal"
                                    style={{ color: "#ff3e3e" }}>{this.state.msgsplitschemaid}</span> : null}
                                </td>
                            </tr>
                                                </tbody>
                    </table>
                </div>
            </div>
        </div>
      

        <hr/>
        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="table-responsive">
                    <table class="table my-table">
                        <colgroup style={{width: "70%"}}></colgroup>
                        <colgroup style={{width: "30%"}}></colgroup>
                        <tbody>
                        <tr>
                            <td>Use a different amount to trigger a different response from the Integration Response Emulator.</td>
                            <td align="right;">
                            <button type="button" class="btn btn-success" onClick={() =>  window.open('/processingapi/test_ranges', '_blank')}>Test Amount Ranges</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="table-responsive">
                    <table class="table my-table">
                        <colgroup style={{width: "30%"}}></colgroup>
                        <colgroup style={{width: "70%"}}></colgroup>
                        <tbody>
                        <tr>
                            <td>Amount (in cents)</td>
                            <td>
                                <p><input onChange={this.escribir("amount")} type="text" id="amount" data-validation="number" value={this.state.amount} title="Please enter transaction amount in cents"/><br/></p>
                                {this.state.vermsgamount ? <span className="msgError letraNormal"
                                    style={{ color: "#ff3e3e" }}>{this.state.msgamount}</span> : null}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="table-responsive">
                    <table class="table my-table">
                        <colgroup style={{width: "100%"}}></colgroup>
                        <tbody>
                        <tr>
                            <td style={{textAlign: "center"}}>
                                <button onClick={() => this.enviarDatos()} type="submit" class="btn btn-primary link-parser">Submit</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <br/>

                       

                    </ModalBody>
                </Modal>
            </>
        )
    }
}



