import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalProcessorRules extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Processor Rules
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <span class="h3">Paysafe (ACH)</span>
                            </div>
                            <div class="panel-body">
                                The following fields are required for <em>Processing File (request)</em>:
                                <ul type="circle" style={{marginLeft: "10px", color: "#5688c9"}}>
                                    <li>
                                        <strong>street</strong>
                                    </li>
                                    <li>
                                        <strong>city</strong>
                                    </li>
                                    <li>
                                        <strong>phone</strong>
                                    </li>
                                    <li>
                                        <strong>countryCode</strong>
                                    </li>
                                    <li>
                                        <strong>subscriptionCode</strong>
                                    </li>
                                </ul>
                                <br/>
                                The following fields are required for <em>Subscription File (request)</em>:
                                <ul type="circle" style={{marginLeft: "10px", color: "#5688c9"}}>
                                    <li>
                                        <strong>street</strong>
                                    </li>
                                    <li>
                                        <strong>city</strong>
                                    </li>
                                    <li>
                                        <strong>phone</strong>
                                    </li>
                                    <li>
                                        <strong>countryCode</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {this.state.modal=="ProcessorRules2" ? 
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <span class="h3">JPMorgan Chase (ACH)</span>
                            </div>
                            <div class="panel-body">
                            <strong>countryCode</strong> field is required for <em>Subscription File (request)</em>
                            </div>
                        </div>  
                        : null}

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

