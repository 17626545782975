import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINProviderResponseData extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Provider Response Data</h2>

                        <div class="panel panel-default">
                        <div class="panel-body">
                            Generally response message for Sale-Auth, Sale, Credit-Auth or Credit, includes two groups of fields: responseCode (with its respective responseMessage), avsResponseCode, cscResponseCode and providerResponseCode (with its respective providerResponseMessage), providerAvsResponseCodeand, providerCscResponseCode. Fields that are responseCode and responseMessage as well as avsResponseCode and cscResponseCode fields are processor independent responses, while providerResponseCode and providerResponseMessage as well as providerAvsResponseCodeand and providerCscResponseCode fields are the original responses, coming from the underlying processor or bank.
                            <br/>
                            <br/>
                            It is recommended to use responseCode with responseMessage, because they are unified across all of the underlying processors or banks, with which processing is possible. Two others fields, providerResponseCode and providerResponseMessage are mostly for convenience and support of legacy systems.<br/>
                        </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

