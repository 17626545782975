import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalTransactionIndustryType extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Transaction Industry Type</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "15%"}}></colgroup>
                                        <colgroup style={{width: "85%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                            <td width="100px">DM</td>
                            <td>Direct marketing<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">EC</td>
                            <td>Ecommerce<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">PT</td>
                            <td>Petroleum<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">RE</td>
                            <td>Retail<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">RS</td>
                            <td>Restaurant<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">LD</td>
                            <td>Lodging<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">CCD</td>
                            <td>Corporate credit or debit (direct debit only)<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">PPD</td>
                            <td>Prearranged payment and deposit entry (direct debit only)<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">POP</td>
                            <td>Point purchase entry (direct debit only)<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">TEL</td>
                            <td>Telephone initiated entry (direct debit only)<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">WEB</td>
                            <td>Internet initiated entry (direct debit only)<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">C21</td>
                            <td>Check 21 (direct debit only)<br/></td>
                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

