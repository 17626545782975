import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalTokenization extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Tokenization</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                See <a href="http://en.wikipedia.org/wiki/Tokenization_%28data_security%29">What is it? page</a> for more information about Tokenization.

                                <br/>
                                provides a simple and convenient tokenization mechanism for merchants that deal with recurring payments and want to avoid storage of customer’s payment information (credit card and bank account numbers) within their own system. When a merchant uses any given credit card or bank account for the first time (as part of authorization, sale or credit operation),  will generate a unique identification number (token) associated with this payment information. If in the future, the merchant wants to issue another operation (e.g. another sale) on the same credit card or bank account, it is sufficient to pass to  just the value of the previously generated token and omit all of the account information fields.
                                <br/>

                                <br/>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

