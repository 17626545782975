import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINSplitAmountFormat extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Split Amount Format</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                            When <em>amount</em> field is used in the API request within a split subrecord, it can take one of two values. By default, the value of the field is assumed to be amount in cents that will be split out of a total amount of the transaction. However, you can also specify a percentage (rate) that you want to be split out of a total amount of the transaction. 
                            <ul type="circle" style={{marginLeft: "10px", color: "#5688c9"}}>
                            <li>To specify that the submitted value is an amount, it should be preceded by the prefix A. The amount is submitted as an integer value in cents. If you omit the prefix, the system will assume that the value is amount by default. For example, $10 is submitted as <em>amount=A1000</em> or <em>amount=1000</em>; 
                            </li>
                            <li>To specify that the submitted value is a percentage (rate), it should be preceded by the prefix R . The rate is submitted as an integer value with four decimal places precision (10%=100000, 1%=10000, 0.25%=2500). For example, 10% of a total amount is submitted as <em>amount=R100000</em>.</li>
                            </ul>                
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

