import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';

export default class ModalTerminalCode extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Terminal Code</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                    <div class="panel-body">
                                    When provisioning terminals in the portal, you have the ability to enter a 3-digit, numerical terminal code. This terminal code is passed in all API requests to identify the transaction’s target device. As such, it’s important that these terminal codes be tracked within your system. If you do not enter a terminal code, one will be assigned automatically. Each terminal will have a sticker which displays the terminal’s key, a numerical identifier which is comprised of the concatenated account code and terminal code. When a terminal arrives at its destination, the recipient should have the ability to register the device within your software, using the terminal code or key. We recommend that you enter the terminal in your database when you first provision it, making sure to include the terminal code and its related account code. This will allow you to associate the terminal with its recipient upon registration.
                                        <br/>            
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

