import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalProcessorRules extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
        }
    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}></h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>


                        <div class="white-popup">
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <h2>Processor Rules</h2>
                </div>
            </div>

        
            <div class="panel panel-default">
                <div class="panel-heading"><span class="h3">GETI (ACH)</span></div>
                <div class="panel-body">
                    <strong>checkNumber</strong> field is required for <em>Sale and Sale-Auth (requests)</em> for direct debit transactions.<br/>                                    </div>
            </div>
        
            <div class="panel panel-default">
                <div class="panel-heading"><span class="h3">ProfitStars (ACH)</span></div>
                <div class="panel-body">
                    <strong>checkNumber</strong> field is required for <em>Sale and Sale-Auth (requests)</em> for direct debit transactions.<br/>                                    </div>
            </div>
        
            <div class="panel panel-default">
                <div class="panel-heading"><span class="h3">T-Tech EFT (ACH)</span></div>
                <div class="panel-body">
                    <strong>checkNumber</strong> field is required for <em>Sale and Sale-Auth (requests)</em> for direct debit transactions.<br/>                                    </div>
            </div>
        
            <div class="panel panel-default">
                <div class="panel-heading"><span class="h3">WorldPay SecureNet (ACH)</span></div>
                <div class="panel-body">
                    <strong>checkNumber</strong> field is required for <em>Sale and Sale-Auth (requests)</em> for direct debit transactions.<br/>                                    </div>
            </div>
        
            </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

