import React from 'react'
import { LuArrowRightCircle } from 'react-icons/lu';
import Navbar from '../../../../Components/NavBar/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import { getDatos } from '../../../../Services/DatosLocalees';
import './Operations.css'
import parse from 'html-react-parser';
import { HiOutlineMinusSm } from 'react-icons/hi';





export default class TestRanges extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      bandera: props.bandera,
      contenido: [],
      titulo: "",

      tablasporseccion:[],
      tablasporseccionexamples:[],
      showmodal: "",
      modaltesttransaction: false,
      modalimagenservice: false,
   
    }
  }


  async componentDidMount() {

    const query = new URLSearchParams(window.location.search);    //para funcion
    const query_loc = query.get('ban');
    console.log(query_loc)
    var resp = [];

    resp = await getDatos(query_loc);



    this.setState({ bandera: query_loc })
    this.setState({ titulo: resp.tituloge })
    this.setState({ contenido: resp.datos })
  }



  pasartexto = (texto) => {
    try {
      return parse(texto);
    } catch (error) {
      return texto;
    }
  }



  seleccionarModal(titulomodal){
    console.log(titulomodal);
    this.setState({ showmodal: titulomodal})
  }

  showModalExampleTest(contenido){
    this.setState({ modaltesttransaction: true})
  }

  cerrarModal = (accion) => {
    this.setState({ showmodal: "", modaltesttransaction: false })
    if (accion == 1) {

    }
}


  render() {
    return (
      <>
        <Navbar />
        

        <div className='anchoMaximo' style={{ paddingTop: '120px', display: 'block', margin: "auto", width: "95%" }}>

            <div class="row">
                <div class="col-xs-12 col-sm-3">
                                        <h1>
                                                 APIs                    </h1>
                </div>
            </div>

            <div class="row">
                    <div class="col-xs-12 col-sm-12">
                        <h3 style={{color: "#0e2689"}}>Test amount ranges</h3>
                                    
                                                                                                                                                <div class="panel panel-default">
                                            <div class="panel-heading">
                                                <h3 class="panel-title">Cards (Real-Time)</h3>
                                            </div>
                                            <div class="panel-body">
                                                <div class="table-responsive">
                                                    <table class="table my-table">
                                                        <colgroup style={{width: "10%"}} ></colgroup>
                                                        <colgroup style={{width: "10%"}}></colgroup>
                                                        <colgroup style={{width: "10%"}}></colgroup>
                                                        <colgroup style={{width: "20%"}}></colgroup>
                                                        <colgroup style={{width: "35%"}}></colgroup>
                                                      
                                                        <thead>
                                                        <tr>
                                                            <th colspan="2">Amount Range</th>
                                                            <th rowspan="2">Response code</th>
                                                            <th rowspan="2">Response message</th>
                                                            <th rowspan="2">Foot Note</th>
                                                            
                                                        </tr>
                                                        <tr style={{fontWeight: "bold"}}>
                                                            <th style={{width: "150px"}}>In dollars</th>
                                                            <th style={{width: "150px"}}>In cents</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                                                                                    <tr>
                                                                <td>5.00                                                                    - 69.99</td>
                                                                <td>500                                                                    - 6999</td>
                                                                <td>A01</td>
                                                                <td>Approved: XXXXXX (approval code)</td>
                                                                <td>Transaction has been approved.</td>
                                                               
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>5.00                                                                    - 69.99</td>
                                                                <td>500                                                                    - 6999</td>
                                                                <td>A02</td>
                                                                <td>Credit Posted</td>
                                                                <td>Credit has been posted on a cardholder’s account.</td>
                                                                
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>70.00                                                                    - 79.99</td>
                                                                <td>7000                                                                    - 7999</td>
                                                                <td>D05</td>
                                                                <td>Invalid card number</td>
                                                                <td>Specified credit card number is invalid or does not exist.</td>
                                                               
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>80.00                                                                    - 89.99</td>
                                                                <td>8000                                                                    - 8999</td>
                                                                <td>D10</td>
                                                                <td>Card reported lost</td>
                                                                <td>The customer’s bank has declined the transaction and requested the customer’s credit card to be retained because card was reported lost.</td>
                                                              
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>90.00                                                                    - 99.99</td>
                                                                <td>9000                                                                    - 9999</td>
                                                                <td>D30</td>
                                                                <td>Call for authorization</td>
                                                                <td>To proceed with the transaction, call for authorization is needed to confirm the validity of the card.

Note: This range is designated to test voice authorization. 
Sale, Sale-Auth, Account-Verification request in the amount between $90.00 – 99.99 will result in D30 decline code, however if approval code 012345 is specified, then A01 will be received.</td>
                                                             
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>100.00                                                                    - 109.99</td>
                                                                <td>10000                                                                    - 10999</td>
                                                                <td>D04</td>
                                                                <td>Hold - Pick up card</td>
                                                                <td>The customer’s bank has declined the transaction and requested customer’s credit card to be retained.</td>
                                                                
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>110.00                                                                    - 119.99</td>
                                                                <td>11000                                                                    - 11999</td>
                                                                <td>D08</td>
                                                                <td>CVV2 is invalid</td>
                                                                <td>CVV2 value is invalid (Decline CSC/CID Fail).</td>
                                                                
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>120.00                                                                    - 129.99</td>
                                                                <td>12000                                                                    - 12999</td>
                                                                <td>D03</td>
                                                                <td>Insufficient Funds</td>
                                                                <td>Specified credit card does not have sufficient funds.</td>
                                                               
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>130.00                                                                    - 139.99</td>
                                                                <td>13000                                                                    - 13999</td>
                                                                <td>E02</td>
                                                                <td>Processing Network Unavailable</td>
                                                                <td>Processing network is temporarily unavailable.</td>
                                                                
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>140.00                                                                    - 149.99</td>
                                                                <td>14000                                                                    - 14999</td>
                                                                <td>D41</td>
                                                                <td>Processing Error</td>
                                                                <td>A connection error occurred after the transaction has been submitted.
</td>
                                                              
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>150.00                                                                    - 159.99</td>
                                                                <td>15000                                                                    - 15999</td>
                                                                <td>A05</td>
                                                                <td>Partially Approved</td>
                                                                <td>Transaction has been partially approved as a result of split payment.

Note: This range is designated to test partial authorizations. By setting Sale with this amount range the partially approved transaction will be received. Approved amount will be $10 less than the originally requested amount. 
''Partially Approved'' (A05) response can be received under the condition if isPartialAuthorization is set to ''1'' and transactionIndustryType is set to either ''Retail'' or ''Restaurant'', otherwise ''Insufficient Funds'' (D03) response is received. </td>
                                                              
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>170.00                                                                    - 179.99</td>
                                                                <td>17000                                                                    - 17999</td>
                                                                <td>D27</td>
                                                                <td>Transaction Error</td>
                                                                <td>Transaction Error. 

Note: This range is designated to test rejects. In this scenario, A01 approval code is received in the response but after a cycle is closed (batches settled for direct debit), a reject is generated for transactions with the indicated amount range.</td>
                                                                
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>300.00                                                                    - 1,500.00</td>
                                                                <td>30000                                                                    - 150000</td>
                                                                <td>A01</td>
                                                                <td>Approved: XXXXXX (approval code)</td>
                                                                <td>Transaction has been approved.</td>
                                                               
                                                            </tr>
                                                                                                                </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                                                                                                                                                                    <div class="panel panel-default">
                                            <div class="panel-heading">
                                                <h3 class="panel-title">Cards (Batch)</h3>
                                            </div>
                                            <div class="panel-body">
                                                <div class="table-responsive">
                                                    <table class="table my-table">
                                                        <colgroup style={{width: "10%"}} ></colgroup>
                                                        <colgroup style={{width: "10%"}}></colgroup>
                                                        <colgroup style={{width: "10%"}}></colgroup>
                                                        <colgroup style={{width: "20%"}}></colgroup>
                                                        <colgroup style={{width: "35%"}}></colgroup>
                                                       
                                                        <thead>
                                                        <tr>
                                                            <th colspan="2">Amount Range</th>
                                                            <th rowspan="2">Response code</th>
                                                            <th rowspan="2">Response message</th>
                                                            <th rowspan="2">Foot Note</th>
                                                            
                                                        </tr>
                                                        <tr style={{fontWeight: "bold"}}>
                                                            <th style={{width: "150px"}}>In dollars</th>
                                                            <th style={{width: "150px"}}>In cents</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                                                                                    <tr>
                                                                <td>5.00                                                                    - 69.99</td>
                                                                <td>500                                                                    - 6999</td>
                                                                <td>A01</td>
                                                                <td>Approved: XXXXXX (approval code)</td>
                                                                <td>Transaction has been approved.</td>
                                                              
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>70.00                                                                    - 79.99</td>
                                                                <td>7000                                                                    - 7999</td>
                                                                <td>D05</td>
                                                                <td>Invalid card number</td>
                                                                <td>Specified credit card number is invalid or does not exist.</td>
                                                               
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>80.00                                                                    - 89.99</td>
                                                                <td>8000                                                                    - 8999</td>
                                                                <td>D10</td>
                                                                <td>Card reported lost</td>
                                                                <td>The customer’s bank has declined the transaction and requested the customer’s credit card to be retained because card was reported lost.</td>
                                                              
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>90.00                                                                    - 99.99</td>
                                                                <td>9000                                                                    - 9999</td>
                                                                <td>D13</td>
                                                                <td>Stop Recurring</td>
                                                                <td>Customer requested stop of specific recurring payment.</td>
                                                              
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>100.00                                                                    - 109.99</td>
                                                                <td>10000                                                                    - 10999</td>
                                                                <td>D04</td>
                                                                <td>Hold - Pick up card</td>
                                                                <td>The customer’s bank has declined the transaction and requested customer’s credit card to be retained.</td>
                                                               
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>110.00                                                                    - 119.99</td>
                                                                <td>11000                                                                    - 11999</td>
                                                                <td>D06</td>
                                                                <td>Account not found</td>
                                                                <td>Specified credit card number is associated with account that does not exist. </td>
                                                             
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>120.00                                                                    - 129.99</td>
                                                                <td>12000                                                                    - 12999</td>
                                                                <td>D03</td>
                                                                <td>Insufficient Funds</td>
                                                                <td>Specified credit card does not have sufficient funds.</td>
                                                               
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>130.00                                                                    - 139.99</td>
                                                                <td>13000                                                                    - 13999</td>
                                                                <td>E02</td>
                                                                <td>Processing Network Unavailable</td>
                                                                <td>Processing network is temporarily unavailable.</td>
                                                              
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>140.00                                                                    - 149.99</td>
                                                                <td>14000                                                                    - 14999</td>
                                                                <td>D41</td>
                                                                <td>Processing Error</td>
                                                                <td>A connection error occurred after the transaction has been submitted.
</td>
                                                              
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>150.00                                                                    - 159.99</td>
                                                                <td>15000                                                                    - 15999</td>
                                                                <td>A05</td>
                                                                <td>Partially Approved</td>
                                                                <td>Transaction has been partially approved as a result of split payment.

Note: This range is designated to test partial authorizations. By setting Sale with this amount range the partially approved transaction will be received. Approved amount will be $10 less than the originally requested amount. 
''Partially Approved'' (A05) response can be received under the condition if isPartialAuthorization is set to ''1'' and transactionIndustryType is set to either ''Retail'' or ''Restaurant'', otherwise ''Insufficient Funds'' (D03) response is received. </td>
                                                                
                                                            </tr>
                                                                                                                </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                                                                                                                                                                    <div class="panel panel-default">
                                            <div class="panel-heading">
                                                <h3 class="panel-title">Direct Debit (Real-Time)</h3>
                                            </div>
                                            <div class="panel-body">
                                                <div class="table-responsive">
                                                    <table class="table my-table">
                                                       
                                                        <colgroup style={{width: "10%"}} ></colgroup>
                                                        <colgroup style={{width: "10%"}}></colgroup>
                                                        <colgroup style={{width: "10%"}}></colgroup>
                                                        <colgroup style={{width: "20%"}}></colgroup>
                                                        <colgroup style={{width: "35%"}}></colgroup>
                                                       
                                                        <thead>
                                                        <tr>
                                                            <th colspan="2">Amount Range</th>
                                                            <th rowspan="2">Response code</th>
                                                            <th rowspan="2">Response message</th>
                                                            <th rowspan="2">Foot Note</th>
                                                            
                                                        </tr>
                                                        <tr style={{fontWeight: "bold"}}>
                                                            <th style={{width: "150px"}}>In dollars</th>
                                                            <th style={{width: "150px"}}>In cents</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                                                                                    <tr>
                                                                <td>5.00                                                                    - 69.99</td>
                                                                <td>500                                                                    - 6999</td>
                                                                <td>A01</td>
                                                                <td>Approved: XXXXXX (approval code)</td>
                                                                <td>Transaction has been approved.</td>
                                                               
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>5.00                                                                    - 69.99</td>
                                                                <td>500                                                                    - 6999</td>
                                                                <td>A02</td>
                                                                <td>Credit Posted</td>
                                                                <td>Credit has been posted on a cardholder’s account.</td>
                                                              
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>70.00                                                                    - 79.99</td>
                                                                <td>7000                                                                    - 7999</td>
                                                                <td>R05</td>
                                                                <td>Unauthorized Debit to Consumer Account Using Corporate SEC Code</td>
                                                                <td>A CCD debit entry was transmitted to a consumer account and was not authorized by the consumer. </td>
                                                               
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>80.00                                                                    - 89.99</td>
                                                                <td>8000                                                                    - 8999</td>
                                                                <td>R02</td>
                                                                <td>Account Closed</td>
                                                                <td>Previously active account has been closed by customer or customer's bank.</td>
                                                               
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>90.00                                                                    - 99.99</td>
                                                                <td>9000                                                                    - 9999</td>
                                                                <td>R08</td>
                                                                <td>Stop Payment or Stop on Source Document</td>
                                                                <td>Receiver of a recurring debit transaction has stopped payment on a specific ACH debit.</td>
                                                               
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>100.00                                                                    - 109.99</td>
                                                                <td>10000                                                                    - 10999</td>
                                                                <td>R16</td>
                                                                <td>Account Frozen</td>
                                                                <td>Funds unavailable due to specific action by the RDFI or by legal action.</td>
                                                              
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>110.00                                                                    - 119.99</td>
                                                                <td>11000                                                                    - 11999</td>
                                                                <td>R03</td>
                                                                <td>No Account</td>
                                                                <td>Account structure is valid and passes editing process, but does not correspond to individual or is not an open account.</td>
                                                             
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>120.00                                                                    - 129.99</td>
                                                                <td>12000                                                                    - 12999</td>
                                                                <td>R01</td>
                                                                <td>Insufficient Funds</td>
                                                                <td>Available balance is not sufficient to cover the dollar amount of the debit entry.</td>
                                                             
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>130.00                                                                    - 139.99</td>
                                                                <td>13000                                                                    - 13999</td>
                                                                <td>E02</td>
                                                                <td>Processing Network Unavailable</td>
                                                                <td>Processing network is temporarily unavailable.</td>
                                                              
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>150.00                                                                    - 1,500.00</td>
                                                                <td>15000                                                                    - 150000</td>
                                                                <td>A01</td>
                                                                <td>Approved: XXXXXX (approval code)</td>
                                                                <td>Transaction has been approved.</td>
                                                             
                                                            </tr>
                                                                                                                    <tr>
                                                                <td>140.00                                                                    - 149.99</td>
                                                                <td>14000                                                                    - 14999</td>
                                                                <td>D41</td>
                                                                <td>Processing Error</td>
                                                                <td>A connection error occurred after the transaction has been submitted.
                                                        </td>
                                                              
                                                            </tr>
                                                                                                                </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                                                                                                                                    </div>
                </div>



         


            <br />
        </div>
        <Footer />

      </>
      
    )
  }
  
}

