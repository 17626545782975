import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalOfflineTransactions extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Offline Transactions</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                Offline processing occurs when communication with a host is either impossible or not desired. There are two types of offline transactions:
<br/>
<ul type="circle" >
<li><strong>Offline authorization</strong> is used for EMV transactions. In this case, transaction is authorized offline using EMV chip within the card. Commonly used for "on-board" transactions (when there is no internet connection, for example, on a plane or a ship). The chip's decision (approval or decline) is synchronized with a host when the internet connection is reestablished. 
</li>
<li><strong>Store-and-forward</strong> is used for non-EMV transactions. When store-and-forward functionality is used, the transaction is not authorized. If transaction amount is below pre-set floor limit, payment information is saved within the terminal. Once internet connection is re-established, the transaction is submitted to the gateway for processing. The general expectation is that most of such transactions will get approved, although, it is possible that some of them will get declined.
</li>
</ul>
A callback URL can be specified in order to allow POS systems to be notified of the outcome of offline transactions. 
<br/>

<br/>
Please contact support to register your desired callback URL. The delivered callback will follow the same <a href="/processing-api/realtime/operations#component_Callback_Response">format</a> as the response message for a sale or sale-auth operation.                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

