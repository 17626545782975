import React from 'react'
import { LuArrowRightCircle } from 'react-icons/lu';
import Navbar from '../../../Components/NavBar/Navbar';
import Footer from '../../../Components/Footer/Footer';
import './Terminology.css'



//import { Redirect} from 'react-router-dom';

export default class Terminology extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bandera: props.bandera,
            contenido: [],
            titulo: "Transxations Terminology",
            tabactivo: 1,
            opcioncontenido: ""
        }
    }


    async componentDidMount() {

       
    }



    cambiarOptionTab(option){
        this.setState({ tabactivo: option})
    }

    cambiarOpcionContenido(option){
        this.setState({ opcioncontenido: option})
    }


 

    render() {
        return (
            <>
                <Navbar />

                <div className='anchoMaximo' style={{ paddingTop: '120px', display: 'block', margin: "auto", width: "95%" }}>
                    <div class="row">
                        <div class="col-xs-12 col-sm-3">
                            <h1 style={{ fontSize: 'x-large' }}><b>{this.state.titulo}</b></h1>
                        </div>
                    </div>

                    <div class="row" data-t="asd2">
        <div class="col-xs-12 col-sm-12">
            <div class="panel panel-default">
                <div class="panel-heading"><h2  style={{ fontSize: '150%' }} >Contents: </h2></div>
                <div class="panel-body">
                    <div class="row">
                                                        <div class="col-xs-3 col-sm-3">
                                    <a  style={{ fontSize: '120%' }} href="management">Management</a>
                                    <hr class="prettyline"/>

                                    <ul class="list-arrow">
                                        <li class="list__item"><LuArrowRightCircle classname='icoflecha' /><a href="/terminology/affiliate"> Affiliate</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/branding_module">Branding Module</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/branding_profile">Branding Profile</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/fee_schema">Fee Schema</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/merchant">Merchant</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/account">Account</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/account_group">Account Group</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/account_profile">Account Profile</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/descriptor">Descriptor</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/merchant_cycle">Merchant Cycle</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/merchant_fee">Merchant Fee </a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/fee_type">Fee Type</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/pricing_model">Pricing Model</a>

<ul type="circle" class="">

</ul>

</li></ul>

</li><li class="num"><a href="/terminology/merchant_profile">Merchant Profile</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/merchant_settings">Merchant Settings</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/merchant_statement">Merchant Statement</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/adjustment">Adjustment</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/scheduled_adjustment">Scheduled Adjustment</a>

<ul type="circle" class="">

</ul>

</li></ul>

</li><li class="num"><a href="/terminology/merchant_tier_structure">Merchant Tier Structure</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/merchant_type">Merchant Type</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/minimum_fees">Minimum Fees</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/onboarding">Onboarding</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/onboarding_application">Onboarding Application</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/estimates">Estimates</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/merchant_verification">Merchant Verification</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/officer">Officer</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/owner">Owner</a>

<ul type="circle" class="">

</ul>

</li></ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/perspective">Perspective</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/administration">Administration</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/audit_perspective">Audit Perspective</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/monitoring_perspective">Monitoring Perspective</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/setup_perspective">Setup perspective</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/system_perspective">System perspective</a>

<ul type="circle" class="">

</ul>

</li></ul>

</li><li class="num"><a href="/terminology/console_perspective">Console perspective</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/management">Management </a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/distributions_perspective">Distributions Perspective</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/fulfillment_perspective">Fulfillment Perspective</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/merchant_perspective">Merchant Perspective</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/merchant_selection">Merchant Selection</a>

<ul type="circle" class="">

</ul>

</li></ul>

</li><li class="num"><a href="/terminology/portfolio_perspective">Portfolio perspective</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/reseller_perspective">Reseller Perspective </a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/terminal_perspective">Terminal Perspective</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/user_perspective">User Perspective </a>

<ul type="circle" class="">

</ul>

</li></ul>

</li><li class="num"><a href="/terminology/reporting_perspective">Reporting perspective</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/reference_field_type">Reference Field Type</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/remittance">Remittance</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/deposit_information">Deposit Information</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/remittance_basis">Remittance Basis</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/remittance_source">Remittance Source</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/remitter">Remitter</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/security_mechanism">Security Mechanism</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/permission">Permission</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/privileges">Privileges</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/security_role">Security Role</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/statement">Statement</a>

<ul type="circle" class="">

</ul></li>                                    </ul>
                                </div>
                                                                <div class="col-xs-3 col-sm-3">
                                    <a style={{ fontSize: '120%' }} href="processing">Processing</a>
                                    <hr class="prettyline"/>

                                    <ul class="list-arrow">
                                        <li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/batch">Batch</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/sub-batch">Sub-batch</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/verification_file">Verification File</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/batch_transaction_cycle">Batch Transaction Cycle</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/chargeback">Chargeback</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/chargeback_case">Chargeback Case</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/convenience_fee">Convenience Fee</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/fee_processor">Fee Processor</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/direct_debit_return">Direct Debit Return</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/unauthorized_return">Unauthorized Return</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/encryption">Encryption</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/pgp_encryption">PGP Encryption</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/folder_code">Folder Code</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/host_emulator">Host Emulator</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/personal_data">Personal Data</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/sensitive_data">Sensitive Data</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/raw_account_data">Raw Account Data</a>

<ul type="circle" class="">

</ul>

</li></ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/portfolio">Portfolio</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/processor-agnostic_architecture">Processor-Agnostic Architecture</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/provider">Provider</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/provider_account">Provider Account</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/provider_profile">Provider Profile</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/real-time_processing">Real-time Processing</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/realtime_transaction_cycle">Realtime Transaction Cycle </a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/reseller">Reseller</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/channel">Channel</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/commission_basis">Commission Basis</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/reseller_statement">Reseller Statement</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/residual">Residual</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/reserves">Reserves</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/reversal">Reversal</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/split_payments_mechanism">Split Payments Mechanism</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/split_rules">split rules</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/split_schema">Split Schema</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/split_pull_transactions">Split/Pull Transactions</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/submitter">Submitter</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/tokenization">Tokenization</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/detokenization">Detokenization</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/untokenization">Untokenization</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/transaction">Transaction</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/transaction_origin">Transaction Origin </a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/transaction_state">Transaction State</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/transaction_type">Transaction Type</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/transaction_processing">Transaction Processing</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/authorization">Authorization</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/blacklist">Blacklist</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/settlement">Settlement</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/processing_cutoff_time">Processing Cutoff Time </a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/settlement_cutoff_time">Settlement Cutoff Time</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/settlement_mode">Settlement Mode</a>

<ul type="circle" class="">

</ul>

</li></ul>

</li><li class="num"><a href="/terminology/transaction_reprocessing">Transaction Reprocessing</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/verification_process">Verification Process</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/wire_transfer">Wire Transfer</a>

<ul type="circle" class="">

</ul></li>                                    </ul>
                                </div>
                                                                <div class="col-xs-3 col-sm-3">
                                    <a style={{ fontSize: '120%' }} href="recurring_billing">Recurring Billing</a>
                                    <hr class="prettyline"/>

                                    <ul class="list-arrow" id='billing_configurations'>
                                        <li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/billing_configurations" >Billing Configurations</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/billing">Billing</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/billing_cycle">Billing Cycle</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/billing_profile">Billing Profile</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/collections">Collections</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/collections_period">Collections Period</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/collections_phases">Collections Phases</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/customer">Customer</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/customer_account">Customer Account</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/customer_balance">Customer Balance</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/customer_transaction">Customer Transaction</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/claim">Claim</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/credit">Credit</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/fee">Fee</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/invoice">Invoice</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/payment">Payment</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/refund">Refund</a>

<ul type="circle" class="">

</ul>

</li></ul>

</li><li class="num"><a href="/terminology/payment_option">Payment Option</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/payment_plan">Payment Plan</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/scheduled_payment">Scheduled Payment</a>

<ul type="circle" class="">

</ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/subscription">Subscription</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/subscription_adjustment">Subscription Adjustment</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/cancel">Cancel</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/effective_date">Effective date</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/freeze">Freeze</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/pause">Pause</a>

<ul type="circle" class="">

</ul>

</li></ul>

</li><li class="num"><a href="/terminology/subscription_statuses">Subscription Statuses</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/subscription_types">Subscription Types</a>

<ul type="circle" class="">

</ul>

</li></ul></li>                                    </ul>
                                </div>
                                                                <div class="col-xs-3 col-sm-3">
                                    <a style={{ fontSize: '120%' }} href="terminals">Terminals</a>
                                    <hr class="prettyline"/>

                                    <ul class="list-arrow">
                                        <li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/fulfillment">Fulfillment</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/embedded_sdk__software_development_kit_">Embedded SDK (Software Development Kit)</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/fulfillment_center">Fulfillment center</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/injection_keys">Injection keys</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/llt__local_loading_tool_">LLT (Local Loading Tool)</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/rba_kia">RBA/KIA</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/signing_toolkit">Signing toolkit</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/telium_2">Telium 2</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/terminal_application">Terminal Application</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/terminal_management_system">Terminal Management System</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/activity_and_diagnostics_logs">Activity and Diagnostics Logs</a>

<ul type="circle" class="">

</ul>

</li></ul></li><li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/terminology/terminal_update">Terminal Update</a>

<ul type="circle" class="">

<li class="num"><a href="/terminology/base_version">Base Version</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/repository">Repository</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/segment">Segment</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/update_profile">Update Profile</a>

<ul type="circle" class="">

</ul>

</li><li class="num"><a href="/terminology/update_version">Update Version</a>

<ul type="circle" class="">

</ul>

</li></ul></li>                                    </ul>
                                </div>
                                                    </div>
                </div>
            </div>
        </div>
    </div>
                   

    <div class="row" data-t="asd2">
        <div class="col-xs-12 col-sm-12">
            <div class="panel panel-default">
                <div class="panel-heading"><h2 style={{ fontSize: '120%' }}>Concepts:</h2></div>
                <div class="panel-body">
                    <ul class="list-arrow">
                                                    <li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/concepts/1">How to use API</a></li>
                                                    <li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/concepts/3">Integration Testing </a></li>
                                                    <li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="/concepts/4">Message-driven Architecture</a></li>
                                            </ul>
                </div>
            </div>
        </div>
    </div>
                  


                </div>

                <Footer />
            </>
        )
    }
}

