import React from 'react'
import Navbar from '../../Components/NavBar/Navbar'
import { LuArrowRightCircle } from 'react-icons/lu';
import './processing-api.css';
import Footer from '../../Components/Footer/Footer';
import { Table } from 'reactstrap';
import ModalIntroduccion from '../../Components/Modals/ModalIntroduccion/ModalIntroduccion';
import { Redirect, useHistory, useLocation } from 'react-router-dom';


export default class Processingapi extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            verintroduccion: false,
            mensajemodal: "",
            titulomodal: "",
            selecicondetalles: '',
            seleccion_hoja: '',


            tabla1: [
                {
                    id: '1',
                    titulo: "Real Time (HTTPs)",
                    contenido: [
                        {
                            titulo: "Introduction",
                            ruta: ""
                        },
                        {
                            titulo: "Supported Operations",
                            ruta: ""
                        },
                        {
                            titulo: "Integration Notes",
                            ruta: ""
                        },
                        {
                            titulo: "Type Enumerations",
                            ruta: ""
                        },
                        {
                            titulo: "Examples",
                            ruta: ""
                        },
                        {
                            titulo: "Test Data",
                            ruta: ""
                        },
                        {
                            titulo: "Change History",
                            ruta: ""
                        },
                    ]
                },
                {
                    id: '2',
                    titulo: "Batch (sFTP)",
                    contenido: [
                        {
                            titulo: "Introduction",
                            ruta: ""
                        },
                        {
                            titulo: "Supported File Formats",
                            ruta: ""
                        },
                        {
                            titulo: "Integration Notes",
                            ruta: ""
                        },
                        {
                            titulo: "Type Enumerations",
                            ruta: ""
                        },
                        {
                            titulo: "Examples",
                            ruta: ""
                        },
                        {
                            titulo: "Test Data",
                            ruta: ""
                        },
                        {
                            titulo: "Change History",
                            ruta: ""
                        },
                    ]
                },
                {
                    id: '3',
                    titulo: "Terminals",
                    contenido: [
                        {
                            titulo: "Introduction",
                            ruta: ""
                        },
                        {
                            titulo: "Supported Operations",
                            ruta: ""
                        },
                        {
                            titulo: "Integration Notes",
                            ruta: ""
                        },
                        {
                            titulo: "Type Enumerations",
                            ruta: ""
                        },
                        {
                            titulo: "Examples",
                            ruta: ""
                        },
                        {
                            titulo: "Test Data",
                            ruta: ""
                        },
                        {
                            titulo: "Change History",
                            ruta: ""
                        },
                    ]
                },
                {
                    id: '3',
                    titulo: "Onboarding",
                    contenido: [
                        {
                            titulo: "Introduction",
                            ruta: ""
                        },
                        {
                            titulo: "Supported Operations",
                            ruta: ""
                        },
                        {
                            titulo: "Integration Notes",
                            ruta: ""
                        },
                        {
                            titulo: "Type Enumerations",
                            ruta: ""
                        },
                        {
                            titulo: "Examples",
                            ruta: ""
                        },
                        {
                            titulo: "Test Data",
                            ruta: ""
                        },
                        {
                            titulo: "Change History",
                            ruta: ""
                        },
                    ]
                },
                {
                    id: '3',
                    titulo: "Reporting",
                    contenido: [
                        {
                            titulo: "Introduction",
                            ruta: ""
                        },
                        {
                            titulo: "Supported Reports",
                            ruta: ""
                        },
                        {
                            titulo: "Integration Notes",
                            ruta: ""
                        },
                        {
                            titulo: "Type Enumerations",
                            ruta: ""
                        },
                        {
                            titulo: "Change History",
                            ruta: ""
                        },
                    ]
                },

            ],
            tabla2: [
                {
                    id: '1',
                    titulo: "TMS",
                    tabla1: [
                        {
                            titulo: "Introduction",
                            ruta: ""
                        },
                        {
                            titulo: "Supported Operations",
                            ruta: ""
                        },
                        {
                            titulo: "Integration Notes",
                            ruta: ""
                        },
                        {
                            titulo: "Type Enumerations",
                            ruta: ""
                        },
                        {
                            titulo: "Examples",
                            ruta: ""
                        },
                        {
                            titulo: "Change History",
                            ruta: ""
                        },
                    ]


                }
            ]

        }
    }



    cerrarModal = (accion) => {
        this.setState({ verintroduccion: !this.state.verintroduccion })
        if (accion == 1) {

        }
    }

    vermodal = async (tabla, ban, pos) => {

        console.log(tabla + " " + ban + " " + pos)
        //operaciones

        let banderaselect = "";
        let mensaje = "";
        let titulo = "";
        let tipo = this.state.tabla1[pos].titulo;

        if (tipo == "Real Time (HTTPs)") {
            banderaselect = "real_time";
            titulo = "Realtime API";

            mensaje = '<div class="panel-body">'
                + ' The purpose of the realtime API is to process one-time credit card payments. This API is used for both card present and card not present transactions that are sent in realtime.'
                + ' <br />'
                + ' <br />'
                + ' <strong>Supported Operations</strong> – lists all supported realtime operations available via API. The purpose of this section is to describe the format of API requests and responses. In addition, the section includes connectivity information explaining how to submit API requests to the gateway.'
                + ' <br />'
                + ' <br />'
                + ' <strong>Integration Notes</strong> – lists integration notes associated with realtime API operations. The purpose of this section is to provide explicit information about the peculiarities of some operations and fields.'
                + ' <br />'
                + ' <br />'
                + ' <strong>Type Enumerations</strong> – lists possible values that can be used in the respective fields within realtime API requests and responses. The purpose of this section is to provide explanation of all enumerations used for API calls.'
                + ' <br />'
                + ' <br />'
                + ' <strong>Examples</strong> – lists examples of every operation in realtime API specification. The purpose of this section is to provide examples that can be used for testing of the integrations with the gateway. Testing is possible in two ways: by submitting a test transaction right from the documentation (only authorization information and submitted amount can be modified) or by copying an example for further use (any modification that corresponds to the gateway requirements is possible).'
                + ' <br />'
                + ' <br />'
                + ' <strong>Test Data</strong> – lists test data that can be used while testing integration with the gateway. The purpose of this section is to provide an integrator with a set of tools for the integration with the gateway.'
                + ' <br />'
                + '</div>'
        }
        else if (tipo == "Batch (sFTP)") {
            banderaselect = "batch";
            titulo = "Batch API";

            mensaje = '<div class="panel-body">'
                + 'The purpose of batch API is to process batch files. This API is used for billing, Account Update, tokenization and subscription files.'
                + '<br>'
                + '<br>'
                + '<strong>Supported Formats</strong> – lists file formats supported by the batch API within the gateway. The purpose of this section is to describe the available file formats for request and response files. In addition, the section explains what every supported file format is needed for and what requirements are of them to work correctly in the system.'
                + '<br/>'
                + '<br/>'
                + '<strong>Integration Notes</strong> – lists integration notes associated with batch API file formats. The purpose of this section is to provide explicit information about the peculiarities of some operations and fields. In addition, this section provides information related to data export mechanism, FTP folder structure for uploading and downloading of request and response, and the naming convention for request files of various types.'
                + '<br/>'
                + '<br/>'
                + '<strong>Type Enumerations</strong> – lists possible values that can be used in the respective fields within batch API request and response files. The purpose of this section is to provide explanation of all enumerations used for file fields.'
                + '<br/>'
                + '<br/>'
                + '<strong>Examples</strong> – lists examples of every file format in batch API specification. The purpose of this section is to provide examples that can be used for testing integrations with the gateway, and for submitting a live request file using file format fields.'
                + '<br/>'
                + '<br/>'
                + '<strong>Test Data</strong> – lists test data that can be used while testing integration with the gateway. The purpose of this section is to provide an integrator with a set of tools for the integration with the gateway. '
                + '<br/>'
                + ' </div>'
        }
        else if (tipo == "Terminals") {
            banderaselect = "terminals";
            titulo = "Terminals API";

            mensaje = '<div class="panel-body">'
                + 'The purpose of the terminals API is to allow POS systems to integrate and manage payment terminals.'
                + '<br/>'

                + '<br/>'
                + '<strong>Supported Operations</strong> – lists all supported operations available via terminal API. The purpose of this section is to describe the format of API requests and responses. In addition, the section includes connectivity information explaining how to submit API requests to the gateway using different types of integrations.'
                + '<br/>'

                + '<br/>'
                + '<strong>Integration Notes</strong> – lists integration notes associated with terminal API operations. The purpose of this section is to provide explicit information about the peculiarities of some operations and fields.'
                + '<br/>'

                + '<br/>'
                + '<strong>Type Enumerations</strong> – lists possible values that can be used in the respective fields within terminal API requests and responses. The purpose of this section is to provide explanation of all enumerations used for API calls.'
                + '<br/>'

                + '<br/>'
                + '<strong>Examples</strong> – lists examples of every operation in terminal API specification. The purpose of this section is to provide the examples that can be used for testing integrations with the gateway. '
                + '<br/>'

                + '<br/>'
                + '<strong>Test Data</strong> – lists test data that can be used while testing integrations with the gateway. The purpose of this section is to provide an integrator with a set of tools for the integration with the gateway. '
                + '<br/>'
                + '</div>'
        }
        else if (tipo == "Onboarding") {
            banderaselect = "onboarding";
            titulo = "Onboarding API";

            mensaje = '<div class="panel-body">'
                + 'The purpose of the Onboarding API is to provide the ability to onboard merchants within the system.'
                + '<br>'

                + '<br>'
                + '<strong>Supported Operations</strong> – lists all supported operations available via onboarding API. The purpose of this section is to describe the format of API requests and responses. In addition, the section includes connectivity information explaining how to submit API requests to the gateway.'
                + '<br>'

                + '<br>'
                + '<strong>Integration Notes</strong> – lists integration notes associated with onboarding API operations. The purpose of this section is to provide explicit information about the peculiarities of some operations and fields.'
                + '<br>'

                + '<br>'
                + '<strong>Type Enumerations</strong> – lists possible values that can be used in the respective fields within onboarding API requests and responses. The purpose of this section is to provide explanation of all enumerations used for API calls.'
                + '<br>'

                + '<br>'
                + '<strong>Examples</strong> – lists examples of every operation in onboarding API specification. The purpose of this section is to provide the examples that can be used for testing integrations with the gateway. '
                + '<br>'
                + '</div>'
        }
        else if (tipo == "Reporting") {
            banderaselect = "reporting";
            titulo = "Reporting API";

            mensaje = '<div class="panel-body">'
                + 'The purpose of the Reporting API is to provide the ability to retrieve reports, statement and receipts generated by the gateway.'
                + '<br/>'

                + '<br/>'
                + '<strong>Supported Reports</strong> – lists all supported reports available via reporting API. The purpose of this section is to describe the format of API requests and responses. In addition, the section includes connectivity information explaining how to submit API requests to the gateway.'
                + '<br/>'

                + '<br/>'
                + '<strong>Integration Notes</strong> – lists integration notes associated with reporting API. The purpose of this section is to provide explicit information about the peculiarities of some operations and fields.'
                + '<br/>'

                + '<br/>'
                + '<strong>Type Enumerations</strong> – lists possible values that can be used in the respective fields within reporting API requests and responses. The purpose of this section is to provide explanation of all enumerations used for API calls.'
                + '<br/>'

                + '<br/>'
                + '<strong>Examples</strong> – lists examples of every operation in reporting API specification. The purpose of this section is to provide the examples that can be used for testing integrations with the gateway.'
                + '<br/>'
                + '</div>'
        }










        if (tabla == 2 && ban == 1) {
            titulo = "TMS API";
            banderaselect = "tmsapis";
            mensaje = '<div class="panel-body">'
                + 'The purpose of the TMS API is to provide the ability for terminal applications to configure terminals and deploy updates remotely. '
                + '<br>'

                + '<br>'
                + '<strong>Supported Operations</strong> – lists all supported operations available via TMS API. The purpose of this section is to describe the format of API requests and responses. In addition, the section includes connectivity information explaining how to submit API requests to the gateway.'
                + '<br>'

                + '<br>'
                + '<strong>Integration Notes</strong> – lists integration notes associated with TMS API operations. The purpose of this section is to provide explicit information about peculiarities of some operations or fields work. In addition, this section includes the list of TMS parameters used for both API and the user interface of the gateway, as well as information related to the supported brands of terminals.'
                + '<br>'

                + '<br>'
                + '<strong>Type Enumerations</strong> – lists possible values that can be used in the respective fields within TMS API requests and responses. The purpose of this section is to provide explanation of all enumerations used for API calls.'
                + '<br>'

                + '<br>'
                + '<strong>Examples</strong> – lists examples of every operation in TMS API specification. The purpose of this section is to provide the examples that can be used for testing integrations with the gateway. '
                + '<br>'
                + '</div>'
        }


        console.log(tabla + " " + ban + " " + titulo)

        this.setState({ selecicondetalles: banderaselect })
        this.setState({ titulomodal: titulo })
        this.setState({ mensajemodal: mensaje })


        if (tabla == 1 && ban == 2) {
            this.setState({ selecicondetalles: banderaselect })
            this.setState({ seleccion_hoja: "operaciones" })
            this.setState({ salir: true })
            return
        }
        if (tabla == 2 && ban == 2) {
            this.setState({ selecicondetalles: banderaselect })
            this.setState({ selecicon_hoja: "operacionesbatch" })
            this.setState({ salir: true })
            return
        }

        if (tabla == 1 && ban == 3) {
            this.setState({ selecicondetalles: banderaselect })
            this.setState({ seleccion_hoja: "notes" })

            this.setState({ salir: true })
            return
        }

        if (tabla == 1 && ban == 4) {
            this.setState({ selecicondetalles: banderaselect })
            this.setState({ seleccion_hoja: "enums" })

            this.setState({ salir: true })
            return
        }else {
            this.setState({ verintroduccion: !this.state.verintroduccion })
        }


    }


    render() {
        return (
            <>
                <Navbar />


                {this.state.salir ?
                    <>
                        <Acciones hoja={this.state.seleccion_hoja} bandera={this.state.selecicondetalles} />
                    </>
                    : null}


                {(this.state.verintroduccion == true) ? (
                    <ModalIntroduccion tipo="full" show={this.state.verintroduccion}
                        titulomodal={this.state.titulomodal} mensajemodal={this.state.mensajemodal}
                        cerrarModal={this.cerrarModal} />
                ) : null}





                <div className='anchoMaximo' style={{ paddingTop: '120px', display: 'block', margin: "auto", width: "95%" }}>
                    <div className="row" >
                        <div className="col-xs-12 col-sm-12">
                            <div className="row justify-content-center " >
                                <div className="col-12">
                                    <h1 className="sectiontitle">Processing APIs</h1>
                                </div>

                                {/*  <div className='col align-items-end'>

                                    <div class="btn-group">
                                        <button type="button" class="btn btn-secondary dropdown-toggle btnapiss" data-bs-toggle="dropdown"
                                            data-bs-display="static" aria-expanded="false">Arias
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-lg-start">
                                            <li><button clcmdass="dropdown-item" type="button">Entire website</button></li>
                                            <li><button class="dropdown-item" type="button">This section only</button></li>

                                        </ul>
                                    </div>

                                </div>*/}
                            </div>


                        </div>


                        <div className="col-xs-12 col-sm-12">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    This page is your primary source for information about Processing API.
                                    Here you can find latest integration documents as well as certification scenarios necessary for integration with the platform.
                                    <div className="prettyline" ></div>
                                    <b>Please follow the navigation panels below to access the information you are interested in.</b>
                                </div></div>
                        </div>

                    </div>


                    <div class="panel panel-default">
                        <div class="panel-heading" style={{ textAlign: "left !important" }}>
                            <h2 class="panel-title"><img src="/images/usage-integration.png" />Integration Information</h2>
                        </div>

                        <div class="panel-body">
                            <div class="row  justify-content-center align-items-center">
                                <Table style={{ tableLayout: "fixed", width: "97%", border: "none" }}>
                                    <colgroup>
                                        <col style={{ width: "20%" }}></col>
                                        <col style={{ width: "20%" }}></col>
                                        <col style={{ width: "20%" }}></col>
                                        <col style={{ width: "20%" }}></col>
                                        <col style={{ width: "20%" }}></col>
                                    </colgroup>

                                    <tbody>
                                        <tr>

                                            {this.state.tabla1.map((item, index1) => (
                                                <td key={index1}>
                                                    <div class="price price--light price--standard">
                                                        <header class="price__header">
                                                            <p class="price__package">
                                                                <span style={{ fontSize: "smaller" }}>{item.titulo}</span>
                                                            </p>
                                                        </header>
                                                        <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "15px", paddingBottom: "10px" }}>


                                                            {item.contenido.map((item, index) => (


                                                                <h3 key={index} className='lblencabezados_small' onClick={this.vermodal.bind(this, "1", (index + 1), index1)}><a ><LuArrowRightCircle className='icoflecha' /><a >{item.titulo}</a></a></h3>

                                                            ))}

                                                        </div>
                                                    </div>
                                                </td>
                                            ))}



                                        </tr>


                                        <tr>

                                            {this.state.tabla2.map((item, index) => (
                                                <td>
                                                    <div class="price price--light price--standard">
                                                        <header class="price__header">
                                                            <p class="price__package">
                                                                <span style={{ fontSize: "smaller" }}>{item.titulo}</span>
                                                            </p>
                                                        </header>
                                                        <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "15px", paddingBottom: "10px" }}>
                                                            <h3 className='lblencabezados_small' onClick={this.vermodal.bind(this, "2", (index + 1), index)} ><a ><LuArrowRightCircle className='icoflecha' /><a>Introduction</a></a></h3>
                                                            <h3 className='lblencabezados_small'><a ><LuArrowRightCircle className='icoflecha' /><a >Supported Operations</a></a></h3>
                                                            <h3 className='lblencabezados_small'><a ><LuArrowRightCircle className='icoflecha' /><a >Integration Notes</a></a></h3>
                                                            <h3 className='lblencabezados_small'><a ><LuArrowRightCircle className='icoflecha' /><a >Type Enumerations</a></a></h3>
                                                            <h3 className='lblencabezados_small'><a ><LuArrowRightCircle className='icoflecha' /><a >Examples</a></a></h3>
                                                            <h3 className='lblencabezados_small'><a ><LuArrowRightCircle className='icoflecha' /><a >Test Data</a></a></h3>
                                                            <h3 className='lblencabezados_small'><a ><LuArrowRightCircle className='icoflecha' /><a >Change History</a></a></h3>
                                                        </div>
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>


                                    </tbody>
                                </Table>

                            </div>
                        </div>


                    </div>
                </div>

                <Footer />

            </>
        )
    }
}


function Acciones({ hoja, bandera }) {


    const history = useHistory();
    const location = useLocation();
    let params = "?ban=" + bandera;
    console.log(hoja)
    console.log(params)

    if(bandera == "real_time"){
        if (hoja == 'operaciones') {
            history.push(location.pathname);
            return (<Redirect from={'/processing-api'} to={"/realtime/operations" + params} />)
        }
        else if (hoja == 'notes') {
            history.push(location.pathname);
            return (<Redirect from={'/processing-api'} to={"/realtime/notes" + params} />)
        }

    }else if(bandera == "batch"){
        if (hoja == 'operaciones') {
            history.push(location.pathname);
            return (<Redirect from={'/processing-api'} to={"/batch/operations" + params} />)
        }
    }else if(bandera == "terminals"){

    }else if(bandera == "onboarding"){

    }else if(bandera == "reporting"){

    }else if(bandera == "tms"){

    }

    if (hoja == 'enums') {
        history.push(location.pathname);
        return (<Redirect from={'/processing-api'} to={"/enums" + params} />)
    }
/*
    if (hoja == 'operaciones') {
        history.push(location.pathname);
        return (<Redirect from={'/processing-api'} to={"/realtime/operations" + params} />)
    }
    else if (hoja == 'operacionesbatch') {
        history.push(location.pathname);
        return (<Redirect from={'/processing-api'} to={"/operations2" + params} />)
    }
    else if (hoja == 'notes') {
        history.push(location.pathname);
        return (<Redirect from={'/processing-api'} to={"/notes" + params} />)
    }
    else if (hoja == 'enums') {
        history.push(location.pathname);
        return (<Redirect from={'/processing-api'} to={"/enums" + params} />)
    }
*/


}
