import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINHolderNameNormalizationRules extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Holder Name Normalization Rules</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                Currently, the gateway saves the <em>holderName</em> field value as two separate values: firstName and lastName. If a holder’s name, which is submitted to the gateway by a user or within track data/EMV data, contains more than two words (for example, a middle name is included), the following rules are used to split holderName into firstName and lastName:
                                <ul type="circle" style={{marginLeft: "10px", color: "#5688c9"}}>
                                <li>when the additional word is an initial and contains only 1 character, it gets removed automatically. In this scenario, only the first and last names are shown within the <em>holderName</em> field in the response. For example, if <em>JOHN/J SMITH</em> is submitted as the holderName field value within the request, <em>JOHN SMITH</em> is returned within the holderName field value in the response;
                                </li>
                                <li>when the additional word is a middle name and contains 2 or more characters, the system defines it as a part of the first name. In this scenario, the first and the last name get reversed, and the middle name is returned as the first name within the <em>holderName</em> field in the response. For example, if <em>SMITH/JAMES JOHN</em> is submitted as the holderName field value within the request, <em>JAMES JOHN SMITH</em> is returned within the holderName field value in the response.</li>
                                </ul>                
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

