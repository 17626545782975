import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalHealthcareIndustryTransactions extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Healthcare Industry Transactions</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                When processing a healthcare industry transaction (using HSA account <a href="http://en.wikipedia.org/wiki/Health_savings_account">What is it?</a>) additional amount fields need to be specified. 

<br/>
The only amount that is required is healthcareAmount, while other amounts are optional. If medical transportation was involved, then transitAmount should be added. 
<br/>

<br/>
Depending on the type of service rendered, additional amounts can be specified to healthcareAmount to indicate how much was spent on prescription (prescriptionAmount), vision/optical (visionAmount), dental (dentalAmount) and clinic/hospitalization (clinicAmount) services. The total of prescription, vision, dental and clinic amounts can be less than or equal to healthcareAmount.<br/>                </div>
                                
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

