import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINBatchEncryption extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Batch Encryption</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                For customers that do not use a built-in tokenization functionality and would like to process files containing raw card data (either for processing or for account update), an additional layer of encryption - PGP - can be used to remain compliant with PCI regulations. In order to use PGP encryption, a public key must be requested from the integration support. Any PGP encryption software can be used.
                                <br/>

                                <br/>
                                For security reasons, PGP keys should be rotated regularly - every two years. Two keys are used during the key rotation process - current key and newly generated key. Key rotation procedure goes as follows:
                                <br/>
                                <ol style={{marginLeft: "25px", color: "inherit !important"}}>
                                <li>One month prior to the required rotation time, new private key gets generated by the integration team. A respective public key is derived from the private key and distributed to a client with the request to replace the current public key with the new one. 
                                </li>
                                <li>Both keys, current and new one, can be used during 30-day rotation period. Current key is the primary key, and a new key is the secondary one.
                                </li>
                                <li>At the end of the rotation period, secondary key replaces the current key, thereby becoming the primary key and completing the rotation. The old primary key cannot be used from this moment.</li>
                                </ol>                
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

