import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalExtendedAccountType extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Extended Account Type</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "15%"}}></colgroup>
                                        <colgroup style={{width: "85%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>Value</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                            <td width="100px">VC</td>
                            <td>Visa credit card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">VD</td>
                            <td>Visa debit card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">MC</td>
                            <td>MasterCard credit card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">MD</td>
                            <td>MasterCard debit card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">DC</td>
                            <td>Discover credit card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">DD</td>
                            <td>Discover debit card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">AC</td>
                            <td>AmEx credit card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">NC</td>
                            <td>Diners credit card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">BD</td>
                            <td>Bank debit Card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">BC</td>
                            <td>Checking account<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">BS</td>
                            <td>Savings account<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">VP</td>
                            <td>Visa prepaid card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">VS</td>
                            <td>Visa fleet card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">MP</td>
                            <td>MasterCard prepaid card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">MF</td>
                            <td>MasterCard fleet card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">EF</td>
                            <td>EBT food stamp<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">EC</td>
                            <td>EBT cash benefit<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">GG</td>
                            <td>General gift card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">FL</td>
                            <td>Fleet One card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">GF</td>
                            <td>Fuelman or FleetWide card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">VF</td>
                            <td>Voyager fleet card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">WX</td>
                            <td>WEX fleet card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">CA</td>
                            <td>Cash (used for sale-info, credit-info API calls)<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">CK</td>
                            <td>Check (used for sale-info, credit-info API calls)<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">VE</td>
                            <td>Visa debit checking account<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">VV</td>
                            <td>Visa debit saving account<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">UC</td>
                            <td>UnionPay credit card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">UD</td>
                            <td>UnionPay debit card<br/></td>
                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

