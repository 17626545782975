import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalAccountType extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Account Type</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "15%"}}></colgroup>
                                        <colgroup style={{width: "85%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                            <td width="100px">R</td>
                            <td>Branded credit card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">E</td>
                            <td>Branded debit checking card. This type is used for Canadian debit cards (Interac) only.<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">V</td>
                            <td>Branded debit savings card.  This type is used for Canadian debit cards (Interac) only.<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">D</td>
                            <td>Unbranded debit checking card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">I</td>
                            <td>Unbranded debit savings card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">S</td>
                            <td>Bank saving account<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">C</td>
                            <td>Bank checking account <br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">F</td>
                            <td>EBT food stamp<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">H</td>
                            <td>EBT cash benefit<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">G</td>
                            <td>Gift card<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">L</td>
                            <td>Fleet<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">A</td>
                            <td>Cash (used for sale-info, credit-info API calls)<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">K</td>
                            <td>Check (used for sale-info, credit-info API calls)<br/></td>
                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

