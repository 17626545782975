import logo from './logo.svg';
import './App.css';
import './page/Welcome/Welcome.css';



import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import 'bootstrap/dist/css/bootstrap.min.css';
import Welcome from './page/Welcome/Welcome';
import Processingapi from './page/Processing-api/processing-api';

import TestRanges from './page/Processing-api/Realtime/Operations/TestRanges';

import Notes from './page/Notes/Notes';
import TypeEnumerations from './page/TypeEnumerations/TypeEnumerations';

//Componentes  Realtime
import Operations from './page/Processing-api/Realtime/Operations/Operations';
import RealtimeNotes from './page/Processing-api/Realtime/Notes/Notes';

//Componentes  Batch
import SuportFileFormat from './page/Processing-api/Batch/suportFileFormat';

//Componentes Billing APIS
import Billing from './page/Billing-api/Billing';
import BalancingSummary from './page/Billing-api/Objects/BalancingSummary';
import SQLBALANCINGSUMMARY from './page/Billing-api/Objects/SQLBALANCINGSUMMARY';
import CustomerAccount from './page/Billing-api/Objects/CustomerAccount';


//Componentes de Manuals - Terminology
import Terminology from './page/Manuals/Terminology/Terminology';


export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});


function App() {

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>

      <Switch>
    
        <Route path="/processing-api" component={Processingapi} /> 
        <Route path="/processingapi/test_ranges" component={TestRanges} /> 
        
        <Route path="/realtime/operations" component={Operations} /> 
        <Route path="/realtime/notes" component={RealtimeNotes} /> 

        <Route path="/batch/operations" component={SuportFileFormat} /> 

        <Route path="/notes" component={Notes} /> 
        <Route path="/enums" component={TypeEnumerations} /> 

        <Route path="/billing-api" component={Billing} /> 
        <Route path="/objects/1647" component={BalancingSummary} /> 
        <Route path="/code/table/1647" component={SQLBALANCINGSUMMARY} /> 
        <Route path="/objects/1647" component={BalancingSummary} /> 

        <Route path="/terminology" component={Terminology} />

        <Route path="/" component={Welcome} />

      </Switch>
    </BrowserRouter>
  );

}

export default App;