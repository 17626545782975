import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINRSequenceNumber extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Sequence Number</h2>

                        <div class="panel panel-default">
                        <div class="panel-body">
                        The <em>sequenceNumber</em> field can be used either in the request or in the response, although the designation of the field differs in the following cases:
                        <ul type="circle" style={{marginLeft: "10px", color: "#5688c9"}}>
                        <li>If submitted within the request file, it is used to indicate a sequential number of an installment payment. In this case, the field is used in conjunction with the<em> sequenceCount</em> field, which provides information about a total number of installment payments expected for this particular cardholder. 
                        </li>
                        <li>If retrieved within the response file, it is used to indicate a sequential number of a received chargeback or reversal. For the first chargeback/reversal, the value of the <em>sequenceNumber</em> field equals to 1, and this value is going to be incremented each subsequent round. The value can be used to avoid misinterpretation of a new chargeback/reversal as a duplicate of a previously processed chargeback/reversal. </li>
                        </ul>                
                        </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

