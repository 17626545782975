import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalSplitItemSubrecord extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Split Item Subrecord</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "5%"}}></colgroup>
                                        <colgroup style={{width: "10%"}}></colgroup>
                                        <colgroup style={{width: "10%"}}></colgroup>
                                        <colgroup style={{width: "20%"}}></colgroup>
                                        <colgroup style={{width: "5%"}}></colgroup>
                                        <colgroup style={{width: "50%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>№</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Possible values / Format</th>
                                                <th>Usage</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                                                <tr>
                                <td>1</td>
                                <td>code</td>
                                                                    <td>String(12)</td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="SR">SR</span></td>
                                <td>Unique identifier of an item within a submitter's system or inventory catalog.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>2</td>
                                <td>totalAmount</td>
                                                                    <td>Integer</td>
                                                                <td>amount in cents</td>
                                <td><span class="popup-usage" type="usage" page="components" data="SR">SR</span></td>
                                <td>Total amount of an item that includes discount and tax.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>3</td>
                                <td>quantity</td>
                                                                    <td>Float</td>
                                                                <td>up to 4 decimal places</td>
                                <td><span class="popup-usage" type="usage" page="components" data="O">O</span></td>
                                <td>Number of item units purchased/refunded as a part of the transaction.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>4</td>
                                <td>description</td>
                                                                    <td>String(35)</td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="O">O</span></td>
                                <td>Description of an item.&nbsp;                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>5</td>
                                <td>splits</td>
                                                                    <td>
                                        <span data="409" type="list" class="popup-link">List</span>
                                    </td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="C">C</span></td>
                                <td>List of split payment scenarios included in the transaction. See <span class="note-doc" type="note" data="Subrecord_Format">integration notes</span> for more information.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>6</td>
                                <td>splitSchemaId</td>
                                                                    <td>
                                        <span data-mfp-src="#test-popup" data="entityreference" type="entityreference" class="popup-link">Reference</span>
                                    </td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="C">C</span></td>
                                <td>Reference to a split schema associated with the transaction. See <span class="note-doc" type="note" data="Split_Payments">integration notes</span> for more information.                                                                    </td>
                            </tr>
                                                </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

