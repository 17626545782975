import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalDirectDebit extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Direct Debit</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                Direct debit is the process of funds transfer from one bank account to another. Currently, the following direct debit systems are supported within the system: ACH for US and Canada, BACS for UK, SEPA for EU and Direct Entry for Australia and New Zealand. For direct debit transactions, two fields are required: bank identification number, submitted as <em>accountAccessory</em>, and bank account number, submitted as <em>accountNumber</em>. To format these fields correctly, please use guidelines below depending on the country. 

<br/>
<h2>US ACH</h2>
For ACH transaction processing in the US, the routing number is used as accountAccessory and its format is a nine-digit number. For example, <em>325070760</em>. See <a href="https://en.wikipedia.org/wiki/Routing_transit_number#Routing_number_format">this</a> link for more information. 

<br/>
<h2>Canada ACH</h2>
For ACH (EFT) transaction processing in Canada, the routing number is submitted as a nine-digit number including three required fields: three-digit institution number, five-digit branch number, and account number. For processing purposes of Canadian ACH, the institution number and branch number are combined in a single routing number value using the rules below.  
<br/>
<ul type="circle"  >
<li>The Canadian routing number is comprised of three parts: a leading zero (0), three-digit institution number (YYY) and five-digit branch number (XXXXX), which go respectively all together with no dashes: 0YYYXXXXX. Please, note that the institution number (YYY) should never be all zeros (000). See <a href="http://en.wikipedia.org/wiki/Routing_transit_number#Canadian_transit_number">this</a> link for complete list of institution numbers.
</li>
<li>The XXXXX-YYY format with a dash between the branch number and the institution number is only valid for paper-type transactions, such as checks. Thus, if a check reads XXXXX-YYY, the corresponding EFT code will be 0YYYXXXXX.
</li>
</ul>

<br/>
<h2>EU SEPA</h2>
For SEPA transaction processing in the European Union, bank identification number is submitted in <em>BIC</em> format and bank account number is submitted in <em>IBAN</em> format. 
<ul type="circle" >
<li>Bank identification number consists of 8-11 characters and is comprised of four parts: four-letter institution code or bank code, two-letter country code, two-letter or two-digit location code and three-letter or three-digit branch code. Last three characters are optional. See <a href="https://en.wikipedia.org/wiki/ISO_9362#Examples">this</a> link to have better understanding of BIC format.
</li>
<li>Account number consists of up to 34 alphanumeric characters and is comprised of three parts: two-letter country code, two check digits and a long and detailed bank account number. See <a href="https://en.wikipedia.org/wiki/International_Bank_Account_Number#Structure">this</a> link to have better understanding of IBAN format.
</li>
</ul>

<br/>
<h2>UK BACS</h2>
For BACS transaction processing in the United Kingdom, format of these fields is the following:
<br/>
<ul type="circle" >
<li>Bank identification number is formatted as <em>sort code</em>, which is a six-digit number, formatted as three pairs of numbers. For example, <em>12-34-56</em>. See <a href="https://en.wikipedia.org/wiki/Sort_code#List_of_sort_codes_of_the_United_Kingdom">this</a> link for complete list of sort codes. 
</li>
<li>Account number consists of 8-10 digits.
</li>
</ul>

<br/>
<h2>Australia/New Zealand Direct Entry</h2>
For Direct Entry transaction processing in Australia, format of these fields is the following:
<br/>
<ul type="circle" >
<li>Bank identification number is submitted in <em>BSB</em> format. It consists of 6 digits and is comprised of two parts: two-digit bank code and four-digit branch number. For example, <em>033088</em>. See <a href="https://en.wikipedia.org/wiki/Bank_State_Branch#List_of_Australian_Bank_codes">this</a> link for complete list of Australian bank codes and <a href="https://en.wikipedia.org/wiki/New_Zealand_bank_account_prefix#Account_number_prefix">this</a> link for complete list of New Zealand bank codes.
</li>
<li>Account number consists of up to 17 characters.</li>
</ul>                </div>       
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

