
import React from 'react'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Navbar.css';
import { BsSearch } from 'react-icons/bs';
import { IoMdArrowDropdown } from 'react-icons/io';

const Navbar = () => {







    return (




        <nav className="navbar navbar-expand-lg navbar-light navbarflex" >
            <div className="container-fluid navbarflex anchoMaximo">



                <div class="logo pull-left ">
                    <a className="navbar-brand" href="/">

                        <img class="client-image logo-img betlogo" src="/images/logo.png" alt="gatewayaid logo" />

                    </a>
                </div>


                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>



                <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-end">

                        <li class="nav-item">
                            <div class="dropdown">
                                <button class="dropbtn"><a style={{ textTransform: "none", textTransform: 'none', color: "black" }} href="/">Home</a></button>
                            </div>
                        </li>


                        <li className="nav-item dropdown space">

                            <div class="dropdown">
                                <button class="dropbtn">APIs</button>
                                <div class="dropdown-content">
                                    <a className='albltitles' href="/processing-api">Processing</a>
                                    <a className='albltitles' href="/billing-api">Billing</a>
                                    <a className='albltitles' href="/management-api">Management</a>
                                    <a className='albltitles' href="/webhooks-api">Webhooks</a>
                                </div>
                            </div>
                        </li>

                        <li className="nav-item dropdown space">
                            <div style={{width:"60px"}}></div>
                        </li>
                        {/**

                        <li className="nav-item dropdown space">

                            <div class="dropdown">
                                <button class="dropbtn">Manuals</button>
                                <div class="dropdown-content">
                                    <a className='albltitles' href="/terminology">Terminology</a>
                                    <a className='albltitles' href="/guides">User Guides</a>
                                    <a className='albltitles' href="/ui">User Interface Reference</a>
                                    <a className='albltitles' href="/tutorials">How-To Tutorials</a>
                                    <a className='albltitles' href="/lessons">Lessons</a>
                                </div>





                            </div>

                        </li>

                        <li className="nav-item dropdown space">

                            <div class="dropdown">
                                <button class="dropbtn">Data Dictionaries</button>
                                <div class="dropdown-content">
                                    <a className='albltitles' href="/apps/UniCore">UniCore</a>
                                    <a className='albltitles' href="/apps/UniCharge">UniCharge</a>
                                    <a className='albltitles' href="/apps/UniBill">UniBill</a>
                                </div>
                            </div>

                        </li>

                        <li className="nav-item dropdown space">

                            <div class="dropdown">
                                <button class="dropbtn">Build</button>
                                <div class="dropdown-content">
                                    <a className='albltitles' href="/properties">Properties Definition</a>
                                    <a className='albltitles' href="/resources">Resources (Directory Structure)</a>
                                    <a className='albltitles' href="/countries">Zip and Country Codes</a>
                                </div>
                            </div>

                        </li>


                        <li className="nav-item dropdown space">

                            <div class="dropdown">
                                <button class="dropbtn">Development</button>
                                <div class="dropdown-content">
                                    <a href="/log-files">Log Files</a>
                                    <a href="/permissions">Permissions</a>
                                    <a href="/jndi-names">JNDI Names</a>
                                    <a href="/job-queues">Job Queues</a>
                                </div>
                            </div>

                        </li>


                        <li className="nav-item dropdown space">

                            <div class="dropdown">
                                <button class="dropbtn">Reference Materials</button>
                                <div class="dropdown-content">
                                    <a className='albltitles' href="/providers">Supported Providers (Banks &amp; Networks)</a>
                                    <a className='albltitles' href="/codes">transxation Codes &amp; Code Mappings</a>
                                    <a className='albltitles' href="/libraries">Integrated 3rd Party Libraries</a>
                                    <a className='albltitles' href="/documents">Diagrams and Documents</a>
                                </div>
                            </div>

                        </li>

                      
                        */}


                    </ul>




                </div>
                {/**
                <form class="form-inline my-2 my-lg-0">

                    <div style={{ display: 'flex' }} >

                        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />




                        <div class="btn-group">
                            <button type="button" class="btn btn-secondary dropdown-toggle btncircle" data-bs-toggle="dropdown"
                                data-bs-display="static" aria-expanded="false"><BsSearch className='icocircle' />
                            </button>
                            <ul class="dropdown-menu dropdown-menu-lg-end">
                                <li><button class="dropdown-item" type="button">Entire website</button></li>
                                <li><button class="dropdown-item" type="button">This section only</button></li>

                            </ul>
                        </div>


                    </div>

                </form>
                */}
            </div>
        </nav>

    )
}

export default Navbar