import React from 'react'
import { LuArrowRightCircle } from 'react-icons/lu';
import Navbar from '../../Components/NavBar/Navbar';
import { getDatos, getDatosNotes, getDatosTypeEnum } from '../../Services/DatosLocalees';
import './TypeEnumerations.css'
import parse from 'html-react-parser';
import Footer from '../../Components/Footer/Footer';


export default class TypeEnumerations extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bandera: props.bandera,
            contenido: [],
            titulo: ""

        }
    }


    async componentDidMount() {

        const query = new URLSearchParams(window.location.search);    //para funcion
        const query_loc = query.get('ban');
        console.log(query_loc)
        var resp = [];

        resp = await getDatosTypeEnum(query_loc);
        console.log(resp)



        this.setState({ bandera: query_loc })
        this.setState({ titulo: resp.tituloge })
        this.setState({ contenido: resp.datos })
    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }

    render() {
        return (
            <>
                <Navbar />

                <div className='anchoMaximo' style={{ paddingTop: '120px', display: 'block', margin: "auto", width: "95%" }}>

                    <div class="row">
                        <div class="col-xs-12 col-sm-6">
                            <h1  style={{ fontSize: 'x-large' }}><b>{this.pasartexto(this.state.titulo)}</b></h1>
                        </div>



                        {this.state.contenido.map((item, index) => (
                            <div key={index}>
                                <div class="row">
                                    <div class="col-12">
                                        <h2 className='lbltitulosection'>{item.titulo}</h2>

                                        <div class="row">

                                            {item.data.map((data, index) => (
                                                <div class="col-4">
                                                    <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                                        <h3 className='lblencabezados'><a  ><LuArrowRightCircle className='icoflecha' /><a href={"#"+data.nombre.replaceAll(" ", "_")}>{data.nombre}</a></a></h3>

                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>

                                </div>

                                <div className="prettyline" ></div>

                            </div>

                        ))}
                            
                        {this.state.contenido.map((item, index) => (
                            item.tablas.length>0 ?
                           item.tablas.map((tabla, index) => (
                            <section id={tabla.titulo.replaceAll(" ", "_")}>
                                
                                <h2>{tabla.titulo}</h2>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12">
                                        <div class="table-responsive">
                                            <table class="table my-table newtable newtablenorowspan pull-left">
                                                <tbody>
                                                    {
                                                        tabla.datos.map((element, index) => (
                                                            <tr>
                                                                <td width="20%">{element.tipo}</td>
                                                                <td width="80%">{element.descripcion}<br/></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            
                            )):null

                        ))}

                        
                    </div>



                </div>

                <Footer />
            </>
        )
    }
}

