import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalTerminalDataEntry extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Terminal Data Entry</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                To define which payment option is used and how payment information is submitted through a terminal, the <em>entryModeType</em> and <em>entryMediumType</em> fields are used. For more information about the available values of these fields, review <a href="/processing-api/realtime/enums#enum_Entry_Medium_Type">entryMediumType</a> and <a href="/processing-api/realtime/enums#enum_Entry_Mode_Type">entryModeType</a> enumerations. 

<br/>
All available combinations of the <em>entryModeType</em> and <em>entryMediumType</em> field values are shown in the table below.

<br/>
<div class="table-responsive"><table class="newtable newtablenorowspan pull-left" ><tbody><tr>
<th  style={{fontWeight: "bold"}}>
</th>
<th style={{fontWeight: "bold"}}>Manual
</th>
<th style={{fontWeight: "bold"}}>Swipe/Scan
</th>
<th style={{fontWeight: "bold"}}>Chip
</th>
<th style={{fontWeight: "bold"}}>Contactless
</th>
</tr><tr>
<td>Not Present
</td>
<td>x
</td>
<td>
</td>
<td>
</td>
<td>
</td>
</tr><tr>
<td>Magnetic Card
</td>
<td>x
</td>
<td>x
</td>
<td>
</td>
<td>x
</td>
</tr><tr>
<td>Chip Card
</td>
<td>x
</td>
<td>x
</td>
<td>x
</td>
<td>x
</td>
</tr><tr>
<td>Check
</td>
<td>x
</td>
<td>x
</td>
<td>
</td>
<td>
</td>
</tr></tbody></table>                </div>
            </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

