import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalTransactionLevels extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Transaction Levels</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                While most transactions require standardized set of fields (such as card number, expiration date, etc), there are also types of transactions, that require higher level of detailing. Additional detail level is coming by context of the transaction and is designed to simplify credit card processing in the specific situations. Aside from the standard transaction level, the following ones are available:
<br/>
<ul type="circle" >
<li><a href="/processing-api/realtime/operations#component_Sale_Request_field_group_Level_II_Transaction_Information">Level II</a> - transactions with commercial (corporate) payment cards used by corporates (API fields associated with taxes are required to be submitted);
</li>
<li><a href="/processing-api/realtime/operations#component_Sale_Request_field_group_Level_III_Transaction_Information">Level III</a> - transactions with government or corporate payment cards used by corporations and government (detailed information of every item associated with a transaction is required to be submitted).
</li>
</ul>

<br/>
Level II transactions are used to simplify business-to-business (B2B) payment processing with lower interchange rates applied. The system will process transactions as level II, if the following fields are included in the API call:
<br/>
<ul type="circle" >
<li>orderCode
</li>
<li>shippingAmount
</li>
<li>dutyAmount
</li>
<li>taxAmount
</li>
<li>taxRate
</li>
<li>taxIndicator
</li>
<li>customerAccountTaxId
</li>
</ul>
 
<br/>
Level III transactions are used to simplify payment processing for business-to-business-heavy industry and government cards with lower interchange rates applied. The system will process transactions as level III, if the following fields are included in the API call:
<br/>
<ul type="circle" >
<li>shippingOriginZipCode
</li>
<li>discountAmount
</li>
<li>taxInvoiceCode
</li>
<li>localTaxAmount
</li>
<li>localTaxIndicator
</li>
<li>nationalTaxAmount
</li>
<li>nationalTaxIndicator
</li>
<li>orderDate
</li>
<li>commodityCode
</li>
<li>items
</li>
<li>itemCount
</li>
</ul>

<br/>
Level I and II transactions can be run through a standard terminal or POS software. Level III transactions require special software to be installed for transferring the additional information required to qualify the transaction.<br/>                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

