import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINNoticesofChange extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Notices of Change (NOCs)</h2>

                        <div class="panel panel-default">
                        <div class="panel-body">
                            When ACH transactions in the US and Canada are processed, the realtime approval information is not available like it is for credit cards. For this purpose many companies will verify accounts against blacklists and, if an account is not blacklisted, return an approval response by default. Subsequently, when any types of returns come back from the bank, they are forwarded over to the transaction's submitter/merchant.
                            <br/>

                            <br/>
                            Under some circumstances (for instance, bank mergers), a bank may need to update information about account such as bank account number, routing number or name on the account. It is done through 'notice of change' mechanism, which is similar to the mechanism used to deliver ACH returns, and it's important not to confuse the two. While ACH return implies that a payment was not honored, notice of change implies a payment was processed successfully, but some information (which is provided within the NOC) should be updated in any future payment requests for this account.
                            <br/>

                            <br/>
                            Within the response file transaction type for ACH return is 'N' and transaction type for NOCs is 'U'.<br/>                
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

