import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';

export default class ModalProcessingModeType extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Processing Mode Type</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "15%"}}></colgroup>
                                        <colgroup style={{width: "85%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>Value</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                            <td width="100px">online</td>
                            <td>Indicates that the transaction is processed online<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">offline</td>
                            <td>Indicates that the transaction is processed offline, i.e. it is pre-processed (in case of swiped transactions) or it has been approved by the chip (in case of EMV transactions)<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">request</td>
                            <td>Indicates that the transaction is executed by the terminal<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">queue</td>
                            <td>Indicates that the transaction is queued by the terminal for further processing<br/></td>
                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

