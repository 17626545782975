import React from 'react'
import { LuArrowRightCircle } from 'react-icons/lu';
import './IntegrationNotes.css'


import ModalMessageFormats from '../../../Components/Modals/Billing-api/Objects/ModalMessageFormats';
import ModalMessageFormatsMode from '../../../Components/Modals/Billing-api/Objects/ModalMessageFormatsMode';


export default class IntegrationNotes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showmodal: "",

        }
    }


    async componentDidMount() {

      
    }
    seleccionarModal(titulomodal){
        this.setState({ showmodal: titulomodal})
    }
 
    
     cerrarModal = (accion) => {
        this.setState({ showmodal: "" })
    
    }


    render() {
        return (
            <>
                {
                    /** Modales Usage */
                    (this.state.showmodal == "Message Formats") ? 
                    (<ModalMessageFormats tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
                    : (this.state.showmodal == "Message Formats Mode") ? 
                    (<ModalMessageFormatsMode tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 

                    : null
                
              
                }



                <div className=''>
                    <div className="row pt-3">
                        <div className="col-12">
                        <div id="object-container">

        
        
        
<div class="row">
<div class="col-xs-12 col-sm-12">
<h3>Integration Notes</h3>
</div>
</div>
<div class="row">
<div class="col-xs-12 col-sm-12">
                                                                    <a name="connectivity_information-note"></a>
    <div class="panel panel-default">
        <div class="panel-heading" id=''>
            <b>
                Connectivity Information                                </b> &nbsp;&nbsp;
                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="#connectivity_information-note" onclick="return true;" data-original-title="Direct link">
                    <i class="fa fa-share-square-o"></i>
                </a>
        </div>
        <div class="panel-body">
                                            The connection URL for the sandbox server is: <em>https://[server-name]/api/v01/</em>.
Authorization is done via service users. To access the API, a service user is required to be granted with a Management API privilege. A user can submit API requests using either associated credentials or a temporary password generated via the authentication operation. See Security Management guide for more information.
<br/>

<br/>
Request method can be either GET or POST depending on the action you are submitting. The content-type must be set to <em>application/x-www-form-urlencoded</em>.
Request fields are passed within the HTTPS request body and are required to be URL-encoded.
<br/>
Response fields are passed within the HTTPS response body.
<br/>
Both request and response field values are passed using <em>name1=key1&amp;name2=key2</em> format.

<br/>
When submitting an API request, you can use either <strong>gateway server URL</strong> or the <strong>Sanitizing Data Filter</strong> (Unibroker) server URL as an endpoint. 
<br/>
Requests that contain tokenized card numbers/bank account numbers can be submitted directly to <strong>Transxactions</strong> server. 
<br/>
To reduce PCI exposure, requests with non-tokenized (raw) account data should be passed through the sanitizing data filter server. Otherwise, L04 error will be returned (Processing of raw account data is not allowed through the specified API end-point for the current user).
<br/>
If you don’t have a specific reason, we recommend sending all the requests to the same endpoint (data filter).
<br/>

<br/>
In Billing module, <strong>the following API requests can be submitted only to the sanitizing data filter server URL</strong>:
<br/>
<ul type="circle" style={{marginLeft:"10px"}} >
<li>[version]/customers/[customer-id]/transactions/~process
</li>
<li>[version]/customers/[id]/payment-options/[payment-option-id]/~process
</li>
<li>[version]/customers/[customer-id]/payment-options/~create
</li>
</ul>

<br/>

        </div>
    </div>
                                                <a name="how_to_use_billing_api-note"></a>
    <div class="panel panel-default">
        <div class="panel-heading">
            <b>
                How to use Billing API                                </b> &nbsp;&nbsp;
                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="#how_to_use_billing_api-note" onclick="return true;" data-original-title="Direct link">
                    <i class="fa fa-share-square-o"></i>
                </a>
        </div>
        <div class="panel-body">
                                            <bold>UniBill</bold> is one of the Transxactions modules providing the opportunity to set up recurring billing to withdraw payments from customers on a regular basis and keep track of their pricing plans, subscriptions and associated payments. Both user interface and RESTful API is used to communicate with the application within this module.
<br/>

<br/>
Before you start work with the API make sure that you familiarize yourself with the basic concepts of recurring billing.
<br/>

<br/>
There are goods and services that customers purchase on a regular basis. For example, gym membership, subscriptions to magazines or mobile apps, etc. Customers can pay for these types of goods/services via recurring billing. In the process of recurring billing,  a merchant automatically charges a <strong>customer</strong> for the specific goods/services on a prearranged schedule, or a  <strong>payment plan</strong>. To use a payment plan, the customer has to sign up to it by giving the consent to pay a certain amount on a regular basis. When the customer agrees to make payments with or without the underlying payment plan, a <strong>subscription</strong> is created. If there is a need for the customer to change the existing subscription (for example, cancel gym membership for a certain period time), the <strong>subscription adjustment</strong> is applied. To pay for the subscription, the customer can use any <strong>payment option</strong> (cash, card, check, etc).
<br/>

<br/>
In order to fully utilize the billing API functionality, you have to create a set of the objects for a merchant in the following sequence: 
<br/>

<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/components/251">Customer</a> - a merchant's customer who subscribes to the merchant’s goods/services and pays for them via the gateway, through an external system (for example, a terminal that is used separately from the gateway), or via cash/check. 
</li>
</ul>
Supported actions: 
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/billing-api/actions/v01/customers#actcreate">create</a> - creates a customer.
</li>
<li><a href="/billing-api/actions/v01/customers#actfind">find</a> - returns a list of available customers based on specified search criteria: first name, last name, organization name. 
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]#actload">load</a> - returns configuration settings for the selected customer. 
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]#actmodify">modify</a> - updates the selected customer with the specified values.
</li>
</ul>

<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/components/252">Plan</a> - pricing plan, which determines how often and how much a customer has to pay. 
</li>
</ul>
Supported actions:  
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/billing-api/actions/v01/plans#actcreate">create</a> - creates a pricing plan.
</li>
<li><a href="/billing-api/actions/v01/plans#actfind">find</a> - returns a list of available pricing plans based on specified search criteria: customer account number, create date, amount, billing cycle type.
</li>
<li><a href="/billing-api/actions/v01/plans/[plan-id]#actload">load</a> -  returns configuration settings for the selected pricing plan.
</li>
<li><a href="/billing-api/actions/v01/plans/[plan-id]#actmodify">modify</a> - updates the selected pricing plan with the specified values.
</li>
</ul>

<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/components/256">Subscription</a> - customer’s subscription to a specific pricing plan. 
</li>
</ul>
Supported actions:
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions#actcreate">create</a> - creates a subscription. 
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions#actfind">find</a> - returns a list of available subscriptions based on specified criteria: identifier of a plan associated with the subscription, create date, subscription status, next billing date.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actload">load</a> -  returns settings of the subscription specified in the request.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actmodify">modify</a> - Updates the subscription with the specified values.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actcancel">cancel</a> - cancels the subscription right away or on the specified date.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actfreeze">freeze</a> - freezes the subscription for the specified period.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actpause">pause</a> -  pauses the subscription right away or on the specified date.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actuncancel">uncancel</a> - uncancels the specified subscription if it has not been canceled yet. Applicable only to pending cancellation subscriptions (the ones that have an effective date in future).  
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actunfreeze">unfreeze</a> -  unfreezes the specified subscription if it has been previously frozen.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actunpause">unpause</a> - unpauses the specified subscription if it has been previously paused.
</li>
</ul>

<br/>
See  integration notes for more information about the <a href="/billing-api/notes/subscription_types_and_statuses#subscription_types_and_statuses-note">subscription object</a> and about <a href="/billing-api/notes/subscription_management#subscription_management-note">available subscription-related actions</a>. 

<br/>
Subscription is associated with the following object:
<br/>

<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/components/257">Subscription adjustment</a> - adjustments made to a particular subscription (for example, subscription termination, cancellation, etc). 
</li>
</ul>
Supported actions:
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}> 
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]/adjustments#actfind">find</a> - returns a list of available adjustments associated with the specified subscription. </li></ul>


<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/components/250">Payment Option</a> - customer’s payment card or bank account encrypted and stored within the system. 
</li>
</ul>
Supported actions: 
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/payment-options#actcreate">create</a> - creates a payment option.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/payment-options#actfind">find</a> - returns a list of available payment options associated with the specified customer.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/payment-options/[payment-option-id]#actload">load</a> - returns configuration settings for the specified payment option.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/payment-options/[payment-option-id]#actmodify">modify</a> - updates the selected payment option with the specified values.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/payment-options/[payment-option-id]#actprocess">process</a> - creates and processes a transaction based on the specified payment option.
</li>
</ul>

<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/components/254">Transaction</a> - customer’s transactions used to pay for a subscription. 
</li>
</ul>
Supported actions:
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/transactions#actcreate">create</a> - creates a transaction associated with the specified customer.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/transactions#actfind">find</a> - returns a list of the transactions associated with the specified customer.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/transactions#actprocess">process</a> - creates and processes a transaction associated with the specified customer.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/transactions#actload">load</a> - returns data associated with the specified transaction.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/transactions#actreverse">reverse</a> - reverses/voids the specified transaction.
</li>
</ul>

<br/>
See <a href="/billing-api/notes/transaction-related_actions#transaction-related_actions-note">integration notes</a> for more information. 

<br/>
Transaction is associated with the following objects: 
<br/>

<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/components/255">ProcessingRequest</a> - contains transaction request data to process a transaction via the gateway.
</li>
<li><a href="/components/253">ProcessingInfo</a> - contains transaction response data, i.e. detailed information about the transaction processed by the gateway (for example, responseCode, responseMessage, etc). Included in the Transaction object.
</li>
</ul>

<br/>
The relationship between all of the billing API objects is shown in the diagram below. 
<br/>
   <img src="/images/Assets/billing_api.jpg" alt="BillingApi"/>                        </div>
    </div>
                                                <a name="processing_priority_-note"></a>
    <div class="panel panel-default">
        <div class="panel-heading">
            <b>
                Processing Priority                                 </b> &nbsp;&nbsp;
                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="#processing_priority_-note" onclick="return true;" data-original-title="Direct link">
                    <i class="fa fa-share-square-o"></i>
                </a>
        </div>
        <div class="panel-body">
                                            When submitting a payment/refund transaction, the information can be passed via one of the following fields:
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li>accountData - track/EMV data of a payment card. See <span class="note-doc" type="note" data="Account_Data">integration notes</span> for more information.
</li>
<li>accountNumber - number of a payment card or bank account.
</li>
<li>token - token associated with a payment card or bank account. It can be used instead of a payment card or bank account number in subsequent transaction processing. Note: This field can be submitted only if tokenization service is enabled.
</li>
</ul>

<br/>
Any of these fields can be included in the request. If more than one field value is submitted within a transaction, the processing priority will be the following:
<br/>
1) accountData (accountNumber and token are ignored);
<br/>
2) accountNumber (token is ignored);
<br/>
3) token.
<br/>

        </div>
    </div>
                                                <a name="reference_entity-note"></a>
    <div class="panel panel-default">
        <div class="panel-heading">
            <b>
                Reference Entity                                </b> &nbsp;&nbsp;
                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="#reference_entity-note" onclick="return true;" data-original-title="Direct link">
                    <i class="fa fa-share-square-o"></i>
                </a>
        </div>
        <div class="panel-body">
                                            While working with the API, various entities within the system, such as merchants, resellers, etc, need to be referenced. API fields that correspond to the references for these entities are of the <em>Reference type</em> (for example, customerId, planId, etc). Each of these entities has two identifiers:
— Internal identifier - numeric value, provided by the gateway; unique within the system.
<br/>
— External identifier - alphanumeric value up to 60 characters, provided by an integrated external system for cross-referencing; preferably unique within the scope that makes sense to an integrator.
<br/>

<br/>
When an API call with reference to an entity has to be submitted, this entity can be referenced by either an internal or external identifier. By default, the system expects an internal identifier to be submitted (e.g. customerId=1234). To let the system know that an external identifier is being submitted, its value must be preceded by an asterisk (e.g. customerId=*1234). This is applicable to all fields of <em>Reference</em> type.

<br/>
For example, a customer has an internal ID, generated by the gateway (e.g.5678), and an external ID, provided by a submitter (e.g. cust_8765). If an internal ID should be used in the API calls, it is submitted via the customerId field as it is: customerId=5678. If an external ID should be used in the API calls, it is submitted with an asterisk as a prefix: customerId=*cust_8765.
<br/>

<br/>
The following entities can be submitted using either an internal or external identifier:
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li>customer;
</li>
<li>payment option;
</li>
<li>plan;
</li>
<li>subscription;
</li>
<li>transaction. 
</li>
</ul>
        </div>
    </div>
                                                <a name="subscription_management-note"></a>
    <div class="panel panel-default">
        <div class="panel-heading">
            <b>
                Subscription Management                                </b> &nbsp;&nbsp;
                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="#subscription_management-note" onclick="return true;" data-original-title="Direct link">
                    <i class="fa fa-share-square-o"></i>
                </a>
        </div>
        <div class="panel-body">
                                            Pre-set prices for the services that a merchant provides to customers are represented in the API as the <a href="/components/252">Plan</a> object. To create a plan, use the <a href="/billing-api/actions/v01/plans#actcreate">create action</a>.

<br/>
To use a service, a customer can be subscribed to a previously created plan, though the payment plan is not required for the creation of the subscription. 
<br/>

<br/>
To <strong>create a subscription</strong>, use the <a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions#actcreate">create action</a>, indicating the ID of the selected plan in the request. Note that a customer can only be subscribed to one plan.
<br/>
After a subscription is created, the customer can <strong>terminate or cancel the subscription</strong>. 
<br/>
For this purpose, you can use one of the following actions:
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actpause"><strong>pause</strong></a> - pauses a subscription in cases when a customer wants to suspend the subscription for an unspecified period of time. A subscription can be paused immediately or on the next billing date.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actfreeze"><strong>freeze</strong></a> - freezes a subscription in cases when a customer wants to terminate the subscription for a specified period (from 1 to 6 periods). Can be applied only to the subscriptions with billingCycleType=weekly or monthly. The subscription can be frozen only on one of the next billing dates. After the predefined number of freeze charges has passed, the subscription is reactivated automatically.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actcancel"><strong>cancel</strong></a> - cancels a subscription in cases when a customer wants to refuse the services or change the plan they subscribed to. The subscription can be canceled immediately or on the next billing date.
</li>
</ul>

<br/>
In cases when a customer wants to re-activate the subscription, you can reverse the actions described above. To reverse a previously applied action, the following actions are used:
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actunpause"><strong>unpause</strong></a> - reverses the pause action;
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actunfreeze"><strong>unfreeze</strong></a> - reverses the freeze action. Note that there may be a situation when you set a freeze for a billing date in the future, and then you want to prolong the freeze. In this case, you have to unfreeze the subscription that is set for freeze in the future and re-set the freeze for a desirable number of months. On the other hand, if the subscription has already been frozen (i.e. billing date has already come), you have to set an additional freeze for a desirable number of months without unfreezing the previous one.
</li>
<li><a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]#actuncancel"><strong>uncancel</strong></a> - reverses the cancel action. Can be applied only if cancellation date is a date in future. If cancellation date is set as today or a previous date, a subscription cannot be uncancelled.
</li>
</ul>

<br/>
After reversal, a state of a subscription depends on whether an effective date passed:                                                                                                          
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li>if the effective date of the pause/freeze/cancel has not come, a planned action is cancelled, and the state of the subscription stays the same, i.e. its status is indicated as Current both before and after the action is undone;
</li>
<li>if the effective date of the pause/freeze/cancel has already come, a current action is cancelled, and the subscription is reactivated, i.e. its status is indicated as 
</li>
</ul>
<em>Paused/Frozen/Cancelled</em> before the action is undone, and <em>Current</em> after the action is undone.

<br/>
To <strong>view all actions that have been applied to a certain subscription</strong>, use the <a href="/billing-api/actions/v01/customers/[customer-id]/subscriptions/[subscription-id]/adjustments#actfind">find action</a>, indicating an ID for the subscription in the request.
        </div>
    </div>
                                                <a name="subscription_types_and_statuses-note"></a>
    <div class="panel panel-default">
        <div class="panel-heading">
            <b>
                Subscription Types and Statuses                                </b> &nbsp;&nbsp;
                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="#subscription_types_and_statuses-note" onclick="return true;" data-original-title="Direct link">
                    <i class="fa fa-share-square-o"></i>
                </a>
        </div>
        <div class="panel-body">
                                            The following subscription types can be created within the system:
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><strong>Fixed subscription</strong> – indicates that the subscription will continue for a set number of periods. For example, $10 per month for 12 months.
</li>
<li><strong>Perpetual subscription</strong> – indicates that the subscription will continue with a recurring amount until the user explicitly cancels it. For example, $10 per month until cancelled.
</li>
</ul>

<br/>
When a subscription is created, it can obtain one of the following statuses:
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><strong>Unbilled</strong> - indicates that no billing has occurred since the subscription was created.
</li>
<li><strong>Deferred</strong> - indicates that no invoice will be generated in the upcoming billing associated with the subscription, because the first billing date is set for the following month. For example, today is November 15, and we create a monthly subscription with the first billing date set as December 20. In this case, on November 20 the subscription will obtain a deferred status, because this date is expected by the system to be the first billing date.
</li>
<li><strong>Current</strong> - indicates that the subscription is active.
</li>
<li><strong>Paused</strong> - indicates that the subscription is set on hold by a customer. See <a href="/billing-api/notes/subscription_types_and_statuses#subscription_management-note">integration notes</a> for more information.
</li>
<li><strong>Freeze</strong> - indicates that the subscription is terminated. See <a href="/billing-api/notes/subscription_types_and_statuses#subscription_management-note">integration notes</a> for more information.
</li>
<li><strong>Cancelled</strong> - indicates that the subscription is canceled. See <a href="/billing-api/notes/subscription_types_and_statuses#subscription_management-note">integration notes</a> for more information..
</li>
<li><strong>Expired</strong> - indicates that all payments associated with a subscription have been made. Note that only a fixed subscription can obtain this status.</li>
</ul>                            </div>
    </div>
                                                <a name="transaction_types-note"></a>
    <div class="panel panel-default">
        <div class="panel-heading">
            <b>
                Transaction Types                                </b> &nbsp;&nbsp;
                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="#transaction_types-note" onclick="return true;" data-original-title="Direct link">
                    <i class="fa fa-share-square-o"></i>
                </a>
        </div>
        <div class="panel-body">
                                            Recurring billing is also used to keep track of the payments and outstanding charges of a customer according to their payment plan. The transaction types described below are available to align the customer’s balance.
<br/>

<br/>
Since all these transactions are initiated and controlled by a merchant, they belong to a group of merchant-initiated transactions. However, in the process of recurring billing you may also receive transactions from a processor. This type of transactions is considered as processor/bank initiated. 
<br/>

<br/>
All transactions are divided into <strong>merchant-initiated</strong> (i.e. that are submitted by a merchant) and <strong>bank/processor-initiated</strong> (i.e. that are retrieved from a processor). 
<br/>

<br/>
The following merchant-initiated transactions are available within the billing API: 
<br/>

<br/>
To track the amounts that are expected to be withdrawn from a customer:
<br/>
<strong>Invoice</strong> - represents an amount that a customer owes to a merchant. 
<br/>
<strong>Fee</strong> - represents the amount of a fee collected from a customer under certain conditions. The fee can be charged to a customer only if this condition is explicitly stated in the agreement between the customer and the merchant. 
<br/>

<br/>
To record the funds withdrawn from a customer and deposited to the merchant’s account:
<br/>
<strong>Payment</strong> - represents a real-time transaction used to cover a customer’s debt to a merchant. The amount of the debt equals the amount of a previously generated invoice.
<br/>
<strong>Claim</strong> – represents a batch transaction used to cover a customer’s debt to a merchant. The amount of the debt equals the amount of a previously generated invoice.
<br/>

<br/>
To record funds withdrawn from the merchant’s bank account and transferred to the customer in case of an error, or when specific business scenario is applied:
<br/>
<strong>Credit</strong> - represents an amount that a merchant owes to a customer. For example, a customer cancels their subscription. To cover an amount of a previously generated invoice, a credit transaction is used. After the credit is issued, the customer’s balance is set to zero.
<br/>
<strong>Refund</strong> - represents amount that a merchant credits to a customer. Can only be performed after settlement. 
<br/>
<strong>Void</strong> - represents a transaction that has been reverted. Can only be performed before settlement. No further action can be taken for a voided transaction. 
<br/>

<br/>
The following bank/processor-initiated transactions are available within the billing API: 
<br/>
<strong>Decline</strong> - represents a payment or refund that got declined.
<br/>
<strong>Returns</strong> - represents a direct debit payment that has not been honored by a bank. 
<br/>
<strong>Chargeback</strong> - represents a reversal of a prior outbound transfer of funds from a customer's payment card to a merchant. 
<br/>
<strong>Chargeback Reversal</strong> - represents a reversal of a payment card chargeback, i.e. money is given back to the merchant or customer. 
<br/>

<br/>
For merchant-initiated transactions you can apply the following actions. See <a href="/billing-api/notes/transaction-related_actions#transaction-related_actions-note">integration notes</a> for more information.

<br/>
<div style={{display: "inline-block"}} ><br/>
<div class="table-responsive"><table class="newtable pull-left"><tbody><tr>
<th style={{fontWeight: "bold"}}>
</th>
<th style={{fontWeight: "bold"}}>create
</th>
<th style={{fontWeight: "bold"}}>process
</th>
<th style={{fontWeight: "bold"}}>reverse
</th>
</tr><tr>
<td>Invoice 
</td>
<td>+
</td>
<td>
</td>
<td>+
</td>
</tr><tr>
<td>Credit 
</td>
<td>+
</td>
<td>
</td>
<td>+
</td>
</tr><tr>
<td>Fee  
</td>
<td>+
</td>
<td>
</td>
<td>+
</td>
</tr><tr>
<td>Payment   
</td>
<td>+
</td>
<td>+
</td>
<td>+
</td>
</tr><tr>
<td>Claim    
</td>
<td>
</td>
<td>
</td>
<td>+
</td>
</tr><tr>
<td>Refund     
</td>
<td>+
</td>
<td>+
</td>
<td>+
</td>
</tr><tr>
<td>Void     
</td>
<td>
</td>
<td>
</td>
<td>
</td>
</tr></tbody></table>
</div>
<div style={{display: "inline-block"}}>
        </div>
    </div>
                                                <a name="transaction-related_actions-note"></a>
    <div class="panel panel-default">
        <div class="panel-heading">
            <b>
                Transaction-related Actions                                </b> &nbsp;&nbsp;
                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="#transaction-related_actions-note" onclick="return true;" data-original-title="Direct link">
                    <i class="fa fa-share-square-o"></i>
                </a>
        </div>
        <div class="panel-body">
                                            There may be cases when some payments are processed outside of the gateway (i.e. paid via cash/check). However, the system needs to keep track of either transactions that were processed through the system (processed transactions) or those that were processed outside of the system (recorded transactions) to align the customer’s balance. For this reason, the recorded transactions need to be posted against customer’s account to keep balance of the customer. Thus, you can use the billing API for both processing of transactions and keeping record of them. 
<br/>

<br/>
<strong>How to process a transaction</strong>
<br/>
To submit a transaction for processing, the following actions are used:
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li><a href="/management-api/v01/customers/[customer-id]/transactions#actprocess">transactions~process action</a>- submits a transaction to the gateway using data within the ProcessingRequest object. See <a href="/billing-api/notes/processing_priority_#processing_priority_-note">integration notes</a> for more information about payment data processing priority.
</li>
<li><a href="/management-api/v01/customers/[customer-id]/payment-options/[payment-option-id]#actprocess">payment-options/[payment-option-id</a>~process] - submits a transaction to the gateway using data within the ProcessingRequest object and a previously created payment option, which ID is indicated in the request. See <a href="/billing-api/notes/processing_priority_#processing_priority_-note">integration notes</a> for more information about payment data processing priority.
</li>
</ul>

<br/>
In both cases, the transaction response returned by the system contain the following data: 
<br/>
<ul type="circle" style={{ marginLeft:"10px"}}>
<li>Transaction object with the payment or refund type that contains transaction data;
</li>
<li>ProcessingInfo object that contains data associated with the processed transaction. 
</li>
</ul>
These actions can be used to process only payment and refund transactions. See <span class="note-doc" type="note" data="Transaction_Types">integration notes</span> for more information about transaction types.

<br/>
<strong>How to create a transaction and store it within the gateway without processing</strong> 
<br/>
To add transaction record to the gateway without its processing, <a href="/management-api/v01/customers/[customer-id]/transactions#actcreate/">transactions~create create action</a> is used. It creates a transaction using data submitted within the Transaction object and saves the record within the gateway. 

<br/>
This action can be used to create an invoice, credit, fee, payment, refund transactions. See <span class="note-doc" type="note" data="Transaction_Types">integration notes</span> for more information about transaction types.

<br/>

<br/>
<strong>How to cancel processed and stored transactions</strong>
<br/>
In case you need to cancel a transaction that has been either created or processed, use the  <a href="/management-api/v01/customers/[customer-id]/transactions/[transaction-id]#actreverse">reverse action</a>. After the action is applied, the indicate transaction is reversed. Note that the transaction cannot be returned to its initial state after reversal. A list of the transaction types that can be reversed, as well as their final states, are listed below. 

<br/>

<br/>
<div style={{display: "inline-block"}}>
<div class="table-responsive"><table class="newtable newtablenorowspan pull-left"><tbody><tr>
<th style={{fontWeight: "bold"}}>Transaction Type
</th>
<th style={{fontWeight: "bold"}}>Reversal Result
</th>
</tr><tr>
<td>Invoice
</td>
<td>Invoice Reversal
</td>
</tr><tr>
<td>Credit
</td>
<td>Credit Reversal
</td>
</tr><tr>
<td>Customer Fee
</td>
<td>Fee Reversal
</td>
</tr><tr>
<td>Payment
</td>
<td>Void or Refund
</td>
</tr><tr>
<td>Claim
</td>
<td>Refund
</td>
</tr><tr>
<td>Refund
</td>
<td>Refund Reversal
</td>
</tr></tbody></table></div><br/>
<div style={{display: "inline-block"}}>

<br/>

        </div>
    </div>
                                                <a name="billing_api_response-note"></a>
    <div class="panel panel-default">
        <div class="panel-heading">
            <b>
                Billing API response                                </b> &nbsp;&nbsp;
                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="#billing_api_response-note" onclick="return true;" data-original-title="Direct link">
                    <i class="fa fa-share-square-o"></i>
                </a>
        </div>
        <div class="panel-body">
                                            API response consists of the <strong>header</strong> (HTTP status code) and the <strong>body</strong>.
<br/>

<br/>
<em>500 Internal Server Error</em> is returned in the response header if the server encountered an unexpected issue that prevented it from processing and fulfilling the request that you have submitted. In this case, the response is not generated and, thus, the response body is empty. 

<br/>
If the request has been fulfilled on the server, <em>200 OK</em> is returned in the header, and the API response is generated and returned in the body. The response body may contain an API object - if the request has been processed successfully or a Transxactions Exception (response code and error message) - if an application error occurred while processing your request. For example, when processing a sale transaction, 200 OK status code is returned in the header both in the case of approval and decline.                             </div>
    </div>
                                                <a name="testing-note"></a>
    <div class="panel panel-default">
        <div class="panel-heading">
            <b>
                Testing                                </b> &nbsp;&nbsp;
                <a class="mytooltip cursor_copy" title="" data-toggle="tooltip" data-placement="right" href="#testing-note" onclick="return true;" data-original-title="Direct link">
                    <i class="fa fa-share-square-o"></i>
                </a>
        </div>
        <div class="panel-body">
                                            <div style={{display: "inline-block"}} >
<div class="table-responsive"><table class="newtable pull-left"><tbody><tr>
<th style={{fontWeight: "bold"}}>Integration Type 
</th>
<th style={{fontWeight: "bold"}}>Profile Type 
</th>
</tr><tr>
<td>direct debit processing
</td>
<td>ach/proxy
</td>
</tr><tr>
<td>cards batch processing
</td>
<td>cards-batch/proxy
</td>
</tr><tr>
<td>cards realtime processing
</td>
<td>cards-realtime/proxy
</td>
</tr><tr>
<td>tokenization
</td>
<td>tokenization/proxy
</td>
</tr></tbody></table></div><br/>
<div style={{display: "inline-block"}} >                            </div>
    </div>
                </div>
</div>


</div>

</div></div></div></div></div></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

