import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalHostedPaymentPageHPP extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Concepts</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Hosted Payment Page (HPP)</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                <h2>General Information</h2> 
General overview of the Hosted Payment Page concept can be found <a href="https://unipaygateway.info/guide_content?id=26#Hosted_Payment_Pages">here</a>.

<br/>
According to PCI requirements, it is forbidden to store any card data. If non-tokenized card data is stored within the system or any sensitive data goes through the software system, the company goes under PCI scope and subsequent PCI audit. To avoid having cardholder's data uploaded to the software system, Hosted Payment Page mechanism can be used. When HPP is used, all sensitive data is collected through the page and subsequently converted into a token that can be safely used by the software system for processing. Since the page is hosted by gateway service provider, the software application that uses an HPP remains out of scope. 
<br/>

<br/>
 provides a simple and secure mechanism for capturing cardholder’s information using a secure HPP (web page to capture account holder’s information, including credit or debit card numbers). Using HPP, the submitter’s application doesn’t touch sensitive account holder's data, and thus remains outside of the PCI scope. The common fields that HPP captures are card number, expiration date, CSC code and optionally address information. HPP seamlessly integrates into the Real-Time HTTPs API and accommodates all of the parameters supported by it.
<br/>

<br/>
<h2>HPP-related fields</h2> 
HPP mechanism makes use of the following parameters that can be provided as part of the sale, sale-auth, credit or tokenization API requests.
<br/>
<ul type="circle" >
<li>notifyURL – the presence of this parameter triggers the appearance of HPP. If definite URL is specified within the notifyURL field, the result of the transaction is delivered to this URL. For example, the notifyURL page with the transaction result may look as shown on the <span class="screenshot" type="screenshot" data="/images/note_realtime-API_HPP_1.png">screenshot</span>.
</li>
<li>postNotifyURL – URL, which is used in conjunction with notifyURL. The result of the transaction is delivered to this URL after the callback with the same information is successfully delivered to notifyURL. The format of the callback to this URL is the same as the one used for notifyURL. You can use this parameter if you need to receive a callback within two different systems. 
</li>
<li>cancelURL – URL to which a user gets redirected if he or she decides to terminate transaction without completing.
</li>
<li>returnURL – URL to which a user gets redirected after a transaction is successfully processed. 
</li>
<li>returnURLPolicy – defines how returnURL is used when a transaction is completed.
</li>
</ul>
If returnURLPolicy is set as <em>page</em>, a confirmation page is rendered with specified returnURL. If returnURLPolicy is set as <em>redirect</em>, a user is redirected to the specified returnURL bypassing confirmation page. If returnURLPolicy is set as <em>redirect-approval</em>, a user is redirected to the specified returnURL only in case of transaction approval. In case of decline, a user is forwarded to the result page (HPP session closed, no further action allowed).
<ul type="circle" >
<li>styleURL – when dynamic styles are needed, URL of a CSS stylesheet that should be dynamically included in the HPP.
</li>
<li>providerData – used to transfer data from the provider that has been received on HPP. It is required to process transactions via PayPal. 
</li>
<li>theme – indicates which color theme will be used.
</li>
<li>pageFormat – defines what stages are shown on the hosted payment page.
</li>
<li>isEmbedded – indicates whether header and footer of the hosted payment page are shown within the page.
</li>
</ul>

<br/>
There are situations when a notify URL fails to reach a merchant system. In such cases, it does not try to reach it again. The information about a failed attempt can be retrieved within the system logs only. However, an information about failed transactions can still be retrieved traditionally via the user interface or a transaction export report. 
<br/>

<br/>
Additionally, the HPP mechanism allows a user to control the source of notifyURL parameter. It can be pulled from the value specified either within an API request or on the user interface. On the UI, there are two URLs that are stored within the merchant profile in the system and can be used for callback purposes. If notifyURL value should be taken from the API request, it is submitted as it is. If the value of notifyURL should be taken from one of URLs specified in the processing settings of the merchant, the following values preceded by tilde (~) should be submitted: <em>offline-url</em> – to submit URL used for offline transactions, or <em>chargeback-url</em> – to submit URL used for returns or chargebacks receiving, e.g.: notifyURL=~offline-url or notifyURL=~chargeback-url.  

<br/>
When cancelURL is used, the HPP is going to have the cancellation option available (cancel transaction button). By default, if a user clicks on the cancel button, he or she will be redirected to the URL, specified within the cancelURL field. Additionally, if the keyword <em>page</em> is specified as cancelURL field value, then if the customer clicks on the cancel button, he or she will be redirected to the confirmation page (results.html), which will indicate that the transaction was cancelled.

<br/>

<br/>
<h2>HPP Rendering</h2> 
There are two ways in which an HPP can be rendered:
<br/>
1) By generating a payment page via an API call. The most typical way, in which a transaction is processed, is as follows:
<br/>
<ul type="circle" >
<li>a user initiates a sale, sale-auth, credit or tokenization transaction;
</li>
<li>a software platform submits an API request specifying the value of the <em>notifyURL</em> field to the gateway. This field triggers rendering of a hosted payment page. Additionally, <em>returnURL</em> and <em>cancelURL</em> can be submitted along with the<em> notifyURL</em> field;
</li>
<li>the gateway renders a payment page and returns it to the software platform, which forwards it to the user;
</li>
<li>a user enters secure information and clicks "Submit" button. The transaction gets processed and the result is posted to the URL specified in the <em>notifyURL</em> field;
</li>
<li>a confirmation page with “Continue” button is rendered. When clicking on this button, the user gets redirected to the URL specified in the <em>returnURL</em> field;
</li>
<li>if during the process the cardholder clicks "Cancel" button, further transaction processing gets canceled and the user is redirected to the URL specified in the <em>cancelURL</em> field.
</li>
</ul>
To learn how an HPP is rendered for this scenario, review this <span class="screenshot" type="screenshot" data="/images/HPP_Rendering_via_an_API_call.png">diagram</span>.

<br/>
2) By generating a payment page via a URL. It is used when a payment page has to be rendered solely by the gateway bypassing a software platform:
<br/>
<ul type="circle" >
<li>a user initiates a sale, sale-auth, credit or tokenization transaction;
</li>
<li>a software platform submits an API request specifying the values of the <em>notifyURL</em> and <em>processingMode</em> fields to the gateway. The <em>processingMode</em> has to be set as <em>queue</em>; 
</li>
<li>the gateway generates an API response containing a particular <em>requestId</em> and returns it to the software platform; 
</li>
<li>after receiving a request ID, the software platform forms the URL and passes it to a user. The URL is formed as follows:<em> https://[server-name]/gates/redirects/r671684f1-5c73-4c3d-96ea-0bee3190497a</em>, where <em>r671684f1-5c73-4c3d-96ea-0bee3190497a</em> is a <em>requestId</em> that was returned within the API response; 
</li>
<li>the user receives the URL that is available for 10 minutes and follows it to access the hosted payment page;
</li>
<li>after the HPP has been rendered, the user finishes the transaction following the steps described above.
</li>
</ul>

<br/>
The time during which the page is available to the user can be extended in two cases:
<br/>
1) If the authenticate API request is sent with the <em>requestId</em> received in the previous steps, the time during which the page is available can be extended by 15 minutes up to three times.
Authenticate API request submitted for the fourth time will return an error with the following code/message: <em>L01	(Username or password is invalid)</em>.
2) If the invalid card number is submitted via HPP, you should press “OK” button inside the <em>Reauthenticate</em> pop-up window to extend the session.

<br/>
To learn how an HPP is rendered for this scenario, review this <span class="screenshot" type="screenshot" data="/images/HPP_Rendering_via_a_URL.png">diagram</span>.

<br/>

<br/>
Note that if GET request is used in an initial call to render HPP, it is advised to use a temporary password instead of the original one, to prevent caching of a password by the browser. In such cases, a temporary password can be acquired by using authentication request. Please, see the integration notes for more information.
<br/>

<br/>
<h2>HPP Customization</h2> 
HPP can be configured by each merchant to reflect look and feel of its website or application and accommodate its specific business needs. There are five resources available for customization: paypage_card.html, tokenization_card.html, paypage_account.html, tokenization_account.html, result.html. Pages with _card are used for card processing and pages with _account are used for processing of direct debit transactions.
<br/>
<ul type="circle" >
<li>paypage_card.html - used for sale, sale-auth and credit operations done with payment cards. 
</li>
<li>tokenization_card.html - used for tokenization operations done with payment cards.
</li>
<li>paypage_account.html - used for sale, sale-auth and credit operations done with bank accounts.
</li>
<li>tokenization_account.html - used for tokenization operations done with bank accounts.
</li>
<li>result.html – a page shown to a user once an operation is successfully completed. 
</li>
</ul>

<br/>
Note that these files have to be saved as UTF-8 with the content encoded in UTF-8 within the file as well.
<br/>

<br/>
To include custom scripts in any of the HTML files above, separate JS files have to be used. These scripts must be placed in the /resources folder located in a root folder of a corresponding merchant/account. This should be done via <a href="/tutorials/136&amp;t=1">the user interface</a>. References to the external custom scripts have to be specified as follows: 
<syntaxhighlight lang="java"><script language="JavaScript" src="/resources/hpp/[merchant-id]/[script-name]"></script></syntaxhighlight>
<br/>
For example: 
<br/>
<syntaxhighlight lang="java"><script language="JavaScript" src="/resources/hpp/2000/paypage_card.js"></script></syntaxhighlight>
<br/>
Customized hosted payment pages can look similar to <span class="screenshot" type="screenshot" data="/images/hostedpaymentpage_customise.png">this</span> one or <span class="screenshot" type="screenshot" data="/images/Picture_2Buttons.png">this</span> one.

<br/>

<br/>
<h2>Customizing the HPP color</h2> 
You can customize the look and feel of your HPP. If you prefer a color theme different from the default one, you have two options.
<br/>
1) You can pass the desired color in hex format or specify the name of the color in the "theme" field. The system will generate mono-colored theme for you.
<br/>
2) If you need a more intricate theme with multiple colors, you can build your own. To do so, follow these steps:
<br/>
<ul type="circle" >
<li>contact our support team to request a custom theme;
</li>
<li>our support team will provide you with an example of a theme as a CSS template;
</li>
<li>in the CSS template, specify the exact colors you want;
</li>
<li>return the modified CSS template to our support team;
</li>
<li>our support team will upload the file to the server;
</li>
<li>you can use the name of the file without the extension as value of the theme parameter in the call and your custom theme will be applied.</li>
</ul>                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

