import React from 'react'
import { LuArrowRightCircle } from 'react-icons/lu';
import Navbar from '../../../Components/NavBar/Navbar';
import Footer from '../../../Components/Footer/Footer';
import '../Billing.css'
import parse from 'html-react-parser';



export default class BalancingSummary extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bandera: props.bandera,
            contenido: [],
            titulo: "Balancing Summary",
            tabactivo: 1,
            optionobjects: "",

        }
    }


    async componentDidMount() {

       
    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }

    cambiarOptionTab(option){
        this.setState({ tabactivo: option})
    }

    cambiarOptionObjects(option){
        this.setState({ optionobjects: option})
    }

   

    render() {
        return (
            <>
                <Navbar />

            <div className='anchoMaximo' style={{ paddingTop: '120px', display: 'block', margin: "auto", width: "95%" }}>
                
                <section class="container-fluid">
    <div class="row">
        <div class="col-xs-12 col-sm-12">
            <h1>
                Balancing Summary                                                    <a class="btn-xs btn-info" href="/uml/1647" >UML</a>
                                </h1>
        </div>
    </div>
    <br/>

    <div class="row">
        <div class="col-xs-12 col-sm-12">
                    </div>
    </div>
    
    <div class="row">
        <div class="col-xs-4 col-sm-4">
            <b>Added On: &nbsp;</b>03/16/12&nbsp;
        </div>
        <div class="col-xs-4 col-sm-4">
                            <b>SQL Table: &nbsp;</b><a href="/code/table/1647" target="_blank">BALANCING_SUMMARY</a>&nbsp;
                    </div>
        <div class="col-xs-4 col-sm-4">
            <b>Module: &nbsp;</b>UniBill&nbsp;
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4 col-sm-4">
            <b>Type: &nbsp;</b>Entity&nbsp;
            <b>Status: &nbsp;</b>Active&nbsp;
        </div>
        <div class="col-xs-4 col-sm-4">
                            <b>Java Class: &nbsp;</b>BalancingSummary&nbsp;
                    </div>
        <div class="col-xs-4 col-sm-4">
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12">
            <div class="panel panel-default">
                <div class="panel-body">
                                    </div>
            </div>
        </div>
    </div>
        
        <div class="row">
            <div class="col-xs-12 col-sm-12">
                <div class="panel panel-default">
                    <div class="panel-heading">Available Fields:</div>
                        <div class="panel-body">
                                                <div class="row">
                                                            <div class="col-xs-3 col-sm-3">
                                    <ul class="list-arrow">
                                                                                                                                <li>
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldAge">Age</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldAgent_Comission_FK">Agent Comission FK</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldAmount">Amount</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldCause_Transaction_Cl">Cause Transaction Cl</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldCause_Transaction_Code">Cause Transaction Code</a>
                                            </li>
                                                                            </ul>
                                </div>
                                                            <div class="col-xs-3 col-sm-3">
                                    <ul class="list-arrow">
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldCommission_Status_CL">Commission Status CL</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldCreate_Date">Create Date</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldId">Id</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldIs_Active">Is Active</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldIs_In_Collections">Is In Collections</a>
                                            </li>
                                                                            </ul>
                                </div>
                                                            <div class="col-xs-3 col-sm-3">
                                    <ul class="list-arrow">
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldIs_Source_Invalid">Is Source Invalid</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldIs_Target_Invalid">Is Target Invalid</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldMerchant_Account_Code">Merchant Account Code</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldPayment_Option_Type">Payment Option Type</a>
                                            </li>
                                                                                                                                <li class="list__item">
                                                <LuArrowRightCircle className='icoflecha' />
                                                <a href="#fldSource_FK">Source FK</a>
                                            </li>
                                                                            </ul>
                                </div>
                                                        <div class="col-xs-3 col-sm-3">
                                                                    <ul class="list-arrow">
                                                                                                                                                                                                                <li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="#fldTarget_FK">Target FK</a></li>
                                                                                                                                <li class="list__item"><LuArrowRightCircle className='icoflecha' /><a href="#fldTransaction_Source_CL">Transaction Source CL</a></li>
                                                                                                                    </ul>
                                                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldAge">
                        <span class="label">Age</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>AGE</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>age</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>int(11)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Integer</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                        </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                                    </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                                                </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldAgent_Comission_FK">
                        <span class="label">Agent Comission FK</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>09/16/16</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>AGENT_COMMISSIONS</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>agentCommissions</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>bigint(20)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Long</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                                <b>References</b> <a href="/objects/1643">Agent Commission</a>
                                    </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                                    </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                                                </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldAmount">
                        <span class="label">Amount</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>AMOUNT</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>amount</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>int(11)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Integer</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                        </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                                    </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                        The amount element defines the amount of the transaction. Supply the value in cents without a decimal point.                        </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldCause_Transaction_Cl">
                        <span class="label">Cause Transaction Cl</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>07/02/21</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>CAUSE_TRANSACTION_CL</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>causeTransactionCl</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>varchar(2)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Object</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                                <b>References</b> <a href="/objects/1794">Account Activity Classifier</a>
                                    </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                                    </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                                                </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldCause_Transaction_Code">
                        <span class="label">Cause Transaction Code</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>07/02/21</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>CAUSE_TRANSACTION_CODE</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>causeTransactionCode</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>bigint(20)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Long</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                        </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                                    </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                                                </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldCommission_Status_CL">
                        <span class="label">Commission Status CL</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>COMMISSION_STATUS_CL</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>commissionStatusCl</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>varchar(1)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>String</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                                <b>References</b> <a href="/objects/1806">Commission Status Classifier</a>
                                    </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                                    </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                                                </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldCreate_Date">
                        <span class="label">Create Date</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>CREATE_DATE</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>createDate</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>datetime</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Date</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                        </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                                    </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                        Date when the record was created                        </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldId">
                        <span class="label">Id</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>ID</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>id</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>bigint(20)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Long</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <b>Identifier / Primary Key</b>                                    </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                    Required                </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                        Identifier of the object used for references; auto-incremented integer value.                        </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldIs_Active">
                        <span class="label">Is Active</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>IS_ACTIVE</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>isActive</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>tinyint(1)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Boolean</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                        </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                    Default:true                </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                        Indicates whether the record is active in the system.                        </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldIs_In_Collections">
                        <span class="label">Is In Collections</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>IS_IN_COLLECTIONS</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>isInCollections</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>tinyint(1)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Boolean</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                        </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                    Default:false                </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                                                </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldIs_Source_Invalid">
                        <span class="label">Is Source Invalid</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>IS_SOURCE_INVALID</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>isSourceInvalid</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>tinyint(1)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Boolean</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                        </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                    Default:false                </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                                                </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldIs_Target_Invalid">
                        <span class="label">Is Target Invalid</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>IS_TARGET_INVALID</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>isTargetInvalid</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>tinyint(1)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Boolean</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                        </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                    Default:false                </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                                                </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldMerchant_Account_Code">
                        <span class="label">Merchant Account Code</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>MERCHANT_ACCOUNT_CODE</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>merchantAccountCode</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>int(11)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Integer</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                        </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                    Required                </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                        Code of <a class="glossaryLink" href="/terminology/merchant_account">Merchant Account</a> to which instance of this object is attributed to.

Depending on the context, Merchant Account Code field may contain either Merchant Code or Merchant Account Code.<br/>

The field is primarily used for data partitioning and data management, to make it easy to determine the ownership of a record within the database.                        </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldPayment_Option_Type">
                        <span class="label">Payment Option Type</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>PAYMENT_OPTION_TYPE</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>paymentOptionType</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>varchar(2)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>String</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                                <b>References</b> <a href="/objects/1795">Asset Transaction Classifier</a>
                                    </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                                    </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                                                </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldSource_FK">
                        <span class="label">Source FK</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>SOURCE_FK</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>source</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>bigint(20)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Long</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                                <b>References</b> <a href="/objects/1635">Account Transaction</a>
                                    </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                                    </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                                                </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldTarget_FK">
                        <span class="label">Target FK</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>TARGET_FK</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>target</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>bigint(20)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>Long</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                                <b>References</b> <a href="/objects/1635">Account Transaction</a>
                                    </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                                    </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                        Reference to the entity that represents balancing summary.                        </div>
                    </div>
                </div>
            </div>

                    <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <a name="fldTransaction_Source_CL">
                        <span class="label">Transaction Source CL</span>
                    </a>
                </div>
            </div>
                        <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Added On: &nbsp;</b>03/16/12</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Column: &nbsp;</b>TRANSACTION_SOURCE_CL</div>
                <div class="col-xs-4 col-sm-4"><b>Java Field: &nbsp;</b>transactionSourceCl</div>
            </div>
            <div class="row">
                <div class="col-xs-4 col-sm-4"><b>Status: &nbsp;</b>Active</div>
                <div class="col-xs-4 col-sm-4"><b>SQL Type: &nbsp;</b>varchar(1)</div>
                <div class="col-xs-4 col-sm-4"><b>Java Type: &nbsp;</b>String</div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                                                                <b>References</b> <a href="/objects/1841">Transaction Source Classifier</a>
                                    </div>
            </div>
            <div class="row">
                <div class="col-xs-8 col-sm-8">
                    <b>Attributes:</b>
                                    </div>
                            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                                                </div>
                    </div>
                </div>
            </div>

            
</section>



            </div>

                <Footer />
            </>
        )
    }
}

