import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalLodgingExtraChargeSubrecord extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Lodging Extra Charge Subrecord</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "5%"}}></colgroup>
                                        <colgroup style={{width: "10%"}}></colgroup>
                                        <colgroup style={{width: "10%"}}></colgroup>
                                        <colgroup style={{width: "20%"}}></colgroup>
                                        <colgroup style={{width: "5%"}}></colgroup>
                                        <colgroup style={{width: "50%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>№</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Possible values / Format</th>
                                                <th>Usage</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                                                <tr>
                                <td>1</td>
                                <td>code</td>
                                                                    <td>String(12)</td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="R">R</span></td>
                                <td>Unique identifier of an extra charged item.&nbsp;                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>2</td>
                                <td>type</td>
                                                                    <td >
                                        <span data="26" type="enum" class="popup-link">Enum</span>
                                    </td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="R">R</span></td>
                                <td>Type of an extra charged item.&nbsp;                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>3</td>
                                <td>amount</td>
                                                                    <td>Integer</td>
                                                                <td>amount in cents</td>
                                <td><span class="popup-usage" type="usage" page="components" data="R">R</span></td>
                                <td>Amount of an extra charged item.                                                                    </td>
                            </tr>
                                                </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

