import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalReferenceFieldType extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Reference Field Type</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                        <div class="col-xs-12 col-sm-12">
                While working with API, various entities within the system, such as merchants, resellers, etc, need to be referenced. API fields that correspond to the references to these entities are of the <em>Reference</em> type (for example, accountId, terminalId, etc). Each of these entities has two identifiers:
— Internal identifier (represented as <em>ID</em>) - numeric value, provided by the gateway; unique within the system.
— External identifier (represented as <em>code</em>) - alphanumeric value up to 60 characters, provided by an integrated external system for cross-referencing; preferably unique within the scope that makes sense to an integrator.

<br/>
When an API call with reference to an entity has to be submitted, this entity can be referenced by either internal or external identifier. By default, the system expects an internal identifier to be submitted (e.g. accountId/merchantAccountCode=1234). To let the system know that an external identifier is submitted, its value must be preceded by an asterisk (e.g. accountId/merchantAccountCode=*1234). This is applicable to all fields of <em>Reference</em> type.

<br/>
For example, an account has an internal ID, generated by the gateway (e.g.5678), and an external ID,  provided by an integrated POS system (e.g. pos8765). If an internal ID should be used in the API calls, it is submitted via <em>accountId</em> field as it is: <em>accountId=5678</em>. If an external ID is used should be used in the API calls, it is submitted with an asterisk as a prefix: <em>accountId=*pos8765</em>.            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

