import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalLegacyFieldsNames extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Legacy Fields Names</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                To unify field names within the APIs and the user interface of the gateway, the following reference field names have been changed:
<br/>
<div style={{display: "inline-block"}} ><br/>
<div class="table-responsive"><table class="newtable pull-left"><tbody><tr>
<th style={{fontWeight: "bold"}} >Deprecated Field Name
</th>
<th style={{fontWeight: "bold"}}>New Field Name
</th>
</tr><tr>
<td>merchantCode
</td>
<td>merchantId
</td>
</tr><tr>
<td>merchantAccountCode
</td>
<td>accountId
</td>
</tr><tr>
<td>terminalCode
</td>
<td>terminalId
</td>
</tr><tr>
<td>referenceNumber
</td>
<td>transactionId
</td>
</tr><tr>
<td>providerReferenceNumber
</td>
<td>providerTransactionId
</td>
</tr><tr>
<td>originalReferenceNumber
</td>
<td>originalTransactionId
</td>
</tr><tr>
<td>aggregateReferenceNumber
</td>
<td>aggregateTransactionId
</td>
</tr><tr>
<td>submissionCode
</td>
<td>submissionId
</td>
</tr><tr>
<td>transactionCategory
</td>
<td>transactionCategoryType
</td>
</tr></tbody></table>
</div>
<div style={{display: "inline-block"}}>
<br/>
Both deprecated and new names are currently supported by the system. The deprecated names will be supported until October, 30th, 2019. All new integrations should be done with the new names only.
<br/>

<br/>
Note that if you submit account ID value via the <em>merchantAccountCode</em> field within an API request, the system will return deprecated field names within the response. If account ID value is submitted via the <em>accountId</em> field, the system will perform validation using new field names. 
</div>                </div>
            </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

