import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINEffectiveDate extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Effective Date</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                            Transactions with effective date (transaction date) in the past as well as in the future can be processed. Any transactions with dates in the past are processed with the effective date of today, while any future dated transactions are processed on the specified effective date in the future. The only limitation is that a single file should not mix transactions with past, current and future effective dates. In other words, all transactions in the file must be either in the past, effective today or effective in the future.
                            <br/>                
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

