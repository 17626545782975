import React from 'react'
import { LuArrowRightCircle } from 'react-icons/lu';
import Navbar from '../../../../Components/NavBar/Navbar';
import Footer from '../../../../Components/Footer/Footer';
import { getDatos } from '../../../../Services/DatosLocalees';
import './Operations.css'
import parse from 'html-react-parser';
import { HiOutlineMinusSm } from 'react-icons/hi';


//Modales Name
import ModalProcessorRules from '../../../../Components/Modals/RealTime/ModalProcessorRules';
import ModalProcessorRulesCsc from '../../../../Components/Modals/RealTime/ModalProcessorRulesCsc';
import ModalProcessorRulesZipCode from '../../../../Components/Modals/RealTime/ModalProcessorRulesZipCode';
import ModalProcessorRulesGuestName from '../../../../Components/Modals/RealTime/ModalProcessorRulesGuestName';

//Modales Type
import ModalSale from '../../../../Components/Modals/RealTime/ModalSale';
import ModalReferenceFieldType from '../../../../Components/Modals/RealTime/ModalReferenceFieldType';
import ModalAccountType from '../../../../Components/Modals/RealTime/ModalAccountType';
import ModalHolderType from '../../../../Components/Modals/RealTime/ModalHolderType';
import ModalCSCIndicatorType from '../../../../Components/Modals/RealTime/ModalCSCIndicatorType';
import ModalCurrencyCodeType from '../../../../Components/Modals/RealTime/ModalCurrencyCodeType';
import ModalTransactionIndustryType from '../../../../Components/Modals/RealTime/ModalTransactionIndustryType';
import ModalTransactionCategoryType from '../../../../Components/Modals/RealTime/ModalTransactionCategoryType';
import ModalTransactionModeType from '../../../../Components/Modals/RealTime/ModalTransactionModeType';
import ModalFormatType from '../../../../Components/Modals/RealTime/ModalFormatType';
import ModalSplitSubrecord from '../../../../Components/Modals/RealTime/ModalSplitSubrecord';
import ModalSplitItemSubrecord from '../../../../Components/Modals/RealTime/ModalSplitItemSubrecord';
import ModalDistributionSubrecord from '../../../../Components/Modals/RealTime/ModalDistributionSubrecord';
import ModalTaxIndicatorType from '../../../../Components/Modals/RealTime/ModalTaxIndicatorType';
import ModalLevelIIIItemSubrecord from '../../../../Components/Modals/RealTime/ModalLevelIIIItemSubrecord';
import ModalReturnURLPolicyType from '../../../../Components/Modals/RealTime/ModalReturnURLPolicyType';
import ModalAuthenticationStatusType from '../../../../Components/Modals/RealTime/ModalAuthenticationStatusType';
import ModalTerminalType from '../../../../Components/Modals/RealTime/ModalTerminalType';
import ModalPetroleumItemSubrecord from '../../../../Components/Modals/RealTime/ModalPetroleumItemSubrecord';
import ModalLodgingChargeType from '../../../../Components/Modals/RealTime/ModalLodgingChargeType';
import ModalSpecialProgramType from '../../../../Components/Modals/RealTime/ModalSpecialProgramType';
import ModalLodgingExtraChargeSubrecord from '../../../../Components/Modals/RealTime/ModalLodgingExtraChargeSubrecord';
import ModalIdentityVerificationType from '../../../../Components/Modals/RealTime/ModalIdentityVerificationType';
import ModalDriverLicenseState from '../../../../Components/Modals/RealTime/ModalDriverLicenseState';
import ModalEntryModeType from '../../../../Components/Modals/RealTime/ModalEntryModeType';
import ModalEntryMediumType from '../../../../Components/Modals/RealTime/ModalEntryMediumType';
import ModalEntryPINModeType from '../../../../Components/Modals/RealTime/ModalEntryPINModeType';
import ModalTerminalCapabilityModeType from '../../../../Components/Modals/RealTime/ModalTerminalCapabilityModeType';
import ModalProcessingModeType from '../../../../Components/Modals/RealTime/ModalProcessingModeType';
import ModalHolderVerificationModeType from '../../../../Components/Modals/RealTime/ModalHolderVerificationModeType';
import ModalFallbackReasonCodeType from '../../../../Components/Modals/RealTime/ModalFallbackReasonCodeType';
import ModalCarRentalExtraChargeSubrecord from '../../../../Components/Modals/RealTime/ModalCarRentalExtraChargeSubrecord';
import ModalExtraChargeType from '../../../../Components/Modals/RealTime/ModalExtraChargeType';
import ModalPetroleumProductType from '../../../../Components/Modals/RealTime/ModalPetroleumProductType';
import ModalExtendedAccountType from '../../../../Components/Modals/RealTime/ModalExtendedAccountType';
import ModalSplitSubrecordSale from '../../../../Components/Modals/RealTime/ModalSplitSubrecordSale';
import ModalSplitItemSubrecordSale from '../../../../Components/Modals/RealTime/ModalSplitItemSubrecordSale';

//Modales Pattern
import ModalValidationPatternsTypes from '../../../../Components/Modals/RealTime/ModalValidationPatternsTypes';
//Modales Usage
import ModalMessageFormats from '../../../../Components/Modals/RealTime/ModalMessageFormats';

//Modales Description
import ModalPasswordEncoding from '../../../../Components/Modals/RealTime/ModalPasswordEncoding';
import ModalLegacyFieldsNames from '../../../../Components/Modals/RealTime/ModalLegacyFieldsNames';
import ModalTerminalCode from '../../../../Components/Modals/RealTime/ModalTerminalCode';
import ModalDirectDebit from '../../../../Components/Modals/RealTime/ModalDirectDebit';
import ModalAccountData from '../../../../Components/Modals/RealTime/ModalAccountData';
import ModalServiceFees from '../../../../Components/Modals/RealTime/ModalServiceFees';
import ModalStoredCredentials from '../../../../Components/Modals/RealTime/ModalStoredCredentials';
import ModalTransactionOriginCode from '../../../../Components/Modals/RealTime/ModalTransactionOriginCode';
import ModalCrossReferenceFields from '../../../../Components/Modals/RealTime/ModalCrossReferenceFields';
import ModalClientHost from '../../../../Components/Modals/RealTime/ModalClientHost';
import ModalSettlementGroup from '../../../../Components/Modals/RealTime/ModalSettlementGroup';
import ModalBankInformationSubscription from '../../../../Components/Modals/RealTime/ModalBankInformationSubscription';
import ModalFormatField from '../../../../Components/Modals/RealTime/ModalFormatField';
import ModalDynamicDescriptor from '../../../../Components/Modals/RealTime/ModalDynamicDescriptor';
import ModalSubrecordFormat from '../../../../Components/Modals/RealTime/ModalSubrecordFormat';
import ModalSplitPayments from '../../../../Components/Modals/RealTime/ModalSplitPayments';
import ModalInterchangeRateQualification from '../../../../Components/Modals/RealTime/ModalInterchangeRateQualification';
import ModalTransactionLevels from '../../../../Components/Modals/RealTime/ModalTransactionLevels';
import ModalHostedPaymentPageHPP from '../../../../Components/Modals/RealTime/ModalHostedPaymentPageHPP';
import ModalPageFormat from '../../../../Components/Modals/RealTime/ModalPageFormat';
import ModalEmbeddedMode from '../../../../Components/Modals/RealTime/ModalEmbeddedMode';
import ModalTransitFields from '../../../../Components/Modals/RealTime/ModalTransitFields';
import Modal3DSecureVerification from '../../../../Components/Modals/RealTime/Modal3DSecureVerification';
import ModalHealthcareIndustryTransactions from '../../../../Components/Modals/RealTime/ModalHealthcareIndustryTransactions';
import ModalIdentityVerification from '../../../../Components/Modals/RealTime/ModalIdentityVerification';
import ModalTerminalDataEntry from '../../../../Components/Modals/RealTime/ModalTerminalDataEntry';
import ModalOfflineTransactions from '../../../../Components/Modals/RealTime/ModalOfflineTransactions';
import ModalFallbackTransactions from '../../../../Components/Modals/RealTime/ModalFallbackTransactions';
import ModalSplitAmountFormat from '../../../../Components/Modals/RealTime/ModalSplitAmountFormat';
import ModalTerminalID from '../../../../Components/Modals/RealTime/ModalTerminalID';
import ModalHolderNameNormalizationRules from '../../../../Components/Modals/RealTime/ModalHolderNameNormalizationRules';
import ModalRecordID from '../../../../Components/Modals/RealTime/ModalRecordID';
import ModalProviderResponseData from '../../../../Components/Modals/RealTime/ModalProviderResponseData';
import ModalWarningCode from '../../../../Components/Modals/RealTime/ModalWarningCode';
import ModalProcessorRulesPaymentNetworkName from '../../../../Components/Modals/RealTime/ModalProcessorRulesPaymentNetworkName';
import ModalTerminalMessage from '../../../../Components/Modals/RealTime/ModalTerminalMessage';
import ModalReceiptRequirements from '../../../../Components/Modals/RealTime/ModalReceiptRequirements';


//Modal example
import ModalRequestParameters from '../../../../Components/Modals/RealTime/ModalRequestParameters';

export default class Operations extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      bandera: props.bandera,
      contenido: [],
      titulo: "",

      tablasporseccion:[],
      tablasporseccionexamples:[],
      showmodal: "",
      modaltesttransaction: false,
      modalimagenservice: false,
      arrayDatos: [
        {
          titulo: "Supported Operations",
          subdatos: [
            
            {//////////////////////////////////////////
              titulo: "Sale-Auth",
              notas: "an operation used to withdraw specific amounts of money from the supplied credit card or bank account. Sale-Auth requires an explicit capture operation in order for the money to be transferred; otherwise, an automatic void is issued.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Extended Logic" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Shipping Address Information *" },
                  { nombre: "Level II Transaction Information *" },
                  { nombre: "Level III Transaction Information *" },
                  { nombre: "Restaurant Industry *" },
                  { nombre: "PIN Debit/EBT cards *" },
                  { nombre: "Interac *" },
                  { nombre: "HPP Fields *" },
                  { nombre: "Transit Fields" },
                  { nombre: "Voice Authorization *" },
                  { nombre: "3D Secure *" },
                  { nombre: "Healthcare Industry *" },
                  { nombre: "Petroleum *" },
                  { nombre: "Lodging *" },
                  { nombre: "Identity Verification *" },
                  { nombre: "Terminals" },
                  { nombre: "Check 21" },
                  { nombre: "Car Rental" }
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Account Data is used" },
                  { nombre: "Account Number is used" },
                  { nombre: "Token is used" },
                  { nombre: "LodgingChargeType = H" },
                  { nombre: "LodgingChargeType ≠ H" },
                  { nombre: "Splits field is used" },
                  { nombre: "SplitSchemaId field is used" }
                ],
                subtitulo3: "Subrecords",
                arraytitulo3: [
                  { nombre: "Split Subrecord" },
                  { nombre: "Split Item Subrecord" },
                  { nombre: "Level III Item Subrecord" },
                  { nombre: "Petroleum Item Subrecord" },
                  { nombre: "Lodging Extra Charge Subrecord" },
                  { nombre: "Car Rental Extra Charge Subrecord" }

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Sale-Auth Request" },
                  { nombre: "Sale-Auth Request for Petroleum" },
                  { nombre: "Sale-Auth Request with Track Data" },
                  { nombre: "Sale-Auth Request with Proxy Number" },
                  { nombre: "Sale-Auth Request for Lodging" },
                  { nombre: "Sale-Auth Request for Car Rental" },
                  { nombre: "Sale-Auth Request with Items" },
                  { nombre: "Sale-Auth Request with EMV Data" },
                  { nombre: "Sale-Auth Request with PIN Debit" },
                  { nombre: "Sale-Auth Request with PIN Debit" },
                  { nombre: "Sale-Auth Request for Voice Authorization" },
                  { nombre: "Sale-Auth Request via HPP" },
                  { nombre: "Sale-Auth Request with Processing Mode" },
                  { nombre: "Sale-Auth Request with Multiple Vendors for Marketplace" },
                  { nombre: "Sale-Auth Request with Convenience Fee" },
                  { nombre: "Sale-Auth Request with EBT card" },
                  { nombre: "Sale-Auth Request with Split Payments" },
                  { nombre: "Sale-Auth Request with Split Payments and Items" }

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "General Information" },
                  { nombre: "Account Information " },
                  { nombre: "Transaction Information " },
                  { nombre: "Extended Logic " },
                  { nombre: "Billing Address Information" },
                  { nombre: "Response Codes Information" },
                  { nombre: "Interac *" },
                  { nombre: "EBT Information" },
                  { nombre: "Terminals " }

                ],
                subtitulo2: " ",
                arraytitulo2: [],
                subtitulo3: "Subrecords",
                arraytitulo3: [
                  { nombre: "Split Subrecord " },
                  { nombre: "Split Item Subrecord " },
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Sale-Auth Response" },
                  { nombre: "Sale-Auth Response for Petroleum" },
                  { nombre: "Sale-Auth Response with Track Data" },
                  { nombre: "Sale-Auth Response with Proxy Number" },
                  { nombre: "Sale-Auth Response for Lodging" },
                  { nombre: "Sale-Auth Response for Car Rental" },
                  { nombre: "Sale-Auth Response with Items" },
                  { nombre: "Sale-Auth Response with EMV Data" },
                  { nombre: "Sale-Auth Response with PIN Debit" },
                  { nombre: "Sale-Auth Response for Voice Authorization" },
                  { nombre: "Sale-Auth Response via HPP" },
                  { nombre: "Sale-Auth Response with Processing Mode" },
                  { nombre: "Sale-Auth Response with Multiple Vendors for Marketplace" },
                  { nombre: "Sale-Auth Response with Convenience Fee" },
                  { nombre: "Sale-Auth Response with EBT card" },
                  { nombre: "Sale-Auth Response with Split Payments" },
                  { nombre: "Sale-Auth Response with Split Payments and Items" }

                ],
              }],
              tablas: [
                {
                  titulo: "Sale-Auth (Request)",
                  tabla: {
                    titulotabla: "Core Infо",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                         "1",
                         "requestType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Type of the request.",
                        ],
                        vinculoType: "Sale",
                        vinculoUsage: "Message Formats",

                      },
                      {
                        row: [
                         "2",
                         "userName",
                         "String(50)",
                         "",
                         "",
                         "R",
                         "Name of a user that initiates the API call. Provided by gateway owner.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "password",
                         "String(32)",
                         "",
                         "",
                         "R",
                         "Password of a user that initiates the API call. Provided by gateway owner. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Password Encoding"
                      },
                      {
                        row: [
                         "4",
                         "accountId",
                         "Reference",
                         "",
                         "",
                         "R",
                         "Reference to an account associated with the API call.",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded.",
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "5",
                         "terminalId",
                         "Reference",
                         "",
                         "",
                         "O",
                         "Reference to a terminal associated with the API call. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded.",
                        descripcion2: "Formerly called terminalCode. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Terminal Code",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "6",
                         "languageCode",
                         "String(2)",
                         "",
                         "",
                         "O",
                         "Language in which API response message will be returned. The name of the language must be submitted in the format defined in ISO 639-1 standard.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "7",
                         "pageFormat",
                         "String(5)",
                         "",
                         "",
                         "O",
                         "",
                        ],
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "8",
                         "accountType *",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Type of an account used in the transaction. This value doesn't affect the cost of transaction processing. It is used for validation purposes only.",
                        ],
                        vinculoType: "Account Type",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "9",
                         "accountNumber *",
                         "String(20)",
                         "",
                         "",
                         "C",
                         "Number of a payment card or bank account.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "10",
                         "accountAccessory *",
                         "String(14)",
                         "",
                         "",
                         "C",
                         "Payment card's expiration date or bank account's routing number. See ",
                        ],
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion2: "Formerly called accountAccessory. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Direct Debit",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "11",
                         "accountData *",
                         "String(500)",
                         "",
                         "",
                         "C",
                         "Track/EMV data of a payment card. See ",
                        ],
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion2: "Formerly called trackData. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Account Data",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "12",
                         "token",
                         "String(40)",
                         "",
                         "",
                         "C",
                         "Token associated with a payment card or bank account. Can be used instead of a payment card or bank account number in further transaction processing.",
                        ],
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion1: "Note: This field can be submitted only if tokenization service is enabled."
                      },
                      {
                        row: [
                         "13",
                         "checkNumber",
                         "String(15)",
                         "",
                         "",
                         "O",
                         "Number of a check. Used for direct debit transactions only.",
                        ],
                        vinculoName: "Processor Rules",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field requires ",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "Processor Rules"
                      },
                      {
                        row: [
                         "14",
                         "holderType",
                         "Enum",
                         "",
                         "default - P",
                         "O",
                         "Type of a payment card or bank account holder.",
                        ],
                        vinculoType: "Holder Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "15",
                         "holderName",
                         "String(150)",
                         "",
                         "",
                         "C",
                         "Name of a payment card or bank account holder.",
                        ],
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion1: "Note: This field is required when accountNumber field is present in the request.",
                        descripcion2: "Formerly called holderName. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "16",
                         "holderBirthdate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "O",
                         "Birthdate of a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "17",
                         "csc",
                         "String(4)",
                         "",
                         "",
                         "C",
                         "Card security code (three digits on the back of a payment card).",
                        ],
                        vinculoName: "Processor Rules csc",
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion1: "Note: This field requires ",
                        descripcion2: "Formerly called csc. See ",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "Processor Rules csc",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "18",
                         "cscIndicator",
                         "Enum",
                         "",
                         "",
                         "C",
                         "Defines how CSC is handled.",
                        ],
                        vinculoType: "CSC Indicator Type",
                        vinculoUsage: "#Account_Data_is_used*"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Transaction Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "19",
                         "amount *",
                         "Integer",
                         "",
                         "amount in cents",
                         "R",
                         "	Total amount of the transaction that includes discount, taxes and fees (if present).",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "20",
                         "feeAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of a service fee included in the total amount. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for some processors.",
                        modalname0: "Service Fees",
                      },
                      {
                        row: [
                         "21",
                         "cashbackAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money returned to a payment card holder. Used for sale with cashback transactions.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "22",
                         "currencyCode",
                         "Enum",
                         "",
                         "default - DEF",
                         "O",
                         "Currency used in the transaction.",
                        ],
                        vinculoType: "Currency Code Type",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "23",
                         "transactionIndustryType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Industry type of the transaction.",
                        ],
                        vinculoType: "Transaction Industry Type",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "24",
                         "transactionCategoryType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Category of the transaction.",
                        ],
                        vinculoType: "Transaction Category Type",
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called transactionCategory. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "25",
                         "transactionModeType",
                         "Enum",
                         "",
                         "",
                         "C",
                         "Mode of the transaction. See ",
                        ],
                        vinculoType: "Transaction Mode Type",
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion1: "Note: This field is required for card-on-file transactions, i.e. when transactionModeType=S/O.",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Stored Credentials"
                      },
                      {
                        row: [
                         "26",
                         "memo",
                         "String(255)",
                         "",
                         "",
                         "O",
                         "Additional information about the transaction.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: 70 of 255 characters are reserved for system use and only 185 characters can be used within the API request."
                      },
                      {
                        row: [
                         "27",
                         "isPartialAuthorization",
                         "Boolean",
                         "",
                         "0 - disabled, 1 - enabled; default = 0",
                         "O",
                         "Indicates whether partial authorization is enabled. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "28",
                         "isAccountTypeCorrectionEnabled",
                         "Boolean",
                         "",
                         "0 - determined by a processor, 1 - determined by the gateway; default = 1",
                         "O",
                         "Indicates whether a card type is determined by the gateway or a processor. Used for PINless debit processing to determine the accurate card type. If set to true, the gateway will use its internal BIN table to identify a card type. If set to false, a card number will be sent to the underlying processor, allowing it to determine a card type on its own.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "29",
                         "rewardNumber",
                         "String(20)",
                         "ALPHANUMERIC",
                         "",
                         "O",
                         "Number of bonus account.",
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoPattern: "Validation Patterns Types",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Cross-References",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "30",
                         "userCode",
                         "	String(60)",
                         "",
                         "",
                         "O",
                         "Identifier of a user supplied by a submitter that initiates the API call.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "31",
                         "transactionOriginCode",
                         "String(35)",
                         "",
                         "",
                         "O",
                         "Identifier of a place within a submitter's application from which the transaction is originated. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Origin Code"
                      },
                      {
                        row: [
                         "32",
                         "transactionCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Identifier of the transaction supplied by a submitter. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Cross-reference Fields"
                      },
                      {
                        row: [
                         "33",
                         "transactionInternalCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Internal identifier of the transaction within a submitter's system. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Cross-reference Fields"
                      },
                      {
                        row: [
                         "34",
                         "customerAccountCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Identifier of a customer, associated with the transaction, within a submitter's system. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Cross-reference Fields"
                      },
                      {
                        row: [
                         "35",
                         "customerAccountInternalCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Internal identifier of a customer, associated with the transaction, within a submitter's system. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Cross-reference Fields"
                      },
                      {
                        row: [
                         "36",
                         "itemCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Identifier of a purchased product or service within a submitter’s system.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "37",
                         "laneCode",
                         "String(10)",
                         "",
                         "digits only; no special characters",
                         "O",
                         "Identifier of a lane associated with the transaction.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "38",
                         "clientHost",
                         "String(60)",
                         "",
                         "IP-address or URL",
                         "O",
                         "IP-address or a host associated with a submitter that originated the transaction. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Client Host"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Extended Logic",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "39",
                         "sequenceNumber",
                         "Integer",
                         "",
                         "greater than 0; less than or equal to sequenceCount",
                         "O",
                         "Sequential number of the payment (e.g. 2nd of 12 payments). Used for installment and recurring payments only (for transactionCategoryType = I or R).",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "40",
                         "sequenceCount",
                         "Integer",
                         "",
                         "greater than 0",
                         "O",
                         "Total number of payments in an installment sequence (e.g. 12 payments). Used for installment payments only (for transactionCategoryType = I).",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "41",
                         "settlementDelay",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Period for which the transaction can be captured (up to six days). Note that when the delay period is due and the transaction has not been captured, it gets declined automatically.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "42",
                         "originalNetworkTransactionId *",
                         "String(30)",
                         "",
                         "",
                         "O",
                         "Identifier of the first transaction in a sequence of payments. Provided by a payment network. Used for installment and recurring payments only (for transactionCategoryType = I or R). See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Stored Credentials"
                      },
                      {
                        row: [
                         "43",
                         "settlementGroupCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Identifier of a settlement group. Used when it is needed to void a group of transactions if at least one transaction from the group is voided at the moment when settlement is done. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Settlement Group"
                      },
                      {
                        row: [
                         "44",
                         "subscriptionCode",
                         "Long",
                         "",
                         "",
                         "O",
                         "Code of a subscription to a processing bank. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Bank Information Subscription"
                      },
                      {
                        row: [
                         "45",
                         "format",
                         "Enum",
                         "",
                         "values are pipe-delimited",
                         "O",
                         "Defines what resource is going to be received within an offline callback. See ",
                        ],
                        vinculoType: "Format Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Format Field"
                      },
                      {
                        row: [
                         "46",
                         "descriptor",
                         "String(25)",
                         "",
                         "",
                         "O",
                         "Dynamic descriptor of the transaction that is shown on customer statements and can be customized to simplify understanding of charge for a cardholder. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Dynamic Descriptor"
                      },
                      {
                        row: [
                         "47",
                         "distributions",
                         "List<Distribution>",
                         "",
                         "",
                         "O",
                         "List of distributions included in the transaction. See ",
                        ],
                        vinculoType: "Distribution Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "48",
                         "splits",
                         "List<Split>",
                         "",
                         "",
                         "C",
                         "List of split payment scenarios included in the transaction. See ",
                        ],
                        vinculoType: "Split Subrecord",
                        vinculoUsage: "#Account_Data_is_used*",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "49",
                         "splitSchemaId",
                         "Reference",
                         "",
                         "",
                         "C",
                         "Reference to a split schema associated with the transaction. See ",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "#Account_Data_is_used*",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Split Payments"
                      },
                      {
                        row: [
                         "50",
                         "items",
                         "List<SplitItem>",
                         "",
                         "",
                         "O",
                         "List of items included in the transaction. See ",
                        ],
                        vinculoType: "Split Item Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Billing Address Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "51",
                         "street",
                         "String(128)",
                         "",
                         "",
                         "O",
                         "Street of a billing address associated with a payment card or bank account holder. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded.",
                        descripcion2: "Formerly called street. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Interchange Rate Qualification",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "52",
                         "city",
                         "String(50)",
                         "",
                         "",
                         "O",
                         "City of a billing address associated with a payment card or bank account holder. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called city. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Interchange Rate Qualification",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "53",
                         "countryCode",
                         "String(2)",
                         "",
                         "default - US",
                         "O",
                         "Country of a billing address associated with a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called countryCode. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "54",
                         "state",
                         "String(2)",
                         "",
                         "",
                         "O",
                         "State of a billing address associated with a payment card or bank account holder. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called state. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Interchange Rate Qualification",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "55",
                         "zipCode",
                         "String(15)",
                         "",
                         "",
                         "O",
                         "ZIP code of a billing address associated with a payment card or bank account holder. See  ",
                        ],
                        vinculoName: "Processor RuleszipCode",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field requires ",
                        descripcion2: "Formerly called zipCode. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Interchange Rate Qualification",
                        seemoreinformation1: "special handling ",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "Processor RuleszipCode",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "56",
                         "phone",
                         "String(20)",
                         "",
                         "digits only; no special characters",
                         "O",
                         "Phone number associated with a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called phone. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "57",
                         "email",
                         "String(100)",
                         "",
                         "",
                         "O",
                         "Email associated with a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called email. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Shipping Address Information *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "58",
                         "shippingDate",
                         "Date",
                         "",
                         "yyyyMMdd; default - capture date",
                         "O",
                         "Date of a purchase (current date or any date in the past).",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "59",
                         "shippingName",
                         "String(100)",
                         "",
                         "",
                         "R2",
                         "Name of an order recipient.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "60",
                         "shippingStreet",
                         "String(128)",
                         "",
                         "",
                         "R2",
                         "Street of a shipping address associated with an order recipient.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded."
                      },
                      {
                        row: [
                         "61",
                         "shippingCity",
                         "String(50)",
                         "",
                         "",
                         "R2",
                         "City of a shipping address associated with an order recipient.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "62",
                         "shippingCountryCode",
                         "String(2)",
                         "",
                         "",
                         "R2",
                         "Country code of a shipping address associated with an order recipient.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "63",
                         "shippingState",
                         "String(2)",
                         "",
                         "",
                         "R2",
                         "State of a shipping address associated with an order recipient.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "64",
                         "shippingZipCode",
                         "String(15)",
                         "",
                         "",
                         "R2",
                         "ZIP code of a shipping address associated with an order recipient.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Level II Transaction Information *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "65",
                         "orderCode",
                         "String(17)",
                         "",
                         "",
                         "R2",
                         "Unique identifier of an order within a submitter's system. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "66",
                         "shippingAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R2",
                         "Amount of shipping and handling fees charged for an order delivery. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "67",
                         "dutyAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R2",
                         "Amount of a duty (import tax) charged on the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "68",
                         "taxAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R2",
                         "Tax amount charged on the order/transaction. Included in the total amount. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "69",
                         "taxRate",
                         "Integer",
                         "",
                         "implied 2 decimal places (e.g. 150 = 1.5%; 1000 = 10%)",
                         "R2",
                         "Tax rate charged on the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "70",
                         "taxIndicator",
                         "Enum",
                         "",
                         "",
                         "R2",
                         "Defines how taxes are handled for the transaction. See ",
                        ],
                        vinculoType: "Tax Indicator Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "71",
                         "customerAccountTaxId",
                         "String(13)",
                         "",
                         "",
                         "R2",
                         "Tax ID of a customer associated with the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Level III Transaction Information *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "72",
                         "shippingOriginZipCode",
                         "String(10)",
                         "",
                         "",
                         "R3",
                         "ZIP-code associated with an address from which an order is shipped. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "73",
                         "discountAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R3",
                         "Discount amount applied to the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "74",
                         "taxInvoiceCode",
                         "String(15)",
                         "",
                         "",
                         "R3",
                         "Tax invoice number associated with the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "75",
                         "localTaxAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R3",
                         "Amount of a local (city, country, etc) tax charged on the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "76",
                         "localTaxIndicator",
                         "Enum",
                         "",
                         "",
                         "R3",
                         "Defines how a local tax is handled. See ",
                        ],
                        vinculoType: "Tax Indicator Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "77",
                         "nationalTaxAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R3",
                         "Amount of a national/federal tax charged on the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "78",
                         "nationalTaxIndicator",
                         "Enum",
                         "",
                         "",
                         "R3",
                         "Defines how a national/federal tax is handled. See ",
                        ],
                        vinculoType: "Tax Indicator Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "79",
                         "orderDate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "R3",
                         "Date of an order placement. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "80",
                         "commodityCode",
                         "String(4)",
                         "",
                         "",
                         "R3",
                         "Acquirer designated standardized identifier of a group of items associated with the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "81",
                         "items",
                         "List<L3Item>",
                         "",
                         "",
                         "R3",
                         "List of items included in the transaction. See ",
                        ],
                        vinculoType: "Level III Item Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Restaurant Industry *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "82",
                         "tipAmount",
                         "Integer",
                         "",
                         "amount in cents; greater than 0",
                         "O",
                         "Amount of tips.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "83",
                         "tipRecipientCode",
                         "String(15)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a person that is going to receive tips.",
                        ],
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "PIN Debit/EBT cards *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "84",
                         "pinValue *",
                         "String(16)",
                         "",
                         "",
                         "O",
                         "PIN Debit only. PIN value associated with a debit card.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called pinCode. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      
                      },
                      {
                        row: [
                         "85",
                         "ksnValue *",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "PIN Debit only. Value of a temporary key (KSN) that encrypts a PIN value associated with a payment card.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called dukpt. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      
                      },
                      {
                        row: [
                         "86",
                         "voucherNumber",
                         "String(15)",
                         "",
                         "",
                         "O",
                         "For EBT Voucher Clear transactions only. Value of a voucher number.",
                        ],
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Interac *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "87",
                         "macValue",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "For Canadian transactions only. Value of a message authentication code (MAC). After submitted, the value of the field is not stored in the database.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "88",
                         "stanValue",
                         "String(6)",
                         "",
                         "",
                         "O",
                         "For Canadian transactions only. Value identifying the transaction in a terminal. After submitted, the value of the field is stored in the database.",
                        ],
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "HPP Fields *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "89",
                         "notifyURL",
                         "String(255)",
                         "",
                         "none or URL",
                         "R",
                         "Callback URL used for delivering of a transaction result. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded.",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)"
                      
                      },
                      {
                        row: [
                         "90",
                         "postNotifyURL",
                         "String(255)",
                         "",
                         "none or URL",
                         "O",
                         "Callback URL used for delivering of a transaction result when more than one submitter's systems are used. Used in conjunction with notifyURL only. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)"
                      },
                      {
                        row: [
                         "91",
                         "cancelURL",
                         "String(255)",
                         "",
                         "none, page or URL",
                         "O",
                         "URL used for redirection when the transaction gets cancelled. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)"
                      },
                      {
                        row: [
                         "92",
                         "returnURL",
                         "String(255)",
                         "",
                         "none, page or URL",
                         "O",
                         "URL used for redirection when the transaction gets processed successfully. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded."
                      
                      },
                      {
                        row: [
                         "93",
                         "styleURL",
                         "String(255)",
                         "",
                         "none or URL",
                         "O",
                         "URL of a CSS stylesheet that should be dynamically included in the hosted payment page. Used when dynamic styles are needed. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded."
                      
                      },
                      {
                        row: [
                         "94",
                         "returnURLPolicy",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how returnURL is handled when the transaction is processed. See ",
                        ],
                        vinculoType: "Return URL Policy Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)"
                      },
                      {
                        row: [
                         "95",
                         "providerData",
                         "String",
                         "",
                         "",
                         "O",
                         "Used to transfer data from the provider that has been received on HPP. It is required to process transactions via PayPal.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "96",
                         "theme",
                         "String",
                         "",
                         "black",
                         "O",
                         "Indicates which color theme will be used. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)"
                      },
                      {
                        row: [
                         "97",
                         "isEmbedded",
                         "Boolean",
                         "",
                         "",
                         "O",
                         "Indicates whether header and footer of the hosted payment page are shown within the page. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Embedded Mode"
                      },
                      {
                        row: [
                         "98",
                         "pageFormat",
                         "String",
                         "",
                         "",
                         "O",
                         "Defines what stages are shown on the hosted payment page. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Page Format"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Transit Fields",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "99",
                         "isCscVerificationEnabled",
                         "Boolean",
                         "",
                         "0 - not applied, 1 - applied; default = 1",
                         "O",
                         "Signals an underlying processing system (in case when it supports such functionality) that CSC verification procedure should be applied. Note that validation rules, as well as the outcome of the transaction based on a value verification, is determined by the underlying processor. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transit Fields"
                      },
                      {
                        row: [
                         "100",
                         "isAvsVerificationEnabled",
                         "Boolean",
                         "",
                         "0 - not required, 1 - required; default = 1",
                         "O",
                         "Signals an underlying processing system (in case when it supports such functionality) that additional verification based on AVS information (billing address) is required. Note that validation rules, as well as the outcome of the transaction based on a value verification, is determined by the underlying processor. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transit Fields"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Voice Authorization *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "101",
                         "approvalCode *",
                         "String(100)",
                         "",
                         "",
                         "O",
                         "Voice Authorization only.  Authorization number given by a cardholder's bank (ex processorCode).",
                        ],
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "3D Secure *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "102",
                         "authenticationStatus",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Defines a status of 3D Secure verification process. See ",
                        ],
                        vinculoType: "Authentication Status Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "3D Secure Verification"
                      },
                      {
                        row: [
                         "103",
                         "authenticationCode",
                         "String(36)",
                         "",
                         "typically 28 bytes in length and base 64-encoded.",
                         "O",
                         "Unique 3D Secure Transaction Identifier generated by MPI. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "3D Secure Verification"
                      },
                      {
                        row: [
                         "104",
                         "authenticationVerificationValue",
                         "String(100)",
                         "",
                         "",
                         "R",
                         "Issuer authentication value that corresponds to Visa CAVV or MasterCard AAV. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "3D Secure Verification"
                      },
                      {
                        row: [
                         "105",
                         "authenticationVersion",
                         "String(8)",
                         "",
                         "",
                         "O",
                         "3D-Secure version related to the received authenticationCode and authenticationVerificationValue. Required for 3D-Secure V2.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Healthcare Industry *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "106",
                         "healthcareAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R",
                         "Total amount of money, spent on healthcare-related services or products. Included in the total amount of the transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "107",
                         "transitAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money, spent on a healthcare-related transportation. Included in the total amount of the transaction. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "108",
                         "copayAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "	Amount of money, spent each time a medical service is accessed. Included in the healthcare amount. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "109",
                         "prescriptionAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money, spent on prescription drugs. Included in the healthcare amount. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "110",
                         "visionAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money, spent on prescription drugs. Included in the healthcare amount. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "111",
                         "clinicAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money, spent on a hospitalization. Included in the healthcare amount. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "112",
                         "dentalAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money, spent on dental-related medical services. Included in the healthcare amount. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "113",
                         "isQualifiedIIAS",
                         "Boolean",
                         "",
                         "0 - not verified, 1 - verified; default = 0",
                         "O",
                         "	Indicates whether purchased items are verified against IIAS as qualified for healthcare purchases. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Petroleum *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "114",
                         "terminalType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Terminal used for the transaction.",
                        ],
                        vinculoType: "Terminal Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "115",
                         "driverNumber",
                         "String(10)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a driver.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "116",
                         "userIdNumber",
                         "String(12)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a driver in an agency's system.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "117",
                         "vehicleNumber",
                         "String(10)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a vehicle.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "118",
                         "vehicleIdNumber",
                         "String(8)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a vehicle in an agency's system.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "119",
                         "odometrReading",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Distance travelled by a vehicle, read from the vehicle's odometr.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "120",
                         "jobNumber",
                         "String(12)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a job associated with a driver.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "121",
                         "jobIdNumber",
                         "String(12)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a job associated with a driver in an agency's system.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "122",
                         "departmentNumber",
                         "String(12)",
                         "",
                         "",
                         "O",
                         "Unique identifier of an agency's department.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "123",
                         "serviceRestrictionCode",
                         "String(3)",
                         "",
                         "",
                         "O",
                         "Identifier of a service availability. Required for Visa/MasterCard keyed transactions.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "124",
                         "petroleumItems",
                         "List<PetroleumItem>",
                         "",
                         "",
                         "O",
                         "List of petroleum items included in the transaction. See ",
                        ],
                        vinculoType: "Petroleum Item Subrecord",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: Code values associated with the petroleum items may be different depending on a processor. To receive a full list of petroleum products supported by a particular processor, please contact gateway support team.",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "125",
                         "deviceSequenceNumber",
                         "String(5)",
                         "",
                         "",
                         "O",
                         "Sequence number of a vehicle.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Lodging *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "126",
                         "roomNumber",
                         "String(25)",
                         "",
                         "",
                         "O",
                         "Number of a guest's hotel room.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "127",
                         "guestName ",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "Name of a guest.",
                        ],
                        vinculoName: "Processor Rules GuestName",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field requires ",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "Processor Rules GuestName"
                      },
                      {
                        row: [
                         "128",
                         "folioNumber",
                         "String(15)",
                         "",
                         "",
                         "O",
                         "Number of a guest's folio.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "129",
                         "roomRateAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "C",
                         "Amount of a daily room rate..",
                        ],
                        vinculoUsage: "#Account_Data_is_used*"
                      },
                      {
                        row: [
                         "130",
                         "roomTaxAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of charged tax.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "131",
                         "lodgingChargeType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Type of a lodging.",
                        ],
                        vinculoType: "Lodging Charge Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "132",
                         "checkInDate",
                         "Date",
                         "",
                         "",
                         "C",
                         "Date of guest's check-in.",
                        ],
                        vinculoUsage: "#Account_Data_is_used*"
                      },
                      {
                        row: [
                         "133",
                         "checkOutDate",
                         "Date",
                         "",
                         "",
                         "C",
                         "Date of guest's check-out.",
                        ],
                        vinculoUsage: "#Account_Data_is_used*"
                      },
                      {
                        row: [
                         "134",
                         "duration",
                         "Integer",
                         "",
                         "duration in days",
                         "O",
                         "Duration of a guest's stay.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "135",
                         "specialProgramType",
                         "Enum",
                         "",
                         "",
                         "C",
                         "Special services available during the reservation and stay.",
                        ],
                        vinculoType: "Special Program Type",
                        vinculoUsage: "#Account_Data_is_used*",
                      },
                      {
                        row: [
                         "136",
                         "extraCharges",
                         "List<ExtraCharge>",
                         "",
                         "",
                         "O",
                         "List of extra charges included in the transaction. See ",
                        ],
                        vinculoType: "Lodging Extra Charge Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "137",
                         "extraChargesAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Sequence number of a vehicle.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Identity Verification *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "138",
                         "driverLicenseNumber",
                         "String(50)",
                         "",
                         "",
                         "O",
                         "Number of a bank account holder's driver license. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Identity Verification"
                      },
                      {
                        row: [
                         "139",
                         "driverLicenseState ",
                         "Enum",
                         "",
                         "",
                         "O",
                         "State where a bank account holder's driver license was issued. See ",
                        ],
                        vinculoType: "Driver License State",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Identity Verification"
                      },
                      {
                        row: [
                         "140",
                         "identityVerificationType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how a bank account holder's identity is verified. See integration notes for more information.",
                        ],
                        vinculoType: "Identity Verification Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Identity Verification"
                      },
                      {
                        row: [
                         "141",
                         "identityVerificationValue",
                         "String(4)",
                         "",
                         "",
                         "O",
                         "Value used to verify a bank account holder’s identity. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Identity Verification"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Terminals",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "142",
                         "transactionDate",
                         "Date",
                         "",
                         "yyyyMMddHHmmss",
                         "O",
                         "Date of the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "143",
                         "ticketNumber ",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a ticket. Provided by the gateway.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "144",
                         "ticketAmount",
                         "Integer",
                         "",
                         "cannot exceed total amount",
                         "O",
                         "Amount of a ticket.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "145",
                         "entryModeType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how payment information is entered. See ",
                        ],
                        vinculoType: "Entry Mode Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Terminal Data Entry"
                      },
                      {
                        row: [
                         "146",
                         "entryMediumType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how input source of payment information is provided. See integration notes for more information.",
                        ],
                        vinculoType: "Entry Medium Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Terminal Data Entry"
                      },
                      {
                        row: [
                         "147",
                         "entryPINModeType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines whether an input source supports PIN entry.",
                        ],
                        vinculoType: "Entry PIN Mode Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "148",
                         "terminalCapabilityMode",
                         "Collection",
                         "",
                         "values are pipe-delimited",
                         "O",
                         "Defines how a terminal reads payment information.",
                        ],
                        vinculoType: "Terminal Capability Mode Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "149",
                         "processingMode",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how the transaction is processed. See ",
                        ],
                        vinculoType: "Processing Mode Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Offline Transactions"
                      },
                      {
                        row: [
                         "150",
                         "holderVerificationModeType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how a payment card is verified.",
                        ],
                        vinculoType: "Holder Verification Mode Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "151",
                         "fallbackReasonCode",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines a reason why fallback has occurred. See ",
                        ],
                        vinculoType: "Fallback Reason Code Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Fallback Transactions"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Check 21",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "152",
                         "checkFrontImage",
                         "Image",
                         "",
                         "base64-encoded TIFF-image",
                         "O",
                         "Image of a front side of a check.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field is required for transactionIndustryType = C21."
                      },
                      {
                        row: [
                         "153",
                         "checkBackImage ",
                         "Image",
                         "",
                         "base64-encoded TIFF-image",
                         "O",
                         "Image of a back side of a check.  ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field is required for transactionIndustryType = C21."
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Car Rental",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "154",
                         "rentalName",
                         "String(20)",
                         "",
                         "",
                         "R",
                         "Name of a person who rents a car.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "155",
                         "rentalAddress ",
                         "String(26)",
                         "",
                         "",
                         "O",
                         "Address where a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "156",
                         "rentalCity",
                         "String(25)",
                         "",
                         "",
                         "O",
                         "City where a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "157",
                         "rentalState",
                         "String(3)",
                         "",
                         "",
                         "O",
                         "State where a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "158",
                         "rentalCountryCode",
                         "String(2)",
                         "",
                         "",
                         "O",
                         "Country where a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "159",
                         "rentalDate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "R",
                         "Date when a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "160",
                         "rentalTime",
                         "Integer",
                         "",
                         "hhMM",
                         "O",
                         "Time when a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "161",
                         "returnCity",
                         "String(25)",
                         "",
                         "",
                         "O",
                         "City where a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "162",
                         "returnState",
                         "String(3)",
                         "",
                         "",
                         "O",
                         "State where a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "163",
                         "returnCountryCode",
                         "String(2)",
                         "",
                         "",
                         "O",
                         "Country where a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "164",
                         "returnDate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "R",
                         "Date when a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "165",
                         "returnTime",
                         "Integer",
                         "",
                         "hhMM",
                         "O",
                         "Time when a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "166",
                         "agreementNumber",
                         "String(25)",
                         "",
                         "",
                         "R",
                         "Agreement number of a car rental.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "167",
                         "duration",
                         "Integer",
                         "",
                         "",
                         "R",
                         "Period of a car rental.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "168",
                         "specialProgramType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Special services provided as a part of car rental.",
                        ],
                        vinculoType: "Special Program Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "169",
                         "returnLocationCode",
                         "String(10)",
                         "",
                         "",
                         "O",
                         "Identifier of a location where a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "170",
                         "dailyRateAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Rent amount per day.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "171",
                         "mileRateAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Rent amount per mile.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "172",
                         "totalMiles",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Total mileage of a rented car.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "173",
                         "maxFreeMiles",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Total mileage of a rented car for rent period.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "174",
                         "insuranceAmount",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Amount of a car insurance.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "175",
                         "extraCharges",
                         "List<ExtraCharge>",
                         "",
                         "",
                         "O",
                         "List of extra charges included in the transaction. See ",
                        ],
                        vinculoType: "Car Rental Extra Charge Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "176",
                         "extraChargesAmount",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Amount of extra charges included in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "Conditions (Sale-Auth Request)",
                  tabla: {
                    titulotabla: "Account Data is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "holderName",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "accountNumber",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "accountAccessory",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "accountData",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "csc",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "cscIndicator",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "7",
                         "token",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Number is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "holderName",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "accountNumber",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "accountAccessory",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "accountData",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "csc",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "cscIndicator",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "7",
                         "token",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Token is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "holderName",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "accountNumber",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "accountAccessory",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "accountData",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "csc",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "cscIndicator",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "7",
                         "token",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "LodgingChargeType = H",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "roomRateAmount",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "checkInDate",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "checkOutDate",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "specialProgramType",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "LodgingChargeType ≠ H",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "roomRateAmount",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "checkInDate",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "checkOutDate",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "specialProgramType",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Splits field is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "splits",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "splitSchemaId",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "SplitSchemaId field is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "splits",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "splitSchemaId",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "Subrecords (Sale-Auth Request)",
                  tabla: {
                    titulotabla: "Split Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "accountId",
                         "Reference",
                         "",
                         "R",
                         "Reference to an account that is going to receive funds as a result of a split payment processing.",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: The submitted value can be either account ID/code or merchant ID/code. If merchantId or merchantCode value is submitted, the system will automatically use the first account under this merchant for further transaction processing."
                      },
                      {
                        row: [
                         "2",
                         "amount ",
                         "String(20)",
                         "amount in cents or rate with implied 4 decimal places",
                         "R",
                         "Amount or rate of the amount that is going to be received by an account as a result of a split payment processing. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Split Amount Format"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Split Item Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "code",
                         "String(12)",
                         "",
                         "SR",
                         "Unique identifier of an item within a submitter's system or inventory catalog.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "totalAmount ",
                         "Integer",
                         "amount in cents",
                         "SR",
                         "Total amount of an item that includes discount and tax.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "quantity ",
                         "Float",
                         "up to 4 decimal places",
                         "O",
                         "Number of item units purchased/refunded as a part of the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "description ",
                         "String(35)",
                         "",
                         "O",
                         "Description of an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "splits ",
                         "List<Split>",
                         "",
                         "C",
                         "List of split payment scenarios included in the transaction. See integration notes for more information.",
                        ],
                        vinculoType: "Split Subrecord",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "splitSchemaId ",
                         "Reference",
                         "",
                         "C",
                         "Reference to a split schema associated with the transaction. See integration notes for more information.",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Level III Item Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "code",
                         "String(12)",
                         "",
                         "R3",
                         "Unique identifier of an item within a submitter's system or inventory catalog. ",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "commodityCode ",
                         "String(12)",
                         "",
                         "R3",
                         "Standardized identifier of an item. Usually acquirer designated.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "itemNumber ",
                         "String(20)",
                         "",
                         "O",
                         "Sequential number of an item in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "quantity ",
                         "Float",
                         "up to 4 decimal places",
                         "R3",
                         "Number of item units purchased/refunded as a part of the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "totalAmount ",
                         "Integer",
                         "amount in cents",
                         "R3",
                         "Total amount of an item that includes discount and tax.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "unitCostAmount ",
                         "Float",
                         "amount in cents; up to 4 decimal places",
                         "R3",
                         "Cost of a single unit of an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "7",
                         "unitMeasure ",
                         "String(12)",
                         "",
                         "R3",
                         "Unit of measure used to quantify items purchased/refunded as a part of the transaction (for example, g, kg, lb, inch).",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "8",
                         "description ",
                         "String(35)",
                         "",
                         "R3",
                         "Description of an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "9",
                         "taxRate ",
                         "Integer",
                         "implied 2 decimal places (e.g. 10% = 1000)",
                         "R3",
                         "Tax rate charged on an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "10",
                         "taxAmount",
                         "Integer",
                         "amount in cents",
                         "R3",
                         "Tax amount charged on an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "11",
                         "taxIndicator",
                         "Enum",
                         "",
                         "R3",
                         "Defines how tax is handled for an item.",
                        ],
                        vinculoType: "Tax Indicator Type",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "12",
                         "taxCode",
                         "String(4)",
                         "",
                         "R3",
                         "Acquirer designated identifier of a tax charged on an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "13",
                         "discountRate",
                         "Integer",
                         "implied 2 decimal places (e.g. 150 = 1.5%; 1000 = 10%)",
                         "R3",
                         "Discount rate applied to an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "14",
                         "discountAmount",
                         "Integer",
                         "amount in cents",
                         "R3",
                         "Discount amount applied to an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "15",
                         "isCredit",
                         "Boolean",
                         "1 - item is returned, 0 - item is purchased; default - 0",
                         "R3",
                         "Indicates whether an item is purchased or returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Petroleum Item Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "code",
                         "String(12)",
                         "",
                         "R",
                         "Unique identifier of a petroleum item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "type ",
                         "Enum",
                         "",
                         "R",
                         "Type of a petroleum item. ",
                        ],
                        vinculoType: "Petroleum Product Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "quantity ",
                         "Float",
                         "up to 3 digits after point",
                         "R",
                         "Number of petroleum items.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "unitCostAmount ",
                         "Float",
                         "amount in cents, up to 3 decimal places",
                         "R",
                         "Cost of a single unit of a petroleum item.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Lodging Extra Charge Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "code",
                         "String(12)",
                         "",
                         "R",
                         "Unique identifier of an extra charged item. ",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "type ",
                         "Enum",
                         "",
                         "R",
                         "Type of an extra charged item.",
                        ],
                        vinculoType: "Extra Charge Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "amount ",
                         "Integer",
                         "amount in cents",
                         "R",
                         "Amount of an extra charged item.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Car Rental Extra Charge Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "code",
                         "String(12)",
                         "",
                         "R",
                         "Unique identifier of an extra charged item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "type ",
                         "Enum",
                         "",
                         "R",
                         "Type of an extra charged item.",
                        ],
                        vinculoType: "Extra Charge Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "amount ",
                         "Integer",
                         "amount in cents",
                         "R",
                         "Amount of an extra charged item.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "Sale-Auth (Response)",
                  tabla: {
                    titulotabla: "General Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "responseType",
                         "Enum",
                         "",
                         "",
                         "E",
                         "Type of the response.",
                        ],
                        vinculoType: "Sale",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "accountId",
                         "Reference",
                         "",
                         "",
                         "E",
                         "accountId used in the transaction.",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "3",
                         "terminalId",
                         "Reference",
                         "",
                         "",
                         "E",
                         "terminalId used in the transaction. See ",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called terminalCode. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Terminal ID",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "4",
                         "userCode",
                         "String(60)",
                         "",
                         "",
                         "E",
                         "userCode used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Information ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "5",
                         "accountType *",
                         "Enum",
                         "",
                         "",
                         "E",
                         "accountType used in the transaction.",
                        ],
                        vinculoType: "Account Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "extendedAccountType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Brand of a payment card or type of an account used in the transaction.",
                        ],
                        vinculoType: "Extended Account Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "7",
                         "accountNumberMasked",
                         "String(20)",
                         "",
                         "the first 1 and the last 4 digits of an account number",
                         "E",
                         "Masked number of a payment card or bank account associated with the transaction (e.g. 4********5655).",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: The field is present in the response if either token or accountNumber is submitted in the request."
                      },
                      {
                        row: [
                         "8",
                         "accountAccessory",
                         "String(14)",
                         "",
                         "",
                         "E",
                         "accountAccessory used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "9",
                         "accountData *",
                         "String(500)",
                         "",
                         "",
                         "C",
                         "EMV response from the issuer. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called trackData. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Account Data",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "10",
                         "holderName",
                         "String(150)",
                         "",
                         "",
                         "E",
                         "Normalized holderName value used in the transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Holder Name Normalization Rules"
                      },
                      {
                        row: [
                         "11",
                         "token",
                         "String(40)",
                         "",
                         "",
                         "O",
                         "Token associated with a payment card or bank account. Present if tokenization service is enabled.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "12",
                         "balance",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "For debit and gift cards only. Balance remaining on a card used for the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "13",
                         "currencyCode",
                         "String(3)",
                         "",
                         "",
                         "R",
                         "Currency used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Transaction Information ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "14",
                         "amount *",
                         "Integer",
                         "",
                         "amount in cents",
                         "R",
                         "Total authorized amount.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "15",
                         "originalAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R",
                         "Amount provided in the request. Differs from amount value if partial authorization has occurred.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "16",
                         "cashbackAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "E",
                         "cashbackAmount used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "17",
                         "taxAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "E",
                         "taxAmount used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "18",
                         "tipAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "E",
                         "For restaurant industry only. tipAmount used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "19",
                         "transactionDate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "R",
                         "Date of the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "20",
                         "transactionCode",
                         "String(60)",
                         "",
                         "",
                         "E",
                         "transactionCode used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "21",
                         "providerTransactionCode",
                         "String(25)",
                         "",
                         "",
                         "O",
                         "Identifier of the transaction in a processor's system. Provided by the gateway.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "22",
                         "transactionId",
                         "Long",
                         "",
                         "",
                         "R",
                         "Identifier of the transaction. Provided by the gateway. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called referenceNumber. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Record ID",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "23",
                         "providerTransactionId",
                         "String(50)",
                         "",
                         "",
                         "O",
                         "Identifier of the transaction in the processor's system. Provided by the processor.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called providerReferenceNumber. See integration notes for more information.",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "24",
                         "requestId",
                         "String(40)",
                         "",
                         "",
                         "O",
                         "Identifier of the API request. Provided by the gateway.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "25",
                         "approvalCode *",
                         "String(100)",
                         "",
                         "",
                         "O",
                         "Authorization number given by a cardholder's bank (ex processorCode). Often shown on a credit card receipt.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "26",
                         "cycleCode",
                         "Long",
                         "",
                         "if empty, no cycle was opened",
                         "R",
                         "Identifier of a retail transaction cycle associated with the transaction. Refers to real-time processing only.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "27",
                         "rewardNumber",
                         "String(20)",
                         "ALPHANUMERIC",
                         "",
                         "E",
                         "Number of bonus account.",
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoPattern: "Validation Patterns Types"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Extended Logic ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "28",
                         "networkTransactionId *",
                         "String(30)",
                         "",
                         "",
                         "O",
                         "Identifier of the first transaction in a sequence of payments. Provided by a payment network. Used for installment and recurring payments only (for transactionCategoryType = I or R). See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Stored Credentials"
                      },
                      {
                        row: [
                         "29",
                         "splits",
                         "List<Split>",
                         "",
                         "amount in cents",
                         "C",
                         "List of split payment scenarios included in the transaction. See ",
                        ],
                        vinculoType: "Split Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "30",
                         "items",
                         "List<SplitItem>",
                         "",
                         "",
                         "C",
                         "List of items included in the split transaction. See integration notes for more information.",
                        ],
                        vinculoType: "Split Item Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Billing Address Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "31",
                         "email",
                         "String(100)",
                         "",
                         "",
                         "E",
                         "Email associated with a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Response Codes Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "32",
                         "warningCode",
                         "String(1)",
                         "",
                         "",
                         "O",
                         "Code warning that non-criticial issue with the transaction is present. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Warning Code"
                      },
                      {
                        row: [
                         "33",
                         "responseCode *",
                         "String(5)",
                         "",
                         "",
                         "R",
                         "Response code returned by the gateway. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "https://unipaygateway.info/codes/your_gateway/processing_codes*"
                      },
                      {
                        row: [
                         "34",
                         "responseMessage *",
                         "String(255)",
                         "",
                         "",
                         "R",
                         "Response message returned by the gateway. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "https://unipaygateway.info/codes/your_gateway/processing_codes*"
                      },
                      {
                        row: [
                         "35",
                         "providerResponseCode",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "Response code returned by an underlying processor/bank. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Provider Response Data"
                      },
                      {
                        row: [
                         "36",
                         "providerResponseMessage",
                         "String(260)",
                         "",
                         "",
                         "O",
                         "Response message returned by an underlying processor/bank. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Provider Response Data"
                      },
                      {
                        row: [
                         "37",
                         "avsResponseCode *",
                         "String(2)",
                         "",
                         "",
                         "O",
                         "AVS response code returned by the gateway (if address information has been provided). See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "https://unipaygateway.info/codes/your_gateway/processing_codes#AVS_Response_Codes*"
                      },
                      {
                        row: [
                         "38",
                         "providerAvsResponseCode *",
                         "String(5)",
                         "",
                         "",
                         "O",
                         "AVS response code returned by an underlying processor/bank. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Provider Response Data"
                      },
                      {
                        row: [
                         "39",
                         "cscResponseCode *",
                         "String(1)",
                         "",
                         "",
                         "O",
                         "CSC response code returned by the gateway (if CSC has been provided). See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "https://unipaygateway.info/codes/your_gateway/processing_codes#CSC_Response_Codes*"
                      },
                      {
                        row: [
                         "40",
                         "providerCscResponseCode",
                         "String(3)",
                         "",
                         "",
                         "O",
                         "CSC response code returned by an underlying processor/bank. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Provider Response Data"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Interac *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "41",
                         "macValue",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "For Canadian transactions only. Value of a message authentication code (MAC). After submitted, the value of the field is not stored in the database.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "EBT Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "42",
                         "ebtFoodStampBalance",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Food stamp balance remaining on an EBT card.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "43",
                         "ebtCashBenefitBalance",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Cash benefit balance remaining on an EBT card.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Terminals ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "44",
                         "ticketAmount",
                         "Integer",
                         "",
                         "cannot exceed total amount",
                         "E",
                         "ticketAmount used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "45",
                         "ticketNumber",
                         "String(20)",
                         "",
                         "",
                         "E",
                         "ticketNumber used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "46",
                         "holderVerificationModeType",
                         "Enum",
                         "",
                         "",
                         "E",
                         "holderVerificationModeType used in the transaction.",
                        ],
                        vinculoType: "Holder Verification Mode Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "47",
                         "entryModeType",
                         "Enum",
                         "",
                         "",
                         "E",
                         "entryModeType used in the transaction.",
                        ],
                        vinculoType: "Entry Mode Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "48",
                         "entryMediumType",
                         "Enum",
                         "",
                         "",
                         "E",
                         "entryMediumType used in the transaction.",
                        ],
                        vinculoType: "Entry Medium Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "49",
                         "cardApplicationName",
                         "String(20)",
                         "",
                         "Application Preferred Name (tag 9F12)",
                         "O",
                         "Name of an EMV application associated with a chip that has been used to process the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "50",
                         "receiptTagData *",
                         "String(35)",
                         "",
                         "",
                         "R",
                         "EMV tags associated with the transaction and shown on a receipt. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Receipt Requirements"
                      },
                      {
                        row: [
                         "51",
                         "paymentNetworkName",
                         "String(10)",
                         "",
                         "",
                         "O",
                         "Name of the network associated with a payment card.",
                        ],
                        vinculoName: "Processor Rules Payment Network Name",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field requires ",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "Processor Rules Payment Network Name"
                      },
                      {
                        row: [
                         "52",
                         "terminalMessage",
                         "String(100)",
                         "",
                         "",
                         "I",
                         "System field for exchange of control messages between TMS system and a terminal. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Terminal Message"
                      }
                    ]
                  }
                },
                {
                  titulo: "Subrecords (Sale-Auth Request)",
                  tabla: {
                    titulotabla: "Split Subrecord ",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "accountId",
                         "Reference",
                         "",
                         "E",
                         "Reference to an account that has received funds as a result of a split payment processing.",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "2",
                         "transactionId ",
                         "String(60)",
                         "",
                         "R",
                         "Identifier of the split transaction. Provided by the gateway. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Record ID"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Split Item Subrecord ",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "itemId",
                         "String(60)",
                         "",
                         "SR",
                         "Identifier of an item. Provided by the gateway. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Record ID"
                      },
                      {
                        row: [
                         "2",
                         "code ",
                         "String(12)",
                         "",
                         "E",
                         "Identifier of an item within a submitter's system.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "splits ",
                         "List<Split>",
                         "",
                         "C",
                         "List of split payment scenarios included in the transaction.",
                        ],
                        vinculoType: "Split Subrecord",
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                }

              ],
              tablasexample: [
                {
                  titulo: "Examples (Sale-Auth)",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&transactionIndustryType=RE&transactionCode=0000000001&amount=5000&holderType=P&holderName=John+Smith&street=12+Main+St&city=Denver&state=CO&zipCode=30301&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&customerAccountCode=0000000001",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "street",
                        value: "12 Main St",
                      },
                      {
                        key: "city",
                        value: "Denver",
                      },
                      {
                        key: "state",
                        value: "CO",
                      },
                      {
                        key: "zipCode",
                        value: "30301",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      }                      
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response",
                    path: "",
                    parameters: "responseType=sale-auth&transactionId=123456&transactionDate=20101027&transactionCode=0000000001&responseCode=A01&responseMessage=Approved&approvalCode=TAS408&avsResponseCode=N&accountId=2001&cycleCode=8765&accountType=R&extendedAccountType=VC&providerResponseCode=AA&providerResponseMessage=Approved",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "transactionDate",
                        value: "20101027",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "approvalCode",
                        value: "TAS408",
                      },
                      {
                        key: "avsResponseCode",
                        value: "N",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "providerResponseCode",
                        value: "AA",
                      },
                      {
                        key: "providerResponseMessage",
                        value: "Approved",
                      }                   
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request for Petroleum",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&accountType=L&transactionIndustryType=PT&transactionCode=1212121&accountData=!4F07A0000000031010500B564953412043524544495457114761739001010119D151220117589893895A0847617390010101195F201A56495341204143515549524552205445535420434152442030315F24031512315F280208405F300202015F3401019F120F4352454449544F20444520564953419F1A0208409F1E083830323437363932&amount=5000&zipCode=00000&customerAccountCode=0000000001&driverNumber=123456&odometrReading=10000&terminalType=POS&petroleumItems=(code=001;type=F;quantity=1;unitCostAmount=2000)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "L",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "PT",
                      },
                      {
                        key: "transactionCode",
                        value: "1212121",
                      },
                      {
                        key: "accountData",
                        value: "!4F07A0000000031010500B564953412043524544495457114761739001010119D151220117589893895A0847617390010101195F201A56495341204143515549524552205445535420434152442030315F24031512315F280208405F300202015F3401019F120F4352454449544F20444520564953419F1A0208409F1E083830323437363932",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "zipCode",
                        value: "00000",
                      },
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      },
                      {
                        key: "driverNumber",
                        value: "123456",
                      },
                      {
                        key: "odometrReading",
                        value: "10000",
                      },
                      {
                        key: "terminalType",
                        value: "POS",
                      },
                      {
                        key: "petroleumItems",
                        value: "(code=001;type=F;quantity=1;unitCostAmount=2000)",
                      }                    
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response for Petroleum",
                    path: "",
                    parameters: "responseType=sale-auth&transactionId=123456&transactionDate=20151110&transactionCode=1212121&responseCode=A01&responseMessage=Approved&approvalCode=517885238&amount=1500&originalAmount=1500&accountAccessory=0422&accountId=2001&cycleCode=192&accountData=!8A023030910A35373537353735373537&accountNumberMasked=4***********0119&accountType=L&cardApplicationName=CREDITO+DE+VISA&currencyCode=USD&entryMediumType=CC&entryModeType=MX&extendedAccountType=VC&receiptTagData=4F:A0000000031010;95:;9F10:;9B:;91:35373537353735373537;8A:00&token=VC10000000000102060119",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "transactionDate",
                        value: "20151110",
                      },
                      {
                        key: "transactionCode",
                        value: "1212121",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "approvalCode",
                        value: "517885238",
                      },
                      {
                        key: "amount",
                        value: "1500",
                      },
                      {
                        key: "originalAmount",
                        value: "1500",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "cycleCode",
                        value: "192",
                      },
                      {
                        key: "accountData",
                        value: "!8A023030910A35373537353735373537",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "4***********0119",
                      },
                      {
                        key: "accountType",
                        value: "L",
                      },
                      {
                        key: "cardApplicationName",
                        value: "CREDITO DE VISA",
                      },
                      {
                        key: "currencyCode",
                        value: "USD",
                      },
                      {
                        key: "entryMediumType",
                        value: "CC",
                      },
                      {
                        key: "entryModeType	",
                        value: "MX",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "receiptTagData",
                        value: "4F:A0000000031010;95:;9F10:;9B:;91:35373537353735373537;8A:00",
                      },
                      {
                        key: "token",
                        value: "VC10000000000102060119",
                      }                       
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with Track Data",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&transactionIndustryType=RE&amount=5000&accountType=R&accountData=(~2001)021301000F3D0000%*4111********1111^SMITH/JOHN^*****************************?*8D40AD26E14C98F73B0210CD5F18A62ACF2B655EABC19BC10DAEEB870B42D6EADC20CE84E3677286B56D6F1CB7C4276C6BF10A91DB9E5947CA68AFD5884C2737312165237673628FB321C9B5F5094594C71F123CE8980E3482F3CB89629949003A0002E000E759D903&customerAccountCode=0000000001&transactionCode=0000000001",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountData",
                        value: "(~2001)021301000F3D0000%*4111********1111^SMITH/JOHN^*****************************?*8D40AD26E14C98F73B0210CD5F18A62ACF2B655EABC19BC10DAEEB870B42D6EADC20CE84E3677286B56D6F1CB7C4276C6BF10A91DB9E5947CA68AFD5884C2737312165237673628FB321C9B5F5094594C71F123CE8980E3482F3CB89629949003A0002E000E759D903",
                      },
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      }                  
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response with Track Data",
                    path: "",
                    parameters: "responseType=sale-auth&transactionId=123456&accountId=2001&transactionDate=20110316&responseCode=A01&responseMessage=Approved&transactionCode=0000000001&avsResponseCode=N&approvalCode=TAS326&token=XVC01P0000000084632147254611114111001111&cycleCode=8765&accountType=R&extendedAccountType=VC",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },                      
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionDate",
                        value: "20110316",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "avsResponseCode",
                        value: "N",
                      },
                      {
                        key: "approvalCode",
                        value: "TAS326",
                      },
                      {
                        key: "token",
                        value: "XVC01P0000000084632147254611114111001111",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      }                   
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with Proxy Number",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&transactionIndustryType=RE&amount=1500&accountType=R&accountNumber=*40e0525f-fa12-4218-a708-77465acf6de6+&accountAccessory=0422",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "amount",
                        value: "1500",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "*40e0525f-fa12-4218-a708-77465acf6de6",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      }                
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response with Proxy Number",
                    path: "",
                    parameters: "responseType=sale-auth&extendedAccountType=VC&holderName=John+Smith&accountType=R&accountNumberMasked=4***********1111&cycleCode=892",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "holderName",
                        value: "John+Smith",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "4***********1111",
                      },
                      {
                        key: "cycleCode",
                        value: "892",
                      }           
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request for Lodging",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&terminalId=001&accountId=2001&transactionIndustryType=LD&transactionCode=0000000001&amount=5000&holderType=P&holderName=John+Smith&accountType=R&accountData=!4F07A0000000041010500A4D41535445524341524457115413330089020110D2512601079360805F820230008407A0000000041010950500000000009A031412309B02E8009C01005F2A0208405F3401039F02060000000015009F0607A00000000410109F090200029F10120210A00000000000000000000000000000FF9F120A4D6173746572436172649F1A0208409F1E0838303234373639329F21031107349F2608264E8FDAE6E596D69F2701809F3303E0B8C89F34034103029F3501229F360200019F37049B82EA999F3901059F4005F000F0A0019F4104000000139F530152&isPartialAuthorization=1&customerAccountCode=0000000001&taxIndicator=N&roomNumber=1515&folioNumber=333&roomRateAmount=1500&roomTaxAmount=150&lodgingChargeType=H&checkInDate=2013-08-15&checkOutDate=2013-08-18&duration=3&specialProgramType=NC&extraChargesAmount=100&extraCharges=(code=001;type=R;amount=100)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "terminalId",
                        value: "001",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "LD",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountData",
                        value: "!4F07A0000000041010500A4D41535445524341524457115413330089020110D2512601079360805F820230008407A0000000041010950500000000009A031412309B02E8009C01005F2A0208405F3401039F02060000000015009F0607A00000000410109F090200029F10120210A00000000000000000000000000000FF9F120A4D6173746572436172649F1A0208409F1E0838303234373639329F21031107349F2608264E8FDAE6E596D69F2701809F3303E0B8C89F34034103029F3501229F360200019F37049B82EA999F3901059F4005F000F0A0019F4104000000139F530152",
                      },
                      {
                        key: "isPartialAuthorization",
                        value: "1",
                      },
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      },
                      {
                        key: "taxIndicator",
                        value: "N",
                      },
                      {
                        key: "roomNumber",
                        value: "1515",
                      },
                      {
                        key: "folioNumber",
                        value: "333",
                      },
                      {
                        key: "roomRateAmount",
                        value: "1500",
                      },
                      {
                        key: "roomTaxAmount",
                        value: "150",
                      },
                      {
                        key: "lodgingChargeType",
                        value: "H",
                      },
                      {
                        key: "checkInDate",
                        value: "2013-08-15",
                      },
                      {
                        key: "checkOutDate",
                        value: "2013-08-18",
                      },
                      {
                        key: "duration",
                        value: "3",
                      },
                      {
                        key: "specialProgramType",
                        value: "NC",
                      },
                      {
                        key: "extraChargesAmount",
                        value: "100",
                      },
                      {
                        key: "extraCharges",
                        value: "(code=001;type=R;amount=100)",
                      },                                       
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response for Lodging",
                    path: "",
                    parameters: "responseType=sale-auth&transactionId=123456&accountId=2001&transactionDate=20151110&responseCode=A01&responseMessage=Approved&transactionCode=0000000001&cycleCode=192&approvalCode=1261757124&accountAccessory=0422&accountData=!8A023030910A35373537353735373537&accountNumberMasked=5***********0110&accountType=R&amount=1500&cardApplicationName=MasterCard&currencyCode=USD&entryMediumType=CC&entryModeType=MX&extendedAccountType=MC&holderName=John+Smith&originalAmount=1500&receiptTagData=4F:A0000000041010;95:0000000000;9F10:0210A00000000000000000000000000000FF;9B:E800;91:35373537353735373537;8A:00&terminalId=001&token=MC10000000000102150110",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionDate",
                        value: "20151110",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "cycleCode",
                        value: "192",
                      },
                      {
                        key: "approvalCode",
                        value: "1261757124",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "accountData",
                        value: "!8A023030910A35373537353735373537",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "5***********0110",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "amount",
                        value: "1500",
                      },
                      {
                        key: "cardApplicationName",
                        value: "MasterCard",
                      },
                      {
                        key: "currencyCode",
                        value: "USD",
                      },
                      {
                        key: "entryMediumType",
                        value: "CC",
                      },
                      {
                        key: "entryModeType",
                        value: "MX",
                      },
                      {
                        key: "extendedAccountType",
                        value: "MC",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "originalAmount",
                        value: "1500",
                      },
                      {
                        key: "receiptTagData",
                        value: "4F:A0000000041010;95:0000000000;9F10:0210A00000000000000000000000000000FF;9B:E800;91:35373537353735373537;8A:00",
                      },
                      {
                        key: "terminalId",
                        value: "001",
                      },
                      {
                        key: "token",
                        value: "MC10000000000102150110",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request for Car Rental",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=MyUserName&password=myP%40ssword&accountId=2001&transactionIndustryType=PT&amount=2300&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&rentalName=rentalName&rentalDate=20150609&returnDate=20150609&agreementNumber=agreementNumber&duration=10&specialProgramType=AD&rentalCity=City&rentalState=AL&rentalCountryCode=US&rentalTime=0930&returnCity=returnCity&returnState=OT&returnCountryCode=CA&returnTime=1930&returnLocationCode=10&dailyRateAmount=200&mileRateAmount=300&totalMiles=500&maxFreeMiles=600&insuranceAmount=800&extraChargesAmount=500&extraCharges=(code=S01;type=S;amount=1000)(code=M01;type=M;amount=500)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "MyUserName",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "PT",
                      },
                      {
                        key: "amount",
                        value: "2300",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "rentalName",
                        value: "rentalName",
                      },
                      {
                        key: "returnDate",
                        value: "20150609",
                      },
                      {
                        key: "agreementNumber",
                        value: "agreementNumber",
                      },
                      {
                        key: "duration",
                        value: "10",
                      },
                      {
                        key: "specialProgramType",
                        value: "AD",
                      },
                      {
                        key: "rentalCity",
                        value: "City",
                      }, 
                      {
                        key: "rentalState",
                        value: "AL",
                      },   
                      {
                        key: "rentalCountryCode",
                        value: "US",
                      },   
                      {
                        key: "rentalTime",
                        value: "0930",
                      },   
                      {
                        key: "returnCity",
                        value: "returnCity",
                      },    
                      {
                        key: "returnState",
                        value: "OT",
                      },   
                      {
                        key: "returnCountryCode",
                        value: "CA",
                      },   
                      {
                        key: "returnTime",
                        value: "1930",
                      },   
                      {
                        key: "returnLocationCode",
                        value: "10",
                      },   
                      {
                        key: "dailyRateAmount",
                        value: "200",
                      },   
                      {
                        key: "mileRateAmount",
                        value: "300",
                      },   
                      {
                        key: "totalMiles",
                        value: "500",
                      }, 
                      {
                        key: "maxFreeMiles",
                        value: "600",
                      }, 
                      {
                        key: "insuranceAmount",
                        value: "800",
                      }, 
                      {
                        key: "extraChargesAmount",
                        value: "500",
                      }, 
                      {
                        key: "extraCharges",
                        value: "(code=S01;type=S;amount=1000)(code=M01;type=M;amount=500)",
                      },                    
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response for Car Rental",
                    path: "",
                    parameters: "responseType=sale-auth&cycleCode=8765&accountType=R",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      }       
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with Items",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&csc=111&transactionIndustryType=RE&amount=120000&holderName=John+Smith&items=(code=001;quantity=1;description=t-shirt;totalAmount=50000)(code=002;quantity=1;description=jeans;totalAmount=70000)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "csc",
                        value: "111",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "amount",
                        value: "120000",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "items",
                        value: "(code=001;quantity=1;description=t-shirt;totalAmount=50000)(code=002;quantity=1;description=jeans;totalAmount=70000)",
                      }                 
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response with Items",
                    path: "",
                    parameters: "responseType=sale-auth&accountId=2001&accountAccessory=0422&accountNumberMasked=4********1111&responseCode=A01&holderName=John+Smith&amount=120000&responseMessage=Approved&currencyCode=USD&transactionDate=20180131&transactionId=123456&cycleCode=09876549&accountType=R&extendedAccountType=VC&items=(code=001;quantity=1;description=t-shirt;totalAmount=50000)(code=002;quantity=1;description=jeans;totalAmount=70000)",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "4********1111",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "amount",
                        value: "120000",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "currencyCode",
                        value: "USD",
                      },
                      {
                        key: "transactionDate",
                        value: "20180131",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "cycleCode",
                        value: "09876549",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "items",
                        value: "(code=001;quantity=1;description=t-shirt;totalAmount=50000)(code=002;quantity=1;description=jeans;totalAmount=70000)",
                      }                   
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with EMV Data",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&accountType=R&amount=1500&holderType=P&holderName=John+Smith&transactionIndustryType=RE&accountData=!4F07A0000000041010500A4D41535445524341524457115413330089020110D2512601079360805F820230008407A0000000041010950500000000009A031412309B02E8009C01005F2A0208405F3401039F02060000000015009F0607A00000000410109F090200029F10120210A00000000000000000000000000000FF9F120A4D6173746572436172649F1A0208409F1E0838303234373639329F21031107349F2608264E8FDAE6E596D69F2701809F3303E0B8C89F34034103029F3501229F360200019F37049B82EA999F3901059F4005F000F0A0019F4104000000139F530152",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "amount",
                        value: "1500",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "accountData",
                        value: "!4F07A0000000041010500A4D41535445524341524457115413330089020110D2512601079360805F820230008407A0000000041010950500000000009A031412309B02E8009C01005F2A0208405F3401039F02060000000015009F0607A00000000410109F090200029F10120210A00000000000000000000000000000FF9F120A4D6173746572436172649F1A0208409F1E0838303234373639329F21031107349F2608264E8FDAE6E596D69F2701809F3303E0B8C89F34034103029F3501229F360200019F37049B82EA999F3901059F4005F000F0A0019F4104000000139F530152",
                      }                  
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response with EMV Data",
                    path: "",
                    parameters: "responseType=sale-auth&transactionId=123456&entryModeType=MX&cardApplicationName=MasterCard&responseCode=A01&amount=1500&responseMessage=Approved&originalAmount=1500&extendedAccountType=MC&token=MC10000000000102150110&accountAccessory=0422&entryMediumType=CC&currencyCode=USD&accountData=!8A023030910A35373537353735373537&approvalCode=524201530&transactionDate=20151105&holderName=John+Smith&warningCode=00&accountId=2001&accountType=R&receiptTagData=4F:A0000000041010;95:0000000000;9F10:0210A00000000000000000000000000000FF;9B:E800;91:35373537353735373537;8A:00&accountNumberMasked=5***********0110&cycleCode=892",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "entryModeType",
                        value: "MX",
                      },
                      {
                        key: "cardApplicationName",
                        value: "MasterCard",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "amount",
                        value: "1500",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "originalAmount",
                        value: "1500",
                      },
                      {
                        key: "extendedAccountType",
                        value: "MC",
                      },
                      {
                        key: "token",
                        value: "MC10000000000102150110",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "entryMediumType",
                        value: "CC",
                      },
                      {
                        key: "currencyCode",
                        value: "USD",
                      },
                      {
                        key: "accountData",
                        value: "!8A023030910A35373537353735373537",
                      },
                      {
                        key: "approvalCode",
                        value: "524201530",
                      },
                      {
                        key: "transactionDate",
                        value: "20151105",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "warningCode",
                        value: "00",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "receiptTagData",
                        value: "4F:A0000000041010;95:0000000000;9F10:0210A00000000000000000000000000000FF;9B:E800;91:35373537353735373537;8A:00",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "5***********0110",
                      },
                      {
                        key: "cycleCode",
                        value: "892",
                      },                   
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with PIN Debit",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&holderName=John+Smith&accountNumber=4111111111111111&accountAccessory=0422",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      }          
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response with PIN Debit",
                    path: "",
                    parameters: "responseType=sale-auth&cycleCode=8765&accountType=D&extendedAccountType=VD",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "D",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VD",
                      }          
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with PIN Debit",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      }       
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request for Voice Authorization",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&password=myP%40ssword",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      }         
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response for Voice Authorization",
                    path: "",
                    parameters: "responseType=sale-auth&transactionId=123456&cycleCode=8765&accountType=R&extendedAccountType=VC",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      }     
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request via HPP",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&amount=5000&notifyURL=http%3A%2F%2Fyour.domain.com%2Fnotify&accountType=R&transactionIndustryType=RE&accountAccessory=0435&holderName=John+Smith",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },  
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "notifyURL",
                        value: "http%3A%2F%2Fyour.domain.com%2Fnotify",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "accountAccessory",
                        value: "0435",
                      },    
                      {
                        key: "holderName",
                        value: "John+Smith",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response via HPP",
                    path: "",
                    parameters: "If you are submitting a sale-auth transaction via hosted payment page, Secure Payment Form will be opened as a response and your screen will look similar to the screenshot.",
                    body: [
                      
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with Processing Mode",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&terminalId=001&accountType=R&transactionIndustryType=RE&amount=5000&processingMode=request&notifyURL=http://your.domain.com/notify",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },  
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "terminalId",
                        value: "001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },    
                      {
                        key: "processingMode",
                        value: "request",
                      },    
                      {
                        key: "notifyURL",
                        value: "http://your.domain.com/notify",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response with Processing Mode",
                    path: "",
                    parameters: "responseType=sale-auth&responseCode=A10&responseMessage=Request+Accepted&accountId=2001&transactionCode=123456&cycleCode=8765&requestId=r756ddb8e-66ed-4ec9-833e-9fea835ec0b7",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "responseCode",
                        value: "A10",
                      },
                      {
                        key: "responseMessage",
                        value: "Request+Accepted",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionCode",
                        value: "123456",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "requestId",
                        value: "r756ddb8e-66ed-4ec9-833e-9fea835ec0b7",
                      },
                           
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with Multiple Vendors for Marketplace",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUserName&password=myP%40ssword&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&terminalId=001&amount=500&transactionIndustryType=RE&distributions=(code=1;amount=200)(code=6;amount=300)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },  
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "terminalId",
                        value: "001",
                      },
                      {
                        key: "amount",
                        value: "500",
                      },    
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },    
                      {
                        key: "distributions",
                        value: "(code=1;amount=200)(code=6;amount=300)",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response with Multiple Vendors for Marketplace",
                    path: "",
                    parameters: "responseType=sale-auth&transactionId=123456&responseCode=A01&amount=500&responseMessage=Approved&originalAmount=500&extendedAccountType=VC&token=VC84632147254611111111&accountAccessory=0422&approvalCode=1754486860&transactionDate=20150923&warningCode=00&accountId=2001&accountType=R&accountNumberMasked=4***********1111&cycleCode=8765",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "amount",
                        value: "500",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "originalAmount",
                        value: "500",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "token",
                        value: "VC84632147254611111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "approvalCode",
                        value: "1754486860",
                      },
                      {
                        key: "transactionDate",
                        value: "20150923",
                      },
                      {
                        key: "warningCode",
                        value: "00",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "4***********1111",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      }  
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with Convenience Fee",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&transactionIndustryType=RE&transactionCode=0000000001&amount=5000&holderType=P&holderName=John+Smith&street=12+Main+St&city=Denver&state=CO&zipCode=30301&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&customerAccountCode=0000000001&feeAmount=500",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "street",
                        value: "12 Main St",
                      },
                      {
                        key: "city",
                        value: "Denver",
                      },
                      {
                        key: "state",
                        value: "CO",
                      },
                      {
                        key: "zipCode",
                        value: "30301",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      }, 
                      {
                        key: "accountAccessory",
                        value: "0422",
                      }, 
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      },
                      {
                        key: "feeAmount",
                        value: "500",
                      }                  
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response with Convenience Fee",
                    path: "",
                    parameters: "responseType=sale-auth&transactionId=123456&transactionDate=20101027&transactionCode=0000000001&responseCode=A01&responseMessage=Approved&approvalCode=TAS408&avsResponseCode=N&accountId=2001&cycleCode=8765&accountType=R&extendedAccountType=VC",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "transactionDate",
                        value: "20101027",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "approvalCode",
                        value: "TAS408",
                      },
                      {
                        key: "avsResponseCode",
                        value: "N",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      }                 
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with EBT card",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&transactionIndustryType=RE&transactionCode=0000000001&amount=5000&holderType=P&holderName=John+Smith&street=12+Main+St&city=Denver&state=CO&zipCode=30301&accountType=F&accountNumber=4111111111111111&accountAccessory=0422&customerAccountCode=0000000001&voucherNumber=123456789876543",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "street",
                        value: "12 Main St",
                      },
                      {
                        key: "city",
                        value: "Denver",
                      },
                      {
                        key: "state",
                        value: "CO",
                      },
                      {
                        key: "zipCode",
                        value: "30301",
                      },   
                      {
                        key: "accountType",
                        value: "F",
                      }, 
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      }, 
                      {
                        key: "accountAccessory",
                        value: "0422",
                      }, 
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      },  
                      {
                        key: "voucherNumber",
                        value: "123456789876543",
                      }              
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response with EBT card",
                    path: "",
                    parameters: "responseType=sale-auth&transactionId=123456&transactionDate=20101027&transactionCode=0000000001&responseCode=A01&responseMessage=Approved&approvalCode=TAS408&avsResponseCode=N&accountId=2001&cycleCode=8765&accountType=F&extendedAccountType=EF",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "transactionDate",
                        value: "20101027",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "approvalCode",
                        value: "TAS408",
                      },
                      {
                        key: "avsResponseCode",
                        value: "N",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "EF",
                      }               
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with Split Payments",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&holderType=P&csc=123&amount=13000&transactionIndustryType=RE&splits=(accountId=2001;amount=7000)(accountId=3001;amount=2000)(accountId=4001;amount=1000)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "csc",
                        value: "123",
                      },
                      {
                        key: "amount",
                        value: "13000",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "splits",
                        value: "(accountId=2001;amount=7000)(accountId=3001;amount=2000)(accountId=4001;amount=1000)",
                      }             
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response with Split Payments",
                    path: "",
                    parameters: "responseType=sale-auth&accountId=2001&accountType=R&extendedAccountType=VC&amount=13000&originalAmount=13000&transactionDate=20170425&transactionId=123456&cycleCode=8765&splits=(accountId=2001;transactionId=S10001)(accountId=3001;transactionId=S10002)(accountId=4001;transactionId=S10003)&responseCode=A01&responseMessage=Approved",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "amount",
                        value: "13000",
                      },
                      {
                        key: "originalAmount",
                        value: "13000",
                      },
                      {
                        key: "transactionDate",
                        value: "20170425",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "splits",
                        value: "(accountId=2001;transactionId=S10001)(accountId=3001;transactionId=S10002)(accountId=4001;transactionId=S10003)",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      }                  
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale-Auth Request with Split Payments and Items",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&holderType=P&csc=123&amount=13000&transactionIndustryType=RE&items=(code=001;description=t-shirt;quantity=1;totalAmount=50000;splits=(accountId=2001;amount=7000)(accountId=3001;amount=2000))(code=002;description=jeans;quantity=1;totalAmount=80000;splits=(accountId=2001;amount=9000)(accountId=3001;amount=2000)(accountId=4001;amount=3000))",
                    body: [
                      {
                        key: "requestType",
                        value: "sale-auth",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "csc",
                        value: "123",
                      },
                      {
                        key: "amount",
                        value: "13000",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "items",
                        value: "(code=001;description=t-shirt;quantity=1;totalAmount=50000;splits=(accountId=2001;amount=7000)(accountId=3001;amount=2000))(code=002;description=jeans;quantity=1;totalAmount=80000;splits=(accountId=2001;amount=9000)(accountId=3001;amount=2000)(accountId=4001;amount=3000))",
                      }             
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale-Auth Response with Split Payments and Items",
                    path: "",
                    parameters: "responseType=sale-auth&accountId=2001&accountType=R&extendedAccountType=VC&amount=13000&originalAmount=13000&transactionDate=20170425&transactionId=123456&cycleCode=8765&items=(code=001;itemId=I123;splits=(accountId=2001;transactionId=S10001)(accountId=3001;transactionId=S10002))(code=002;itemId=I124;splits=(accountId=3001;transactionId=S10003)(accountId=4001;transactionId=S10004))&responseCode=A01&responseMessage=Approved",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "amount",
                        value: "13000",
                      },
                      {
                        key: "originalAmount",
                        value: "13000",
                      },
                      {
                        key: "transactionDate",
                        value: "20170425",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "items",
                        value: "(code=001;itemId=I123;splits=(accountId=2001;transactionId=S10001)(accountId=3001;transactionId=S10002))(code=002;itemId=I124;splits=(accountId=3001;transactionId=S10003)(accountId=4001;transactionId=S10004))",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      }                  
                    ]
                  }
                },
              ]

            },

            {//////////////////////////////////////////
              titulo: "Get-Configuration",
              notas: "",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "",
                arraytitulo4: [
                ]
                

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: " ",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "",
                arraytitulo4: [
                ],

              }],
              tablas: [
                {
                  titulo: "Get Configuration (Request)",
                  tabla: {
                    titulotabla: "",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                         "1",
                         "requestType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Type of the request.",
                        ]
                      },
                      {
                        row: [
                         "2",
                         "userName",
                         "String(50)",
                         "",
                         "",
                         "R",
                         "Name of a user that initiates the API call. Provided by gateway owner.",
                        ]
                      },
                      {
                        row: [
                         "3",
                         "password",
                         "String(32)",
                         "",
                         "",
                         "R",
                         "Password of a user that initiates the API call. Provided by gateway owner.",
                        ]
                      },
                      {
                        row: [
                         "4",
                         "accountId",
                         "Integer",
                         "",
                         "",
                         "R",
                         "Reference to an account associated with the API call.",
                        ]
                      },
                      {
                        row: [
                         "5",
                         "transactionId",
                         "Long",
                         "",
                         "",
                         "R",
                         "",
                        ]
                      },
                      {
                        row: [
                         "6",
                         "paymentMethodType",
                         "Enum",
                         "",
                         "applepay",
                         "R",
                         "",
                        ]
                      }
                    ]
                  }
                },
                {
                  titulo: "Get Configuration (Response)",
                  tabla: {
                    titulotabla: "",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "configuration",
                         "String",
                         "",
                         "",
                         "R",
                         "",
                        ]
                      }
                    ]
                  }
                }
              ]

            },

            


            {//////////////////////////////////////////
              titulo: "Sale",
              notas: "sale-auth and capture in a single process.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Extended Logic" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Shipping Address Information *" },
                  { nombre: "Level II Transaction Information *" },
                  { nombre: "Level III Transaction Information *" },
                  { nombre: "Restaurant Industry *" },
                  { nombre: "PIN Debit/EBT cards *" },
                  { nombre: "Interac *" },
                  { nombre: "HPP Fields *" },
                  { nombre: "Transit Fields" },
                  { nombre: "Voice Authorization *" },
                  { nombre: "3D Secure *" },
                  { nombre: "Healthcare Industry *" },
                  { nombre: "Petroleum *" },
                  { nombre: "Lodging *" },
                  { nombre: "Identity Verification *" },
                  { nombre: "Terminals" },
                  { nombre: "Check 21" },
                  { nombre: "Car Rental" }
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Account Data is used" },
                  { nombre: "Account Number is used" },
                  { nombre: "Token is used" },
                  { nombre: "lodgingChargeType = H" },
                  { nombre: "LodgingChargeType ≠ H" },
                  { nombre: "Splits field is used" },
                  { nombre: "SplitSchemaId field is used" }
                ],
                subtitulo3: "Subrecords",
                arraytitulo3: [

                  { nombre: "Split Subrecord" },
                  { nombre: "Split Item Subrecord" },
                  { nombre: "Level III Item Subrecord" },
                  { nombre: "Petroleum Item Subrecord" },
                  { nombre: "Lodging Extra Charge Subrecord" },
                  { nombre: "Car Rental Extra Charge Subrecord" },

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Sale Request" },
                  { nombre: "Sale Request with Track Data" },
                  { nombre: "Sale Request with Proxy Number" },
                  { nombre: "Sale Request for Lodging" },
                  { nombre: "Sale Request for Car Rental" },
                  { nombre: "Sale Request with Items" },
                  { nombre: "Sale Request with EMV Data" },
                  { nombre: "Sale Request with PIN Debit" },
                  { nombre: "Sale Request with PIN Debit" },
                  { nombre: "Sale Request for Voice Authorization" },
                  { nombre: "Sale Request via HPP" },
                  { nombre: "Sale Request with Processing Mode" },
                  { nombre: "Sale Request with Multiple Vendors for Marketplace" },
                  { nombre: "Sale Request for Petroleum" },
                  { nombre: "Sale Request with Convenience Fee" },
                  { nombre: "Sale Request with EBT card" },
                  { nombre: "Sale Request with Split Payments" },
                  { nombre: "Sale Request with Split Payments and Items" },

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "General Information " },
                  { nombre: "Account Information " },
                  { nombre: "Transaction Information " },
                  { nombre: "Extended Logic " },
                  { nombre: "Billing Address Information " },
                  { nombre: "Response Codes Information " },
                  { nombre: "Interac * " },
                  { nombre: "EBT Information " },
                  { nombre: "Terminals " }

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "Subrecords",
                arraytitulo3: [
                  { nombre: "Split Subrecord " },
                  { nombre: "Split Item Subrecord " },
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Sale Response" },
                  { nombre: "Sale Response with Track Data" },
                  { nombre: "Sale Response with Proxy Number" },
                  { nombre: "Sale Response for Lodging" },
                  { nombre: "Sale Response for Car Rental" },
                  { nombre: "Sale Response with Items" },
                  { nombre: "Sale Response with EMV Data" },
                  { nombre: "Sale Response with PIN Debit" },
                  { nombre: "Sale Response for Voice Authorization" },
                  { nombre: "Sale Response via HPP" },
                  { nombre: "Sale Response with Processing Mode" },
                  { nombre: "Sale Response with Multiple Vendors for Marketplace" },
                  { nombre: "Sale Response for Petroleum" },
                  { nombre: "Sale Response with Convenience Fee" },
                  { nombre: "Sale Response with EBT card" },
                  { nombre: "Sale Response with Split Payments" },
                  { nombre: "Sale Response with Split Payments and Items" }

                ],
              }],

              tablas: [
                {
                  titulo: "Sale (Request)",
                  tabla: {
                    titulotabla: "Core Infо",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [ 
                         "1",
                         "requestType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Type of the request.",
                        ],
                        vinculoType: "Sale",
                        vinculoUsage: "Message Formats",

                      },
                      {
                        row: [
                         "2",
                         "userName",
                         "String(50)",
                         "",
                         "",
                         "R",
                         "Name of a user that initiates the API call. Provided by gateway owner.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "password",
                         "String(32)",
                         "",
                         "",
                         "R",
                         "Password of a user that initiates the API call. Provided by gateway owner. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Password Encoding"
                      },
                      {
                        row: [
                         "4",
                         "accountId",
                         "Reference",
                         "",
                         "",
                         "R",
                         "Reference to an account associated with the API call.",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded.",
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "5",
                         "terminalId",
                         "Reference",
                         "",
                         "",
                         "O",
                         "Reference to a terminal associated with the API call. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded.",
                        descripcion2: "Formerly called terminalCode. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Terminal Code",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "6",
                         "languageCode",
                         "String(2)",
                         "",
                         "",
                         "O",
                         "Language in which API response message will be returned. The name of the language must be submitted in the format defined in ISO 639-1 standard.",
                        ],
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "7",
                         "accountType *",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Type of an account used in the transaction. This value doesn't affect the cost of transaction processing. It is used for validation purposes only.",
                        ],
                        vinculoType: "Account Type",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "8",
                         "accountNumber *",
                         "String(20)",
                         "",
                         "",
                         "C",
                         "Number of a payment card or bank account.",
                        ],
                        vinculoUsage: "#Account_Data_is_used*",
                      },
                      {
                        row: [
                         "9",
                         "accountAccessory *",
                         "String(14)",
                         "",
                         "",
                         "C",
                         "Payment card's expiration date or bank account's routing number. See ",
                        ],
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion2: "Formerly called accountAccessory. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Direct Debit",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "10",
                         "accountData *",
                         "String(500)",
                         "",
                         "",
                         "C",
                         "Track/EMV data of a payment card. See ",
                        ],
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion2: "Formerly called trackData. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Account Data",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "11",
                         "token",
                         "String(40)",
                         "",
                         "",
                         "C",
                         "Token associated with a payment card or bank account. Can be used instead of a payment card or bank account number in further transaction processing.",
                        ],
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion1: "Note: This field can be submitted only if tokenization service is enabled."
                      },
                      {
                        row: [
                         "12",
                         "checkNumber",
                         "String(15)",
                         "",
                         "",
                         "O",
                         "Number of a check. Used for direct debit transactions only.",
                        ],
                        vinculoName: "Processor Rules",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field requires ",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "Processor Rules"
                      },
                      {
                        row: [
                         "13",
                         "holderType",
                         "Enum",
                         "",
                         "default - P",
                         "O",
                         "Type of a payment card or bank account holder.",
                        ],
                        vinculoType: "Holder Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "14",
                         "holderName",
                         "String(150)",
                         "",
                         "",
                         "C",
                         "Name of a payment card or bank account holder.",
                        ],
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion1: "Note: This field is required when accountNumber field is present in the request.",
                        descripcion2: "Formerly called holderName. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "15",
                         "holderBirthdate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "O",
                         "Birthdate of a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "16",
                         "csc",
                         "String(4)",
                         "",
                         "",
                         "C",
                         "Card security code (three digits on the back of a payment card).",
                        ],
                        vinculoName: "Processor Rules csc",
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion1: "Note: This field requires ",
                        descripcion2: "Formerly called csc. See ",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "Processor Rules csc",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "17",
                         "cscIndicator",
                         "Enum",
                         "",
                         "",
                         "C",
                         "Defines how CSC is handled.",
                        ],
                        vinculoType: "CSC Indicator Type",
                        vinculoUsage: "#Account_Data_is_used*"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Transaction Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "18",
                         "amount *",
                         "Integer",
                         "",
                         "amount in cents",
                         "R",
                         "	Total amount of the transaction that includes discount, taxes and fees (if present).",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "19",
                         "feeAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of a service fee included in the total amount. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for some processors.",
                        modalname0: "Service Fees",
                      },
                      {
                        row: [
                         "20",
                         "cashbackAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money returned to a payment card holder. Used for sale with cashback transactions.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "21",
                         "currencyCode",
                         "Enum",
                         "",
                         "default - DEF",
                         "O",
                         "Currency used in the transaction.",
                        ],
                        vinculoType: "Currency Code Type",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "22",
                         "transactionIndustryType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Industry type of the transaction.",
                        ],
                        vinculoType: "Transaction Industry Type",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "23",
                         "transactionCategoryType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Category of the transaction.",
                        ],
                        vinculoType: "Transaction Category Type",
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called transactionCategory. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "24",
                         "transactionModeType",
                         "Enum",
                         "",
                         "",
                         "C",
                         "Mode of the transaction. See ",
                        ],
                        vinculoType: "Transaction Mode Type",
                        vinculoUsage: "#Account_Data_is_used*",
                        descripcion1: "Note: This field is required for card-on-file transactions, i.e. when transactionModeType=S/O.",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Stored Credentials"
                      },
                      {
                        row: [
                         "25",
                         "memo",
                         "String(255)",
                         "",
                         "",
                         "O",
                         "Additional information about the transaction.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: 70 of 255 characters are reserved for system use and only 185 characters can be used within the API request."
                      },
                      {
                        row: [
                         "26",
                         "isPartialAuthorization",
                         "Boolean",
                         "",
                         "0 - disabled, 1 - enabled; default = 0",
                         "O",
                         "Indicates whether partial authorization is enabled. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "27",
                         "isAccountTypeCorrectionEnabled",
                         "Boolean",
                         "",
                         "0 - determined by a processor, 1 - determined by the gateway; default = 1",
                         "O",
                         "Indicates whether a card type is determined by the gateway or a processor. Used for PINless debit processing to determine the accurate card type. If set to true, the gateway will use its internal BIN table to identify a card type. If set to false, a card number will be sent to the underlying processor, allowing it to determine a card type on its own.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "28",
                         "rewardNumber",
                         "String(20)",
                         "ALPHANUMERIC",
                         "",
                         "O",
                         "Number of bonus account.",
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoPattern: "Validation Patterns Types",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Cross-References",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "29",
                         "userCode",
                         "	String(60)",
                         "",
                         "",
                         "O",
                         "Identifier of a user supplied by a submitter that initiates the API call.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "30",
                         "transactionOriginCode",
                         "String(35)",
                         "",
                         "",
                         "O",
                         "Identifier of a place within a submitter's application from which the transaction is originated. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Origin Code"
                      },
                      {
                        row: [
                         "31",
                         "transactionCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Identifier of the transaction supplied by a submitter. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Cross-reference Fields"
                      },
                      {
                        row: [
                         "32",
                         "transactionInternalCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Internal identifier of the transaction within a submitter's system. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Cross-reference Fields"
                      },
                      {
                        row: [
                         "33",
                         "customerAccountCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Identifier of a customer, associated with the transaction, within a submitter's system. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Cross-reference Fields"
                      },
                      {
                        row: [
                         "34",
                         "customerAccountInternalCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Internal identifier of a customer, associated with the transaction, within a submitter's system. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Cross-reference Fields"
                      },
                      {
                        row: [
                         "35",
                         "itemCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Identifier of a purchased product or service within a submitter’s system.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "36",
                         "laneCode",
                         "String(10)",
                         "",
                         "digits only; no special characters",
                         "O",
                         "Identifier of a lane associated with the transaction.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "37",
                         "clientHost",
                         "String(60)",
                         "",
                         "IP-address or URL",
                         "O",
                         "IP-address or a host associated with a submitter that originated the transaction. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Client Host"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Extended Logic",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "38",
                         "sequenceNumber",
                         "Integer",
                         "",
                         "greater than 0; less than or equal to sequenceCount",
                         "O",
                         "Sequential number of the payment (e.g. 2nd of 12 payments). Used for installment and recurring payments only (for transactionCategoryType = I or R).",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "39",
                         "sequenceCount",
                         "Integer",
                         "",
                         "greater than 0",
                         "O",
                         "Total number of payments in an installment sequence (e.g. 12 payments). Used for installment payments only (for transactionCategoryType = I).",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "40",
                         "originalNetworkTransactionId *",
                         "String(30)",
                         "",
                         "",
                         "O",
                         "Identifier of the first transaction in a sequence of payments. Provided by a payment network. Used for installment and recurring payments only (for transactionCategoryType = I or R). See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Stored Credentials"
                      },
                      {
                        row: [
                         "41",
                         "settlementGroupCode",
                         "String(60)",
                         "",
                         "",
                         "O",
                         "Identifier of a settlement group. Used when it is needed to void a group of transactions if at least one transaction from the group is voided at the moment when settlement is done. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Settlement Group"
                      },
                      {
                        row: [
                         "42",
                         "subscriptionCode",
                         "Long",
                         "",
                         "",
                         "O",
                         "Code of a subscription to a processing bank. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Bank Information Subscription"
                      },
                      {
                        row: [
                         "43",
                         "format",
                         "Enum",
                         "",
                         "values are pipe-delimited",
                         "O",
                         "Defines what resource is going to be received within an offline callback. See ",
                        ],
                        vinculoType: "Format Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Format Field"
                      },
                      {
                        row: [
                         "44",
                         "descriptor",
                         "String(25)",
                         "",
                         "",
                         "O",
                         "Dynamic descriptor of the transaction that is shown on customer statements and can be customized to simplify understanding of charge for a cardholder. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Dynamic Descriptor"
                      },
                      {
                        row: [
                         "45",
                         "distributions",
                         "List<Distribution>",
                         "",
                         "",
                         "O",
                         "List of distributions included in the transaction. See ",
                        ],
                        vinculoType: "Distribution Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "46",
                         "splits",
                         "List<Split>",
                         "",
                         "",
                         "C",
                         "List of split payment scenarios included in the transaction. See ",
                        ],
                        vinculoType: "Split Subrecord",
                        vinculoUsage: "#Account_Data_is_used*",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "47",
                         "splitSchemaId",
                         "Reference",
                         "",
                         "",
                         "C",
                         "Reference to a split schema associated with the transaction. See ",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "#Account_Data_is_used*",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Split Payments"
                      },
                      {
                        row: [
                         "48",
                         "items",
                         "List<SplitItem>",
                         "",
                         "",
                         "O",
                         "List of items included in the transaction. See ",
                        ],
                        vinculoType: "Split Item Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "49",
                         "isVerificationEnabled",
                         "Boolean",
                         "",
                         "default - false",
                         "O",
                         "Indicates, whether account verification is enabled for direct debit transactions in real-time processing mode.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Billing Address Information",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "50",
                         "street",
                         "String(128)",
                         "",
                         "",
                         "O",
                         "Street of a billing address associated with a payment card or bank account holder. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded.",
                        descripcion2: "Formerly called street. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Interchange Rate Qualification",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "51",
                         "city",
                         "String(50)",
                         "",
                         "",
                         "O",
                         "City of a billing address associated with a payment card or bank account holder. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called city. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Interchange Rate Qualification",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "52",
                         "countryCode",
                         "String(2)",
                         "",
                         "default - US",
                         "O",
                         "Country of a billing address associated with a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called countryCode. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "53",
                         "state",
                         "String(2)",
                         "",
                         "",
                         "O",
                         "State of a billing address associated with a payment card or bank account holder. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called state. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Interchange Rate Qualification",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "54",
                         "zipCode",
                         "String(15)",
                         "",
                         "",
                         "O",
                         "ZIP code of a billing address associated with a payment card or bank account holder. See  ",
                        ],
                        vinculoName: "Processor RuleszipCode",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field requires ",
                        descripcion2: "Formerly called zipCode. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Interchange Rate Qualification",
                        seemoreinformation1: "special handling ",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "Processor RuleszipCode",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "55",
                         "phone",
                         "String(20)",
                         "",
                         "digits only; no special characters",
                         "O",
                         "Phone number associated with a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called phone. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "56",
                         "email",
                         "String(100)",
                         "",
                         "",
                         "O",
                         "Email associated with a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called email. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Shipping Address Information *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "57",
                         "shippingDate",
                         "Date",
                         "",
                         "yyyyMMdd; default - capture date",
                         "O",
                         "Date of a purchase (current date or any date in the past).",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "58",
                         "shippingName",
                         "String(100)",
                         "",
                         "",
                         "R2",
                         "Name of an order recipient.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "59",
                         "shippingStreet",
                         "String(128)",
                         "",
                         "",
                         "R2",
                         "Street of a shipping address associated with an order recipient.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded."
                      },
                      {
                        row: [
                         "60",
                         "shippingCity",
                         "String(50)",
                         "",
                         "",
                         "R2",
                         "City of a shipping address associated with an order recipient.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "61",
                         "shippingCountryCode",
                         "String(2)",
                         "",
                         "",
                         "R2",
                         "Country code of a shipping address associated with an order recipient.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "62",
                         "shippingState",
                         "String(2)",
                         "",
                         "",
                         "R2",
                         "State of a shipping address associated with an order recipient.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "63",
                         "shippingZipCode",
                         "String(15)",
                         "",
                         "",
                         "R2",
                         "ZIP code of a shipping address associated with an order recipient.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Level II Transaction Information *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "64",
                         "orderCode",
                         "String(17)",
                         "",
                         "",
                         "R2",
                         "Unique identifier of an order within a submitter's system. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "65",
                         "shippingAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R2",
                         "Amount of shipping and handling fees charged for an order delivery. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "66",
                         "dutyAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R2",
                         "Amount of a duty (import tax) charged on the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "67",
                         "taxAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R2",
                         "Tax amount charged on the order/transaction. Included in the total amount. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "68",
                         "taxRate",
                         "Integer",
                         "",
                         "implied 2 decimal places (e.g. 150 = 1.5%; 1000 = 10%)",
                         "R2",
                         "Tax rate charged on the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "69",
                         "taxIndicator",
                         "Enum",
                         "",
                         "",
                         "R2",
                         "Defines how taxes are handled for the transaction. See ",
                        ],
                        vinculoType: "Tax Indicator Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "70",
                         "customerAccountTaxId",
                         "String(13)",
                         "",
                         "",
                         "R2",
                         "Tax ID of a customer associated with the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Level III Transaction Information *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "71",
                         "shippingOriginZipCode",
                         "String(10)",
                         "",
                         "",
                         "R3",
                         "ZIP-code associated with an address from which an order is shipped. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "72",
                         "discountAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R3",
                         "Discount amount applied to the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "73",
                         "taxInvoiceCode",
                         "String(15)",
                         "",
                         "",
                         "R3",
                         "Tax invoice number associated with the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "74",
                         "localTaxAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R3",
                         "Amount of a local (city, country, etc) tax charged on the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "75",
                         "localTaxIndicator",
                         "Enum",
                         "",
                         "",
                         "R3",
                         "Defines how a local tax is handled. See ",
                        ],
                        vinculoType: "Tax Indicator Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "76",
                         "nationalTaxAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R3",
                         "Amount of a national/federal tax charged on the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "77",
                         "nationalTaxIndicator",
                         "Enum",
                         "",
                         "",
                         "R3",
                         "Defines how a national/federal tax is handled. See ",
                        ],
                        vinculoType: "Tax Indicator Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "78",
                         "orderDate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "R3",
                         "Date of an order placement. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "79",
                         "commodityCode",
                         "String(4)",
                         "",
                         "",
                         "R3",
                         "Acquirer designated standardized identifier of a group of items associated with the order/transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      },
                      {
                        row: [
                         "80",
                         "items",
                         "List<L3Item>",
                         "",
                         "",
                         "R3",
                         "List of items included in the transaction. See ",
                        ],
                        vinculoType: "Level III Item Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transaction Levels"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Restaurant Industry *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "81",
                         "tipAmount",
                         "Integer",
                         "",
                         "amount in cents; greater than 0",
                         "O",
                         "Amount of tips.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "82",
                         "tipRecipientCode",
                         "String(15)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a person that is going to receive tips.",
                        ],
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "PIN Debit/EBT cards *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "83",
                         "pinValue *",
                         "String(16)",
                         "",
                         "",
                         "O",
                         "PIN Debit only. PIN value associated with a debit card.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called pinCode. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      
                      },
                      {
                        row: [
                         "84",
                         "ksnValue *",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "PIN Debit only. Value of a temporary key (KSN) that encrypts a PIN value associated with a payment card.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called dukpt. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      
                      },
                      {
                        row: [
                         "85",
                         "voucherNumber",
                         "String(15)",
                         "",
                         "",
                         "O",
                         "For EBT Voucher Clear transactions only. Value of a voucher number.",
                        ],
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Interac *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "86",
                         "macValue",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "For Canadian transactions only. Value of a message authentication code (MAC). After submitted, the value of the field is not stored in the database.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "87",
                         "stanValue",
                         "String(6)",
                         "",
                         "",
                         "O",
                         "For Canadian transactions only. Value identifying the transaction in a terminal. After submitted, the value of the field is stored in the database.",
                        ],
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "HPP Fields *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "88",
                         "notifyURL",
                         "String(255)",
                         "",
                         "none or URL",
                         "R",
                         "Callback URL used for delivering of a transaction result. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded.",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)"
                      
                      },
                      {
                        row: [
                         "89",
                         "postNotifyURL",
                         "String(255)",
                         "",
                         "none or URL",
                         "O",
                         "Callback URL used for delivering of a transaction result when more than one submitter's systems are used. Used in conjunction with notifyURL only. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)"
                      },
                      {
                        row: [
                         "90",
                         "cancelURL",
                         "String(255)",
                         "",
                         "none, page or URL",
                         "O",
                         "URL used for redirection when the transaction gets cancelled. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)"
                      },
                      {
                        row: [
                         "91",
                         "returnURL",
                         "String(255)",
                         "",
                         "none, page or URL",
                         "O",
                         "URL used for redirection when the transaction gets processed successfully. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded."
                      
                      },
                      {
                        row: [
                         "92",
                         "styleURL",
                         "String(255)",
                         "",
                         "none or URL",
                         "O",
                         "URL of a CSS stylesheet that should be dynamically included in the hosted payment page. Used when dynamic styles are needed. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)",
                        descripcion1: "Note: If the field's value contains special characters (&, ?, =, etc), it must be URL-encoded."
                      
                      },
                      {
                        row: [
                         "93",
                         "returnURLPolicy",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how returnURL is handled when the transaction is processed. See ",
                        ],
                        vinculoType: "Return URL Policy Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)"
                      },
                      {
                        row: [
                         "94",
                         "providerData",
                         "String",
                         "",
                         "",
                         "O",
                         "Used to transfer data from the provider that has been received on HPP. It is required to process transactions via PayPal.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "95",
                         "theme",
                         "String",
                         "",
                         "black",
                         "O",
                         "Indicates which color theme will be used. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Hosted Payment Page (HPP)"
                      },
                      {
                        row: [
                         "96",
                         "isEmbedded",
                         "Boolean",
                         "",
                         "",
                         "O",
                         "Indicates whether header and footer of the hosted payment page are shown within the page. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Embedded Mode"
                      },
                      {
                        row: [
                         "97",
                         "pageFormat",
                         "String",
                         "",
                         "",
                         "O",
                         "Defines what stages are shown on the hosted payment page. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Page Format"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Transit Fields",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "98",
                         "isCscVerificationEnabled",
                         "Boolean",
                         "",
                         "0 - not applied, 1 - applied; default = 1",
                         "O",
                         "Signals an underlying processing system (in case when it supports such functionality) that CSC verification procedure should be applied. Note that validation rules, as well as the outcome of the transaction based on a value verification, is determined by the underlying processor. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transit Fields"
                      },
                      {
                        row: [
                         "99",
                         "isAvsVerificationEnabled",
                         "Boolean",
                         "",
                         "0 - not required, 1 - required; default = 1",
                         "O",
                         "Signals an underlying processing system (in case when it supports such functionality) that additional verification based on AVS information (billing address) is required. Note that validation rules, as well as the outcome of the transaction based on a value verification, is determined by the underlying processor. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Transit Fields"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Voice Authorization *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "100",
                         "approvalCode *",
                         "String(100)",
                         "",
                         "",
                         "O",
                         "Voice Authorization only.  Authorization number given by a cardholder's bank (ex processorCode).",
                        ],
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "3D Secure *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "101",
                         "authenticationStatus",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Defines a status of 3D Secure verification process. See ",
                        ],
                        vinculoType: "Authentication Status Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "3D Secure Verification"
                      },
                      {
                        row: [
                         "102",
                         "authenticationCode",
                         "String(36)",
                         "",
                         "typically 28 bytes in length and base 64-encoded.",
                         "O",
                         "Unique 3D Secure Transaction Identifier generated by MPI. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "3D Secure Verification"
                      },
                      {
                        row: [
                         "103",
                         "authenticationVerificationValue",
                         "String(100)",
                         "",
                         "",
                         "R",
                         "Issuer authentication value that corresponds to Visa CAVV or MasterCard AAV. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "3D Secure Verification"
                      },
                      {
                        row: [
                         "104",
                         "authenticationVersion",
                         "String(8)",
                         "",
                         "",
                         "O",
                         "3D-Secure version related to the received authenticationCode and authenticationVerificationValue. Required for 3D-Secure V2.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Healthcare Industry *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "105",
                         "healthcareAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R",
                         "Total amount of money, spent on healthcare-related services or products. Included in the total amount of the transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "106",
                         "transitAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money, spent on a healthcare-related transportation. Included in the total amount of the transaction. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "107",
                         "copayAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "	Amount of money, spent each time a medical service is accessed. Included in the healthcare amount. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "108",
                         "prescriptionAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money, spent on prescription drugs. Included in the healthcare amount. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "109",
                         "visionAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money, spent on prescription drugs. Included in the healthcare amount. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "110",
                         "clinicAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money, spent on a hospitalization. Included in the healthcare amount. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "111",
                         "dentalAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of money, spent on dental-related medical services. Included in the healthcare amount. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      },
                      {
                        row: [
                         "112",
                         "isQualifiedIIAS",
                         "Boolean",
                         "",
                         "0 - not verified, 1 - verified; default = 0",
                         "O",
                         "	Indicates whether purchased items are verified against IIAS as qualified for healthcare purchases. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Healthcare Industry Transactions"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Petroleum *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "113",
                         "terminalType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Terminal used for the transaction.",
                        ],
                        vinculoType: "Terminal Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "114",
                         "driverNumber",
                         "String(10)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a driver.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "115",
                         "userIdNumber",
                         "String(12)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a driver in an agency's system.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "116",
                         "vehicleNumber",
                         "String(10)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a vehicle.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "117",
                         "vehicleIdNumber",
                         "String(8)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a vehicle in an agency's system.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "118",
                         "odometrReading",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Distance travelled by a vehicle, read from the vehicle's odometr.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "119",
                         "jobNumber",
                         "String(12)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a job associated with a driver.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "120",
                         "jobIdNumber",
                         "String(12)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a job associated with a driver in an agency's system.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "121",
                         "departmentNumber",
                         "String(12)",
                         "",
                         "",
                         "O",
                         "Unique identifier of an agency's department.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "122",
                         "serviceRestrictionCode",
                         "String(3)",
                         "",
                         "",
                         "O",
                         "Identifier of a service availability. Required for Visa/MasterCard keyed transactions.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "123",
                         "petroleumItems",
                         "List<PetroleumItem>",
                         "",
                         "",
                         "O",
                         "List of petroleum items included in the transaction. See ",
                        ],
                        vinculoType: "Petroleum Item Subrecord",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: Code values associated with the petroleum items may be different depending on a processor. To receive a full list of petroleum products supported by a particular processor, please contact gateway support team.",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "124",
                         "deviceSequenceNumber",
                         "String(5)",
                         "",
                         "",
                         "O",
                         "Sequence number of a vehicle.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Lodging *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "125",
                         "roomNumber",
                         "String(25)",
                         "",
                         "",
                         "O",
                         "Number of a guest's hotel room.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "126",
                         "guestName ",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "Name of a guest.",
                        ],
                        vinculoName: "Processor Rules GuestName",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field requires ",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "Processor Rules GuestName"
                      },
                      {
                        row: [
                         "127",
                         "folioNumber",
                         "String(15)",
                         "",
                         "",
                         "O",
                         "Number of a guest's folio.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "128",
                         "roomRateAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "C",
                         "Amount of a daily room rate..",
                        ],
                        vinculoUsage: "#Account_Data_is_used*"
                      },
                      {
                        row: [
                         "129",
                         "roomTaxAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Amount of charged tax.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "130",
                         "lodgingChargeType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Type of a lodging.",
                        ],
                        vinculoType: "Lodging Charge Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "131",
                         "checkInDate",
                         "Date",
                         "",
                         "",
                         "C",
                         "Date of guest's check-in.",
                        ],
                        vinculoUsage: "#Account_Data_is_used*"
                      },
                      {
                        row: [
                         "132",
                         "checkOutDate",
                         "Date",
                         "",
                         "",
                         "C",
                         "Date of guest's check-out.",
                        ],
                        vinculoUsage: "#Account_Data_is_used*"
                      },
                      {
                        row: [
                         "133",
                         "duration",
                         "Integer",
                         "",
                         "duration in days",
                         "O",
                         "Duration of a guest's stay.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "134",
                         "specialProgramType",
                         "Enum",
                         "",
                         "",
                         "C",
                         "Special services available during the reservation and stay.",
                        ],
                        vinculoType: "Special Program Type",
                        vinculoUsage: "#Account_Data_is_used*",
                      },
                      {
                        row: [
                         "135",
                         "extraCharges",
                         "List<ExtraCharge>",
                         "",
                         "",
                         "O",
                         "List of extra charges included in the transaction. See ",
                        ],
                        vinculoType: "Lodging Extra Charge Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "136",
                         "extraChargesAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Sequence number of a vehicle.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Identity Verification *",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "137",
                         "driverLicenseNumber",
                         "String(50)",
                         "",
                         "",
                         "O",
                         "Number of a bank account holder's driver license. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Identity Verification"
                      },
                      {
                        row: [
                         "138",
                         "driverLicenseState ",
                         "Enum",
                         "",
                         "",
                         "O",
                         "State where a bank account holder's driver license was issued. See ",
                        ],
                        vinculoType: "Driver License State",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Identity Verification"
                      },
                      {
                        row: [
                         "139",
                         "identityVerificationType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how a bank account holder's identity is verified. See integration notes for more information.",
                        ],
                        vinculoType: "Identity Verification Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Identity Verification"
                      },
                      {
                        row: [
                         "140",
                         "identityVerificationValue",
                         "String(4)",
                         "",
                         "",
                         "O",
                         "Value used to verify a bank account holder’s identity. See integration notes for more information.",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Identity Verification"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Terminals",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "141",
                         "transactionDate",
                         "Date",
                         "",
                         "yyyyMMddHHmmss",
                         "O",
                         "Date of the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "142",
                         "ticketNumber ",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "Unique identifier of a ticket. Provided by the gateway.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "143",
                         "ticketAmount",
                         "Integer",
                         "",
                         "cannot exceed total amount",
                         "O",
                         "Amount of a ticket.",
                        ],
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "144",
                         "entryModeType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how payment information is entered. See ",
                        ],
                        vinculoType: "Entry Mode Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Terminal Data Entry"
                      },
                      {
                        row: [
                         "145",
                         "entryMediumType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how input source of payment information is provided. See integration notes for more information.",
                        ],
                        vinculoType: "Entry Medium Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Terminal Data Entry"
                      },
                      {
                        row: [
                         "146",
                         "entryPINModeType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines whether an input source supports PIN entry.",
                        ],
                        vinculoType: "Entry PIN Mode Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "147",
                         "terminalCapabilityMode",
                         "Collection",
                         "",
                         "values are pipe-delimited",
                         "O",
                         "Defines how a terminal reads payment information.",
                        ],
                        vinculoType: "Terminal Capability Mode Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "148",
                         "processingMode",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how the transaction is processed. See ",
                        ],
                        vinculoType: "Processing Mode Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Offline Transactions"
                      },
                      {
                        row: [
                         "149",
                         "holderVerificationModeType",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines how a payment card is verified.",
                        ],
                        vinculoType: "Holder Verification Mode Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "150",
                         "fallbackReasonCode",
                         "Enum",
                         "",
                         "",
                         "O",
                         "Defines a reason why fallback has occurred. See ",
                        ],
                        vinculoType: "Fallback Reason Code Type",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Fallback Transactions"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Check 21",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "151",
                         "checkFrontImage",
                         "Image",
                         "",
                         "base64-encoded TIFF-image",
                         "O",
                         "Image of a front side of a check.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field is required for transactionIndustryType = C21."
                      },
                      {
                        row: [
                         "152",
                         "checkBackImage ",
                         "Image",
                         "",
                         "base64-encoded TIFF-image",
                         "O",
                         "Image of a back side of a check.  ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field is required for transactionIndustryType = C21."
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Car Rental",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "153",
                         "rentalName",
                         "String(20)",
                         "",
                         "",
                         "R",
                         "Name of a person who rents a car.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "154",
                         "rentalAddress ",
                         "String(26)",
                         "",
                         "",
                         "O",
                         "Address where a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "155",
                         "rentalCity",
                         "String(25)",
                         "",
                         "",
                         "O",
                         "City where a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "156",
                         "rentalState",
                         "String(3)",
                         "",
                         "",
                         "O",
                         "State where a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "157",
                         "rentalCountryCode",
                         "String(2)",
                         "",
                         "",
                         "O",
                         "Country where a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "158",
                         "rentalDate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "R",
                         "Date when a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "159",
                         "rentalTime",
                         "Integer",
                         "",
                         "hhMM",
                         "O",
                         "Time when a car rental starts.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "160",
                         "returnCity",
                         "String(25)",
                         "",
                         "",
                         "O",
                         "City where a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "161",
                         "returnState",
                         "String(3)",
                         "",
                         "",
                         "O",
                         "State where a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "162",
                         "returnCountryCode",
                         "String(2)",
                         "",
                         "",
                         "O",
                         "Country where a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "163",
                         "returnDate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "R",
                         "Date when a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "164",
                         "returnTime",
                         "Integer",
                         "",
                         "hhMM",
                         "O",
                         "Time when a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "165",
                         "agreementNumber",
                         "String(25)",
                         "",
                         "",
                         "R",
                         "Agreement number of a car rental.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "166",
                         "duration",
                         "Integer",
                         "",
                         "",
                         "R",
                         "Period of a car rental.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "167",
                         "specialProgramType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Special services provided as a part of car rental.",
                        ],
                        vinculoType: "Special Program Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "168",
                         "returnLocationCode",
                         "String(10)",
                         "",
                         "",
                         "O",
                         "Identifier of a location where a car is returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "169",
                         "dailyRateAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Rent amount per day.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "170",
                         "mileRateAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "Rent amount per mile.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "171",
                         "totalMiles",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Total mileage of a rented car.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "172",
                         "maxFreeMiles",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Total mileage of a rented car for rent period.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "173",
                         "insuranceAmount",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Amount of a car insurance.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "174",
                         "extraCharges",
                         "List<ExtraCharge>",
                         "",
                         "",
                         "O",
                         "List of extra charges included in the transaction. See ",
                        ],
                        vinculoType: "Car Rental Extra Charge Subrecord",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "175",
                         "extraChargesAmount",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Amount of extra charges included in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "Conditions (Sale Request)",
                  tabla: {
                    titulotabla: "Account Data is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "holderName",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "accountNumber",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "accountAccessory",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "token",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "csc",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "cscIndicator",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Number is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "holderName",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "accountNumber",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "accountAccessory",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "csc",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "cscIndicator",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "token",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Token is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "holderName",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "accountNumber",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "accountAccessory",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "csc",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "cscIndicator",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "token",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "LodgingChargeType = H",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "roomRateAmount",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "checkInDate",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "checkOutDate",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "specialProgramType",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "lodgingItemCount",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "LodgingChargeType ≠ H",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "roomRateAmount",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "checkInDate",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "checkOutDate",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "specialProgramType",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "lodgingItemCount",
                         "O"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Splits field is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "splits",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "splitSchemaId",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "SplitSchemaId field is used",
                    headers:[
                     "#",
                     "Fields",
                     "Usage"
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "splits",
                         "N"
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "splitSchemaId",
                         "R"
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "Subrecords (Sale Request)",
                  tabla: {
                    titulotabla: "Split Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "accountId",
                         "Reference",
                         "",
                         "R",
                         "Reference to an account that is going to receive funds as a result of a split payment processing.",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: The submitted value can be either account ID/code or merchant ID/code. If merchantId or merchantCode value is submitted, the system will automatically use the first account under this merchant for further transaction processing."
                      },
                      {
                        row: [
                         "2",
                         "amount ",
                         "String(20)",
                         "amount in cents or rate with implied 4 decimal places",
                         "R",
                         "Amount or rate of the amount that is going to be received by an account as a result of a split payment processing. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Split Amount Format"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Split Item Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "code",
                         "String(12)",
                         "",
                         "SR",
                         "Unique identifier of an item within a submitter's system or inventory catalog.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "totalAmount ",
                         "Integer",
                         "amount in cents",
                         "SR",
                         "Total amount of an item that includes discount and tax.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "quantity ",
                         "Float",
                         "up to 4 decimal places",
                         "O",
                         "Number of item units purchased/refunded as a part of the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "description ",
                         "String(35)",
                         "",
                         "O",
                         "Description of an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "splits ",
                         "List<Split>",
                         "",
                         "C",
                         "List of split payment scenarios included in the transaction. See integration notes for more information.",
                        ],
                        vinculoType: "Split Subrecord",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "splitSchemaId ",
                         "Reference",
                         "",
                         "C",
                         "Reference to a split schema associated with the transaction. See integration notes for more information.",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Level III Item Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "code",
                         "String(12)",
                         "",
                         "R3",
                         "Unique identifier of an item within a submitter's system or inventory catalog. ",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "commodityCode ",
                         "String(12)",
                         "",
                         "R3",
                         "Standardized identifier of an item. Usually acquirer designated.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "itemNumber ",
                         "String(20)",
                         "",
                         "O",
                         "Sequential number of an item in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "quantity ",
                         "Float",
                         "up to 4 decimal places",
                         "R3",
                         "Number of item units purchased/refunded as a part of the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "5",
                         "totalAmount ",
                         "Integer",
                         "amount in cents",
                         "R3",
                         "Total amount of an item that includes discount and tax.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "unitCostAmount ",
                         "Float",
                         "amount in cents; up to 4 decimal places",
                         "R3",
                         "Cost of a single unit of an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "7",
                         "unitMeasure ",
                         "String(12)",
                         "",
                         "R3",
                         "Unit of measure used to quantify items purchased/refunded as a part of the transaction (for example, g, kg, lb, inch).",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "8",
                         "description ",
                         "String(35)",
                         "",
                         "R3",
                         "Description of an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "9",
                         "taxRate ",
                         "Integer",
                         "implied 2 decimal places (e.g. 10% = 1000)",
                         "R3",
                         "Tax rate charged on an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "10",
                         "taxAmount",
                         "Integer",
                         "amount in cents",
                         "R3",
                         "Tax amount charged on an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "11",
                         "taxIndicator",
                         "Enum",
                         "",
                         "R3",
                         "Defines how tax is handled for an item.",
                        ],
                        vinculoType: "Tax Indicator Type",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "12",
                         "taxCode",
                         "String(4)",
                         "",
                         "R3",
                         "Acquirer designated identifier of a tax charged on an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "13",
                         "discountRate",
                         "Integer",
                         "implied 2 decimal places (e.g. 150 = 1.5%; 1000 = 10%)",
                         "R3",
                         "Discount rate applied to an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "14",
                         "discountAmount",
                         "Integer",
                         "amount in cents",
                         "R3",
                         "Discount amount applied to an item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "15",
                         "isCredit",
                         "Boolean",
                         "1 - item is returned, 0 - item is purchased; default - 0",
                         "R3",
                         "Indicates whether an item is purchased or returned.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Petroleum Item Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "code",
                         "String(12)",
                         "",
                         "R",
                         "Unique identifier of a petroleum item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "type ",
                         "Enum",
                         "",
                         "R",
                         "Type of a petroleum item. ",
                        ],
                        vinculoType: "Petroleum Product Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "quantity ",
                         "Float",
                         "up to 3 digits after point",
                         "R",
                         "Number of petroleum items.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "4",
                         "unitCostAmount ",
                         "Float",
                         "amount in cents, up to 3 decimal places",
                         "R",
                         "Cost of a single unit of a petroleum item.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Lodging Extra Charge Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "code",
                         "String(12)",
                         "",
                         "R",
                         "Unique identifier of an extra charged item. ",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "type ",
                         "Enum",
                         "",
                         "R",
                         "Type of an extra charged item.",
                        ],
                        vinculoType: "Extra Charge Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "amount ",
                         "Integer",
                         "amount in cents",
                         "R",
                         "Amount of an extra charged item.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Car Rental Extra Charge Subrecord",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "code",
                         "String(12)",
                         "",
                         "R",
                         "Unique identifier of an extra charged item.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "type ",
                         "Enum",
                         "",
                         "R",
                         "Type of an extra charged item.",
                        ],
                        vinculoType: "Extra Charge Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "amount ",
                         "Integer",
                         "amount in cents",
                         "R",
                         "Amount of an extra charged item.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "Sale (Response)",
                  tabla: {
                    titulotabla: "General Information ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "responseType",
                         "Enum",
                         "",
                         "",
                         "E",
                         "Type of the response.",
                        ],
                        vinculoType: "Sale",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "2",
                         "accountId",
                         "Reference",
                         "",
                         "",
                         "E",
                         "accountId used in the transaction.",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called merchantAccountCode. See ",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "3",
                         "terminalId",
                         "Reference",
                         "",
                         "",
                         "E",
                         "terminalId used in the transaction. See ",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called terminalCode. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Terminal Code",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "4",
                         "userCode",
                         "String(60)",
                         "",
                         "",
                         "E",
                         "userCode used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Account Information ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "5",
                         "accountType *",
                         "Enum",
                         "",
                         "",
                         "E",
                         "accountType used in the transaction.",
                        ],
                        vinculoType: "Account Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "6",
                         "extendedAccountType",
                         "Enum",
                         "",
                         "",
                         "R",
                         "Brand of a payment card or type of an account used in the transaction.",
                        ],
                        vinculoType: "Extended Account Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "7",
                         "accountNumberMasked",
                         "String(20)",
                         "",
                         "the first 1 and the last 4 digits of an account number",
                         "E",
                         "Masked number of a payment card or bank account associated with the transaction (e.g. 4********5655).",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: The field is present in the response if either token or accountNumber is submitted in the request."
                      },
                      {
                        row: [
                         "8",
                         "accountAccessory",
                         "String(14)",
                         "",
                         "",
                         "E",
                         "accountAccessory used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "9",
                         "accountData *",
                         "String(500)",
                         "",
                         "",
                         "C",
                         "EMV response from the issuer. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called trackData. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Account Data",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "10",
                         "holderName",
                         "String(150)",
                         "",
                         "",
                         "E",
                         "Normalized holderName value used in the transaction. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Holder Name Normalization Rules"
                      },
                      {
                        row: [
                         "11",
                         "token",
                         "String(40)",
                         "",
                         "",
                         "O",
                         "Token associated with a payment card or bank account. Present if tokenization service is enabled.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "12",
                         "balance",
                         "Integer",
                         "",
                         "amount in cents",
                         "O",
                         "For debit and gift cards only. Balance remaining on a card used for the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "13",
                         "currencyCode",
                         "String(3)",
                         "",
                         "",
                         "R",
                         "Currency used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Transaction Information ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "14",
                         "amount *",
                         "Integer",
                         "",
                         "amount in cents",
                         "R",
                         "Total authorized amount.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "15",
                         "originalAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "R",
                         "Amount provided in the request. Differs from amount value if partial authorization has occurred.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "16",
                         "cashbackAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "E",
                         "cashbackAmount used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "17",
                         "taxAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "E",
                         "taxAmount used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "18",
                         "feeAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "E",
                         "feeAmount used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "19",
                         "tipAmount",
                         "Integer",
                         "",
                         "amount in cents",
                         "E",
                         "For restaurant industry only. tipAmount used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "20",
                         "transactionDate",
                         "Date",
                         "",
                         "yyyyMMdd",
                         "R",
                         "Date of the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "21",
                         "transactionCode",
                         "String(60)",
                         "",
                         "",
                         "E",
                         "transactionCode used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "22",
                         "providerTransactionCode",
                         "String(25)",
                         "",
                         "",
                         "O",
                         "Identifier of the transaction in a processor's system. Provided by the gateway.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "23",
                         "transactionId",
                         "Long",
                         "",
                         "",
                         "R",
                         "Identifier of the transaction. Provided by the gateway. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called referenceNumber. See ",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Record ID",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "24",
                         "providerTransactionId",
                         "String(50)",
                         "",
                         "",
                         "O",
                         "Identifier of the transaction in the processor's system. Provided by the processor.",
                        ],
                        vinculoUsage: "Message Formats",
                        descripcion2: "Formerly called providerReferenceNumber. See integration notes for more information.",
                        seemoreinformation2: "integration notes",
                        seemoreinformationcom2: " for more information.",
                        modalname2: "Legacy Fields Names"
                      },
                      {
                        row: [
                         "25",
                         "requestId",
                         "String(40)",
                         "",
                         "",
                         "O",
                         "Identifier of the API request. Provided by the gateway.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "26",
                         "approvalCode *",
                         "String(100)",
                         "",
                         "",
                         "O",
                         "Authorization number given by a cardholder's bank (ex processorCode). Often shown on a credit card receipt.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "27",
                         "cycleCode",
                         "Long",
                         "",
                         "if empty, no cycle was opened",
                         "R",
                         "Identifier of a retail transaction cycle associated with the transaction. Refers to real-time processing only.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "28",
                         "rewardNumber",
                         "String(20)",
                         "ALPHANUMERIC",
                         "",
                         "E",
                         "Number of bonus account.",
                        ],
                        vinculoUsage: "Message Formats",
                        vinculoPattern: "Validation Patterns Types"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Extended Logic ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "29",
                         "networkTransactionId *",
                         "String(30)",
                         "",
                         "",
                         "O",
                         "Identifier of the first transaction in a sequence of payments. Provided by a payment network. Used for installment and recurring payments only (for transactionCategoryType = I or R). See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Stored Credentials"
                      },
                      {
                        row: [
                         "30",
                         "splits",
                         "List<Split>",
                         "",
                         "amount in cents",
                         "C",
                         "List of split payment scenarios included in the transaction. See ",
                        ],
                        vinculoType: "Split SubrecordSale",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      },
                      {
                        row: [
                         "31",
                         "items",
                         "List<SplitItem>",
                         "",
                         "",
                         "C",
                         "List of items included in the split transaction. See integration notes for more information.",
                        ],
                        vinculoType: "Split Item SubrecordSale",
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Subrecord Format"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Billing Address Information ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "32",
                         "email",
                         "String(100)",
                         "",
                         "",
                         "E",
                         "Email associated with a payment card or bank account holder.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Response Codes Information ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "33",
                         "warningCode",
                         "String(1)",
                         "",
                         "",
                         "O",
                         "Code warning that non-criticial issue with the transaction is present. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Warning Code"
                      },
                      {
                        row: [
                         "34",
                         "responseCode *",
                         "String(5)",
                         "",
                         "",
                         "R",
                         "Response code returned by the gateway. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "https://unipaygateway.info/codes/your_gateway/processing_codes"
                      },
                      {
                        row: [
                         "35",
                         "responseMessage *",
                         "String(255)",
                         "",
                         "",
                         "R",
                         "Response message returned by the gateway. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "https://unipaygateway.info/codes/your_gateway/processing_codes*"
                      },
                      {
                        row: [
                         "36",
                         "providerResponseCode",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "Response code returned by an underlying processor/bank. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Provider Response Data"
                      },
                      {
                        row: [
                         "37",
                         "providerResponseMessage",
                         "String(260)",
                         "",
                         "",
                         "O",
                         "Response message returned by an underlying processor/bank. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Provider Response Data"
                      },
                      {
                        row: [
                         "38",
                         "avsResponseCode *",
                         "String(2)",
                         "",
                         "",
                         "O",
                         "AVS response code returned by the gateway (if address information has been provided). See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "https://unipaygateway.info/codes/your_gateway/processing_codes#AVS_Response_Codes*"
                      },
                      {
                        row: [
                         "39",
                         "providerAvsResponseCode *",
                         "String(5)",
                         "",
                         "",
                         "O",
                         "AVS response code returned by an underlying processor/bank. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Provider Response Data"
                      },
                      {
                        row: [
                         "40",
                         "cscResponseCode *",
                         "String(1)",
                         "",
                         "",
                         "O",
                         "CSC response code returned by the gateway (if CSC has been provided). See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "response codes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "https://unipaygateway.info/codes/your_gateway/processing_codes#CSC_Response_Codes*"
                      },
                      {
                        row: [
                         "41",
                         "providerCscResponseCode",
                         "String(3)",
                         "",
                         "",
                         "O",
                         "CSC response code returned by an underlying processor/bank. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Provider Response Data"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Interac * ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "42",
                         "macValue",
                         "String(20)",
                         "",
                         "",
                         "O",
                         "For Canadian transactions only. Value of a message authentication code (MAC). After submitted, the value of the field is not stored in the database.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "EBT Information ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "43",
                         "ebtFoodStampBalance",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Food stamp balance remaining on an EBT card.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "44",
                         "ebtCashBenefitBalance",
                         "Integer",
                         "",
                         "",
                         "O",
                         "Cash benefit balance remaining on an EBT card.",
                        ],
                        vinculoUsage: "Message Formats"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Terminals ",
                    headers:[
                     "#",
                     "Name",
                     "Type",
                     "Pattern",
                     "Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "45",
                         "ticketAmount",
                         "Integer",
                         "",
                         "cannot exceed total amount",
                         "E",
                         "ticketAmount used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "46",
                         "ticketNumber",
                         "String(20)",
                         "",
                         "",
                         "E",
                         "ticketNumber used in the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "47",
                         "holderVerificationModeType",
                         "Enum",
                         "",
                         "",
                         "E",
                         "holderVerificationModeType used in the transaction.",
                        ],
                        vinculoType: "Holder Verification Mode Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "48",
                         "entryModeType",
                         "Enum",
                         "",
                         "",
                         "E",
                         "entryModeType used in the transaction.",
                        ],
                        vinculoType: "Entry Mode Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "49",
                         "entryMediumType",
                         "Enum",
                         "",
                         "",
                         "E",
                         "entryMediumType used in the transaction.",
                        ],
                        vinculoType: "Entry Medium Type",
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "50",
                         "cardApplicationName",
                         "String(20)",
                         "",
                         "Application Preferred Name (tag 9F12)",
                         "O",
                         "Name of an EMV application associated with a chip that has been used to process the transaction.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "51",
                         "receiptTagData *",
                         "String(35)",
                         "",
                         "",
                         "R",
                         "EMV tags associated with the transaction and shown on a receipt. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Receipt Requirements"
                      },
                      {
                        row: [
                         "52",
                         "paymentNetworkName",
                         "String(10)",
                         "",
                         "",
                         "O",
                         "Name of the network associated with a payment card.",
                        ],
                        vinculoName: "Processor Rules Payment Network Name",
                        vinculoUsage: "Message Formats",
                        descripcion1: "Note: This field requires ",
                        seemoreinformation1: "special handling",
                        seemoreinformationcom1: " for some processors.",
                        modalname1: "Processor Rules Payment Network Name"
                      },
                      {
                        row: [
                         "53",
                         "terminalMessage",
                         "String(100)",
                         "",
                         "",
                         "I",
                         "System field for exchange of control messages between TMS system and a terminal. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Terminal Message"
                      }
                    ]
                  }
                },
                {
                  titulo: "Subrecords (Sale Response)",
                  tabla: {
                    titulotabla: "Split Subrecord ",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "accountId",
                         "Reference",
                         "",
                         "E",
                         "Reference to an account that has received funds as a result of a split payment processing.",
                        ],
                        vinculoType: "Reference Field Type",
                        vinculoUsage: "Message Formats",
                      },
                      {
                        row: [
                         "2",
                         "transactionId ",
                         "String(60)",
                         "",
                         "R",
                         "Identifier of the split transaction. Provided by the gateway. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Record ID"
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  tabla: {
                    titulotabla: "Split Item Subrecord ",
                    headers:[
                     "N°",
                     "Name",
                     "Type",
                     "Possible values / Format",
                     "Usage",
                     "Description",
                    ],
                    body: [
                      {
                        row: [
                         "1",
                         "itemId",
                         "String(60)",
                         "",
                         "SR",
                         "Identifier of an item. Provided by the gateway. See ",
                        ],
                        vinculoUsage: "Message Formats",
                        seemoreinformation0: "integration notes",
                        seemoreinformationcom0: " for more information.",
                        modalname0: "Record ID"
                      },
                      {
                        row: [
                         "2",
                         "code ",
                         "String(12)",
                         "",
                         "E",
                         "Identifier of an item within a submitter's system.",
                        ],
                        vinculoUsage: "Message Formats"
                      },
                      {
                        row: [
                         "3",
                         "splits ",
                         "List<Split>",
                         "",
                         "C",
                         "List of split payment scenarios included in the transaction.",
                        ],
                        vinculoType: "Split SubrecordSale",
                        vinculoUsage: "Message Formats",
                      }
                    ]
                  }
                }

              ],
              
              tablasexample: [
                {
                  titulo: "Examples (Sale)",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=myUsername&password=myP%40ssword&accountId=2001&amount=5000&accountType=R&transactionIndustryType=RE&holderType=P&holderName=John+Smith&accountNumber=4111111111111111&accountAccessory=0430&street=12+Main+St&city=Denver&state=CO&zipCode=30301&customerAccountCode=0000000001&transactionCode=0000000001",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0430",
                      },
                      {
                        key: "street",
                        value: "12 Main St",
                      },
                      {
                        key: "city",
                        value: "Denver",
                      },
                      {
                        key: "state",
                        value: "CO",
                      },
                      {
                        key: "zipCode",
                        value: "30301",
                      },
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      }                    
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response",
                    path: "",
                    parameters: "responseType=sale&transactionId=123456&accountId=2001&transactionDate=20101027&responseCode=A01&responseMessage=Approved&transactionCode=0000000001&avsResponseCode=N&approvalCode=TAS326&cycleCode=8765&accountType=R&extendedAccountType=VC&providerResponseCode=AA&providerResponseMessage=Approved",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionDate",
                        value: "20101027",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "avsResponseCode",
                        value: "N",
                      },
                      {
                        key: "approvalCode",
                        value: "TAS326",
                      },
                      
                      
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "providerResponseCode",
                        value: "AA",
                      },
                      {
                        key: "providerResponseMessage",
                        value: "Approved",
                      }                   
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with Track Data",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&accountType=L&transactionIndustryType=PT&transactionCode=1212121&accountData=!4F07A0000000031010500B564953412043524544495457114761739001010119D151220117589893895A0847617390010101195F201A56495341204143515549524552205445535420434152442030315F24031512315F280208405F300202015F3401019F120F4352454449544F20444520564953419F1A0208409F1E083830323437363932&amount=5000&zipCode=00000&customerAccountCode=0000000001&driverNumber=123456&odometrReading=10000&terminalType=POS&petroleumItems=(code=001;type=F;quantity=1;unitCostAmount=2000)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountData",
                        value: "(~2001)021301000F3D0000%*4111********1111^SMITH/JOHN^*****************************?*8D40AD26E14C98F73B0210CD5F18A62ACF2B655EABC19BC10DAEEB870B42D6EADC20CE84E3677286B56D6F1CB7C4276C6BF10A91DB9E5947CA68AFD5884C2737312165237673628FB321C9B5F5094594C71F123CE8980E3482F3CB89629949003A0002E000E759D903",
                      },
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      },
                      {
                        key: "transactionCode",
                        value: "1212121",
                      }                
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response with Track Data",
                    path: "",
                    parameters: "responseType=sale&transactionId=123456&accountId=2001&transactionDate=20110316&responseCode=A01&responseMessage=Approved&transactionCode=0000000001&avsResponseCode=N&approvalCode=TAS326&token=XVC01P0000000084632147254611114111001111&cycleCode=8765&accountType=R&extendedAccountType=VC",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionDate",
                        value: "20110316",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "avsResponseCode",
                        value: "N",
                      },
                      {
                        key: "approvalCode",
                        value: "TAS326",
                      },
                      {
                        key: "token",
                        value: "XVC01P0000000084632147254611114111001111",
                      },
                      {
                        key: "cycleCode	",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      }                  
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with Proxy Number",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=myUsername&password=myP%40ssword&accountId=2001&transactionIndustryType=RE&amount=1500&accountType=R&accountNumber=*40e0525f-fa12-4218-a708-77465acf6de6+&accountAccessory=0422",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "amount",
                        value: "1500",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "*40e0525f-fa12-4218-a708-77465acf6de6",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      }                
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response with Proxy Number",
                    path: "",
                    parameters: "extendedAccountType=VC&holderName=John+Smith&accountType=R&accountNumberMasked=4***********1111&cycleCode=892",
                    body: [
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "holderName",
                        value: "John+Smith",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "4***********1111",
                      },
                      {
                        key: "cycleCode",
                        value: "892",
                      }           
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request for Lodging",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=myUsername&password=myP%40ssword&accountId=2001&transactionIndustryType=LD&transactionCode=0000000001&amount=5000&holderType=P&holderName=John+Smith&accountType=R&accountData=!4F07A0000000041010500A4D41535445524341524457115413330089020110D2512601079360805F820230008407A0000000041010950500000000009A031412309B02E8009C01005F2A0208405F3401039F02060000000015009F0607A00000000410109F090200029F10120210A00000000000000000000000000000FF9F120A4D6173746572436172649F1A0208409F1E0838303234373639329F21031107349F2608264E8FDAE6E596D69F2701809F3303E0B8C89F34034103029F3501229F360200019F37049B82EA999F3901059F4005F000F0A0019F4104000000139F530152&isPartialAuthorization=1&customerAccountCode=0000000001&taxIndicator=N&roomNumber=1515&folioNumber=333&roomRateAmount=1500&roomTaxAmount=150&lodgingChargeType=H&checkInDate=2013-08-15&checkOutDate=2013-08-18&duration=3&specialProgramType=NC&extraChargesAmount=100&extraCharges=(code=001;type=R;amount=100)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "LD",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountData",
                        value: "!4F07A0000000041010500A4D41535445524341524457115413330089020110D2512601079360805F820230008407A0000000041010950500000000009A031412309B02E8009C01005F2A0208405F3401039F02060000000015009F0607A00000000410109F090200029F10120210A00000000000000000000000000000FF9F120A4D6173746572436172649F1A0208409F1E0838303234373639329F21031107349F2608264E8FDAE6E596D69F2701809F3303E0B8C89F34034103029F3501229F360200019F37049B82EA999F3901059F4005F000F0A0019F4104000000139F530152",
                      },
                      {
                        key: "isPartialAuthorization",
                        value: "1",
                      },
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      },
                      {
                        key: "taxIndicator",
                        value: "N",
                      },
                      {
                        key: "roomNumber",
                        value: "1515",
                      },
                      {
                        key: "folioNumber",
                        value: "333",
                      },
                      {
                        key: "roomRateAmount",
                        value: "1500",
                      },
                      {
                        key: "roomTaxAmount",
                        value: "150",
                      },
                      {
                        key: "lodgingChargeType",
                        value: "H",
                      },
                      {
                        key: "checkInDate",
                        value: "2013-08-15",
                      },
                      {
                        key: "checkOutDate",
                        value: "2013-08-18",
                      },
                      {
                        key: "duration",
                        value: "3",
                      },
                      {
                        key: "specialProgramType",
                        value: "NC",
                      },
                      {
                        key: "extraChargesAmount",
                        value: "100",
                      },
                      {
                        key: "extraCharges",
                        value: "(code=001;type=R;amount=100)",
                      },                                       
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response for Lodging",
                    path: "",
                    parameters: "responseType=sale&transactionId=123456&accountId=2001&transactionDate=20151110&responseCode=A01&responseMessage=Approved&transactionCode=0000000001&cycleCode=192&approvalCode=1261757124&accountAccessory=0422&accountData=!8A023030910A35373537353735373537&accountNumberMasked=5***********0110&accountType=R&amount=1500&cardApplicationName=MasterCard&currencyCode=USD&entryMediumType=CC&entryModeType=MX&extendedAccountType=MC&holderName=John+Smith&originalAmount=1500&receiptTagData=4F:A0000000041010;95:0000000000;9F10:0210A00000000000000000000000000000FF;9B:E800;91:35373537353735373537;8A:00&terminalId=001&token=MC10000000000102150110",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionDate",
                        value: "20151110",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "cycleCode",
                        value: "192",
                      },
                      {
                        key: "approvalCode",
                        value: "1261757124",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "accountData",
                        value: "!8A023030910A35373537353735373537",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "5***********0110",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "amount",
                        value: "1500",
                      },
                      {
                        key: "cardApplicationName",
                        value: "MasterCard",
                      },
                      {
                        key: "currencyCode",
                        value: "USD",
                      },
                      {
                        key: "entryMediumType",
                        value: "CC",
                      },
                      {
                        key: "entryModeType",
                        value: "MX",
                      },
                      {
                        key: "extendedAccountType",
                        value: "MC",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "originalAmount",
                        value: "1500",
                      },
                      {
                        key: "receiptTagData",
                        value: "4F:A0000000041010;95:0000000000;9F10:0210A00000000000000000000000000000FF;9B:E800;91:35373537353735373537;8A:00",
                      },
                      {
                        key: "terminalId",
                        value: "001",
                      },
                      {
                        key: "token",
                        value: "MC10000000000102150110",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request for Car Rental",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=MyUserName&password=myP%40ssword&accountId=2001&transactionIndustryType=PT&amount=2300&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&rentalName=rentalName&rentalDate=20150609&returnDate=20150609&agreementNumber=agreementNumber&duration=10&specialProgramType=AD&rentalCity=City&rentalState=AL&rentalCountryCode=US&rentalTime=0930&returnCity=returnCity&returnState=OT&returnCountryCode=CA&returnTime=1530&returnLocationCode=10&dailyRateAmount=200&mileRateAmount=300&totalMiles=500&maxFreeMiles=600&insuranceAmount=800&extraChargesAmount=500&extraCharges=(code=S01;type=S;amount=1000)(code=M01;type=M;amount=500)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "MyUserName",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "PT",
                      },
                      {
                        key: "amount",
                        value: "2300",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "rentalName",
                        value: "rentalName",
                      },
                      {
                        key: "rentalDate",
                        value: "20150609",
                      },
                      {
                        key: "returnDate",
                        value: "20150609",
                      },
                      {
                        key: "agreementNumber",
                        value: "agreementNumber",
                      },
                      {
                        key: "duration",
                        value: "10",
                      },
                      {
                        key: "specialProgramType",
                        value: "AD",
                      },
                      {
                        key: "rentalCity",
                        value: "City",
                      }, 
                      {
                        key: "rentalState",
                        value: "AL",
                      },   
                      {
                        key: "rentalCountryCode",
                        value: "US",
                      },   
                      {
                        key: "rentalTime",
                        value: "0930",
                      },   
                      {
                        key: "returnCity",
                        value: "returnCity",
                      },    
                      {
                        key: "returnState",
                        value: "OT",
                      },   
                      {
                        key: "returnCountryCode",
                        value: "CA",
                      },   
                      {
                        key: "returnTime",
                        value: "1530",
                      },   
                      {
                        key: "returnLocationCode",
                        value: "10",
                      },   
                      {
                        key: "dailyRateAmount",
                        value: "200",
                      },   
                      {
                        key: "mileRateAmount",
                        value: "300",
                      },   
                      {
                        key: "totalMiles",
                        value: "500",
                      }, 
                      {
                        key: "maxFreeMiles",
                        value: "600",
                      }, 
                      {
                        key: "insuranceAmount",
                        value: "800",
                      }, 
                      {
                        key: "extraChargesAmount",
                        value: "500",
                      }, 
                      {
                        key: "extraCharges",
                        value: "(code=S01;type=S;amount=1000)(code=M01;type=M;amount=500)",
                      },                    
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response for Car Rental",
                    path: "",
                    parameters: "responseType=sale&cycleCode=8765&accountType=R",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      }       
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with Items",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale-auth&userName=myUsername&password=myP%40ssword&accountId=2001&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&csc=111&transactionIndustryType=RE&amount=120000&holderName=John+Smith&items=(code=001;quantity=1;description=t-shirt;totalAmount=50000)(code=002;quantity=1;description=jeans;totalAmount=70000)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "csc",
                        value: "111",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "amount",
                        value: "120000",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "items",
                        value: "(code=001;quantity=1;description=t-shirt;totalAmount=50000)(code=002;quantity=1;description=jeans;totalAmount=70000)",
                      }                 
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response with Items",
                    path: "",
                    parameters: "responseType=sale-auth&accountId=2001&accountAccessory=0422&accountNumberMasked=4********1111&responseCode=A01&holderName=John+Smith&responseMessage=Approved&amount=120000&currencyCode=USD&transactionDate=20180131&transactionId=123456&cycleCode=09876549&accountType=R&extendedAccountType=VC&items=(code=001;quantity=1;description=t-shirt;totalAmount=50000)(code=002;quantity=1;description=jeans;totalAmount=70000)",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "4********1111",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "amount",
                        value: "120000",
                      },
                      {
                        key: "currencyCode",
                        value: "USD",
                      },
                      {
                        key: "transactionDate",
                        value: "20180131",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "cycleCode",
                        value: "09876549",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "items",
                        value: "(code=001;quantity=1;description=t-shirt;totalAmount=50000)(code=002;quantity=1;description=jeans;totalAmount=70000)",
                      }                   
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with EMV Data",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=myUsername&password=myP%40ssword&accountId=2001&accountType=R&amount=1500&holderType=P&holderName=John+Smith&transactionIndustryType=RE&accountData=!4F07A0000000041010500A4D41535445524341524457115413330089020110D2512601079360805F820230008407A0000000041010950500000000009A031412309B02E8009C01005F2A0208405F3401039F02060000000015009F0607A00000000410109F090200029F10120210A00000000000000000000000000000FF9F120A4D6173746572436172649F1A0208409F1E0838303234373639329F21031107349F2608264E8FDAE6E596D69F2701809F3303E0B8C89F34034103029F3501229F360200019F37049B82EA999F3901059F4005F000F0A0019F4104000000139F530152",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "amount",
                        value: "1500",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "accountData",
                        value: "!4F07A0000000041010500A4D41535445524341524457115413330089020110D2512601079360805F820230008407A0000000041010950500000000009A031412309B02E8009C01005F2A0208405F3401039F02060000000015009F0607A00000000410109F090200029F10120210A00000000000000000000000000000FF9F120A4D6173746572436172649F1A0208409F1E0838303234373639329F21031107349F2608264E8FDAE6E596D69F2701809F3303E0B8C89F34034103029F3501229F360200019F37049B82EA999F3901059F4005F000F0A0019F4104000000139F530152",
                      }                  
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response with EMV Data",
                    path: "",
                    parameters: "responseType=sale&transactionId=123456&entryModeType=MX&cardApplicationName=MasterCard&amount=1500&responseCode=A01&responseMessage=Approved&originalAmount=1500&extendedAccountType=MC&token=MC10000000000102150110&accountAccessory=0422&entryMediumType=CC&currencyCode=USD&accountData=!8A023030910A35373537353735373537&approvalCode=524201530&transactionDate=20151105&holderName=John+Smith&warningCode=00&accountId=2001&accountType=R&receiptTagData=4F:A0000000041010;95:0000000000;9F10:0210A00000000000000000000000000000FF;9B:E800;91:35373537353735373537;8A:00&accountNumberMasked=5***********0110&cycleCode=892",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "entryModeType",
                        value: "MX",
                      },
                      {
                        key: "cardApplicationName",
                        value: "MasterCard",
                      },
                      {
                        key: "amount",
                        value: "1500",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "originalAmount",
                        value: "1500",
                      },
                      {
                        key: "extendedAccountType",
                        value: "MC",
                      },
                      {
                        key: "token",
                        value: "MC10000000000102150110",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "entryMediumType",
                        value: "CC",
                      },
                      {
                        key: "currencyCode",
                        value: "USD",
                      },
                      {
                        key: "accountData",
                        value: "!8A023030910A35373537353735373537",
                      },
                      {
                        key: "approvalCode",
                        value: "524201530",
                      },
                      {
                        key: "transactionDate",
                        value: "20151105",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "warningCode",
                        value: "00",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "receiptTagData",
                        value: "4F:A0000000041010;95:0000000000;9F10:0210A00000000000000000000000000000FF;9B:E800;91:35373537353735373537;8A:00",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "5***********0110",
                      },
                      {
                        key: "cycleCode",
                        value: "892",
                      },                   
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with PIN Debit",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "holderName=John+Smith&accountNumber=4111111111111111&accountAccessory=0422",
                    body: [
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      }          
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response with PIN Debit",
                    path: "",
                    parameters: "",
                    body: [
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with PIN Debit",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "",
                    body: [    
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request for Voice Authorization",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "password=myP%40ssword",
                    body: [
                      {
                        key: "password",
                        value: "myP%40ssword",
                      }         
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response for Voice Authorization",
                    path: "",
                    parameters: "responseType=sale-auth&transactionId=123456&cycleCode=8765&accountType=R&extendedAccountType=VC",
                    body: [
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      }     
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request via HPP",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=myUsername&password=myP%40ssword&accountId=2001&amount=5000&notifyURL=http%3A%2F%2Fyour.domain.com%2Fnotify&accountType=R&transactionIndustryType=RE&accountAccessory=0435&holderName=John+Smith",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },  
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "notifyURL",
                        value: "http%3A%2F%2Fyour.domain.com%2Fnotify",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "accountAccessory",
                        value: "0435",
                      },    
                      {
                        key: "holderName",
                        value: "John+Smith",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response via HPP",
                    path: "",
                    parameters: "If you are submitting a sale transaction via hosted payment page, Secure Payment Form will be opened as a response and your screen will look similiar to the screenshot.",
                    body: [
                      
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with Processing Mode",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=myUsername&password=myP%40ssword&accountId=2001&terminalId=001&accountType=R&transactionIndustryType=RE&amount=5000&processingMode=request&notifyURL=http://your.domain.com/notify",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },  
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "terminalId",
                        value: "001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },    
                      {
                        key: "processingMode",
                        value: "request",
                      },    
                      {
                        key: "notifyURL",
                        value: "http://your.domain.com/notify",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response with Processing Mode",
                    path: "",
                    parameters: "responseType=sale&responseCode=A10&responseMessage=Request+Accepted&accountId=2001&transactionCode=123456&cycleCode=8765&requestId=r756ddb8e-66ed-4ec9-833e-9fea835ec0b7",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "responseCode",
                        value: "A10",
                      },
                      {
                        key: "responseMessage",
                        value: "Request+Accepted",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionCode",
                        value: "123456",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "requestId",
                        value: "r756ddb8e-66ed-4ec9-833e-9fea835ec0b7",
                      },
                           
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with Multiple Vendors for Marketplace",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=myUserName&password=myP%40ssword&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&terminalId=001&amount=500&transactionIndustryType=RE&distributions=(code=1;amount=200)(code=6;amount=300)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },  
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "terminalId",
                        value: "001",
                      },
                      {
                        key: "amount",
                        value: "500",
                      },    
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },    
                      {
                        key: "distributions",
                        value: "(code=1;amount=200)(code=6;amount=300)",
                      }
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response with Multiple Vendors for Marketplace",
                    path: "",
                    parameters: "responseType=sale&transactionId=123456&amount=500&responseCode=A01&responseMessage=Approved&originalAmount=500&extendedAccountType=VC&token=VC84632147254611111111&accountAccessory=0422&approvalCode=1754486860&transactionDate=20150923&warningCode=00&accountId=2001&accountType=R&accountNumberMasked=4***********1111&cycleCode=8765",
                    body: [
                      {
                        key: "responseType",
                        value: "sale-auth",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "amount",
                        value: "500",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "originalAmount",
                        value: "500",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "token",
                        value: "VC84632147254611111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "approvalCode",
                        value: "1754486860",
                      },
                      {
                        key: "transactionDate",
                        value: "20150923",
                      },
                      {
                        key: "warningCode",
                        value: "00",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "4***********1111",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      }  
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request for Petroleum",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=myUsername&password=myP%40ssword&accountId=2001&accountType=L&transactionIndustryType=PT&transactionCode=1212121&accountData=!4F07A0000000031010500B564953412043524544495457114761739001010119D151220117589893895A0847617390010101195F201A56495341204143515549524552205445535420434152442030315F24031512315F280208405F300202015F3401019F120F4352454449544F20444520564953419F1A0208409F1E083830323437363932&amount=5000&zipCode=00000&customerAccountCode=0000000001&driverNumber=123456&odometrReading=10000&terminalType=POS&petroleumItems=(code=001;type=F;quantity=1;unitCostAmount=2000)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "L",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "PT",
                      },
                      {
                        key: "transactionCode",
                        value: "1212121",
                      },
                      {
                        key: "accountData",
                        value: "!4F07A0000000031010500B564953412043524544495457114761739001010119D151220117589893895A0847617390010101195F201A56495341204143515549524552205445535420434152442030315F24031512315F280208405F300202015F3401019F120F4352454449544F20444520564953419F1A0208409F1E083830323437363932",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "zipCode",
                        value: "00000",
                      },
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      },
                      {
                        key: "driverNumber",
                        value: "123456",
                      },
                      {
                        key: "odometrReading",
                        value: "10000",
                      },
                      {
                        key: "terminalType",
                        value: "POS",
                      },
                      {
                        key: "petroleumItems",
                        value: "(code=001;type=F;quantity=1;unitCostAmount=2000)",
                      }                    
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response for Petroleum",
                    path: "",
                    parameters: "responseType=sale&transactionId=123456&transactionDate=20151110&transactionCode=1212121&responseCode=A01&responseMessage=Approved&approvalCode=517885238&amount=1500&originalAmount=1500&accountId=2001&cycleCode=192&accountData=!8A023030910A35373537353735373537&accountNumberMasked=4***********0119&accountType=L&cardApplicationName=CREDITO+DE+VISA&currencyCode=USD&entryMediumType=CC&entryModeType=MX&extendedAccountType=VC&receiptTagData=4F:A0000000031010;95:;9F10:;9B:;91:35373537353735373537;8A:00&token=VC10000000000102060119",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "transactionDate",
                        value: "20151110",
                      },
                      {
                        key: "transactionCode",
                        value: "1212121",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "approvalCode",
                        value: "517885238",
                      },
                      {
                        key: "amount",
                        value: "1500",
                      },
                      {
                        key: "originalAmount",
                        value: "1500",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "cycleCode",
                        value: "192",
                      },
                      {
                        key: "accountData",
                        value: "!8A023030910A35373537353735373537",
                      },
                      {
                        key: "accountNumberMasked",
                        value: "4***********0119",
                      },
                      {
                        key: "accountType",
                        value: "L",
                      },
                      {
                        key: "cardApplicationName",
                        value: "CREDITO DE VISA",
                      },
                      {
                        key: "currencyCode",
                        value: "USD",
                      },
                      {
                        key: "entryMediumType",
                        value: "CC",
                      },
                      {
                        key: "entryModeType	",
                        value: "MX",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "receiptTagData",
                        value: "4F:A0000000031010;95:;9F10:;9B:;91:35373537353735373537;8A:00",
                      },
                      {
                        key: "token",
                        value: "VC10000000000102060119",
                      }                       
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with Convenience Fee",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=myUsername&password=myP%40ssword&accountId=2001&terminalId=001&amount=5000&accountType=R&transactionIndustryType=RE&holderType=P&holderName=John+Smith&accountNumber=4111111111111111&accountAccessory=0422&street=12+Main+St&city=Denver&state=CO&zipCode=30301&customerAccountCode=0000000001&transactionCode=0000000001&feeAmount=500",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "terminalId",
                        value: "001",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      }, 
                      {
                        key: "street",
                        value: "12 Main St",
                      },
                      {
                        key: "city",
                        value: "Denver",
                      },
                      {
                        key: "state",
                        value: "CO",
                      },
                      {
                        key: "zipCode",
                        value: "30301",
                      },
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "feeAmount",
                        value: "500",
                      }               
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response with Convenience Fee",
                    path: "",
                    parameters: "responseType=sale&transactionId=123456&accountId=2001&transactionDate=20101027&responseCode=A01&responseMessage=Approved&transactionCode=0000000001&avsResponseCode=N&approvalCode=TAS326&cycleCode=8765&accountType=R&extendedAccountType=VC",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionDate",
                        value: "20101027",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "avsResponseCode",
                        value: "N",
                      },
                      {
                        key: "approvalCode",
                        value: "TAS326",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      }                 
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with EBT card",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=myUsername&password=myP%40ssword&accountId=2001&amount=5000&accountType=F&transactionIndustryType=RE&holderType=P&holderName=John+Smith&accountNumber=4111111111111111&accountAccessory=0422&street=12+Main+St&city=Denver&state=CO&zipCode=30301&customerAccountCode=0000000001&transactionCode=0000000001&voucherNumber=123456789876543",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "amount",
                        value: "5000",
                      },
                      {
                        key: "accountType",
                        value: "F",
                      }, 
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "holderName",
                        value: "John Smith",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      }, 
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },                      
                      {
                        key: "street",
                        value: "12 Main St",
                      },
                      {
                        key: "city",
                        value: "Denver",
                      },
                      {
                        key: "state",
                        value: "CO",
                      },
                      {
                        key: "zipCode",
                        value: "30301",
                      },   
                      {
                        key: "customerAccountCode",
                        value: "0000000001",
                      },  
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "voucherNumber",
                        value: "123456789876543",
                      }              
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response with EBT card",
                    path: "",
                    parameters: "responseType=sale&transactionId=123456&accountId=2001&transactionDate=20101027&responseCode=A01&responseMessage=Approved&transactionCode=0000000001&avsResponseCode=N&approvalCode=TAS326&cycleCode=8765&accountType=F&extendedAccountType=EF",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "transactionDate",
                        value: "20101027",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      },
                      {
                        key: "transactionCode",
                        value: "0000000001",
                      },
                      {
                        key: "avsResponseCode",
                        value: "N",
                      },
                      {
                        key: "approvalCode",
                        value: "TAS326",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "accountType",
                        value: "F",
                      },
                      {
                        key: "extendedAccountType",
                        value: "EF",
                      }               
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with Split Payments",
                    path: "https://[server-name]/gates/xurl",
                    parameters: " requestType=sale&userName=myUsername&password=myP%40ssword&accountId=2001&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&holderType=P&csc=123&amount=13000&transactionIndustryType=RE&splits=(accountId=2001;amount=7000)(accountId=3001;amount=2000)(accountId=4001;amount=1000)",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "csc",
                        value: "123",
                      },
                      {
                        key: "amount",
                        value: "13000",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "splits",
                        value: "	(accountId=2001;amount=7000)(accountId=3001;amount=2000)(accountId=4001;amount=1000)",
                      }             
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response with Split Payments",
                    path: "",
                    parameters: "responseType=sale&accountId=2001&accountType=R&extendedAccountType=VC&amount=13000&originalAmount=13000&transactionDate=20170425&transactionId=123456&cycleCode=8765&splits=(accountId=2001;transactionId=S10001)(accountId=3001;transactionId=S10002)(accountId=4001;transactionId=S10003)&responseCode=A01&responseMessage=Approved",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "amount",
                        value: "13000",
                      },
                      {
                        key: "originalAmount",
                        value: "13000",
                      },
                      {
                        key: "transactionDate",
                        value: "20170425",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "splits",
                        value: "(accountId=2001;transactionId=S10001)(accountId=3001;transactionId=S10002)(accountId=4001;transactionId=S10003)",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      }                  
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: true,
                  tabla: {
                    titulotabla: "Sale Request with Split Payments and Items",
                    path: "https://[server-name]/gates/xurl",
                    parameters: "requestType=sale&userName=myUsername&password=myP%40ssword&accountId=2001&accountType=R&accountNumber=4111111111111111&accountAccessory=0422&holderType=P&csc=123&amount=13000&transactionIndustryType=RE&items=(code=001;description=t-shirt;quantity=1;totalAmount=50000;splits=(accountId=2001;amount=7000)(accountId=3001;amount=2000))(code=002;description=jeans;quantity=1;totalAmount=80000;splits=(accountId=2001;amount=9000)(accountId=3001;amount=2000)(accountId=4001;amount=3000))",
                    body: [
                      {
                        key: "requestType",
                        value: "sale",
                      },
                      {
                        key: "userName",
                        value: "myUsername",
                      },
                      {
                        key: "password",
                        value: "myP%40ssword",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "accountNumber",
                        value: "4111111111111111",
                      },
                      {
                        key: "accountAccessory",
                        value: "0422",
                      },
                      {
                        key: "holderType",
                        value: "P",
                      },
                      {
                        key: "csc",
                        value: "123",
                      },
                      {
                        key: "amount",
                        value: "13000",
                      },
                      {
                        key: "transactionIndustryType",
                        value: "RE",
                      },
                      {
                        key: "items",
                        value: "(code=001;description=t-shirt;quantity=1;totalAmount=50000;splits=(accountId=2001;amount=7000)(accountId=3001;amount=2000))(code=002;description=jeans;quantity=1;totalAmount=80000;splits=(accountId=2001;amount=9000)(accountId=3001;amount=2000)(accountId=4001;amount=3000))",
                      }             
                    ]
                  }
                },
                {
                  titulo: "",
                  isrequest: false,
                  tabla: {
                    titulotabla: "Sale Response with Split Payments and Items",
                    path: "",
                    parameters: "responseType=sale&accountId=2001&accountType=R&extendedAccountType=VC&amount=13000&originalAmount=13000&transactionDate=20170425&transactionId=123456&cycleCode=8765&items=(code=001;itemId=I123;splits=(accountId=5001;transactionId=S10001)(accountId=3001;transactionId=S10002))(code=002;itemId=I124;splits=(accountId=3001;transactionId=S10003)(accountId=4001;transactionId=S10004))&responseCode=A01&responseMessage=Approved",
                    body: [
                      {
                        key: "responseType",
                        value: "sale",
                      },
                      {
                        key: "accountId",
                        value: "2001",
                      },
                      {
                        key: "accountType",
                        value: "R",
                      },
                      {
                        key: "extendedAccountType",
                        value: "VC",
                      },
                      {
                        key: "amount",
                        value: "13000",
                      },
                      {
                        key: "originalAmount",
                        value: "13000",
                      },
                      {
                        key: "transactionDate",
                        value: "20170425",
                      },
                      {
                        key: "transactionId",
                        value: "123456",
                      },
                      {
                        key: "cycleCode",
                        value: "8765",
                      },
                      {
                        key: "items",
                        value: "(code=001;itemId=I123;splits=(accountId=2001;transactionId=S10001)(accountId=3001;transactionId=S10002))(code=002;itemId=I124;splits=(accountId=3001;transactionId=S10003)(accountId=4001;transactionId=S10004))",
                      },
                      {
                        key: "responseCode",
                        value: "A01",
                      },
                      {
                        key: "responseMessage",
                        value: "Approved",
                      }                  
                    ]
                  }
                },
              ]
            },






            {//////////////////////////////////////////
              titulo: "Credit",
              notas: "credit-auth and capture in a single process.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Extended Logic" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Shipping Address Information *" },
                  { nombre: "Level II Transaction Information * " },
                  { nombre: "Level III Transaction Information *" },
                  { nombre: "Restaurant Industry *" },
                  { nombre: "PIN Debit/EBT cards *" },
                  { nombre: "Interac *" },
                  { nombre: "3D Secure *" },
                  { nombre: "Healthcare Industry *" },
                  { nombre: "Petroleum *" },
                  { nombre: "Lodging *" },
                  { nombre: "Identity Verification *" },
                  { nombre: "Terminals" },
                  { nombre: "Check 21" },
                  { nombre: "Car Rental" }
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [

                  { nombre: "Account Data is used" },
                  { nombre: "Account Number is used" },
                  { nombre: "Token is used" },
                  { nombre: "LodgingChargeType = H" },
                  { nombre: "LodgingChargeType ≠ H" },
                  { nombre: "Splits field is used" },
                  { nombre: "SplitSchemaId field is used" }

                ],
                subtitulo3: "Subrecords",
                arraytitulo3: [

                  { nombre: "Split Subrecord" },
                  { nombre: "Split Item Subrecord" },
                  { nombre: "Level III Item Subrecord" },
                  { nombre: "Petroleum Item Subrecord" },
                  { nombre: "Lodging Extra Charge Subrecord" },
                  { nombre: "Car Rental Extra Charge Subrecord" },

                ],
                subtitulo4: "Examples",
                arraytitulo4: [

                  { nombre: "Credit Request" },
                  { nombre: "Credit Request with Split Payments" },
                  { nombre: "Credit Request with Split Payments and Items" },

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "General Information" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Extended Logic" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Response Codes Information" },
                  { nombre: "Interac *" },
                  { nombre: "EBT Information" },
                  { nombre: "Terminals" }

                ],
                subtitulo2: "",
                arraytitulo2: [


                ],
                subtitulo3: "Subrecords",
                arraytitulo3: [
                  { nombre: "Split Subrecord" },
                  { nombre: "Split Item Subrecord" }

                ],
                subtitulo4: "Examples",
                arraytitulo4: [

                  { nombre: "Credit Response" },
                  { nombre: "Credit Response with Split Payments" },
                  { nombre: "Credit Response with Split Payments and Items" }
                ],
              }],
            },



            {//////////////////////////////////////////
              titulo: "Void/Refund",
              notas: "an operation used to reverse a sale operation. See integration notes for more information.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" }

                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Reference Number is used" },
                  { nombre: "Original Transaction Code is used" }

                ],
                subtitulo3: "Subrecords",
                arraytitulo3: [
                  { nombre: "Split Item Subrecord" },

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Void Request" },
                  { nombre: "Refund Request" },
                  { nombre: "Void Request for Split Payment" },
                  { nombre: "Partial Void Request with Items" },

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Void Response" },
                  { nombre: "Refund Response" },
                  { nombre: "Void Response for Split Payment" },
                  { nombre: "Partial Void Response with Items" }

                ],
              }],
            },


            {//////////////////////////////////////////
              titulo: "Capture",
              notas: "confirms a previously requested authorization (sale or credit).",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Shipping Address Information *" },
                  { nombre: "Level II Transaction Information * " },
                  { nombre: "Level III Transaction Information *" },
                  { nombre: "Restaurant Industry *" },
                  { nombre: "Petroleum *" },
                  { nombre: "Lodging *" },

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "Subrecords",
                arraytitulo3: [
                  { nombre: "Level III Item Subrecord" },
                  { nombre: "Petroleum Item Subrecord" },
                  { nombre: "Lodging Extra Charge Subrecord" },

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Capture Request " },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Transaction Information" }
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Capture Response" }

                ],
              }],
            },



            {//////////////////////////////////////////
              titulo: "Account Verification",
              notas: "an operation used to verify that the account is active and perform AVS verification without actual authorization (0 dollar authorization).",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Interac *" },

                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Account Data is used" },
                  { nombre: "Account Number is used" },
                  { nombre: "Token is used" },

                ],
                subtitulo3: "",
                arraytitulo3: [


                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Account Verification Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "General Information" },
                  { nombre: "Response Codes Information" },
                  { nombre: "Interac *" }
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Account Verification Response" },

                ],
              }],
            },


            {//////////////////////////////////////////
              titulo: "Balance Inquiry",
              notas: "an operation used to verify balance on debit, prepaid and gift cards.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Interac *" },
                  { nombre: "Terminals" }

                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Account Data is used" },
                  { nombre: "Account Number is used" },

                ],
                subtitulo3: "",
                arraytitulo3: [


                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Balance Inquiry Request" },
                  { nombre: "Balance Inquiry Request with Account Data" },

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "General Information" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Response Codes Information" },
                  { nombre: "Terminals *" }

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Balance Inquiry Response" },
                  { nombre: "Balance Inquiry Response with Account Data" },
                ],
              }],
            },



            {//////////////////////////////////////////
              titulo: "Convenience Fee",
              notas: "an operation used to calculate a surcharge to the cardholder to cover the cost of the credit card processing.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Billing Address Information" }

                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Track Data is Used" },
                  { nombre: "Account Number is Used" }

                ],
                subtitulo3: "",
                arraytitulo3: [


                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Convenience Fee Request" }

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Convenience Fee Response" }
                ],
              }],
            },





            {//////////////////////////////////////////
              titulo: "Increment",
              notas: "allows to increase the authorization amount of already authorized transaction (to add payment for additional product or service)." +
                "<br/><br/><b>Note:</b> This operation is available only for transactions with transactionIndustryType=RE or LD.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" }

                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Transaction ID is used" },
                  { nombre: "Transaction Code is used" },
                  { nombre: "Token is used" },
                  { nombre: "Account Number is used" },


                ],
                subtitulo3: "",
                arraytitulo3: [


                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Increment Request" }

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Increment Response" }
                ],
              }],
            },




            {//////////////////////////////////////////
              titulo: "Authentication",
              notas: "an operation used to generate temporary password to be used in requests associated with HPP. See integration notes for more information.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                ],
                subtitulo2: "",
                arraytitulo2: [


                ],
                subtitulo3: "",
                arraytitulo3: [


                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Authentication Request" },
                  { nombre: "Authentication Request with Proxynization" }
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Authentication Response" },
                  { nombre: "Authentication Response with Proxynization" }
                ],
              }],
            },



            {//////////////////////////////////////////
              titulo: "Fetch",
              notas: "allows to get a transaction that was made when a terminal was offline (when processingMode=queue or processingMode=request).",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                ],
                subtitulo2: "",
                arraytitulo2: [


                ],
                subtitulo3: "",
                arraytitulo3: [


                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Fetch Request" }
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Fetch Response" }
                ],
              }],
            },

          ]

        },

        {
          titulo: "Tokenization",
          subdatos: [

            {//////////////////////////////////////////
              titulo: "Tokenization",
              notas: "allows conversion of a credit card number or a bank account number into a PCI compliant token value.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Billing Address Information" },
                  { nombre: "HPP Fields *" }

                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Account Number is used" },
                  { nombre: "Account Data is used" }

                ],
                subtitulo3: "",
                arraytitulo3: [


                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Tokenization Request" },
                  { nombre: "Tokenization Request via HPP" }
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Billing Address Information" },
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Tokenization Response" },
                  { nombre: "Tokenization Response via HPP" }
                ],
              }],
            },

            {//////////////////////////////////////////
              titulo: "Untokenization",
              notas: "allows manual removal of a token from the system.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Untokenization Request" }
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Untokenization Response" }
                ],
              }],
            },

            {//////////////////////////////////////////
              titulo: "Retokenization",
              notas: "allows updating of an existing token. <br/><br/><b/>Note:</b> This operation is available only for tokens created for real-time processing with TrustCommerce.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Billing Address Information" },
                  { nombre: "HPP Fields *" }
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Retokenization Request" }
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Retokenization Response" }
                ],
              }],
            }

          ]
        },

        {
          titulo: "Settlement",
          subdatos: [

            {//////////////////////////////////////////
              titulo: "Close Cycle",
              notas: "allows a submitter to close currently open retail cycle (batch) for an account, send all captured transactions within the cycle for settlement, see totals within a retail cycle and reverse any void transactions.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Close Cycle Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Close Cycle Response" },
                ],
              }],
            },

            {//////////////////////////////////////////
              titulo: "Balance Inquiry Cycle",
              notas: "allows a submitter to validate totals within a retail cycle before calling Close Cycle operation.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Balance Inquiry Cycle Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Balance Inquiry Cycle Response" },
                ],
              }],
            },

          ]

        },



        {
          titulo: "Gift/Loyalty Cards",
          subdatos: [

            {//////////////////////////////////////////
              titulo: "Activate",
              notas: "allows a merchant to activate a gift card.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "HPP Fields *" }
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Account Data is used" },
                  { nombre: "Account Number is used" },
                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Activate Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Activate Response" },
                ],
              }],
            },

            {//////////////////////////////////////////
              titulo: "Reactivate",
              notas: "allows a merchant to reactivate a previously deactivated gift card.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "HPP Fields *" }
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Account Data is used" },
                  { nombre: "Account Number is used" },
                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "",
                arraytitulo4: [

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "",
                arraytitulo4: [

                ],
              }],
            },

            {//////////////////////////////////////////
              titulo: "Deactivate",
              notas: "allows a merchant to deactivate an active gift card.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "HPP Fields *" }
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Account Data is used" },
                  { nombre: "Account Number is used" },
                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "",
                arraytitulo4: [

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "",
                arraytitulo4: [

                ],
              }],
            },


            {//////////////////////////////////////////
              titulo: "Transfer",
              notas: "allows to transfer a balance from one card to another card.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Source Account Information" },
                  { nombre: "Target Account Information" }

                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Source Account / Token is used" },
                  { nombre: "Source Account / Account Number is used" },
                  { nombre: "Source Account / Account Data is used" },
                  { nombre: "Target Account / Token is used" },
                  { nombre: "Target Account / Account Number is used" },
                  { nombre: "Target Account / Account Data is used" },

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Transfer Request with Account Number" },
                  { nombre: "Transfer Request with Account Data" },
                  { nombre: "Transfer Request with Token" },

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "General Information" },
                  { nombre: "Response Codes Information" },
                  { nombre: "Balance Information" },

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Transfer Response with Account Number" },
                  { nombre: "Transfer Response with Account Data" },
                  { nombre: "Transfer Response with Token" },
                ],
              }],
            },

            {//////////////////////////////////////////
              titulo: "Sale-Info",
              notas: "allows to supply information about a sale transaction, which was processed outside of the gateway, but affects rewards balance of loyalty cards.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Extended Logic" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Shipping Address Information *" },
                  { nombre: "Level II Transaction Information * " },
                  { nombre: "Level III Transaction Information *" },
                  { nombre: "Restaurant Industry *" },
                  { nombre: "PIN Debit/EBT cards *" },
                  { nombre: "Voice Authorization *" },
                  { nombre: "3D Secure *" },
                  { nombre: "Healthcare Industry *" },
                  { nombre: "Petroleum *" },
                  { nombre: "Lodging *" },
                  { nombre: "Identity Verification *" },
                  { nombre: "Check 21" },
                  { nombre: "Car Rental" }

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "Subrecords",
                arraytitulo3: [
                  { nombre: "Level III Item Subrecord" },
                  { nombre: "Petroleum Item Subrecord" },
                  { nombre: "Lodging Extra Charge Subrecord" },
                  { nombre: "Car Rental Extra Charge Subrecord" },

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Sale-Info Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "General Information" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Response Codes Information" },
                  { nombre: "EBT Information" }

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Sale-Info Response" },
                ],
              }],
            },


            {//////////////////////////////////////////
              titulo: "Credit-Info",
              notas: "allows to supply information about a credit transaction, which was processed outside of the gateway, but affects rewards balance of loyalty cards.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Extended Logic" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Shipping Address Information *" },
                  { nombre: "Level II Transaction Information * " },
                  { nombre: "Level III Transaction Information *" },
                  { nombre: "Restaurant Industry *" },
                  { nombre: "PIN Debit/EBT cards *" },
                  { nombre: "Voice Authorization *" },
                  { nombre: "3D Secure *" },
                  { nombre: "Healthcare Industry *" },
                  { nombre: "Petroleum *" },
                  { nombre: "Lodging *" },
                  { nombre: "Identity Verification *" },
                  { nombre: "Check 21" },
                  { nombre: "Car Rental" }

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "Subrecords",
                arraytitulo3: [
                  { nombre: "Level III Item Subrecord" },
                  { nombre: "Petroleum Item Subrecord" },
                  { nombre: "Lodging Extra Charge Subrecord" },
                  { nombre: "Car Rental Extra Charge Subrecord" },

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Credit-Info Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "General Information" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Response Codes Information" },
                  { nombre: "EBT Information" }

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Credit-Info Response" },
                ],
              }],
            },

            {//////////////////////////////////////////
              titulo: "Create Alias",
              notas: "allows to create an alias for a gift card. Similar to token, alias can be used instead of card number for further processing.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },

                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Account Data is used" },
                  { nombre: "Account Number is used" }

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Create Alias Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Create Alias Response" },
                ],
              }],
            },


            {//////////////////////////////////////////
              titulo: "Delete Alias",
              notas: "allows to remove the alias for a gift card.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },

                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Token is used" },
                  { nombre: "Account Data is used" },
                  { nombre: "Account Number is used" }

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Delete Alias Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Delete Alias Response" },
                ],
              }],
            },

          ]
        },






        {
          titulo: "Callbacks",
          subdatos: [

            {//////////////////////////////////////////
              titulo: "Callback",
              notas: "a callback issued to the URL supplied by a submitter for receiving notifications about offline operations.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "General Information" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Response Codes Information" },
                  { nombre: "EBT Information" },
                  { nombre: "Terminals" }
                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "",
                arraytitulo4: [
                ],

              }],

              info2: [{
                subtitulo: "",
                subtitulo1: "",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "",
                arraytitulo4: [
                ],
              }],
            }
          ]
        },

        {
          titulo: "Reporting",
          subdatos: [

            {//////////////////////////////////////////
              titulo: "Find",
              notas: "allows a submitter to retrieve response of a particular transaction using either transactionId or transactionCode value associated with the transaction." +
                "<br/><br/><b>Note:</b> Information about the following transaction types can be accessed via find API request: sale-auth, sale, credit, refund, sale-info, credit-info, account-verification, balance-inquiry, convenience-fee, capture, tokenization" +
                +"Make sure that your user has access to the merchant associated with the transaction that should be retrieved in the response. Otherwise, your request will result in an error.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Info" },
                  { nombre: "Transaction Information" }
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Transaction Code is used" },
                  { nombre: "Transaction ID is used" }
                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Find Request" },
                  { nombre: "Find Request for Token Transaction" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "General Information" },
                  { nombre: "Account Information" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" },
                  { nombre: "Extended Logic" },
                  { nombre: "Billing Address Information" },
                  { nombre: "Response Codes Information" },
                  { nombre: "Terminals" }

                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "Subrecord",
                arraytitulo3: [
                  { nombre: "Split Subrecord" },
                  { nombre: "Split Item Subrecord" }
                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Find Response" },
                  { nombre: "Find Response for Token Transaction" },
                ],
              }],
            },




            {//////////////////////////////////////////
              titulo: "Merchant Info",
              notas: "allows the submitter to get the business information about a merchant.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Merchant Info Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Merchant Info Response" },
                ],
              }],
            }

          ]
        },

        {
          titulo: "Miscellaneous",
          subdatos: [

            {//////////////////////////////////////////
              titulo: "Ping",
              notas: "allows to verify availability of the server and get the current version of the API.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Ping Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Ping Response" },
                ],
              }],
            },

            {////////////////////////////////////////// 
              titulo: "Upload Resource",
              notas: "allows a submitter to upload resources to the gateway.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Transaction Information" },
                  { nombre: "Cross-References" }

                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Reference Number is used" },
                  { nombre: "Transaction Code is used" },
                  { nombre: "Transaction Internal Code is used" }

                ],
                subtitulo3: "Subrecords",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Upload Resource Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Examples",
                arraytitulo4: [
                  { nombre: "Upload Resource Response" },
                ],
              }],
            },


            {//////////////////////////////////////////
              titulo: "Download Resource",
              notas: "allows a submitter to download resources from the gateway.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                  { nombre: "Core Infо" },
                  { nombre: "Transaction Information" },
                ],
                subtitulo2: "Conditions",
                arraytitulo2: [
                  { nombre: "Reference Number is used" },
                  { nombre: "Transaction Code is used" },
                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Download Resource Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Download Resource Response" },
                ],
              }],
            },


            {//////////////////////////////////////////
              titulo: "Migrate Terminal",
              notas: "allows to migrate a physical device from one accountId/terminalId to another accountId/terminalId.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "",
                arraytitulo4: [

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "",
                arraytitulo4: [

                ],
              }],
            },

            {//////////////////////////////////////////
              titulo: "Get Profile",
              notas: "allows a submitter to get information about stored data in cases when profiling/deprofiling is used.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [

                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "",
                arraytitulo4: [

                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "",
                arraytitulo4: [

                ],
              }],
            },


            {//////////////////////////////////////////
              titulo: "BIN Data",
              notas: "allows a submitter to extract BIN data from a credit card.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "BIN Data Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "BIN Data Response" },
                ],
              }],
            },


            {//////////////////////////////////////////
              titulo: "Bank Data",
              notas: "allows a submitter to extract bank data from a direct debit.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [

                ],
                subtitulo3: "",
                arraytitulo3: [

                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Bank Data Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Bank Data Response" },
                ],
              }],
            },
            {//////////////////////////////////////////
              titulo: "Split Schema",
              notas: "allows a submitter to create a split schema for subsequent funds distribution between several recipients.",
              info1: [{
                subtitulo: "Request",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                  
                ],
                subtitulo3: "Subrecord",
                arraytitulo3: [
                  { nombre: "Split Subrecord" },
                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Split Schema Request" },
                ],

              }],

              info2: [{
                subtitulo: "Response",
                subtitulo1: "Format",
                arraytitulo1: [
                ],
                subtitulo2: "",
                arraytitulo2: [
                ],
                subtitulo3: "",
                arraytitulo3: [
                ],
                subtitulo4: "Example",
                arraytitulo4: [
                  { nombre: "Split Schema Response" },
                ],
              }],
            },



          ]
        }




        ////////////////////////////////////////////
      ],



      tabladedatos: [
        {
          titulo: "R",
          descripcion: "required in request/always present in response for direct debit transactions and credit card transactions of all levels (I, II, III)."
        },
        {
          titulo: "O",
          descripcion: "optional in request/not always present in response."
        },
        {
          titulo: "C",
          descripcion: "echo back from request; if present in request, it is present in response, if it is not present in request, it is not present in response."
        },
        {
          titulo: "E",
          descripcion: "required in request/always present in response for credit card transactions of level II and III only; optional for direct debit and level I credit card transactions."
        },
        {
          titulo: "R2",
          descripcion: "	required in request/always present in response for credit card transactions of III only; optional for direct debit and level I, II credit card transactions."
        },
        {
          titulo: "R3",
          descripcion: "required in request/always present in response for credit card transactions of III only; optional for direct debit and level I, II credit card transactions."
        },
        {
          titulo: "SR",
          descripcion: "required in request/always present in response for split transactions only."
        },
        {
          titulo: "I",
          descripcion: "for internal use only."
        },
        {
          titulo: "N",
          descripcion: "not used."
        },
        {
          titulo: "*",
          descripcion: "required fields in these specific sections are only required if this specific feature is used."
        },

      ]
    }
  }


  async componentDidMount() {

    const query = new URLSearchParams(window.location.search);    //para funcion
    const query_loc = query.get('ban');
    console.log(query_loc)
    var resp = [];

    resp = await getDatos(query_loc);



    this.setState({ bandera: query_loc })
    this.setState({ titulo: resp.tituloge })
    this.setState({ contenido: resp.datos })
  }



  pasartexto = (texto) => {
    try {
      return parse(texto);
    } catch (error) {
      return texto;
    }
  }

  obtenerDatosTablas(tituloseccion, titulosubdato){
    console.log(tituloseccion);
    console.log(titulosubdato);
    this.setState({ tablasporseccionexamples: [] })
    this.setState({ tablasporseccion: [] })
     let datosseccion = this.state.arrayDatos.find(x => x.titulo == tituloseccion);
     let subdatosseccion = datosseccion.subdatos.find(x => x.titulo == titulosubdato);
     console.log(datosseccion);
     console.log(subdatosseccion);

     if(subdatosseccion.hasOwnProperty('tablas')){
      console.log(subdatosseccion.tablas);
      this.setState({ tablasporseccion: subdatosseccion.tablas })
     }
     if(subdatosseccion.hasOwnProperty('tablasexample')){
      console.log(subdatosseccion.tablasexample);
      this.setState({ tablasporseccionexamples: subdatosseccion.tablasexample })
     }
  }

  seleccionarModal(titulomodal){
    console.log(titulomodal);
    this.setState({ showmodal: titulomodal})
  }

  showModalExampleTest(contenido){
    this.setState({ modaltesttransaction: true})
  }

  cerrarModal = (accion) => {
    this.setState({ showmodal: "", modaltesttransaction: false })
    if (accion == 1) {

    }
}


  render() {
    return (
      <>
        <Navbar />
        
        {
          /** Modales Type */
        (this.state.showmodal == "Sale") ? 
          (<ModalSale tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Reference Field Type") ? 
          (<ModalReferenceFieldType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Account Type") ? 
          (<ModalAccountType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Holder Type") ? 
          (<ModalHolderType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "CSC Indicator Type") ? 
          (<ModalCSCIndicatorType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Currency Code Type") ? 
          (<ModalCurrencyCodeType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Transaction Industry Type") ? 
          (<ModalTransactionIndustryType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Transaction Category Type") ? 
          (<ModalTransactionCategoryType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Transaction Mode Type") ? 
          (<ModalTransactionModeType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Format Type") ? 
          (<ModalFormatType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Split Subrecord") ? 
          (<ModalSplitSubrecord tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Split Item Subrecord") ? 
          (<ModalSplitItemSubrecord tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Distribution Subrecord") ? 
          (<ModalDistributionSubrecord tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Tax Indicator Type") ? 
          (<ModalTaxIndicatorType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Level III Item Subrecord") ? 
          (<ModalLevelIIIItemSubrecord tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Return URL Policy Type") ? 
          (<ModalReturnURLPolicyType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Authentication Status Type") ? 
          (<ModalAuthenticationStatusType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Terminal Type") ? 
          (<ModalTerminalType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Petroleum Item Subrecord") ? 
          (<ModalPetroleumItemSubrecord tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Lodging Charge Type") ? 
          (<ModalLodgingChargeType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Special Program Type") ? 
          (<ModalSpecialProgramType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Lodging Extra Charge Subrecord") ? 
          (<ModalLodgingExtraChargeSubrecord tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Identity Verification Type") ? 
          (<ModalIdentityVerificationType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Driver License State") ? 
          (<ModalDriverLicenseState tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Entry Mode Type") ? 
          (<ModalEntryModeType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Entry Medium Type") ? 
          (<ModalEntryMediumType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Entry PIN Mode Type") ? 
          (<ModalEntryPINModeType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Terminal Capability Mode Type") ? 
          (<ModalTerminalCapabilityModeType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Processing Mode Type") ? 
          (<ModalProcessingModeType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Holder Verification Mode Type") ? 
          (<ModalHolderVerificationModeType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Fallback Reason Code Type") ? 
          (<ModalFallbackReasonCodeType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Car Rental Extra Charge Subrecord") ? 
          (<ModalCarRentalExtraChargeSubrecord tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Extra Charge Type") ? 
          (<ModalExtraChargeType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Petroleum Product Type") ? 
          (<ModalPetroleumProductType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Extended Account Type") ? 
          (<ModalExtendedAccountType tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Split Item SubrecordSale") ? 
          (<ModalSplitItemSubrecordSale tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Split SubrecordSale") ? 
          (<ModalSplitSubrecordSale tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :
          
           null}

        {
          /** Modales Pattern */
        (this.state.showmodal == "Validation Patterns Types") ? 
          (<ModalValidationPatternsTypes tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          : null}

        {
          /** Modales Usage */
        (this.state.showmodal == "Message Formats") ? 
          (<ModalMessageFormats tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          : null}

        {
          /** Modales Description */
          (this.state.showmodal == "Password Encoding") ? 
          (<ModalPasswordEncoding tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Legacy Fields Names") ? 
          (<ModalLegacyFieldsNames tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Terminal Code") ? 
          (<ModalTerminalCode tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Direct Debit") ? 
          (<ModalDirectDebit tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Account Data") ? 
          (<ModalAccountData tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Processor Rules") ? 
          (<ModalProcessorRules tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Processor Rules csc") ? 
          (<ModalProcessorRulesCsc tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Service Fees") ? 
          (<ModalServiceFees tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Stored Credentials") ? 
          (<ModalStoredCredentials tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Transaction Origin Code") ? 
          (<ModalTransactionOriginCode tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Cross-reference Fields") ? 
          (<ModalCrossReferenceFields tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Client Host") ? 
          (<ModalClientHost tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Settlement Group") ? 
          (<ModalSettlementGroup tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Bank Information Subscription") ? 
          (<ModalBankInformationSubscription tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Format Field") ? 
          (<ModalFormatField tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Dynamic Descriptor") ? 
          (<ModalDynamicDescriptor tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Subrecord Format") ? 
          (<ModalSubrecordFormat tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Split Payments") ? 
          (<ModalSplitPayments tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Interchange Rate Qualification") ? 
          (<ModalInterchangeRateQualification tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Processor RuleszipCode") ? 
          (<ModalProcessorRulesZipCode tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Transaction Levels") ? 
          (<ModalTransactionLevels tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Hosted Payment Page (HPP)") ? 
          (<ModalHostedPaymentPageHPP tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Page Format") ? 
          (<ModalPageFormat tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Embedded Mode") ? 
          (<ModalEmbeddedMode tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Transit Fields") ? 
          (<ModalTransitFields tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "3D Secure Verification") ? 
          (<Modal3DSecureVerification tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Healthcare Industry Transactions") ? 
          (<ModalHealthcareIndustryTransactions tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Processor Rules GuestName") ? 
          (<ModalProcessorRulesGuestName tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Identity Verification") ? 
          (<ModalIdentityVerification tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Terminal Data Entry") ? 
          (<ModalTerminalDataEntry tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Offline Transactions") ? 
          (<ModalOfflineTransactions tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Fallback Transactions") ? 
          (<ModalFallbackTransactions tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Split Amount Format") ? 
          (<ModalSplitAmountFormat tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Terminal ID") ? 
          (<ModalTerminalID tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Holder Name Normalization Rules") ? 
          (<ModalHolderNameNormalizationRules tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Record ID") ? 
          (<ModalRecordID tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Provider Response Data") ? 
          (<ModalProviderResponseData tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Warning Code") ? 
          (<ModalWarningCode tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Processor Rules Payment Network Name") ? 
          (<ModalProcessorRulesPaymentNetworkName tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Terminal Message") ? 
          (<ModalTerminalMessage tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :(this.state.showmodal == "Receipt Requirements") ? 
          (<ModalReceiptRequirements tipo="full" show={ this.state.showmodal != "" ? true: false} cerrarModal={this.cerrarModal} />) 
          :
          
           null}


{
          /** Modales Examples Test Transation */
        (this.state.modaltesttransaction) ? 
          (<ModalRequestParameters tipo="full" show={ this.state.modaltesttransaction} cerrarModal={this.cerrarModal} />) 
          : null}

        <div className='anchoMaximo' style={{ paddingTop: '120px', display: 'block', margin: "auto", width: "95%" }}>

          <div className="row">
            <div className="col-xs-12 col-sm-3">
              <h1 style={{ fontSize: 'x-large' }}><b>{this.state.titulo}</b></h1>
            </div>


            <div className="col-xs-12 col-sm-12">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h3 className="panel-title">Version</h3>
                </div>
                <div className="panel-body">

                  <div className='row'>
                    <div className='col'>
                      <p style={{ marginBottom: '0px' }}>Current Specification Version: V9.1.4</p>
                    </div>
                    <div className='col-auto'>
                      <p style={{ marginBottom: '0px' }} ><a href="/change-history?application=C&amp;api=realtime">View
                        Change History</a></p>
                    </div>
                  </div>



                </div>
              </div>
            </div>



            {this.state.contenido.map((item, index) => (
              <div key={index}>
                <div className="row">
                  <div className="col-12">
                    <h2>{item.titulo}</h2>

                    <div className="row">

                      {item.data.map((data, index) => (
                        <div className="col-4">
                          <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                            <h3 className='lblencabezados'><a href={"#"+data.nombre.replaceAll(" ", "_")}><LuArrowRightCircle className='icoflecha' /><a >{data.nombre} </a></a>
                            {
                              data.hasOwnProperty('req') ? <b className='colorrojo'>*</b>: null
                            }
                            </h3>

                          </div>
                        </div>
                      ))}

                    </div>
                  </div>

                </div>

                <div className="prettyline" ></div>

              </div>

            ))}

          </div>
          <div class="row">
              <div class="col-xs-12 col-sm-12">
                  <span style={{color: 'red', fontSize: '150%', verticalAlign: 'bottom'}} >*</span> Available
                  for testing with <a href="/terminology#host_emulator" target="_blank">host emulator</a>
                  <br/><br/><br/><br/>
              </div>
          </div>

          <br />
          <h2 style={{ fontSize: 'x-large' }}><b>General Information</b></h2>
          <br />

          <div className="col-xs-12 col-sm-12">
            <a id="note-general-information-overview"></a>
            <div className="panel panel-default">
              <div className="panel-heading" id={"Overview"}>
                <h3 className="panel-title">Overview&nbsp;&nbsp; <a data-toggle="tooltip" data-placement="right" className="mytooltip cursor_copy" title="" id="clipboardnote-general-information-overview" href="/processing-api/realtime/operations#note-general-information-overview" onclick="return false;" data-original-title="Direct link"><i className="fa fa-share-square-o"></i></a></h3>
              </div>
              <div className="panel-body">
                The purpose of this document is to provide documentation for 3rd parties to integrate with UniCharge processing gateway. <br />                                                                                    </div>
            </div>
          </div>





          <div className="panel panel-default" id={"Connectivity_information"}>
            <div className="panel-heading" >
              <h3 className="panel-title">Connectivity information&nbsp;&nbsp; <a data-toggle="tooltip" data-placement="right" className="mytooltip cursor_copy" title="" id="clipboardnote-general-information-connectivity-information" href="/processing-api/realtime/operations#note-general-information-connectivity-information" onclick="return false;" data-original-title="Direct link"><i className="fa fa-share-square-o"></i></a></h3>
            </div>
            <div className="panel-body">
              The connection URL for the sandbox server is: <span className="server-highlighting"><strong>https://[server-name]</strong></span>/gates/xurl.
              <br />
              Authorization is done via service users. To access the API, a service user is required to be granted with a corresponding privilege. A user can submit API requests using either associated credentials or temporary password generated via authentication operation.
              <br />

              <br />
              When submitting an API request, you can use either Transxation server URL or the Sanitizing Data Filter (Unibroker) server URL as an endpoint.
              <br />
              Requests that contain tokenized card numbers/bank account numbers can be submitted directly to transxactions server.
              <br />
              To reduce PCI exposure, requests with non-tokenized (raw) account data should be passed through the sanitizing data filter server. Otherwise, <strong>L04</strong> error will be returned (Processing of raw account data is not allowed through the specified API end-point for the current user).
              <br />
              If you don’t have a specific reason, we recommend sending all the requests to the same endpoint (data filter).
              <br />
              To learn more about service users and end-points used for API submission, review <a href="/guide_content?id=11#Users">Security Management</a> guide.

              <br />
              Request method is <strong>POST</strong>. The content-type must be set to <strong>application/x-www-form-urlencoded</strong>.
              <br />
              Request fields are passed within HTTPS request body (including cases with callbacks) and are required to be URL-encoded.
              <br />
              Response fields are passed within HTTPS response body.
              <br />
              Both request and response field values are passed using <strong>name1=key1&amp;name2=key2</strong> format.<br />                                                                                    </div>
          </div>



          {this.state.arrayDatos.map((itemt, index) => (

            <div key={index}>

              <h2><b className="panel-title" style={{ fontSize: 'large', paddingTop: "30px", paddingBottom: "15px" }}>{itemt.titulo}</b></h2>

              {itemt.subdatos.map((item, index) => (
                <div key={index}>

                  <div className="row ">
                    <div className="col-12 ">

                      <div className='borderdetallestabla'>
                        <div className="panel panel-default">
                          <div className="panel-heading" id={item.titulo.replaceAll(" ", "_")}>
                            <b  className="panel-title" style={{ fontSize: 'small' }}>{item.titulo}</b>
                          </div>
                        </div>




                        <div className="row" >
                          <div className="col-auto" >

                            {item.info1.map((itemi, index2) => (
                              <div className=" rowsinmargin">

                                <div className="row" >
                                  {/* ///////////////////////////////////////////////////////////// */}

                                  <div className='col-12 col-sm-12 col-md-auto bordertablasub'>
                                    <div className='infotblastext' ><label>{itemi.subtitulo}</label></div>
                                  </div>

                                  <div className='col-12 col-sm-12 col-md-auto bordertablasub'>

                                    {itemi.arraytitulo1.length > 0 ?

                                      <div className='infotblastext colorfuerteazul'>
                                        <div className="dropdown">
                                          <label onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className='colorfuerteazul'>{itemi.subtitulo1}</label>
                                          <div className="dropdown-content subtblas">
                                            {itemi.arraytitulo1.map((itema, index3) => (

                                              <a onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} key={index3} className="subtablassubtitles colorfuerteazul" data="1"
                                                 href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <div className='infotblastext colorfuerteazul'><label onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )}>{itemi.subtitulo1}</label></div>
                                    }
                                  </div>
                                  {/* ///////////////////////////////////////////////////////////// */}

                                  <div className='col-12 col-sm-12 col-md-auto bordertablasub'>

                                    {itemi.arraytitulo2.length > 0 ?

                                      <div className='infotblastext colorfuerteazul'>
                                        <div className="dropdown">
                                          <label onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className='colorfuerteazul'>{itemi.subtitulo2}</label>
                                          <div className="dropdown-content subtblas">
                                            {itemi.arraytitulo2.map((itema, index3) => (

                                              <a onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} key={index3} className="subtablassubtitles colorfuerteazul" data="1"
                                              href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <div className='infotblastext colorfuerteazul'><label>{itemi.subtitulo2}</label></div>
                                    }
                                  </div>
                                  {/* ///////////////////////////////////////////////////////////// */}

                                  <div className='col-12 col-sm-12 col-md-auto bordertablasub'>

                                    {itemi.arraytitulo3.length > 0 ?

                                      <div className='infotblastext colorfuerteazul'>
                                        <div className="dropdown">
                                          <label onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className='colorfuerteazul'>{itemi.subtitulo3}</label>
                                          <div className="dropdown-content subtblas">
                                            {itemi.arraytitulo3.map((itema, index3) => (

                                              <a onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} key={index3} className="subtablassubtitles colorfuerteazul" data="1"
                                              href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <div className='infotblastext colorfuerteazul'><label>{itemi.subtitulo3}</label></div>
                                    }
                                  </div>
                                  {/* ///////////////////////////////////////////////////////////// */}

                                  <div className='col-12 col-sm-12 col-md-auto bordertablasub'>

                                    {itemi.arraytitulo4.length > 0 ?

                                      <div className='infotblastext colorfuerteazul'>
                                        <div className="dropdown">
                                          <label onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className='colorfuerteazul'>{itemi.subtitulo4}</label>
                                          <div className="dropdown-content subtblas">
                                            {itemi.arraytitulo4.map((itema, index4) => (

                                              <a onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} key={index4} className="subtablassubtitles colorfuerteazul" data="1"
                                              href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <div className='infotblastext colorfuerteazul'><label>{itemi.subtitulo4}</label></div>
                                    }
                                  </div>
                                  {/* ///////////////////////////////////////////////////////////// */}

                                </div>
                              </div>

                            ))}






                            {item.info2.map((itemi, index2) => (

                              <div key={index2}>
                                {itemi.subtitulo != "" ?

                                  <div className=" rowsinmargin">
                                    <div className="row" >
                                      {/* ///////////////////////////////////////////////////////////// */}

                                      <div className='col-12 col-sm-12 col-md-auto bordertablasub'>
                                        <div className='infotblastext'><label>{itemi.subtitulo}</label></div>
                                      </div>

                                      <div className='col-12 col-sm-12 col-md-auto bordertablasub'>

                                        {itemi.arraytitulo1.length > 0 ?

                                          <div className='infotblastext colorfuerteazul'>
                                            <div className="dropdown">
                                              <label  onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className='colorfuerteazul'>{itemi.subtitulo1}</label>
                                              <div className="dropdown-content subtblas">
                                                {itemi.arraytitulo1.map((itema, index3) => (

                                                  <a  onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} key={index3} className="subtablassubtitles colorfuerteazul" data="1"
                                                  href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          <div className='infotblastext colorfuerteazul'><label>{itemi.subtitulo1}</label></div>
                                        }
                                      </div>
                                      {/* ///////////////////////////////////////////////////////////// */}

                                      <div className='col-12 col-sm-12 col-md-auto bordertablasub'>

                                        {itemi.arraytitulo2.length > 0 ?

                                          <div className='infotblastext colorfuerteazul'>
                                            <div className="dropdown">
                                              <label  onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className='colorfuerteazul'>{itemi.subtitulo2}</label>
                                              <div className="dropdown-content subtblas">
                                                {itemi.arraytitulo2.map((itema, index3) => (

                                                  <a  onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} key={index3} className="subtablassubtitles colorfuerteazul" data="1"
                                                  href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          <div className='infotblastext colorfuerteazul'><label>{itemi.subtitulo2}</label></div>
                                        }
                                      </div>
                                      {/* ///////////////////////////////////////////////////////////// */}

                                      <div className='col-12 col-sm-12 col-md-auto bordertablasub'>

                                        {itemi.arraytitulo3.length > 0 ?

                                          <div className='infotblastext colorfuerteazul'>
                                            <div className="dropdown">
                                              <label  onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className='colorfuerteazul'>{itemi.subtitulo3}</label>
                                              <div className="dropdown-content subtblas">
                                                {itemi.arraytitulo3.map((itema, index3) => (

                                                  <a  onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} key={index3} className="subtablassubtitles colorfuerteazul" data="1"
                                                  href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          <div className='infotblastext colorfuerteazul'><label>{itemi.subtitulo3}</label></div>
                                        }
                                      </div>
                                      {/* ///////////////////////////////////////////////////////////// */}

                                      <div className='col-12 col-sm-12 col-md-auto bordertablasub'>

                                        {itemi.arraytitulo4.length > 0 ?

                                          <div className='infotblastext colorfuerteazul'>
                                            <div className="dropdown">
                                              <label  onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} className='colorfuerteazul'>{itemi.subtitulo4}</label>
                                              <div className="dropdown-content subtblas">
                                                {itemi.arraytitulo4.map((itema, index3) => (

                                                  <a  onClick={() => this.obtenerDatosTablas(itemt.titulo, item.titulo )} key={index3} className="subtablassubtitles colorfuerteazul" data="1"
                                                  href={"#"+itema.nombre.replaceAll(" ", "_")}>{itema.nombre}</a>

                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          <div className='infotblastext colorfuerteazul'><label>{itemi.subtitulo4}</label></div>
                                        }
                                      </div>
                                      {/* ///////////////////////////////////////////////////////////// */}

                                    </div>
                                  </div>

                                  : null}
                              </div>

                            ))}


                          </div>


                          <div className='col-5 '>
                            <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px", textAlign: "justify" }}>
                              {this.pasartexto(item.notas)}
                            </div>
                          </div>


                        </div>


                      </div>

                    </div>

                  </div>
                  <br />

                </div>
              ))}


            </div>

          ))}



          <div className='row justify-content-start'>
            <div>
              <h3>Message Formats</h3><br />
              <h2>Usage: </h2>
            </div>                              
            <div className='col-6 align-items-start'>
              <div className='divdestablas'>
                {this.state.tabladedatos.map((itemt, index) => (


                  <div key={index} className='row nopadding rowborder'>

                    <div className='col-auto'>
                      <label style={{ fontWeight: 'bold', fontSize: "medium", width: "10px", marginTop: "5px" }}>{itemt.titulo}</label>
                    </div>

                    <div className='col-auto'>
                      <HiOutlineMinusSm style={{ fontSize: "Medium" }} />
                    </div>

                    <div className='col'>
                      <label className="txtdescripcion">{itemt.descripcion}</label>
                    </div>

                  </div>

                ))}
              </div> <br />
            </div>

            <br /><br />
            {
              this.state.tablasporseccion.map((tabla, index) => (
                <div>
                  <div>
                    <h2>{tabla.titulo}</h2>
                    <h2 id={tabla.tabla.titulotabla.replaceAll(" ", "_")}>{tabla.tabla.titulotabla}</h2>
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <div class="table-responsive">
                      <table class="newtable newtablenorowspan pull-left">
                        <tbody>
                          <tr>
                            {
                              tabla.tabla.headers.map((header, index) => (
                                <th style={{ fontWeight: "bold" }}>{header}</th>
                                ))
                            }
                          </tr>
                          {
                              tabla.tabla.body.map((body, index) => (
                                <tr>
                                  {
                                    body.row.map((item, indexrow) => (
                                    <td>
                                      {
                                        indexrow == 1 && body.hasOwnProperty('vinculoName') ?
                                        <span className='mb-0'><span className='linked' onClick={() => this.seleccionarModal(body.vinculoName )} style={{color: "#0066B3"}}>{item}</span></span>
                                        :indexrow == 2 && body.hasOwnProperty('vinculoType') ?
                                        <span className='mb-0'><span className='linked' onClick={() => this.seleccionarModal(body.vinculoType )} style={{color: "#0066B3"}}>{item}</span></span>
                                        :indexrow == 3 && body.hasOwnProperty('vinculoPattern') ?
                                        <span className='mb-0'><span className='linked' onClick={() => this.seleccionarModal(body.vinculoPattern )} style={{color: "#0066B3"}}>{item}</span></span>
                                        :indexrow == 4 && body.hasOwnProperty('vinculoFormat') ?
                                        <span className='mb-0'><span className='linked' onClick={() => this.seleccionarModal(body.vinculoFormat )} style={{color: "#0066B3"}}>{item}</span></span>
                                        :indexrow == 4 && body.row.length == 6  && body.hasOwnProperty('vinculoUsage') ? (body.vinculoUsage.slice(-1) == "*" ? <a href={body.vinculoUsage.substring(0, body.vinculoUsage.length -1)}>{item}</a> :  <span className='mb-0'><span className='linked' onClick={() => this.seleccionarModal(body.vinculoUsage )} style={{color: "#0066B3"}}>{item}</span></span>)
                                        :indexrow == 2 && body.row.length == 3 && body.hasOwnProperty('vinculoUsage') ? (body.vinculoUsage.slice(-1) == "*" ? <a href={body.vinculoUsage.substring(0, body.vinculoUsage.length -1)}>{item}</a> :  <span className='mb-0'><span className='linked' onClick={() => this.seleccionarModal(body.vinculoUsage )} style={{color: "#0066B3"}}>{item}</span></span>)
                                        :indexrow == 5 && body.row.length == 7 && body.hasOwnProperty('vinculoUsage') ? (body.vinculoUsage.slice(-1) == "*" ? <a href={body.vinculoUsage.substring(0, body.vinculoUsage.length -1)}>{item}</a> :  <span className='mb-0'><span className='linked' onClick={() => this.seleccionarModal(body.vinculoUsage )} style={{color: "#0066B3"}}>{item}</span></span>)
                                        
                                        : item
                                      }
                                      
                                      {
                                        body.row.length == indexrow+1 && body.hasOwnProperty('seemoreinformation0') ?
                                        <span className='mb-0'><span className='linked' onClick={() => this.seleccionarModal(body.modalname0 )} style={{color: "#0066B3"}}>{body.seemoreinformation0}</span> {body.seemoreinformationcom0} </span>
                                        : null
                                      }
                                      {
                                        body.row.length == indexrow+1 && body.hasOwnProperty('descripcion1') ?  
                                        <div className='notatabla'>
                                          {body.descripcion1}
                                          {
                                            body.hasOwnProperty('seemoreinformation1') ?
                                            <span className='mb-0'> <span className='linked' onClick={() => this.seleccionarModal(body.modalname1 )} style={{color: "#0066B3"}}>{body.seemoreinformation1}</span> {body.seemoreinformationcom1} </span>
                                            : null
                                          }
                                        </div> : null
                                      }
                                      {
                                        body.row.length == indexrow+1 && body.hasOwnProperty('descripcion2') ?  
                                                                              
                                        <div className='infotabla'>
                                          {body.descripcion2}
                                          {
                                            body.hasOwnProperty('seemoreinformation2') ?
                                            <span className='mb-0'> <span className='linked' onClick={() => this.seleccionarModal(body.modalname2 )} style={{color: "#0066B3"}}>{body.seemoreinformation2}</span> {body.seemoreinformationcom2} </span>
                                            : null
                                          }
                                        </div> : null
                                      }
                                    </td>
                                    ))
                                  }
                                  
                              </tr>
                              ))
                          }
                        </tbody>
                      </table>
                    </div><br />
                                        
                  </div><br />
                </div>
                
              ))
            }
            {
              this.state.tablasporseccionexamples.map((tabla, index) => (
                <div>
                  <div>
                    <h2>{tabla.titulo}</h2>
                  </div>
                  <div className=''>
                    <div className='d-flex'>
                      <h2 id={tabla.tabla.titulotabla.replaceAll(" ", "_")}>{tabla.tabla.titulotabla}</h2>
                      {tabla.isrequest ? <button className='btn btn-try-test'  onClick={() => this.showModalExampleTest(tabla.tabla.titulotabla )} >Try Test Transaction</button>: null} 
                    </div>
                  </div>
                  <div className='d-flex box'>
                      <p className='wordbreak'>
                        {tabla.isrequest ? <b style={{fontStyle: "italic"}}>Path: </b>  : null}
                        <code>{tabla.isrequest ? tabla.tabla.path  : null}</code>
                        {tabla.isrequest ? <br/>  : null}
                      
                        {tabla.isrequest ? <b style={{fontStyle: "italic"}}>Parameters: </b> : null}
                        <code>{tabla.tabla.parameters}</code>
                        
                      </p>
                      
                      <p></p>
                    </div>
                  <div style={{ display: "inline-block" }}>
                    <div class="table-responsive">
                      <table class="newtable  pull-left" style={{width: "100%"}}>
                        <tbody>
                          {
                              tabla.tabla.body.map((item, index) => (
                                <tr>
                                  <td style={{width:"10%"}}>key: </td>
                                  <td style={{width:"35%"}}>{item.key}</td>
                                  <td style={{width:"10%"}}>value: </td>
                                  <td style={{width:"45%"}} className='wordbreak'>{item.value}</td>
                              </tr>
                              ))
                          }
                        </tbody>
                      </table>
                    </div><br />
                                        
                  </div><br />
                </div>
                
              ))
            }


          </div>

          <br />
            <br />
            <br />
            <br />
        </div>
        <Footer />

      </>
      
    )
  }
  
}

