import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';

export default class ModalRecordID extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Record ID</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                To identify an object within the system, the gateway assigns its own unique identifier when the object is created. In the context of transaction processing, identifiers of previously created objects, such as merchant (<em>accountId, merchantId</em>) and a terminal (<em>terminalId</em>), are submitted as a part of an API request. Identifiers of objects created as a result of the transaction processing, such as an item (<em>itemId</em>) or a transaction itself (<em>transactionId</em>), are returned within an API response.

<br/>

<br/>
When <em>transactionId</em> and <em>itemId</em> are returned, depending on the context, values of these fields can be preceded by the prefixes listed in the table below: 
<div style={{display: "inline-block"}}>
<div class="table-responsive"><table class="newtable pull-left" style={{marginTop: "5px"}} ><tbody><tr>
<th style={{fontWeight: "bold"}} >Field
</th>
<th style={{fontWeight: "bold"}}>Prefix
</th>
<th style={{fontWeight: "bold"}}>Description
</th>
<th style={{fontWeight: "bold"}}>Example
</th>
</tr><tr>
<td>transactionId
</td>
<td>without prefix
</td>
<td>Reference to a merchant-initiated financial transaction that represents a sale or a credit.
</td>
<td>transactionId=123456
</td>
</tr><tr>
<td>transactionId
</td>
<td>with * prefix
</td>
<td>Reference to a merchant-initiated financial transaction that represents a sale or a credit within a submitter’s system. Used for void and capture operations only. 
</td>
<td>transactionId=*123456
</td>
</tr><tr>
<td>transactionId
</td>
<td>with T prefix
</td>
<td>Reference to a merchant-initiated tokenization transaction.
</td>
<td>transactionId=T123456
</td>
</tr><tr>
<td>transactionId
</td>
<td>with S prefix
</td>
<td>Reference to a split-out transaction.
</td>
<td>transactionId=S123456
</td>
</tr><tr>
<td>itemId
</td>
<td>with I prefix
</td>
<td>Reference to an item created as a part of the transaction.
</td>
<td>itemId=I123
</td>
</tr><tr>
<td>itemId
</td>
<td>with * prefix
</td>
<td>Reference to an item created as a part of the transaction within a submitter’s system.
</td>
<td>itemId=*123
</td>
</tr></tbody></table>
</div><br/><div style={{display: "inline-block"}}><br/>
Values of these fields can be subsequently used within API requests. A prefix used affects which type of the transaction the operation is applied to. For example, for void API request: 
<br/>
<ul type="circle" >
<li>If transactionId value without prefix is submitted, specified transaction is voided;
</li>
<li>If transactionId value with S prefix is submitted, only split-out transaction is voided;
</li>
<li>If itemId with I prefix is submitted,  only specified item in the transaction is voided. Note that the item cannot be voided when an associated transaction was split at transaction level.</li></ul></div>
                </div>
            </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

