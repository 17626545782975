import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINPaymentAggregation extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Payment Aggregation</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                 number aggregation is the mechanism, allowing aggregation of multiple incoming transactions, that have the same account information, into a single transaction, that is physically processed. It is primarily used to reduce processing cost.
                                <br/>

                                <br/>
                                There are two terms associated with account number aggregation: aggregated transaction and aggregate transaction.
                                <br/>
                                Any transaction that is submitted for processing but is actually processed as part of another transaction, is called an aggregated transaction. Any transaction that includes in its amount other (aggregated) transactions and is physically processed with a bank, is called an aggregate transaction.
                                <br/>

                                <br/>
                                Each aggregated transaction is going to include a reference back to the aggregate transaction. This reference is implemented using three fields: aggregateReferenceNumber, aggregateTransactionCode and aggregateTransactionInternalCode. Anyone of them can be used to link aggregated transaction to its respective aggregate.
                                <br/>

                                <br/>
                                The aggregation process also comes into play when chargebacks and ACH returns are received for an aggregate transaction with several underlying aggregated transactions. In such cases, the system generates separate ACH returns or chargebacks for each aggregated transaction.
                                <br/>

                                <br/>
                                <strong>Please note</strong>, that aggregate transaction is always the transaction that came from the submitter, and no new transaction is created as part of the aggregation process. Another way to identify aggregated transactions is to throw processedAmount field. If processedAmount is zero, this transaction was aggregated. If processedAmount is greater that the value of amount field of this transaction, this transaction is aggregate.
                                <br/>

                                <br/>
                                For example, three transactions in the amount of $10 each with the same account number are received. Two of them are aggregated (processedAmount is $0), and one of them becomes the aggregate transaction with the amount of $30 (processedAmount is $30). The $30 transaction is subsequently processed. Should return or chargeback for this $30 transaction ever occur, two additional ACH returns or chargebacks will be generated for the original two aggregated transactions. In the end the submitter will receive three ACH returns or chargebacks with the amount of $10 each.<br/>                
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

