import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINSplitPayments extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    seleccionarModal(titulomodal){
        
        this.setState({ show: false });
        let json = {
            accion: 1,
            modal: titulomodal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Concepts
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Split Payments</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                Split payments mechanism allows merchants to split a fixed amount or a percentage of the payments between up to 9 different third parties (10 with the original merchant included). 
                                <br/>

                                <br/>
                                Split scenario should be specified within an original transaction. There are 2 possible methods to create a split payment on a transaction:
                                <br/>
                                <ul type="circle" style={{marginLeft: "10px", color: "#5688c9"}}>
                                <li>to embed the rules directly into the transaction via the <em>splits</em> parameter, which is allowed to be used in real-time API transactions only;
                                </li>
                                <li>to use predefined rules called a <em>split schema</em>, which is a template with a set of predefined split payouts. The template is primarily used in situations when the same split rules are repeatedly used or batch processing is required; a split schema should be created in advance.
                                </li>
                                </ul>

                                <br/>
                                Directly embedding the split rules within a transaction allows the split rules to be customized for each payment at both the total transaction or specific items within a transaction level. In order to create a split transaction, the <em>splits</em> field should be included in the sale API call. The splits field is formatted as follows:
                                <em>splits=(accountID=*affiliate001;amount=100)</em>,
                                where
                                <br/>
                                <ul type="circle" style={{marginLeft: "10px", color: "#5688c9"}}>
                                <li><em>accountID</em> is either internal or external identifier of the affiliate (see <a class="glossaryLink" href="/terminology/reference_field_type" data-cmtooltip="While working with API, various entities within the system, such as merchants, resellers, etc, need to be referenced. API fields that correspond to the references to these entities are of the <em>Reference</em> type (for example, accountId, terminalId, etc). Each of these entities has two identifiers:
                                — Internal identifier (represented as <em>ID</em>) - numeric value, provided by the gateway; unique within the system.
                                — External identifier (represented as <em>code</em>) - alphanumeric value up to 60 characters, provided by an integrated external system for cross-referencing; preferably unique within the scope that makes sense to an integrator.

                                When an API call with reference to an entity has to be submitted, this entity can be referenced by either internal or external identifier. By default, the system expects an internal identifier to be submitted (e.g. accountId/merchantAccountCode=1234). To let the system know that an external identifier is submitted, its value must be preceded by an asterisk (e.g. accountId/merchantAccountCode=*1234). This is applicable to all fields of <em>Reference</em> type.

                                For example, an account has an internal ID, generated by the gateway (e.g.5678), and an external ID,  provided by an integrated POS system (e.g. pos8765). If an internal ID should be used in the API calls, it is submitted via <em>accountId</em> field as it is: <em>accountId=5678</em>. If an external ID is used should be used in the API calls, it is submitted with an asterisk as a prefix: <em>accountId=*pos8765</em>.">Reference Field Type</a> for more information). The submitted value can be either account ID/code or merchant ID/code. If merchantId or merchantCode value is submitted, the system will automatically use the first account under this merchant for further transaction processing.
                                </li>
                                <li><em>amount</em> is the amount to be transferred to this affiliate  The amount field can either be a fixed amount or a percentage. See <span class="linked" style={{color: "#0066B3"}} onClick={() => this.seleccionarModal("ProsessingINSplitAmountFormat")}>integration notes</span> for more information about amount field.
                                </li>
                                </ul>

                                <br/>
                                For cases when multiple items are included in a single transaction, you can apply either the split scenario to the overall transaction or a unique split scenario to each of the items within the transaction. The <em>items</em> field with <em>splits</em> subrecord included can be used to apply different split rules to each item in a single transaction. An item plays either an informational or functional role within the transaction request. When an item plays an informational role, the transaction can include one or more items and the split payment scenario is applied to the transaction as a whole, the total of all the items in the sale transaction. When an item plays a functional role, the transaction can include one or more items and each item specified in the transaction has its own split payment scenario applied to it.                 
                            </div>                      
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

