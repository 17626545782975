import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINRetryProcess extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Retry Process</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                The system provides support for a retry process, by the means of which certain card declines can be retried for one or several times after the initial attempt. This is often done for declines that indicate a temporary issue with the payment source that might get resolved on the subsequent retry (for example, insufficient balance). Transactions with response codes configured for reattempt will be automatically selected by the system and re-tried, based on retry terms defined for any particular submitter (Merchant). All retry responses are returned to the submitter in the same way as direct debit returns and chargebacks. For direct debit returns transactions, transactionId corresponds to originalTransactionId .
                                <br/>

                                <br/>
                                All responses (initial and retry results) will be sent back to the submitter using standard interchange protocol. Transactions that are still part of the reattempt process (and thus will have additional responses, possibly approvals reversing previous declines) are marked with isRebillEnabled flag (set to 1). The submitter should decide how these transactions are to be handled - whether the initial decline is recognized right away or whether the final response (with isRebillEnabled set to 0) is to be awaited.
                                <br/>

                                <br/>
                                If a transaction is a reattempt initiated by the gateway, it will use the same transaction ID. However, the sequence number indicating the attempt of that transaction will be appended at the end of the transaction ID using colon as a separation. For example, the first reattempt for transaction with transactionId 12312312 will have transactionId 12312312:1, the second retry will have transactionId 12312312:2, etc.
                                <br/>
                                <div style={{display: "inline-block"}}>
                                <div class="table-responsive"><br/><br/><br/><br/><table class="newtable newtablenorowspan drop-spase-retry pull-left" style={{marginTop: "-60px"}}><tbody><tr>
                                <td style={{fontWeight: "bold", width: "300px"}}> Type of transaction
                                </td>
                                <td style={{fontWeight: "bold", width: "300px"}}> Transaction ID Format
                                </td>
                                <td style={{fontWeight: "bold", width: "60px"}}> Examples
                                </td>
                                </tr><tr>
                                <td>Client-initiated transactions (when account number or token is used)
                                </td>
                                <td>[originalTransactionId]
                                </td>
                                <td>123123123
                                </td>
                                </tr><tr>
                                <td> Client-initiated tokenization operation (tokenization transaction)
                                </td>
                                <td> <strong>T</strong>[originalTransactionId]
                                </td>
                                <td> <strong>T</strong>123123123
                                </td>
                                </tr><tr>
                                <td> Gateway initiated transactions (or reattempt transactions)
                                </td>
                                <td>[originalTransactionId]:sequence number of retry
                                </td>
                                <td>123123123:2
                                </td>
                                </tr><tr>
                                <td> Chargebacks 
                                </td>
                                <td><strong>A</strong>[originalTransactionId]
                                </td>
                                <td> <strong>A</strong>123123123
                                </td>
                                </tr></tbody></table>
                                </div>               
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

