import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalDistributionSubrecord extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                    <div class="row">
                        <h3 class="col" style={{color: "#0e2689"}}>Distribution Subrecord</h3>
                        <div class="col-auto">
                            <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                        </div>
                    </div>

                    <div className="prettyline" ></div>

                    <h2></h2>

                    

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

