

export async function getDatos(ban) {
    var resp = [];
    var datos = [];
    var tituloge = '';
    try {

        if (ban == "real_time") {
            tituloge = "Real Time (HTTPs) APIs";
            datos = [
                {
                    titulo: "",
                    data: [
                        { nombre: "Overview" },
                        { nombre: "Connectivity information" },
                    ]
                },
                {
                    titulo: "General",
                    data: [
                        { nombre: "Sale-Auth", req: true },
                        { nombre: "Void/Refund", req: true },                        
                        { nombre: "Convenience Fee", req: true },
                        { nombre: "Get-Configuration" },
                        { nombre: "Capture", req: true },
                        { nombre: "Increment" },
                        { nombre: " Sale", req: true },
                        { nombre: "Account Verification", req: true },
                        { nombre: "Authentication" },
                        { nombre: "Credit", req: true },
                        { nombre: "Balance Inquiry", req: true },
                        { nombre: "Fetch" }
                    ]
                },
                {
                    titulo: "Tokenization",
                    data: [
                        { nombre: "Tokenization", req: true },
                        { nombre: "Untokenization" },
                        { nombre: "Retokenization" },
                    ]
                },
                {
                    titulo: "Settlement",
                    data: [
                        { nombre: "Close Cycle" },
                        { nombre: "Balance Inquiry Cycle" },
                    ]
                },
                {
                    
                    titulo: "Gift/Loyalty Cards",
                    data: [
                        { nombre: "Activate", req: true },
                        { nombre: "Transfer" },
                        { nombre: "Create Alias" },
                        { nombre: "Reactivate", req: true },
                        { nombre: "Sale-Info" },
                        { nombre: "Delete Alias" },
                        { nombre: "Deactivate", req: true },                        
                        { nombre: "Credit-Info" },
                    ]
                },
                {
                    titulo: "Callbacks",
                    data: [
                        { nombre: "Callback" },

                    ]
                },
                {
                    titulo: "Reporting",
                    data: [
                        { nombre: "Find" },
                        { nombre: "Merchant Info" },
                    ]
                },
                {
                    titulo: "Miscellaneous",
                    data: [
                        { nombre: "Ping" },
                        { nombre: "Migrate Terminal" },
                        { nombre: "Bank Data" },
                        { nombre: "Upload Resource" },
                        { nombre: "Get Profile" },
                        { nombre: "Split Schema" },
                        { nombre: "Download Resource" },
                        { nombre: "BIN Data" },
                    ]
                },

            ]
        }
        else if (ban == "batch") {
            tituloge = "Batch (sFTP) APIs";
            datos = [
                {
                    titulo: "",
                    data: [
                        { nombre: "General Information" }
                    ]
                },
                {
                    titulo: "Supported File Formats",
                    data: [
                        { nombre: "Processing", req: true },
                        { nombre: "Subscription" },
                        { nombre: "Capture" },
                        { nombre: "Tokenization" },
                        { nombre: "Returns" },
                        { nombre: "Charges" },
                        { nombre: "Parsing Error File" },
                        { nombre: "Returns Account Update" },
                        { nombre: "Charge Payments" },
                        { nombre: "Account Update", req: true },
                        { nombre: "Returns Subscription" },
                    ]
                },
                {
                    titulo: "Processing",
                    data: [
                        { nombre: "Folder Structure" },
                        { nombre: "Validation Error File" },
                        { nombre: "Returns Subscription File" },
                        { nombre: "File Format and Name Convention" },
                        { nombre: "Account Update File" },
                        { nombre: "Capture File" },
                        { nombre: "Processing File" },
                        { nombre: "Subscription File" },
                        { nombre: "Charges File" },
                        { nombre: "Tokenization File" },
                        { nombre: "Returns File" },
                        { nombre: "Charge Payments File" },
                        { nombre: "Parsing Error  File" },
                        { nombre: "Returns Account Update File" },
                    ]
                },
                {
                    titulo: "Tokenization Concept",
                    data: [
                        { nombre: " Tokenization" },
                        { nombre: "Token Structure (Simple)" },
                        { nombre: "Token Structure (Extended)" },
                        { nombre: "Profiling" },
                    ]
                }

            ]
        }
        else if (ban == "terminals") {
            tituloge = "Terminals APIs";
            datos = [
                {
                    titulo: "General",
                    data: [
                        { nombre: "Overview" },
                        { nombre: "Connectivity Information" },
                        { nombre: "Connectivity Problems" },

                    ]
                },
                {
                    titulo: "Settlement",
                    data: [
                        { nombre: "Sale-Auth", req: true },
                        { nombre: "Sale", req: true },
                        { nombre: "Credit", req: true },
                        { nombre: "Void" },
                        { nombre: "Refund" },
                        { nombre: "Capture" },
                        { nombre: "Callback" },


                    ]
                },
                {
                    titulo: "Gift/Loyalty Cards",
                    data: [
                        { nombre: "Close Cycle" },
                        { nombre: "Clear Batch" },

                    ]
                },
                {
                    titulo: "Miscellaneous",
                    data: [
                        { nombre: "Capture Card" }

                    ]
                },
                {
                    titulo: "Ticket Management",
                    data: [
                        { nombre: "Ping" },
                        { nombre: "Find" },
                        { nombre: "Capture Signature" },
                        { nombre: "Show Splash" },
                        { nombre: "Show Message" },
                        { nombre: "Capture-Value" },
                    ]
                },
                {
                    titulo: "Supported Operations",
                    data: [
                        { nombre: "Create Ticket" },
                        { nombre: "Close Ticket" },
                        { nombre: "Add Item" },
                        { nombre: "Add Item List" },
                        { nombre: "Update Item" },
                        { nombre: "Remove Item" },

                    ]
                },
                {
                    titulo: "* Available for testing with host emulator",
                    data: [
                        { nombre: "Get Configuration" },
                    ]
                }

            ]
        }
        else if (ban == "onboarding") {
            tituloge = "Onboarding APIs";
            datos = [
                {
                    titulo: "Supported Operations",
                    data: [
                        { nombre: "Overview" },
                        { nombre: "Connectivity Information" }
                    ]
                },
                {
                    titulo: "General Information",
                    data: [
                        { nombre: "Create (merchant)" },
                        { nombre: "Create (affiliate)" },
                        { nombre: "Callback" },
                        { nombre: "Get Status (deprecated)" },
                        { nombre: "Status" },
                        { nombre: "Add Owner" },
                        { nombre: "Delete Owner" },
                        { nombre: "Adjust" },
                        { nombre: "Update" },
                        { nombre: "Add Terminal" },
                        { nombre: "Update Terminal" },
                        { nombre: "Load" },

                    ]
                },

            ]
        }
        else if (ban == "reporting") {
            tituloge = "Reporting APIs";
            datos = [
                {
                    titulo: "Database Export",
                    data: [
                        { nombre: "Overview" },
                        { nombre: "Connectivity Information" }
                    ]
                }
            ]
        }
        else if (ban == "tmsapis") {
            tituloge = "TMS APIs";
            datos = [
                {
                    titulo: "Supported Operations",
                    data: [
                        { nombre: "Overview" },
                        { nombre: "Connectivity Information" }
                    ]
                },
                {
                    titulo: "General Information",
                    data: [
                        { nombre: "Get Credentials" },
                        { nombre: "Log Credentials" },
                        { nombre: "Get Parameters" },
                        { nombre: "Set Parameters" },
                        { nombre: "Activate Terminal" },
                        { nombre: "Get Update" },
                        { nombre: "Get Configuration" },
                        { nombre: "Get Resource" },
                        { nombre: "Log Update State" },
                        { nombre: "Upload Log" },
                        { nombre: "Log State" },
                        { nombre: "Get Time" },
                        { nombre: "Get Key" },
                        { nombre: "Log Diagnostics" },
                        { nombre: "Merchant Info" },
                        { nombre: "Initialize Terminal" },
                        { nombre: "Request Authentication" },
                        { nombre: "Authenticate Terminal" }

                    ]
                },


            ]
        }

        var json = {
            tituloge: tituloge,
            datos: datos
        }

        resp = json;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;

}



export async function getDatosNotes(ban) {
    var resp = [];
    var datos = [];
    var tituloge = '';
    try {

        if (ban == "real_time") {
            tituloge = "Real Time (HTTPs) APIs <br/>";
            datos = [
                {
                    titulo: "Integration Notes",
                    data: [
                        { nombre: "3D Secure Verification" },
                        { nombre: "Account Data" },
                        { nombre: "Authentication" },
                        { nombre: "Authorization and Settlement" },
                        { nombre: "Capture, Void and Refund Operation" },
                        { nombre: "Client Host" },
                        { nombre: "Cross-reference Fields" },
                        { nombre: "Delayed Transactions" },
                        { nombre: "Direct Debit" },
                        { nombre: "Dynamic Descriptor" },
                        { nombre: "Embedded Mode" },
                        { nombre: "External API Data" },
                        { nombre: "Fallback Transactions" },
                        { nombre: "Format Field" },
                        { nombre: "Healthcare Industry Transactions" },
                        { nombre: "Holder Name Normalization Rules" },
                        { nombre: "Identity Verification" },
                        { nombre: "Increment" },
                        { nombre: "Interchange Rate Qualification" },
                        { nombre: "Legacy Fields Names" },
                        { nombre: "Manual Cycle Closeout (settlement)" },
                        { nombre: "Non-Financial Operations" },
                        { nombre: "Offline Transactions" },
                        { nombre: "Page Format" },
                        { nombre: "Partial Authorization" },
                        { nombre: "Partial Settlement" },
                        { nombre: "Password Encoding" },
                        { nombre: "PGP Encryption" },
                        { nombre: "Provider Response Data" },
                        { nombre: "Record ID" },
                        { nombre: "Retail Cycle Balance Inquiry" },
                        { nombre: "Resource Management" },
                        { nombre: "Service Fees" },
                        { nombre: "Settlement Delay" },
                        { nombre: "Settlement Group" },
                        { nombre: "Signature Usage" },
                        { nombre: "Split Amount Format" },
                        { nombre: "Stored Credentials" },
                        { nombre: "Subrecord Format" },
                        { nombre: "Terminal Data Entry" },
                        { nombre: "Terminal ID" },
                        { nombre: "Terminal Message" },
                        { nombre: "Timeout Handling" },
                        { nombre: "Tokenization Control" },
                        { nombre: "Transaction Export" },
                        { nombre: "Transaction Levels" },
                        { nombre: "Transaction Origin Code" },
                        { nombre: "Transit Fields" },
                        { nombre: "User Message Overriding Mechanism" },
                        { nombre: "Warning Code" },
                        { nombre: "Statement parameter" }
                    ]
                },
                {
                    titulo: "Concepts",
                    data: [
                        { nombre: "Tokenization (Concepts)" },
                        { nombre: "Pure tokenization" },
                        { nombre: "Profiling" },
                        { nombre: "Token Structure (Extended)" },
                        { nombre: "Token Structure (Simple)" },
                        { nombre: "Convenience Fee" },
                        { nombre: "Hosted Payment Page (HPP)" },
                        { nombre: "Using HPP with MSR (magnetic stripe reader)" },
                        { nombre: "Proxynization API" },
                        { nombre: "Split Payments" },
                        { nombre: "Direct Debit Account Verification" }
                    ]
                }

            ]
        }

        if (ban == "batch") {/////
            tituloge = "Batch (sFTP) APIs <br/> Integration Notes";
            datos = [
                {
                    titulo: "Data Export",
                    data: [
                        { nombre: "Bank Information Subscription" },
                        { nombre: "Batch Encryption" },
                        { nombre: "Batch Tokenization" },
                        { nombre: "Cross-reference Fields" },
                        { nombre: "Direct Debit" },
                        { nombre: "Duplicate Validation" },
                        { nombre: "Dynamic Descriptor" },
                        { nombre: "Effective Date" },
                        { nombre: "Legacy Names" },
                        { nombre: "Merchant Charges" },
                        { nombre: "Multiple Merchants in a Single File" },
                        { nombre: "Notices of Change (NOCs)" },
                        { nombre: "Record ID" },
                        { nombre: "Payment Aggregation" },
                        { nombre: "Retry Fee" },
                        { nombre: "Retry Process" },
                        { nombre: "Sequence Number" },
                        { nombre: "Service Fees" },
                        { nombre: "Stored Credentials" },
                        { nombre: "Transaction Groups" }

                    ]
                },
                {
                    titulo: "Tokenization Concept",
                    data: [
                        { nombre: "Data Export" },
                    ]
                },
                {
                    titulo: "Integration Notes",
                    data: [
                        { nombre: "Tokenization " },
                        { nombre: "Token Structure (Extended) " },
                        { nombre: "Token Structure (Simple) " },
                        { nombre: "Profiling " },
                    ]
                }
            ]
        }

        if (ban == "terminals") {/////
            tituloge = "Terminals APIs <br/> Integration Notes";
            datos = [
                {
                    titulo: "Concepts",
                    data: [
                        { nombre: "Capture, Void and Refund Operation" },
                        { nombre: "Contactless Mode" },
                        { nombre: "Cross-reference Fields" },
                        { nombre: "Dynamic Descriptor" },
                        { nombre: "EMV terminal programming" },
                        { nombre: "Holder Name Normalization Rules" },
                        { nombre: "Legacy Fields Names" },
                        { nombre: "Partial Authorization" },
                        { nombre: "Password Encoding" },
                        { nombre: "Provider Response Data" },
                        { nombre: "Receipt Requirements" },
                        { nombre: "RSA Encryption" },
                        { nombre: "Settlement Group" },
                        { nombre: "Terminal Data Entry" },
                        { nombre: "Terminal ID" },
                        { nombre: "Terminal Images" },
                        { nombre: "Terminal Message" },
                        { nombre: "Terminal Screen Content" },
                        { nombre: "Terminal Split Payments" },
                        { nombre: "Transaction Origin Code" }

                    ]
                },
                {
                    titulo: "Integration Notes",
                    data: [
                        { nombre: "Tokenization" },
                        { nombre: "Рure tokenization" },
                        { nombre: "Profiling" },
                        { nombre: "Token Structure (Extended)" },
                        { nombre: "Token Structure (Simple)" },
                        { nombre: "Terminal Integration" },
                    ]
                }
            ]
        }

        if (ban == "onboarding") {/////
            tituloge = "Onboarding APIs <br/> Integration Notes";
            datos = [
                {
                    titulo: "Integration Notes",
                    data: [
                        { nombre: "Cross-reference Fields" },
                        { nombre: "Descriptor Format" },
                        { nombre: "Field Management" },
                        { nombre: "Internal and External IDs" },
                        { nombre: "Is Embedded Field" },
                        { nombre: "Multiple Owner Field Names" },
                        { nombre: "Officer Field Names" },
                        { nombre: "Onboarding Response" },
                        { nombre: "Page Management" },
                        { nombre: "Terminal Setup" },
                        { nombre: "Data Format" },

                    ]
                }
            ]
        }



        if (ban == "reporting") {/////
            tituloge = "Reporting APIs <br/> Integration Notes";
            datos = [
                {
                    titulo: "Integration Notes",
                    data: [
                        { nombre: "Connectivity Information" },
                        { nombre: "Fields Name Convention" },
                        { nombre: "Report Formats" },
                    ]
                }
            ]
        }


        if (ban == "tmsapis") {/////
            tituloge = "TMS APIs <br/> Integration Notes";
            datos = [
                {
                    titulo: "Integration Notes",
                    data: [
                        { nombre: "Contactless Mode" },
                        { nombre: "Cross-reference Fields" },
                        { nombre: "Get Resource Response" },
                        { nombre: "Password Encoding" },
                        { nombre: "System Message" },
                        { nombre: "TMS parameters" },
                        { nombre: "Terminal Code" },
                        { nombre: "Terminal Hot Keys" },
                        { nombre: "Operations Frequency" }

                    ]
                }
            ]
        }


        var json = {
            tituloge: tituloge,
            datos: datos
        }

        resp = json;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;

}



export async function getDatosTypeEnum(ban) {
    var resp = [];
    var datos = [];
    var tituloge = '';
    try {

        if (ban == "real_time") {
            tituloge = "Real Time (HTTPs) APIs <br/>";
            datos = [
                {
                    titulo: "Type Enumerations",
                    data: [
                        { nombre: "Account Type" },
                        { nombre: "Extra Charge Type" },
                        { nombre: "Settlment Status" },
                        { nombre: "Authentication Status Type" },
                        { nombre: "Fallback Reason Code Type" },
                        { nombre: "Special Program Type" },
                        { nombre: "Authentication Type" },
                        { nombre: "Holder Type" },
                        { nombre: "Submission Type" },
                        { nombre: "Card Class" },
                        { nombre: "Holder Verification Mode Type" },
                        { nombre: "Tax Indicator Type" },
                        { nombre: "Card Indicator Type" },
                        { nombre: "Identity Verification Type" },
                        { nombre: "Terminal Capability Mode Type" },
                        { nombre: "Card Type" },
                        { nombre: "Lodging Charge Type" },
                        { nombre: "Terminal Type" },
                        { nombre: "CSC Indicator Type" },
                        { nombre: "Migrate Status Type" },
                        { nombre: "Tokenization Account Type" },
                        { nombre: "Currency Code Type" },
                        { nombre: "Migrate Terminal Type" },
                        { nombre: "Transaction Category Type" },
                        { nombre: "Cycle Type" },
                        { nombre: "Payment Method Type" },
                        { nombre: "Transaction Industry Type" },
                        { nombre: "Driver License State" },
                        { nombre: "Petroleum Product Type" },
                        { nombre: "Transaction Mode Type" },
                        { nombre: "Entry Medium Type" },
                        { nombre: "Pre-tokenization Verification Mode Type" },
                        { nombre: "Transaction Status Type" },
                        { nombre: "Entry Mode Type" },
                        { nombre: "Processing Mode Type" },
                        { nombre: "Transaction Type" },
                        { nombre: "Entry PIN Mode Type" },
                        { nombre: "Receipt Mode Type" },
                        { nombre: "Visa Product Sub-Type" },
                        { nombre: "Extended Account Type" },
                        { nombre: "Return URL Policy Type" },
                        { nombre: "Void Reason Code Type" }
                    ],
                    tablas: [
                        {
                            titulo: "Account Type",
                            datos: [
                                {
                                    tipo: "R",
                                    descripcion: "Branded credit card."
                                },
                                {
                                    tipo: "E",
                                    descripcion: "Branded debit checking card. This type is used for Canadian debit cards (Interac) only."
                                },
                                {
                                    tipo: "V",
                                    descripcion: "Branded debit savings card. This type is used for Canadian debit cards (Interac) only."
                                },
                                {
                                    tipo: "D",
                                    descripcion: "Unbranded debit checking card."
                                },
                                {
                                    tipo: "I",
                                    descripcion: "Unbranded debit savings card."
                                },
                                {
                                    tipo: "S",
                                    descripcion: "Bank saving account."
                                },
                                {
                                    tipo: "C",
                                    descripcion: "Bank checking account."
                                },
                                {
                                    tipo: "F",
                                    descripcion: "EBT food stamp."
                                },
                                {
                                    tipo: "H",
                                    descripcion: "EBT cash benefit."
                                },
                                {
                                    tipo: "G",
                                    descripcion: "Gift card."
                                },
                                {
                                    tipo: "L",
                                    descripcion: "Fleet."
                                },
                                {
                                    tipo: "A",
                                    descripcion: "Cash (used for sale-info, credit-info API calls)."
                                },
                                {
                                    tipo: "K",
                                    descripcion: "Check (used for sale-info, credit-info API calls)."
                                }
                                
                            ]
                        },
                        {
                            titulo: "Authentication Status Type",
                            datos: [
                                {
                                    tipo: "Y",
                                    descripcion: "Indicates that 3D Secure verification has been completed successfully."
                                },
                                {
                                    tipo: "A",
                                    descripcion: "Indicates that 3D Secure verification has been attempted."
                                },
                                {
                                    tipo: "U",
                                    descripcion: "Indicates that a submitted card cannot be verified."
                                }
                            ]
                        },
                        {
                            titulo: "Authentication Type",
                            datos: [
                                {
                                    tipo: "Processing",
                                    descripcion: "Password is used to process transactions in real-time."
                                },
                                {
                                    tipo: "Onboarding",
                                    descripcion: "Password is used for processing onboarding requests."
                                },
                                {
                                    tipo: "HPP",
                                    descripcion: "Password is used to process transactions via HPP."
                                }
                            ]
                        },
                        {
                            titulo: "Card Class",
                            datos: [
                                {
                                    tipo: "B",
                                    descripcion: "Business"
                                },
                                {
                                    tipo: "C",
                                    descripcion: "Consumer"
                                },
                                {
                                    tipo: "P",
                                    descripcion: "Purchase"
                                },
                                {
                                    tipo: "T",
                                    descripcion: "Corporate"
                                }
                            ]
                        },
                        {
                            titulo: "Card Indicator Type",
                            datos: [
                                {
                                    tipo: "C",
                                    descripcion: "Credit hybrid (PIN and signature)"
                                },
                                {
                                    tipo: "E",
                                    descripcion: "Debit (PIN only with EBT)"
                                },
                                {
                                    tipo: "H",
                                    descripcion: "Debit hybrid (PIN and signature)"
                                },
                                {
                                    tipo: "J",
                                    descripcion: "USA commercial debit (signature only, no PIN access)"
                                },
                                {
                                    tipo: "K",
                                    descripcion: "USA commercial debit (PIN Capable)"
                                },
                                {
                                    tipo: "L",
                                    descripcion: "NON USA Consumer Debit - No Pin Access"
                                },
                                {
                                    tipo: "M",
                                    descripcion: "NON USA Commercial Debit - No Pin Access"
                                },
                                {
                                    tipo: "N",
                                    descripcion: "NON USA Consumer Debit - Pin Capable"
                                },
                                {
                                    tipo: "O",
                                    descripcion: "NON USA Commercial Debit - Pin Capable"
                                },
                                {
                                    tipo: "P",
                                    descripcion: "Debit - PIN Only without EBT"
                                },
                                {
                                    tipo: "R",
                                    descripcion: "Private Label (MasterCard)"
                                },
                                {
                                    tipo: "S",
                                    descripcion: "Signature only (not PIN capable)"
                                },
                                {
                                    tipo: "U",
                                    descripcion: "Reloadable Prepaid - Amex only"
                                },
                                {
                                    tipo: "V",
                                    descripcion: "Stored Value Prepaid - Amex only"
                                },
                                {
                                    tipo: "X",
                                    descripcion: "True credit (No PIN/Signature capability)"
                                }
                            ]
                        },
                        {
                            titulo: "Card Type",
                            datos: [
                                {
                                    tipo: "P",
                                    descripcion: "Physical"
                                },
                                {
                                    tipo: "V",
                                    descripcion: "Virtual"
                                }
                            ]
                        },
                        {
                            titulo: "CSC Indicator Type",
                            datos: [
                                {
                                    tipo: "P",
                                    descripcion: "Indicates that CSC is provided in the API request"
                                },
                                {
                                    tipo: "N",
                                    descripcion: "Indicates that CSC is not provided in the API request"
                                },
                                {
                                    tipo: "I",
                                    descripcion: "Indicates that CSC is illegible"
                                },
                                {
                                    tipo: "X",
                                    descripcion: "Indicates that CSC is not present on the payment card"
                                }
                            ]
                        },
                        {
                            titulo: "Currency Code Type",
                            datos: [
                                {
                                    tipo: "PTS",
                                    descripcion: "Points"
                                },
                                {
                                    tipo: "DEF",
                                    descripcion: "Default currency of an account"
                                }
                            ]
                        },
                        {
                            titulo: "Cycle Type",
                            datos: [
                                {
                                    tipo: "S",
                                    descripcion: "Indicates that a secondary transaction cycle is closed. Secondary RTC includes only direct debit transactions"
                                },
                                {
                                    tipo: "P",
                                    descripcion: "Indicates that a primary transaction cycle is closed. Primary RTC includes only payment card transactions or both card and direct debit transactions"
                                }
                            ]
                        },
                        {
                            titulo: "Driver License State",
                            datos: [
                                {
                                    tipo: "CA",
                                    descripcion: "California"
                                },
                                {
                                    tipo: "AL",
                                    descripcion: "Alabama"
                                },
                                {
                                    tipo: "AK",
                                    descripcion: "Alaska"
                                },
                                {
                                    tipo: "AZ",
                                    descripcion: "Arizona"
                                },
                                {
                                    tipo: "CT",
                                    descripcion: "Connecticut"
                                },
                                {
                                    tipo: "CO",
                                    descripcion: "Colorado"
                                },
                                {
                                    tipo: "GA",
                                    descripcion: "Georgia"
                                },
                                {
                                    tipo: "FL",
                                    descripcion: "Florida"
                                },
                                {
                                    tipo: "DE",
                                    descripcion: "Delaware"
                                },
                                {
                                    tipo: "AR",
                                    descripcion: "Arkansas"
                                },
                                {
                                    tipo: "IL",
                                    descripcion: "Illinois"
                                },
                                {
                                    tipo: "HI",
                                    descripcion: "Hawaii"
                                },
                                {
                                    tipo: "ID",
                                    descripcion: "Idaho"
                                },
                                {
                                    tipo: "IN",
                                    descripcion: "Indiana"
                                },
                                {
                                    tipo: "KS",
                                    descripcion: "Kansas"
                                },
                                {
                                    tipo: "KY",
                                    descripcion: "Commonwealth of Kentucky"
                                },
                                {
                                    tipo: "IA",
                                    descripcion: "Iowa"
                                },
                                {
                                    tipo: "ME",
                                    descripcion: "Maine"
                                },
                                {
                                    tipo: "LA",
                                    descripcion: "Louisiana"
                                },
                                {
                                    tipo: "MN",
                                    descripcion: "Minnesota"
                                },
                                {
                                    tipo: "MI",
                                    descripcion: "Michigan"
                                },
                                {
                                    tipo: "MO",
                                    descripcion: "Missouri"
                                },
                                {
                                    tipo: "MD",
                                    descripcion: "Maryland"
                                },
                                {
                                    tipo: "MA",
                                    descripcion: "Massachusetts"
                                },
                                {
                                    tipo: "MS",
                                    descripcion: "Mississippi"
                                },
                                {
                                    tipo: "MT",
                                    descripcion: "Montana"
                                },
                                {
                                    tipo: "NV",
                                    descripcion: "Nevada"
                                },
                                {
                                    tipo: "NH",
                                    descripcion: "New Hampshire"
                                },
                                {
                                    tipo: "NJ",
                                    descripcion: "New Jersey"
                                },
                                {
                                    tipo: "NE",
                                    descripcion: "Nebraska"
                                },
                                {
                                    tipo: "NY",
                                    descripcion: "New York"
                                },
                                {
                                    tipo: "NC",
                                    descripcion: "North Carolina"
                                },
                                {
                                    tipo: "OK",
                                    descripcion: "Oklahoma"
                                },
                                {
                                    tipo: "OH",
                                    descripcion: "Ohio"
                                },
                                {
                                    tipo: "ND",
                                    descripcion: "North Dakota"
                                },
                                {
                                    tipo: "PA",
                                    descripcion: "Commonwealth of Pennsylvania"
                                },
                                {
                                    tipo: "OR",
                                    descripcion: "Oregon"
                                },
                                {
                                    tipo: "NM",
                                    descripcion: "New Mexico"
                                },
                                {
                                    tipo: "SD",
                                    descripcion: "South Dakota"
                                },
                                {
                                    tipo: "TN",
                                    descripcion: "Tennessee"
                                },
                                {
                                    tipo: "RI",
                                    descripcion: "Rhode Island"
                                },
                                {
                                    tipo: "SC",
                                    descripcion: "South Carolina"
                                },
                                {
                                    tipo: "VA",
                                    descripcion: "Commonwealth of Virginia"
                                },
                                {
                                    tipo: "VT",
                                    descripcion: "Vermont"
                                },
                                {
                                    tipo: "UT",
                                    descripcion: "Utah"
                                },
                                {
                                    tipo: "TX",
                                    descripcion: "Texas"
                                },
                                {
                                    tipo: "WV",
                                    descripcion: "West Virginia"
                                },
                                {
                                    tipo: "WA",
                                    descripcion: "Washington"
                                },
                                {
                                    tipo: "WI",
                                    descripcion: "Wisconsin"
                                },
                                {
                                    tipo: "DC",
                                    descripcion: "Washington DC"
                                },
                                {
                                    tipo: "WY",
                                    descripcion: "Wyoming"
                                }
                            ]
                        },
                        {
                            titulo: "Entry Medium Type",
                            datos: [
                                {
                                    tipo: "NP",
                                    descripcion: "Not present"
                                },
                                {
                                    tipo: "MC",
                                    descripcion: "Magnetic card"
                                },
                                {
                                    tipo: "CC",
                                    descripcion: "Chip card"
                                },
                                {
                                    tipo: "CH",
                                    descripcion: "Check"
                                }
                            ]
                        },
                        {
                            titulo: "Entry Mode Type",
                            datos: [
                                {
                                    tipo: "S",
                                    descripcion: "Swipe/Scan; data is received when a card/check is swiped/scanned"
                                },
                                {
                                    tipo: "H",
                                    descripcion: "Chip; data is received when an EMV card is inserted into a terminal"
                                },
                                {
                                    tipo: "C",
                                    descripcion: "Contactless; data is received when a contactless card is tapped to a terminal"
                                },
                                {
                                    tipo: "M",
                                    descripcion: "Manual; data is received when a user enters it manually. Used when card/check data cannot be read by any of the entry methods indicated above"
                                }
                            ]
                        },
                        {
                            titulo: "Entry PIN Mode Type",
                            datos: [
                                {
                                    tipo: "X",
                                    descripcion: "Unknown"
                                },
                                {
                                    tipo: "S",
                                    descripcion: "Supported"
                                },
                                {
                                    tipo: "U",
                                    descripcion: "Unsupported"
                                },
                                {
                                    tipo: "N",
                                    descripcion: "Inoperative"
                                },
                                {
                                    tipo: "O",
                                    descripcion: "Offline"
                                }
                            ]
                        },
                        {
                            titulo: "Extended Account Type",
                            datos: [
                                {
                                    tipo: "VC",
                                    descripcion: "Visa credit card"
                                },
                                {
                                    tipo: "VD",
                                    descripcion: "Visa debit card"
                                },
                                {
                                    tipo: "MC",
                                    descripcion: "MasterCard credit card"
                                },
                                {
                                    tipo: "MD",
                                    descripcion: "MasterCard debit card"
                                },
                                {
                                    tipo: "DC",
                                    descripcion: "Discover credit card"
                                },
                                {
                                    tipo: "DD",
                                    descripcion: "Discover debit card"
                                },
                                {
                                    tipo: "AC",
                                    descripcion: "AmEx credit card"
                                },
                                {
                                    tipo: "NC",
                                    descripcion: "Diners credit card"
                                },
                                {
                                    tipo: "BD",
                                    descripcion: "Bank debit Card"
                                },
                                {
                                    tipo: "BC",
                                    descripcion: "Checking account"
                                },
                                {
                                    tipo: "BS",
                                    descripcion: "Savings account"
                                },
                                {
                                    tipo: "VP",
                                    descripcion: "Visa prepaid card"
                                },
                                {
                                    tipo: "VS",
                                    descripcion: "Visa fleet card"
                                },
                                {
                                    tipo: "MP",
                                    descripcion: "MasterCard prepaid card"
                                },
                                {
                                    tipo: "MF",
                                    descripcion: "MasterCard fleet card"
                                },
                                {
                                    tipo: "EF",
                                    descripcion: "EBT food stamp"
                                },
                                {
                                    tipo: "EC",
                                    descripcion: "EBT cash benefit"
                                },
                                {
                                    tipo: "GG",
                                    descripcion: "General gift card"
                                },
                                {
                                    tipo: "FL",
                                    descripcion: "Fleet One card"
                                },
                                {
                                    tipo: "GF",
                                    descripcion: "Fuelman or FleetWide card"
                                },
                                {
                                    tipo: "VF",
                                    descripcion: "Voyager fleet card"
                                },
                                {
                                    tipo: "WX",
                                    descripcion: "WEX fleet card"
                                },
                                {
                                    tipo: "CA",
                                    descripcion: "Cash (used for sale-info, credit-info API calls)"
                                },
                                {
                                    tipo: "CK",
                                    descripcion: "Check (used for sale-info, credit-info API calls)"
                                },
                                {
                                    tipo: "VE",
                                    descripcion: "Visa debit checking account"
                                },
                                {
                                    tipo: "VV",
                                    descripcion: "Visa debit saving account"
                                },
                                {
                                    tipo: "UC",
                                    descripcion: "UnionPay credit card"
                                },
                                {
                                    tipo: "UD",
                                    descripcion: "UnionPay debit card"
                                }
                            ]
                        },
                        {
                            titulo: "Extra Charge Type",
                            datos: [
                                {
                                    tipo: "G",
                                    descripcion: "Gift shop"
                                },
                                {
                                    tipo: "L",
                                    descripcion: "Laundry"
                                },
                                {
                                    tipo: "B",
                                    descripcion: "Mini bar"
                                },
                                {
                                    tipo: "R",
                                    descripcion: "Restaurant"
                                },
                                {
                                    tipo: "T",
                                    descripcion: "Telephone"
                                },
                                {
                                    tipo: "S",
                                    descripcion: "Gass"
                                },
                                {
                                    tipo: "M",
                                    descripcion: "Extra mileage"
                                },
                                {
                                    tipo: "N",
                                    descripcion: "Late return"
                                },
                                {
                                    tipo: "W",
                                    descripcion: "One way service"
                                },
                                {
                                    tipo: "P",
                                    descripcion: "Parking violation"
                                }
                            ]
                        },
                        {
                            titulo: "Fallback Reason Code Type",
                            datos: [
                                {
                                    tipo: "NA",
                                    descripcion: "Indicates that none of the applications, available on the chip, are supported by the terminal"
                                },
                                {
                                    tipo: "TE",
                                    descripcion: "Indicates that processing is not physically possible"
                                }
                            ]
                        },
                        {
                            titulo: "Holder Type",
                            datos: [
                                {
                                    tipo: "P",
                                    descripcion: "Person"
                                },
                                {
                                    tipo: "O",
                                    descripcion: "Organization (used for level II and level III transactions)"
                                }
                            ]
                        },
                        {
                            titulo: "Holder Verification Mode Type",
                            datos: [
                                {
                                    tipo: "NN",
                                    descripcion: "No verification"
                                },
                                {
                                    tipo: "II",
                                    descripcion: "Ink signature verification"
                                },
                                {
                                    tipo: "DD",
                                    descripcion: "Digital signature verification"
                                },
                                {
                                    tipo: "PP",
                                    descripcion: "PIN verification"
                                },
                                {
                                    tipo: "PI",
                                    descripcion: "PIN and ink signature verification"
                                },
                                {
                                    tipo: "PD",
                                    descripcion: "PIN and digital signature verification"
                                }
                            ]
                        },
                        {
                            titulo: "Identity Verification Type",
                            datos: [
                                {
                                    tipo: "ssn_4",
                                    descripcion: "The last four digits of a bank account holder’s social security number"
                                },
                                {
                                    tipo: "dob_y",
                                    descripcion: "Birth year of a bank account holder"
                                }
                            ]
                        },
                        {
                            titulo: "Lodging Charge Type",
                            datos: [
                                {
                                    tipo: "H",
                                    descripcion: "Hotel"
                                },
                                {
                                    tipo: "R",
                                    descripcion: "Restaurant"
                                },
                                {
                                    tipo: "G",
                                    descripcion: "Gift shop"
                                },
                                {
                                    tipo: "S",
                                    descripcion: "Health spa"
                                },
                                {
                                    tipo: "B",
                                    descripcion: "Beauty shop"
                                },
                                {
                                    tipo: "F",
                                    descripcion: "Convention fee"
                                },
                                {
                                    tipo: "T",
                                    descripcion: "Tennis"
                                },
                                {
                                    tipo: "O",
                                    descripcion: "Golf"
                                }
                            ]
                        },
                        {
                            titulo: "Migrate Status Type",
                            datos: [
                                {
                                    tipo: "M",
                                    descripcion: "Completed"
                                },
                                {
                                    tipo: "P",
                                    descripcion: "Pending"
                                },
                                {
                                    tipo: "F",
                                    descripcion: "Failed"
                                },
                                {
                                    tipo: "C",
                                    descripcion: "Cancelled"
                                }
                            ]
                        },
                        {
                            titulo: "Migrate Terminal Type",
                            datos: [
                                {
                                    tipo: "migrate-terminal",
                                    descripcion: "Type of the request/response"
                                }
                            ]
                        },
                        {
                            titulo: "Payment Method Type",
                            datos: [
                                {
                                    tipo: "applepay",
                                    descripcion: ""
                                },
                                {
                                    tipo: "googlepay",
                                    descripcion: ""
                                }
                            ]
                        },
                        {
                            titulo: "Petroleum Product Type",
                            datos: [
                                {
                                    tipo: "F",
                                    descripcion: "Fuel"
                                },
                                {
                                    tipo: "N",
                                    descripcion: "Non-fuel"
                                }
                            ]
                        },
                        {
                            titulo: "Petroleum Product Type",
                            datos: [
                                {
                                    tipo: "NN",
                                    descripcion: "Indicates that pre-tokenization verification of the payment card or bank account is not performed"
                                },
                                {
                                    tipo: "PF",
                                    descripcion: "Indicates that pre-tokenization verification of the payment card or bank account is performed by a processor. If verification fails, tokenization request is declined"
                                }
                            ]
                        },
                        {
                            titulo: "Processing Mode Type",
                            datos: [
                                {
                                    tipo: "online",
                                    descripcion: "Indicates that the transaction is processed online"
                                },
                                {
                                    tipo: "offline",
                                    descripcion: "Indicates that the transaction is processed offline, i.e. it is pre-processed (in case of swiped transactions) or it has been approved by the chip (in case of EMV transactions)"
                                },
                                {
                                    tipo: "request",
                                    descripcion: "Indicates that the transaction is executed by the terminal"
                                },
                                {
                                    tipo: "queue",
                                    descripcion: "Indicates that the transaction is queued by the terminal for further processing"
                                }
                            ]
                            
                        },
                        {
                            titulo: "Receipt Mode Type",
                            datos: [
                                {
                                    tipo: "N",
                                    descripcion: "Indicates that the receipt is not needed."
                                },
                                {
                                    tipo: "G",
                                    descripcion: "Indicates that the receipt is sent to a customer via email."
                                },
                                {
                                    tipo: "T",
                                    descripcion: "Indicates that the receipt is printed out by the terminal."
                                },
                                {
                                    tipo: "P",
                                    descripcion: "Indicates that the receipt is printed out by the POS."
                                }
                            ]
                            
                        },
                        {
                            titulo: "Return URL Policy Type",
                            datos: [
                                {
                                    tipo: "page",
                                    descripcion: "Indicates that when a transaction is completed, a result (confirmation) page with the response is rendered, regardless of the transaction response (approval or decline). On the result (confirmation) page there is a Continue button to automatically redirect the user to the link indicated in returnURL (if it is included in the request)."
                                },
                                {
                                    tipo: "page-approval",
                                    descripcion: "Indicates that when a transaction is completed, a result (confirmation) page with the response is rendered only if the transaction has been approved. The Continue button (to automatically redirect the user to the link indicated in returnURL) is present only if the transaction has been approved. If the transaction has been declined, response message is rendered on the main page (HPP session invalidated, no further action is allowed)."
                                },
                                {
                                    tipo: "redirect",
                                    descripcion: "Indicates that when a transaction is completed, a user is automatically redirected to the link indicated in returnURL (bypassing a confirmation page) regardless of the transaction response (approval or decline)."
                                },
                                {
                                    tipo: "redirect-approval",
                                    descripcion: "Indicates that when a transaction is completed and approved by the processor, a user is automatically redirected to the link indicated in returnURL (bypassing a confirmation page). If the transaction has been declined, the user is forwarded to the result page (HPP session closed, no further action allowed)."
                                }
                            ]
                            
                        },
                        {
                            titulo: "Settlment Status",
                            datos: [
                                {
                                    tipo: "settled",
                                    descripcion: "Only settled transactions are included in report"
                                },
                                {
                                    tipo: "unsettled",
                                    descripcion: "Only unsettled transaction are included in report"
                                },
                                {
                                    tipo: "all",
                                    descripcion: "All transactions are included in report"
                                }
                            ]
                            
                        },
                        {
                            titulo: "Special Program Type",
                            datos: [
                                {
                                    tipo: "AD",
                                    descripcion: "Advance deposit"
                                },
                                {
                                    tipo: "AR",
                                    descripcion: "Assured reservation"
                                },
                                {
                                    tipo: "DC",
                                    descripcion: "Delayed charge"
                                },
                                {
                                    tipo: "ES",
                                    descripcion: "Express service"
                                },
                                {
                                    tipo: "NC",
                                    descripcion: "Normal charge"
                                },
                                {
                                    tipo: "NS",
                                    descripcion: "No show charge"
                                },
                                {
                                    tipo: "FR",
                                    descripcion: "Frequent renter"
                                }
                            ]
                            
                        },
                        {
                            titulo: "Submission Type",
                            datos: [
                                {
                                    tipo: "all",
                                    descripcion: "Indicates that transactions of all submission types are exported"
                                },
                                {
                                    tipo: "realtime",
                                    descripcion: "Indicates that real-time transactions are exported"
                                },
                                {
                                    tipo: "batch",
                                    descripcion: "Indicates that batch transactions are exported"
                                },
                                {
                                    tipo: "rebill",
                                    descripcion: "Indicates that transactions that are in rebill are exported"
                                },
                                {
                                    tipo: "retry",
                                    descripcion: "Indicates that transactions that are in retry are exported"
                                }
                            ]
                            
                        },
                        {
                            titulo: "Tax Indicator Type",
                            datos: [
                                {
                                    tipo: "P",
                                    descripcion: "Indicates that tax information is provided"
                                },
                                {
                                    tipo: "N",
                                    descripcion: "Indicates that tax information is not provided"
                                },
                                {
                                    tipo: "E",
                                    descripcion: "Indicates that payment card or bank account holder Is exempted from paying taxes"
                                }
                            ]
                            
                        },
                        {
                            titulo: "Terminal Capability Mode Type",
                            datos: [
                                {
                                    tipo: "unknown",
                                    descripcion: "Terminal capability is unknown"
                                },
                                {
                                    tipo: "unused",
                                    descripcion: "No terminal is used"
                                },
                                {
                                    tipo: "manual",
                                    descripcion: "Transaction data can be entered manually"
                                },
                                {
                                    tipo: "stripe",
                                    descripcion: "Transaction data can be entered via magnetic stripe reader (swipe technology is used)"
                                },
                                {
                                    tipo: "barcode",
                                    descripcion: "Transaction data can be entered via barcode reader"
                                },
                                {
                                    tipo: "qrcode",
                                    descripcion: "Transaction data can be entered via QR code reader"
                                },
                                {
                                    tipo: "ocr",
                                    descripcion: "Transaction data can be entered via optical reader"
                                },
                                {
                                    tipo: "icc",
                                    descripcion: "Transaction data can be entered via EMV reader"
                                },
                                {
                                    tipo: "contactless",
                                    descripcion: "Transaction data can be entered via contactless payment technology"
                                },
                                {
                                    tipo: "signature",
                                    descripcion: "Transaction must be approved with the customer's signature"
                                },
                                {
                                    tipo: "rfid",
                                    descripcion: "Transaction data can be entered via RFID (radio frequency identification) reader"
                                },
                                {
                                    tipo: "micr",
                                    descripcion: "Transaction data can be entered via MICR (magnetic ink character recognition) reader"
                                },
                                {
                                    tipo: "mpos",
                                    descripcion: "Device can be used as mobile POS"
                                }
                            ]
                            
                        },
                        {
                            titulo: "Terminal Type",
                            datos: [
                                {
                                    tipo: "POS",
                                    descripcion: "Indicates that a POS terminal is used"
                                },
                                {
                                    tipo: "AFP",
                                    descripcion: "Indicates that an automated fuel pump terminal is used"
                                }
                            ]
                        },
                        {
                            titulo: "Tokenization Account Type",
                            datos: [
                                {
                                    tipo: "VC",
                                    descripcion: "Visa credit card"
                                },
                                {
                                    tipo: "VD",
                                    descripcion: "Visa debit card"
                                },
                                {
                                    tipo: "MC",
                                    descripcion: "MasterCard credit card"
                                },
                                {
                                    tipo: "MD",
                                    descripcion: "MasterCard debit card"
                                },
                                {
                                    tipo: "DC",
                                    descripcion: "Discover credit card"
                                },
                                {
                                    tipo: "DD",
                                    descripcion: "Discover debit card"
                                },
                                {
                                    tipo: "AC",
                                    descripcion: "AmEx credit card"
                                },
                                {
                                    tipo: "NC",
                                    descripcion: "Diners credit card"
                                },
                                {
                                    tipo: "BD",
                                    descripcion: "Bank debit card"
                                },
                                {
                                    tipo: "BC",
                                    descripcion: "Checking account"
                                },
                                {
                                    tipo: "BS",
                                    descripcion: "Savings account"
                                },
                                {
                                    tipo: "VP",
                                    descripcion: "Visa prepaid card"
                                },
                                {
                                    tipo: "VS",
                                    descripcion: "Visa fleet card"
                                },
                                {
                                    tipo: "MP",
                                    descripcion: "MasterCard prepaid card"
                                },
                                {
                                    tipo: "MF",
                                    descripcion: "MasterCard fleet card"
                                },
                                {
                                    tipo: "EF",
                                    descripcion: "EBT food stamp"
                                },
                                {
                                    tipo: "EC",
                                    descripcion: "EBT cash benefit"
                                },
                                {
                                    tipo: "GG",
                                    descripcion: "General gift card"
                                },
                                {
                                    tipo: "FL",
                                    descripcion: "Fleet One card"
                                },
                                {
                                    tipo: "GF",
                                    descripcion: "Fuelman or FleetWide card"
                                },
                                {
                                    tipo: "VF",
                                    descripcion: "Voyager fleet card"
                                },
                                {
                                    tipo: "WX",
                                    descripcion: "WEX fleet card"
                                },
                                {
                                    tipo: "VE",
                                    descripcion: "Visa debit checking account"
                                },
                                {
                                    tipo: "VV",
                                    descripcion: "Visa debit saving account"
                                },
                                {
                                    tipo: "UC",
                                    descripcion: "UnionPay credit card"
                                },
                                {
                                    tipo: "UD",
                                    descripcion: "UnionPay debit card"
                                }
                            ]
                        },
                        {
                            titulo: "Transaction Category Type",
                            datos: [
                                {
                                    tipo: "B",
                                    descripcion: "Bill payment"
                                },
                                {
                                    tipo: "R",
                                    descripcion: "Recurring"
                                },
                                {
                                    tipo: "I",
                                    descripcion: "Installment"
                                },
                                {
                                    tipo: "H",
                                    descripcion: "Healthcare"
                                }
                            ]
                        },
                        {
                            titulo: "Transaction Industry Type",
                            datos: [
                                {
                                    tipo: "DM",
                                    descripcion: "Direct marketing"
                                },
                                {
                                    tipo: "EC",
                                    descripcion: "Ecommerce"
                                },
                                {
                                    tipo: "PT",
                                    descripcion: "Petroleum"
                                },
                                {
                                    tipo: "RE",
                                    descripcion: "Retail"
                                },
                                {
                                    tipo: "RS",
                                    descripcion: "Restaurant"
                                },
                                {
                                    tipo: "LD",
                                    descripcion: "Lodging"
                                },
                                {
                                    tipo: "CCD",
                                    descripcion: "Corporate credit or debit (direct debit only)"
                                },
                                {
                                    tipo: "PPD",
                                    descripcion: "Prearranged payment and deposit entry (direct debit only)"
                                },
                                {
                                    tipo: "POP",
                                    descripcion: "Point purchase entry (direct debit only)"
                                },
                                {
                                    tipo: "TEL",
                                    descripcion: "Telephone initiated entry (direct debit only)"
                                },
                                {
                                    tipo: "WEB",
                                    descripcion: "Internet initiated entry (direct debit only)"
                                },
                                {
                                    tipo: "C21",
                                    descripcion: "Check 21 (direct debit only)"
                                }
                            ]
                        },
                        {
                            titulo: "Transaction Mode Type",
                            datos: [
                                {
                                    tipo: "P",
                                    descripcion: "Indicates that a transaction is card-present and is initiated by a cardholder"
                                },
                                {
                                    tipo: "N",
                                    descripcion: "Indicates that a transaction is card-not-present and is initiated by a cardholder"
                                },
                                {
                                    tipo: "S",
                                    descripcion: "Indicates that a transaction is going to be stored as card-on-file and is initiated by a cardholder"
                                },
                                {
                                    tipo: "O",
                                    descripcion: "Indicates that a transaction is stored as card-on-file and is initiated by a merchant"
                                }
                            ]
                        },
                        {
                            titulo: "Transaction Status Type",
                            datos: [
                                {
                                    tipo: "N",
                                    descripcion: "Indicates that the transaction is in pending status, i.e. is waiting for approval within a batch file"
                                },
                                {
                                    tipo: "P",
                                    descripcion: "Indicates that the transaction has been processed"
                                },
                                {
                                    tipo: "C",
                                    descripcion: "Indicates that the transaction has been cancelled"
                                },
                                {
                                    tipo: "R",
                                    descripcion: "Indicates that the transaction has been submitted for retry and is in rebill at the moment"
                                }
                            ]
                        },
                        {
                            titulo: "Transaction Type",
                            datos: [
                                {
                                    tipo: "all",
                                    descripcion: "Indicates that transactions of all types are exported"
                                },
                                {
                                    tipo: "sale",
                                    descripcion: "Sale"
                                },
                                {
                                    tipo: "refund",
                                    descripcion: "Refund"
                                },
                                {
                                    tipo: "void",
                                    descripcion: "Void"
                                },
                                {
                                    tipo: "decline",
                                    descripcion: "Decline"
                                },
                                {
                                    tipo: "auth",
                                    descripcion: "Authorization"
                                },
                                {
                                    tipo: "blacklist",
                                    descripcion: "Blacklist"
                                },
                                {
                                    tipo: "return",
                                    descripcion: "Return"
                                },
                                {
                                    tipo: "chargeback",
                                    descripcion: "Chargeback"
                                },
                                {
                                    tipo: "reversal",
                                    descripcion: "Reversal"
                                }
                            ]
                        },
                        {
                            titulo: "Visa Product Sub-Type",
                            datos: [
                                {
                                    tipo: "AC",
                                    descripcion: "Brazil Agriculture Maintenance Account (Custeio)"
                                },
                                {
                                    tipo: "AE",
                                    descripcion: "Brazil Agriculture Debit Account (Electron)"
                                },
                                {
                                    tipo: "AG",
                                    descripcion: "Brazil Agriculture"
                                },
                                {
                                    tipo: "AI",
                                    descripcion: "Brazil Agriculture Investment Loan (Investimento)"
                                },
                                {
                                    tipo: "CG",
                                    descripcion: "Brazil Cargo"
                                },
                                {
                                    tipo: "CS",
                                    descripcion: "Construction"
                                },
                                {
                                    tipo: "DS",
                                    descripcion: "Distribution"
                                },
                                {
                                    tipo: "HC",
                                    descripcion: "Healthcare"
                                },
                                {
                                    tipo: "LP",
                                    descripcion: "Visa Large-Purchase Advantage (VLPA)"
                                },
                                {
                                    tipo: "MA",
                                    descripcion: "Visa Mobile Agent"
                                },
                                {
                                    tipo: "MB",
                                    descripcion: "Interoperable Mobile Branchless Banking"
                                },
                                {
                                    tipo: "MG",
                                    descripcion: "Visa Mobile General"
                                },
                                {
                                    tipo: "VA",
                                    descripcion: "Brazil Food or Supermarket (Alimentacao Visa Vale)"
                                },
                                {
                                    tipo: "VF",
                                    descripcion: "Brazil Fuel (Flex Visa Vale)"
                                },
                                {
                                    tipo: "VR",
                                    descripcion: "Brazil Food or Restaurant (Refeicao Visa Vale)"
                                }
                            ]
                        },
                        {
                            titulo: "Void Reason Code Type",
                            datos: [
                                {
                                    tipo: "CI",
                                    descripcion: "Indicates that a void has been initiated by a customer"
                                },
                                {
                                    tipo: "TR",
                                    descripcion: "Indicates that a void has been done due to a timeout reversal"
                                },
                                {
                                    tipo: "SM",
                                    descripcion: "Indicates that a void has been done due to an error within the system"
                                },
                                {
                                    tipo: "SF",
                                    descripcion: "Indicates that a void has been done due to a suspected fraud attempt"
                                },
                                {
                                    tipo: "PR",
                                    descripcion: "Indicates that a void has been done due to a premature card removal"
                                },
                                {
                                    tipo: "CD",
                                    descripcion: "Indicates that a void has been done due to a payment card chip decline"
                                }
                            ]
                        }
                    ]
                }

            ]
        }
 
        if (ban == "batch") {
            tituloge = "Batch (sFTP) APIs <br/>";
            datos = [
                {
                    titulo: "Type Enumerations",
                    data: [
                        { nombre: "Account Type" },
                        { nombre: "Holder Type" },
                        { nombre: "Transaction Type" },
                        { nombre: "ACH" },
                        { nombre: "Payment Cards" },
                        { nombre: "Charge Type" },
                        { nombre: "Tokenization Account Type" }

                    ],
                    tablas: [
                        {
                            titulo: "Account Type",
                            datos: [
                                {
                                    tipo: "R",
                                    descripcion: "Payment card (debit or credit)"
                                },
                                {
                                    tipo: "C",
                                    descripcion: "Checking"
                                },
                                {
                                    tipo: "S",
                                    descripcion: "Savings"
                                }
                            ]
                        },
                        {
                            titulo: "ACH",
                            datos: [
                                {
                                    tipo: "CCD",
                                    descripcion: "Corporate credit or debit"
                                },
                                {
                                    tipo: "PPD",
                                    descripcion: "Prearranged payment and deposit entry"
                                },
                                {
                                    tipo: "TEL",
                                    descripcion: "Telephone initiated entry"
                                },
                                {
                                    tipo: "WEB",
                                    descripcion: "Telephone initiated entry"
                                },
                                {
                                    tipo: "C21",
                                    descripcion: "Check 21"
                                }
                            ]
                        },
                        {
                            titulo: "Charge Type",
                            datos: [
                                {
                                    tipo: "FF",
                                    descripcion: "Franchising fee"
                                }
                            ]
                        },
                        {
                            titulo: "Holder Type",
                            datos: [
                                {
                                    tipo: "P",
                                    descripcion: "Person"
                                },
                                {
                                    tipo: "O",
                                    descripcion: "Organization (Corporation)"
                                }
                            ]
                        },
                        {
                            titulo: "Payment Cards",
                            datos: [
                                {
                                    tipo: "DM",
                                    descripcion: "Direct marketing"
                                },
                                {
                                    tipo: "EC",
                                    descripcion: "Ecommerce"
                                }
                            ]
                        },
                        {
                            titulo: "Tokenization Account Type",
                            datos: [
                                {
                                    tipo: "VC",
                                    descripcion: "Visa credit card"
                                },
                                {
                                    tipo: "VD",
                                    descripcion: "Visa debit card"
                                },
                                {
                                    tipo: "MC",
                                    descripcion: "MasterCard credit card"
                                },
                                {
                                    tipo: "MD",
                                    descripcion: "MasterCard debit card"
                                },
                                {
                                    tipo: "DC",
                                    descripcion: "Discover credit card"
                                },
                                {
                                    tipo: "DD",
                                    descripcion: "Discover debit card"
                                },
                                {
                                    tipo: "AC",
                                    descripcion: "AmEx credit card"
                                },
                                {
                                    tipo: "NC",
                                    descripcion: "Diners credit card"
                                },
                                {
                                    tipo: "BD",
                                    descripcion: "Bank debit card"
                                },
                                {
                                    tipo: "BC",
                                    descripcion: "Checking account"
                                },
                                {
                                    tipo: "BS",
                                    descripcion: "Savings account"
                                },
                                {
                                    tipo: "VP",
                                    descripcion: "Visa prepaid card"
                                },
                                {
                                    tipo: "VS",
                                    descripcion: "Visa fleet card"
                                },
                                {
                                    tipo: "MP",
                                    descripcion: "MasterCard prepaid card"
                                },
                                {
                                    tipo: "MF",
                                    descripcion: "MasterCard fleet card"
                                },
                                {
                                    tipo: "EF",
                                    descripcion: "EBT food stamp"
                                },
                                {
                                    tipo: "EC",
                                    descripcion: "EBT cash benefit"
                                },
                                {
                                    tipo: "GG",
                                    descripcion: "General gift card"
                                },
                                {
                                    tipo: "FL",
                                    descripcion: "Fleet One card"
                                },
                                {
                                    tipo: "GF",
                                    descripcion: "Fuelman or FleetWide card"
                                },
                                {
                                    tipo: "VF",
                                    descripcion: "Voyager fleet card"
                                },
                                {
                                    tipo: "WX",
                                    descripcion: "WEX fleet card"
                                },
                                {
                                    tipo: "VE",
                                    descripcion: "Visa debit checking account"
                                },
                                {
                                    tipo: "VV",
                                    descripcion: "Visa debit saving account"
                                },
                                {
                                    tipo: "UC",
                                    descripcion: "UnionPay credit card"
                                },
                                {
                                    tipo: "UD",
                                    descripcion: "UnionPay debit card"
                                }
                            ]
                        },
                        {
                            titulo: "Transaction Type",
                            datos: [
                                {
                                    tipo: "S",
                                    descripcion: "Sale (operation used to withdraw specific amount of money from the supplied credit card or bank account)"
                                },
                                {
                                    tipo: "C",
                                    descripcion: "Credit (operation used to deposit (return) specific amount of money to the supplied credit card or bank account)"
                                },
                                {
                                    tipo: "D",
                                    descripcion: "Sale decline (sale transaction that got declined)"
                                },
                                {
                                    tipo: "X",
                                    descripcion: "Credit decline (credit transaction that got declined)"
                                },
                                {
                                    tipo: "E",
                                    descripcion: "Error (transaction that did not go through an internal validation of the gateway and was not sent for further processing)"
                                },
                                {
                                    tipo: "B",
                                    descripcion: "Blacklist (transaction, which has not been processed, as the account is in the blacklist due to the previous hard decline)"
                                },
                                {
                                    tipo: "N",
                                    descripcion: "Return (represents direct debit payment that is not honored by a bank)"
                                },
                                {
                                    tipo: "G",
                                    descripcion: "Chargeback (operation of reversal of a prior outbound transfer of funds from a customer's bank account or credit card)"
                                },
                                {
                                    tipo: "R",
                                    descripcion: "Reversal (operation where credit card chargeback is reversed - money is given back to the merchant or customer)"
                                },
                                {
                                    tipo: "U",
                                    descripcion: "Notice of change (represents direct debit transaction that is returned by the customer's bank as a notification that some details of the transaction were corrected)"
                                },
                                {
                                    tipo: "J",
                                    descripcion: "Reject (direct debit payment that is rejected by the bank due to insufficient funds or errors in payment order)"
                                }
                            ]
                        }
                    ]
                }
            ]
        }

        if (ban == "terminals") {/////
            tituloge = "Terminals APIs <br/>";
            datos = [
                {
                    titulo: "Type Enumerations",
                    data: [
                        { nombre: "Account Type" },
                        { nombre: "Entry PIN Mode Type" },
                        { nombre: "Processing Mode Type" },
                        { nombre: "Capture Value" },
                        { nombre: "Extended Account Type" },
                        { nombre: "Receipt Mode Type" },
                        { nombre: "Currency Type" },
                        { nombre: "Holder Type" },
                        { nombre: "Tokenization Account Type" },
                        { nombre: "Cycle Type" },
                        { nombre: "Holder Verification Mode Type" },
                        { nombre: "Transaction Category Type" },
                        { nombre: "Entry Medium Type" },
                        { nombre: "itemsAction" },
                        { nombre: "Transaction Industry Type" },
                        { nombre: "Entry Mode Type" },
                        { nombre: "Partial Authorization Type" },
                        { nombre: "Void Reason Code Type" }
                    ],
                    tablas: [
                        {
                            titulo: "Account Type",
                            datos: [
                                {
                                    tipo: "R",
                                    descripcion: "Payment card (debit or credit)"
                                },
                                {
                                    tipo: "E",
                                    descripcion: "Branded debit checking card. This type is used for Canadian debit cards (Interac) only."
                                },
                                {
                                    tipo: "V",
                                    descripcion: "Branded debit savings card. This type is used for Canadian debit cards (Interac) only."
                                },
                                {
                                    tipo: "D",
                                    descripcion: "Unbranded debit checking card"
                                },
                                {
                                    tipo: "I",
                                    descripcion: "Unbranded debit savings card"
                                },
                                {
                                    tipo: "C",
                                    descripcion: "Bank checking account"
                                },
                                {
                                    tipo: "S",
                                    descripcion: "Bank saving account"
                                },
                                {
                                    tipo: "F",
                                    descripcion: "EBT food stamp"
                                },
                                {
                                    tipo: "H",
                                    descripcion: "EBT cash benefit"
                                },
                                {
                                    tipo: "G",
                                    descripcion: "Gift card"
                                },
                                {
                                    tipo: "L",
                                    descripcion: "Fleet"
                                },
                                {
                                    tipo: "K",
                                    descripcion: "Check (used for sale-info, credit-info API calls)"
                                },
                                {
                                    tipo: "A",
                                    descripcion: "Cash (used for sale-info, credit-info API calls)"
                                }
                            ]
                        },
                        {
                            titulo: "Capture Value",
                            datos: [
                                {
                                    tipo: "capture-value",
                                    descripcion: "Type of the request/response"
                                }
                            ]
                        },
                        {
                            titulo: "Currency Type",
                            datos: [
                                {
                                    tipo: "DEF",
                                    descripcion: "Default currency of the merchant account"
                                },
                                {
                                    tipo: "PTS",
                                    descripcion: "Points"
                                }
                            ]
                        },
                        {
                            titulo: "Cycle Type",
                            datos: [
                                {
                                    tipo: "S",
                                    descripcion: "Indicates that a secondary transaction cycle is closed. Secondary RTC includes only direct debit transactions."
                                },
                                {
                                    tipo: "P",
                                    descripcion: "Indicates that a primary transaction cycle is closed. Primary RTC includes only payment card transactions or both card and direct debit transactions."
                                }
                            ]
                        },
                        {
                            titulo: "Entry Medium Type",
                            datos: [
                                {
                                    tipo: "NP",
                                    descripcion: "Not present"
                                },
                                {
                                    tipo: "MC",
                                    descripcion: "Magnetic card"
                                },
                                {
                                    tipo: "CC",
                                    descripcion: "Chip card"
                                },
                                {
                                    tipo: "CH",
                                    descripcion: "Check"
                                }
                            ]
                        },
                        {
                            titulo: "Entry PIN Mode Type",
                            datos: [
                                {
                                    tipo: "X",
                                    descripcion: "Unknown"
                                },
                                {
                                    tipo: "S",
                                    descripcion: "Supported"
                                },
                                {
                                    tipo: "U",
                                    descripcion: "Unsupported"
                                },
                                {
                                    tipo: "N",
                                    descripcion: "Inoperative"
                                },
                                {
                                    tipo: "O",
                                    descripcion: "Offline"
                                }
                            ]
                        },
                        {
                            titulo: "Extended Account Type",
                            datos: [
                                {
                                    tipo: "VC",
                                    descripcion: "Visa credit card"
                                },
                                {
                                    tipo: "VD",
                                    descripcion: "Visa debit card"
                                },
                                {
                                    tipo: "MC",
                                    descripcion: "MasterCard credit card"
                                },
                                {
                                    tipo: "MD",
                                    descripcion: "MasterCard debit card"
                                },
                                {
                                    tipo: "DC",
                                    descripcion: "Discover credit card"
                                },
                                {
                                    tipo: "DD",
                                    descripcion: "Discover debit card"
                                },
                                {
                                    tipo: "AC",
                                    descripcion: "AmEx credit card"
                                },
                                {
                                    tipo: "NC",
                                    descripcion: "Dinners credit card"
                                },
                                {
                                    tipo: "BD",
                                    descripcion: "Bank debit card"
                                },
                                {
                                    tipo: "BC",
                                    descripcion: "Checking account"
                                },
                                {
                                    tipo: "BS",
                                    descripcion: "Savings account"
                                },
                                {
                                    tipo: "VP",
                                    descripcion: "Visa prepaid card"
                                },
                                {
                                    tipo: "VS",
                                    descripcion: "Visa fleet card"
                                },
                                {
                                    tipo: "MP",
                                    descripcion: "MasterCard prepaid card"
                                },
                                {
                                    tipo: "MF",
                                    descripcion: "MasterCard fleet card"
                                },
                                {
                                    tipo: "EF",
                                    descripcion: "EBT food stamp"
                                },
                                {
                                    tipo: "EC",
                                    descripcion: "EBT cash benefit"
                                },
                                {
                                    tipo: "GG",
                                    descripcion: "General gift card"
                                },
                                {
                                    tipo: "FL",
                                    descripcion: "Fleet One card"
                                },
                                {
                                    tipo: "GF",
                                    descripcion: "Fuelman or FleetWide card"
                                },
                                {
                                    tipo: "VF",
                                    descripcion: "Voyager fleet card"
                                },
                                {
                                    tipo: "WX",
                                    descripcion: "WEX fleet card"
                                },
                                {
                                    tipo: "CA",
                                    descripcion: "Cash (used for sale-info, credit-info API calls)"
                                },
                                {
                                    tipo: "CK",
                                    descripcion: "Check (used for sale-info, credit-info API calls)"
                                },
                                {
                                    tipo: "VE",
                                    descripcion: "Visa debit checking account"
                                },
                                {
                                    tipo: "VV",
                                    descripcion: "Visa debit saving account"
                                }
                            ]
                        },
                        {
                            titulo: "Holder Type",
                            datos: [
                                {
                                    tipo: "P",
                                    descripcion: "Person"
                                },
                                {
                                    tipo: "O",
                                    descripcion: "Organization (Corporation)"
                                }
                            ]
                        },
                        {
                            titulo: "Holder Verification Mode Type",
                            datos: [
                                {
                                    tipo: "NN",
                                    descripcion: "Person"
                                },
                                {
                                    tipo: "II",
                                    descripcion: "Ink signature verification"
                                },
                                {
                                    tipo: "DD",
                                    descripcion: "Digital signature verification"
                                },
                                {
                                    tipo: "PP",
                                    descripcion: "PIN verification"
                                },
                                {
                                    tipo: "PI",
                                    descripcion: "PIN and ink signature verification"
                                },
                                {
                                    tipo: "PD",
                                    descripcion: "PIN and digital signature verification"
                                }
                            ]
                        },
                        {
                            titulo: "itemsAction",
                            datos: [
                                {
                                    tipo: "A",
                                    descripcion: "Add new items"
                                },
                                {
                                    tipo: "R",
                                    descripcion: "Replace current items"
                                }
                            ]
                        },
                        {
                            titulo: "Partial Authorization Type",
                            datos: [
                                {
                                    tipo: "N",
                                    descripcion: "Indicates that partial authorization is not supported"
                                },
                                {
                                    tipo: "T",
                                    descripcion: "Indicates that partial authorization is done by the terminal"
                                },
                                {
                                    tipo: "P",
                                    descripcion: "Indicates that partial authorization is done by a POS"
                                }
                            ]
                        },
                        {
                            titulo: "Processing Mode Type",
                            datos: [
                                {
                                    tipo: "online",
                                    descripcion: "Indicates that the transaction is processed online"
                                },
                                {
                                    tipo: "offline",
                                    descripcion: "Indicates that the transaction is processed offline, i.e. it is pre-processed (in case of swiped transactions) or it has been approved by the chip (in case of EMV transactions)"
                                }
                            ]
                        },
                        {
                            titulo: "Receipt Mode Type",
                            datos: [
                                {
                                    tipo: "N",
                                    descripcion: "Indicates that the receipt is not needed."
                                },
                                {
                                    tipo: "G",
                                    descripcion: "Indicates that the receipt is sent to a customer via email."
                                },
                                {
                                    tipo: "T",
                                    descripcion: "Indicates that the receipt is printed out by the terminal."
                                },
                                {
                                    tipo: "P",
                                    descripcion: "Indicates that the receipt is printed out by the POS."
                                }
                            ]
                        },
                        {
                            titulo: "Tokenization Account Type",
                            datos: [
                                {
                                    tipo: "VC",
                                    descripcion: "Visa credit card"
                                },
                                {
                                    tipo: "VD",
                                    descripcion: "Visa debit card"
                                },
                                {
                                    tipo: "MC",
                                    descripcion: "MasterCard credit card"
                                },
                                {
                                    tipo: "MD",
                                    descripcion: "MasterCard debit card"
                                },
                                {
                                    tipo: "DC",
                                    descripcion: "Discover credit card"
                                },
                                {
                                    tipo: "DD",
                                    descripcion: "Discover debit card"
                                },
                                {
                                    tipo: "AC",
                                    descripcion: "AmEx credit card"
                                },
                                {
                                    tipo: "NC",
                                    descripcion: "Diners credit card"
                                },
                                {
                                    tipo: "BD",
                                    descripcion: "Bank debit card"
                                },
                                {
                                    tipo: "BC",
                                    descripcion: "Checking account"
                                },
                                {
                                    tipo: "BS",
                                    descripcion: "Savings account"
                                },
                                {
                                    tipo: "VP",
                                    descripcion: "Visa prepaid card"
                                },
                                {
                                    tipo: "VS",
                                    descripcion: "Visa fleet card"
                                },
                                {
                                    tipo: "MP",
                                    descripcion: "MasterCard prepaid card"
                                },
                                {
                                    tipo: "MF",
                                    descripcion: "MasterCard fleet card"
                                },
                                {
                                    tipo: "EF",
                                    descripcion: "EBT food stamp"
                                },
                                {
                                    tipo: "EC",
                                    descripcion: "EBT cash benefit"
                                },
                                {
                                    tipo: "GG",
                                    descripcion: "General gift card"
                                },
                                {
                                    tipo: "FL",
                                    descripcion: "Fleet One card"
                                },
                                {
                                    tipo: "GF",
                                    descripcion: "Fuelman or FleetWide card"
                                },
                                {
                                    tipo: "VF",
                                    descripcion: "Voyager fleet card"
                                },
                                {
                                    tipo: "WX",
                                    descripcion: "WEX fleet card"
                                },
                                {
                                    tipo: "VE",
                                    descripcion: "Visa debit checking account"
                                },
                                {
                                    tipo: "VV",
                                    descripcion: "Visa debit saving account"
                                }
                            ]
                        },
                        {
                            titulo: "Transaction Category Type",
                            datos: [
                                {
                                    tipo: "B",
                                    descripcion: "Bill payment"
                                },
                                {
                                    tipo: "R",
                                    descripcion: "Recurring"
                                },
                                {
                                    tipo: "I",
                                    descripcion: "Installment"
                                },
                                {
                                    tipo: "H",
                                    descripcion: "Healthcare"
                                }
                            ]
                        },
                        {
                            titulo: "Transaction Industry Type",
                            datos: [
                                {
                                    tipo: "RE",
                                    descripcion: "Retail"
                                },
                                {
                                    tipo: "RS",
                                    descripcion: "Restaurant"
                                },
                                {
                                    tipo: "CCD",
                                    descripcion: "Corporate credit or debit (direct debit only)"
                                },
                                {
                                    tipo: "PPD",
                                    descripcion: "Prearranged payment and deposit entry (direct debit only)"
                                },
                                {
                                    tipo: "POP",
                                    descripcion: "Point purchase entry (direct debit only)"
                                }
                            ]
                        },
                        {
                            titulo: "Void Reason Code Type",
                            datos: [
                                {
                                    tipo: "CI",
                                    descripcion: "Indicates that a void has been initiated by a customer"
                                },
                                {
                                    tipo: "TR",
                                    descripcion: "Indicates that a void has been done due to a timeout reversal"
                                },
                                {
                                    tipo: "SM",
                                    descripcion: "Indicates that a void has been done due to an error within the system"
                                },
                                {
                                    tipo: "SF",
                                    descripcion: "Indicates that a void has been done due to a suspected fraud attempt"
                                },
                                {
                                    tipo: "PR",
                                    descripcion: "Indicates that a void has been done due to a premature card removal"
                                },
                                {
                                    tipo: "CD",
                                    descripcion: "Indicates that a void has been done due to a payment card chip decline"
                                }
                            ]
                        }
                    ]
                }
            ]
        }

        if (ban == "onboarding") {
            tituloge = "Onboarding APIs <br/>";
            datos = [
                {
                    titulo: "Type Enumerations",
                    data: [
                        { nombre: "Account Type" },
                        { nombre: "Merchant Creation Policy Type" },
                        { nombre: "Ownership Structure Type" },
                        { nombre: "Action Type" },
                        { nombre: "Merchant Profile" },
                        { nombre: "Return URL Policy Type" },
                        { nombre: "Data Format" },
                        { nombre: "Merchant Type" },
                        { nombre: "Terminal Status" },
                        { nombre: "Industry Type" },
                        { nombre: "Notification Policy Type" },
                        { nombre: "Transaction Industry Type" },
                        { nombre: "Load" },
                        { nombre: "Onboarding Response Codes" },
                        { nombre: "Update Terminal" }

                    ],
                    tablas: [
                        {
                            titulo: "Account Type",
                            datos: [
                                {
                                    tipo: "C",
                                    descripcion: "Checking"
                                },
                                {
                                    tipo: "S",
                                    descripcion: "Savings"
                                }
                            ]
                        },
                        {
                            titulo: "Account Type",
                            datos: [
                                {
                                    tipo: "S",
                                    descripcion: "Submit. Indicates that the onboarding request is going to be submitted immediately to a processor."
                                },
                                {
                                    tipo: "H",
                                    descripcion: "Hold. Indicates that the onboarding request is going to be submitted to a processor after a submission of the get-status API request with the actionType=S field value included."
                                }
                            ]
                        },
                        {
                            titulo: "Data Format",
                            datos: [
                                {
                                    tipo: "all",
                                    descripcion: "Values for all fields"
                                },
                                {
                                    tipo: "non sensitive",
                                    descripcion: "Values for only those fields"
                                }
                            ]
                        },
                        {
                            titulo: "Industry Type",
                            datos: [
                                {
                                    tipo: "R",
                                    descripcion: "Retail"
                                },
                                {
                                    tipo: "S",
                                    descripcion: "Restaurant"
                                }
                            ]
                        },
                        {
                            titulo: "Load",
                            datos: [
                                {
                                    tipo: "load",
                                    descripcion: "Type of the request/response"
                                }
                            ]
                        },
                        {
                            titulo: "Merchant Creation Policy Type",
                            datos: [
                                {
                                    tipo: "A",
                                    descripcion: "Indicates that a merchant is created when an onboarding application is approved by a processor"
                                },
                                {
                                    tipo: "R",
                                    descripcion: "Indicates that a merchant is created when an onboarding application either in review or approved by a processor"
                                }
                            ]
                        },
                        {
                            titulo: "Merchant Profile",
                            datos: [
                                {
                                    tipo: "SSSSM",
                                    descripcion: "Represents a merchant with a single location with a single tax ID, processing and deposit settings associated with it. Recurring fees are charged only once"
                                },
                                {
                                    tipo: "MSSSM",
                                    descripcion: "Represents a merchant with multiple locations. Underwriting is done with a single tax ID. Processing is done with a same MID. Money is deposited in a same single bank account for all locations. Recurring fees are charged only once"
                                },
                                {
                                    tipo: "MMMMM",
                                    descripcion: "Represents a merchant with multiple locations. Underwriting is done with a separate tax ID for each merchant account. Processing is done with multiple MIDs (one MID per descriptor). Money is deposited in a separate bank account for each merchant account. Recurring fees are charged only once"
                                },
                                {
                                    tipo: "MMMMA",
                                    descripcion: "Represents a merchant with multiple locations. Underwriting is done with a separate tax ID for each merchant account. Processing is done with multiple MIDs (one MID per descriptor). Money is deposited in a separate bank account for each merchant account. Recurring fees are charged per each merchant account"
                                },
                                {
                                    tipo: "MSMMM",
                                    descripcion: "Represents a merchant with multiple locations. Underwriting is done with a single tax ID. Processing is done with multiple MIDs (one MID per descriptor). Money is deposited in a separate bank account for each merchant account. Recurring fees are charged only once"
                                },
                                {
                                    tipo: "MSMMA",
                                    descripcion: "Represents a merchant with multiple locations. Underwriting is done with a single tax ID. Processing is done with multiple MIDs (one MID per descriptor). Money is deposited in a separate bank account for each merchant account. Recurring fees are charged per each merchant account"
                                },
                                {
                                    tipo: "MSSMM",
                                    descripcion: "Represents a merchant with multiple locations. Underwriting is done with a single tax ID. Processing is done with a same MID. Money is deposited in a separate bank account for each merchant account. Recurring fees are charged only once"
                                },
                                {
                                    tipo: "MSSMA",
                                    descripcion: "Represents a merchant with multiple locations. Underwriting is done with a single tax ID. Processing is done with a same MID. Money is deposited in a separate bank account for each merchant account. Recurring fees are charged per each merchant account"
                                },
                                {
                                    tipo: "MSMSM",
                                    descripcion: "Represents a merchant with multiple locations. Underwriting is done with a single tax ID. Processing is done with multiple MIDs (one MID per descriptor). Money is deposited in a same single bank account for all locations. Recurring fees are charged only once"
                                }
                            ]
                        },
                        {
                            titulo: "Merchant Type",
                            datos: [
                                {
                                    tipo: "M",
                                    descripcion: "Merchant"
                                },
                                {
                                    tipo: "A",
                                    descripcion: "Affiliate"
                                }
                            ]
                        },
                        {
                            titulo: "Notification Policy Type",
                            datos: [
                                {
                                    tipo: "A",
                                    descripcion: "Indicates that all notifications are enabled"
                                },
                                {
                                    tipo: "X",
                                    descripcion: "Indicates that all notifications are disabled"
                                }
                            ]
                        },
                        {
                            titulo: "Onboarding Response Codes",
                            datos: [
                                {
                                    tipo: "A",
                                    descripcion: "Approved"
                                },
                                {
                                    tipo: "D",
                                    descripcion: "Declined"
                                },{
                                    tipo: "R",
                                    descripcion: "In Review"
                                },
                                {
                                    tipo: "P",
                                    descripcion: "Pending"
                                },
                                {
                                    tipo: "J",
                                    descripcion: "Rejected"
                                },
                                {
                                    tipo: "E",
                                    descripcion: "Error (available in the 7.2 release)"
                                },
                                {
                                    tipo: "C",
                                    descripcion: "Needs Correction (available in the 7.2 release)"
                                },
                                {
                                    tipo: "W",
                                    descripcion: "Approved Warning (available in the 7.2 release)"
                                },
                                {
                                    tipo: "F",
                                    descripcion: "Failed (available in the 7.2 release)"
                                }
                            ]
                        },
                        {
                            titulo: "Ownership Structure Type",
                            datos: [
                                {
                                    tipo: "SP",
                                    descripcion: "Sole proprietorship"
                                },
                                {
                                    tipo: "LLC",
                                    descripcion: "Limited lability company"
                                },
                                {
                                    tipo: "NP",
                                    descripcion: "Nonprofit corporation"
                                },
                                {
                                    tipo: "GA",
                                    descripcion: "Government agency"
                                },
                                {
                                    tipo: "IO",
                                    descripcion: "International organization (deprecated starting from the 7.2 release)"
                                },
                                {
                                    tipo: "GP",
                                    descripcion: "Partnership (available in the 7.2 release)"
                                },
                                {
                                    tipo: "GP",
                                    descripcion: "General partnership (deprecated starting from the 7.1 release)"
                                },
                                {
                                    tipo: "LP",
                                    descripcion: "Limited partnership (deprecated starting from the 7.1 release)"
                                },
                                {
                                    tipo: "LLP",
                                    descripcion: "Limited liability partnership (deprecated starting from the 7.2 release)"
                                },
                                {
                                    tipo: "C",
                                    descripcion: "Corporation (deprecated starting from the 7.2 release)"
                                },
                                {
                                    tipo: "C",
                                    descripcion: "Public corporation (available in the 7.2 release)"
                                },
                                {
                                    tipo: "CSP",
                                    descripcion: "Charitable solicitations program (deprecated starting from the 7.2 release)"
                                },
                                {
                                    tipo: "CTP",
                                    descripcion: "Charitable trust program (deprecated starting from the 7.2 release)"
                                },
                                {
                                    tipo: "PC",
                                    descripcion: "Private corporation (available in the 7.2 release)"
                                },
                                {
                                    tipo: "AT",
                                    descripcion: "Association/estate/trust (deprecated starting from the 7.2 release)"
                                }
                            ]
                        },
                        {
                            titulo: "Return URL Policy Type",
                            datos: [
                                {
                                    tipo: "page",
                                    descripcion: "Indicates that when onboarding process is complete (when the Continue button on the confirmation result page is pressed), a user is redirected to the URL indicated in the returnURL field value"
                                },
                                {
                                    tipo: "redirect",
                                    descripcion: "Indicates that when onboarding process is complete, a user is automatically redirected to the link indicated in the returnURL field value (bypassing a confirmation page)"
                                }
                            ]
                        },
                        {
                            titulo: "Terminal Status",
                            datos: [
                                {
                                    tipo: "A",
                                    descripcion: "Active"
                                },
                                {
                                    tipo: "I",
                                    descripcion: "Inactive"
                                }
                            ]
                        },
                        {
                            titulo: "Transaction Industry Type",
                            datos: [
                                {
                                    tipo: "RX",
                                    descripcion: "Retail Card Not Present"
                                },
                                {
                                    tipo: "RE",
                                    descripcion: "Retail"
                                },
                                {
                                    tipo: "RP",
                                    descripcion: "Recurring"
                                },
                                {
                                    tipo: "RS",
                                    descripcion: "Restaurant"
                                },
                                {
                                    tipo: "IP",
                                    descripcion: "Installment"
                                },
                                {
                                    tipo: "EC",
                                    descripcion: "Ecommerce"
                                },
                                {
                                    tipo: "DM",
                                    descripcion: "Direct Marketing"
                                },
                                {
                                    tipo: "RN",
                                    descripcion: "Restaurant Card Not Present"
                                },
                                {
                                    tipo: "PT",
                                    descripcion: "Petroleum"
                                },
                                {
                                    tipo: "LD",
                                    descripcion: "Lodging"
                                },
                                {
                                    tipo: "CCD",
                                    descripcion: "Corporate Credit Or Debit"
                                },
                                {
                                    tipo: "PPD",
                                    descripcion: "Prearranged Payment And Deposit Entry"
                                },
                                {
                                    tipo: "POP",
                                    descripcion: "Point Of Purchase Entry"
                                },
                                {
                                    tipo: "TEL",
                                    descripcion: "Telephone Initiated Entry"
                                },
                                {
                                    tipo: "WEB",
                                    descripcion: "Internet Initiated Entry"
                                },
                                {
                                    tipo: "C21",
                                    descripcion: "Check21"
                                },
                                {
                                    tipo: "CR",
                                    descripcion: "Car Rental"
                                }
                            ]
                        },
                        {
                            titulo: "Update Terminal",
                            datos: [
                                {
                                    tipo: "update-terminal",
                                    descripcion: "Type of the request/response"
                                }
                            ]
                        }
                        
                    ]
                }
            ]
        }



        if (ban == "reporting") {/////
            tituloge = "Reporting APIs <br/>";
            datos = [
                {
                    titulo: "Type Enumerations",
                    data: [
                        { nombre: "Account Type" },
                        { nombre: "Payment Arrangement Type" },
                        { nombre: "Terminal Operation Type" },

                        { nombre: "Account Type Group" },
                        { nombre: "Report Basis Type" },
                        { nombre: "Terminal Order Type" },

                        { nombre: "Communication Protocol Type" },
                        { nombre: "Source Type" },
                        { nombre: "Terminal Status" },

                        { nombre: "Deposit Type" },
                        { nombre: "Split Type" },
                        { nombre: "Terminal Type" },

                        { nombre: "Flat Fees" },
                        { nombre: "Statement Basis Type" },
                        { nombre: "Terminal Update Mode" },

                        { nombre: "Merchant Statement Type" },
                        { nombre: "Statement Status" },
                        { nombre: "Transaction Industry Type" },

                        { nombre: "Mode" },
                        { nombre: "Submission Type" },
                        { nombre: "Transaction Status" },

                        { nombre: "Mode" },
                        { nombre: "Terminal Level" },
                        { nombre: "Transaction Type" }
                    ],
                    tablas: []
                },
                {
                    titulo: "Data Export Stereotypes",
                    data: [
                       
                    ],
                    tablas: []
                },
                {
                    titulo: "01-Submissions",
                    data: [
                        { nombre: "Sale Submissions" },
                        { nombre: "Credit Declines" },
                        { nombre: "Voided Credits" },

                        { nombre: "Credit Submissions" },
                        { nombre: "Errors" },
                        { nombre: "Blacklisted Sales" },

                        { nombre: "Sale Declines" },
                        { nombre: "Voided Sales" },
                        { nombre: "Blacklisted Credits" }
                    ],
                    tablas: []
                },
                {
                    titulo: "02-Fees",
                    data: [
                        { nombre: "Processing Fees (Sale)" },
                        { nombre: "Annual Service Fees" },
                        { nombre: "Convenience Fee" },

                        { nombre: "Processing Fees (Credit)" },
                        { nombre: "Monthly Service Fees" },
                        { nombre: "Processing Costs" },

                        { nombre: "One Time Servise Fees" }
                    ],
                    tablas: []
                },
                {
                    titulo: "03-Splits",
                    data: [
                        { nombre: "Splits and Pulls" }
                    ],
                    tablas: []
                },
                {
                    titulo: "04-Adjustments",
                    data: [
                        { nombre: "Statement Adjustments (Balance and Fees)" },
                        { nombre: "Statement Adjustments (Taxes)" }
                    ],
                    tablas: []
                },
                {
                    titulo: "05-Miscellaneous",
                    data: [
                        { nombre: "Sale Aggregates" },
                        { nombre: "Deaggregates" },
                        { nombre: "Resubmitted Sales" },

                        { nombre: "Credit Aggregates" },
                        { nombre: "Net Sales" },
                        { nombre: "Resubmitted Credits" },

                        { nombre: "Chargebacks and Returns" },
                        { nombre: "Net Credits" },
                        { nombre: "Remittance" }
                    ],
                    tablas: []
                }
            ]
        }


        if (ban == "tmsapis") {/////
            tituloge = "TMS APIs <br/> Type Enumerations";
            datos = [
                {
                    titulo: "Type Enumerations",
                    data: [
                        { nombre: "Account Type" },
                        { nombre: "Format Type" },
                        { nombre: "Security" },
                        { nombre: "Authenticate Terminal" },
                        { nombre: "Initialize Terminal" },
                        { nombre: "Status" },
                        { nombre: "Communication Protocol Type" },
                        { nombre: "IP Address Type" },
                        { nombre: "Task Type" },

                        { nombre: "Configuration Type" },
                        { nombre: "Key Type" },
                        { nombre: "Terminal Device Status Type" },
                        { nombre: "Content Type" },
                        { nombre: "Merchant Info" },
                        { nombre: "Terminal Mode" },

                        { nombre: "Default Industry Type" },
                        { nombre: "Offline Transaction Mode" },
                        { nombre: "Terminal Model" },
                        { nombre: "Encryption mode" },
                        { nombre: "Operation Type" },
                        { nombre: "Theme Type" },

                        { nombre: "Encryption Type" },
                        { nombre: "Receipt Policy" },
                        { nombre: "Time Zone Type" },

                        { nombre: "Entry Mode Policy" },
                        { nombre: "Receipt Printing Order Type" },
                        { nombre: "Upload Log Type" },

                        { nombre: "Format" },
                        { nombre: "Resource Type" }

                    ],
                    tablas: []
                }
            ]
        }


        var json = {
            tituloge: tituloge,
            datos: datos
        }

        resp = json;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;

}


