import React from 'react'
import { LuArrowRightCircle } from 'react-icons/lu';
import Navbar from '../../Components/NavBar/Navbar';
import Footer from '../../Components/Footer/Footer';
import './Billing.css'
import parse from 'html-react-parser';

import Objects from './Objects/Objects';
import TypeEnumerations from './TypeEnumeration/TypeEnumerations';
import IntegrationNotes from './IntegrationNotes/IntegrationNotes';

import { Redirect} from 'react-router-dom';

export default class Billing extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bandera: props.bandera,
            contenido: [],
            titulo: "Billing APIs",
            tabactivo: 1,
            opcioncontenido: ""
        }
        this.cambiarOpcionContenido = this.cambiarOpcionContenido.bind(this);
    }


    async componentDidMount() {

       
    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }

    cambiarOptionTab(option){
        this.setState({ tabactivo: option})
    }

    cambiarOpcionContenido(option){
        this.setState({ opcioncontenido: option})
    }


    renderizarOptions(){
       switch (this.state.tabactivo) {
        case 1:
            return(
                <div className="row">
                    <div className="col-4">
                        <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                            <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("Allocation")} ><LuArrowRightCircle className='icoflecha' /><a >Allocation</a></a> </h3>
                        </div>
                    </div>
                    <div className="col-4">
                        <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                            <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("Plan")}><LuArrowRightCircle className='icoflecha' /><a >Plan</a></a> </h3>
                        </div>
                    </div>
                    <div className="col-4">
                        <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                            <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("Subscription")}><LuArrowRightCircle className='icoflecha' /><a >Subscription</a></a> </h3>
                        </div>
                    </div>
                    <div className="col-4">
                        <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                            <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("Customer")}><LuArrowRightCircle className='icoflecha' /><a >Customer</a></a> </h3>
                        </div>
                    </div>
                    <div className="col-4">
                        <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                            <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("ProcessingInfo")}><LuArrowRightCircle className='icoflecha' /><a >ProcessingInfo</a></a> </h3>
                        </div>
                    </div>
                    <div className="col-4">
                        <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                            <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("SubscriptionAdjustment")}><LuArrowRightCircle className='icoflecha' /><a >SubscriptionAdjustment</a></a> </h3>
                        </div>
                    </div>
                    <div className="col-4">
                        <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                            <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("PaymentOption")}><LuArrowRightCircle className='icoflecha' /><a >PaymentOption</a></a> </h3>
                        </div>
                    </div>
                    <div className="col-4">
                        <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                            <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("ProcessingRequest")}><LuArrowRightCircle className='icoflecha' /><a >ProcessingRequest</a></a> </h3>
                        </div>
                    </div>
                    <div className="col-4">
                        <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                            <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("Transaction")}><LuArrowRightCircle className='icoflecha' /><a >Transaction</a></a> </h3>
                        </div>
                    </div>


                </div>
                


            )

            case 2:
                return(
                    <div className="row">
                        <div className="col-3">
                            <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("AccountActivityEnum")}><LuArrowRightCircle className='icoflecha' /><a >Account Activity Enum</a></a> </h3>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("AssetTransactionEnum")}><LuArrowRightCircle className='icoflecha' /><a >Asset Transaction Enum</a></a> </h3>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("PaymentOptionEnum")}><LuArrowRightCircle className='icoflecha' /><a >Payment Option Enum</a></a> </h3>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("SubscriptionAdjustmentEnum")}><LuArrowRightCircle className='icoflecha' /><a >Subscription Adjustment Enum</a></a> </h3>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("AdjustmentEnum")}><LuArrowRightCircle className='icoflecha' /><a >Adjustment Enum</a></a> </h3>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("BillingCycleEnum")}><LuArrowRightCircle className='icoflecha' /><a >Billing Cycle Enum</a></a> </h3>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                <h3 className='lblencabezados'><a  onClick={() => this.cambiarOpcionContenido("PaymentPlanEnum")}><LuArrowRightCircle className='icoflecha' /><a >Payment Plan Enum</a></a> </h3>
                            </div>
                        </div>
                        
                    </div>
    
                )

                case 3:
                return(
                    <div className="row">
                        <div class="col-xs-12 col-sm-12">
                                    <h1 style={{display: "inline-block"}} > Billing API Index (Actions) </h1>
                                    
                        </div>

                        <div class="row action-content" style={{display: "block"}}>
                            <div class="col-xs-12 col-sm-12">
                                                                    <div class="table-responsive">
                                        <table class="table my-table">
                                            <colgroup></colgroup>
                                            <colgroup></colgroup>
                                            <colgroup></colgroup>
                                            <thead>
                                            <tr>
                                                <th>URL</th>
                                                <th>Action</th>
                                                <th>Method</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}}  colspan="3">&nbsp;[version]/[customers]&nbsp;&nbsp;<a href="/billing-api/v01/actions/312">view</a></td></tr>                                                <tr>
                                                    <td>[version]/[customers]</td>
                                                    <td><a href="/billing-api/v01/actions/312#actcreate">~create</a></td>
                                                    <td>PUT</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/[customers]</td>
                                                    <td><a href="/billing-api/v01/actions/312#actfind">~find</a></td>
                                                    <td>GET</td>
                                                </tr>
                                            <tr><td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}} colspan="3">&nbsp;[version]/customers/[customer-id]&nbsp;&nbsp;<a href="/billing-api/v01/actions/313">view</a></td></tr>                                                <tr>
                                                    <td>[version]/customers/[customer-id]</td>
                                                    <td><a href="/billing-api/v01/actions/313#actload">~load</a></td>
                                                    <td>GET</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]</td>
                                                    <td><a href="/billing-api/v01/actions/313#actmodify">~modify</a></td>
                                                    <td>POST</td>
                                                </tr>
                                            <tr><td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}} colspan="3">&nbsp;[version]/customers/[customer-id]/[payment-options]&nbsp;&nbsp;<a href="/billing-api/v01/actions/314">view</a></td></tr>                                                <tr>
                                                    <td>[version]/customers/[customer-id]/[payment-options]</td>
                                                    <td><a href="/billing-api/v01/actions/314#actcreate">~create</a></td>
                                                    <td>PUT</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[payment-options]</td>
                                                    <td><a href="/billing-api/v01/actions/314#actfind">~find</a></td>
                                                    <td>GET</td>
                                                </tr>
                                            <tr><td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}} colspan="3">&nbsp;[version]/customers/[customer-id]/[payment-options]/[payment-option-id]&nbsp;&nbsp;<a href="/billing-api/v01/actions/315">view</a></td></tr>                                                <tr>
                                                    <td>[version]/customers/[customer-id]/[payment-options]/[payment-option-id]</td>
                                                    <td><a href="/billing-api/v01/actions/315#actload">~load</a></td>
                                                    <td>GET</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[payment-options]/[payment-option-id]</td>
                                                    <td><a href="/billing-api/v01/actions/315#actmodify">~modify</a></td>
                                                    <td>POST</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[payment-options]/[payment-option-id]</td>
                                                    <td><a href="/billing-api/v01/actions/315#actprocess">~process</a></td>
                                                    <td>POST</td>
                                                </tr>
                                            <tr><td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}} colspan="3">&nbsp;[version]/customers/[customer-id]/[subscriptions]&nbsp;&nbsp;<a href="/billing-api/v01/actions/320">view</a></td></tr>                                                <tr>
                                                    <td>[version]/customers/[customer-id]/[subscriptions]</td>
                                                    <td><a href="/billing-api/v01/actions/320#actcreate">~create</a></td>
                                                    <td>PUT</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[subscriptions]</td>
                                                    <td><a href="/billing-api/v01/actions/320#actfind">~find</a></td>
                                                    <td>GET</td>
                                                </tr>
                                            <tr><td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}} colspan="3">&nbsp;[version]/customers/[customer-id]/[subscriptions]/[subscription-id]&nbsp;&nbsp;<a href="/billing-api/v01/actions/321">view</a></td></tr>                                                <tr>
                                                    <td>[version]/customers/[customer-id]/[subscriptions]/[subscription-id]</td>
                                                    <td><a href="/billing-api/v01/actions/321#actcancel">~cancel</a></td>
                                                    <td>GET</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[subscriptions]/[subscription-id]</td>
                                                    <td><a href="/billing-api/v01/actions/321#actfreeze">~freeze</a></td>
                                                    <td>GET</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[subscriptions]/[subscription-id]</td>
                                                    <td><a href="/billing-api/v01/actions/321#actload">~load</a></td>
                                                    <td>GET</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[subscriptions]/[subscription-id]</td>
                                                    <td><a href="/billing-api/v01/actions/321#actmodify">~modify</a></td>
                                                    <td>POST</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[subscriptions]/[subscription-id]</td>
                                                    <td><a href="/billing-api/v01/actions/321#actpause">~pause</a></td>
                                                    <td>GET</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[subscriptions]/[subscription-id]</td>
                                                    <td><a href="/billing-api/v01/actions/321#actuncancel">~uncancel</a></td>
                                                    <td>GET</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[subscriptions]/[subscription-id]</td>
                                                    <td><a href="/billing-api/v01/actions/321#actunfreeze">~unfreeze</a></td>
                                                    <td>GET</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[subscriptions]/[subscription-id]</td>
                                                    <td><a href="/billing-api/v01/actions/321#actunpause">~unpause</a></td>
                                                    <td>GET</td>
                                                </tr>
                                            <tr><td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}} colspan="3">&nbsp;[version]/customers/[customer-id]/[subscriptions]/[subscription-id]/[adjustments]&nbsp;&nbsp;<a href="/billing-api/v01/actions/322">view</a></td></tr>                                                <tr>
                                                    <td>[version]/customers/[customer-id]/[subscriptions]/[subscription-id]/[adjustments]</td>
                                                    <td><a href="/billing-api/v01/actions/322#actfind">~find</a></td>
                                                    <td>GET</td>
                                                </tr>
                                            <tr><td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}} colspan="3">&nbsp;[version]/customers/[customer-id]/[transactions]&nbsp;&nbsp;<a href="/billing-api/v01/actions/318">view</a></td></tr>                                                <tr>
                                                    <td>[version]/customers/[customer-id]/[transactions]</td>
                                                    <td><a href="/billing-api/v01/actions/318#actcreate">~create</a></td>
                                                    <td>PUT</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[transactions]</td>
                                                    <td><a href="/billing-api/v01/actions/318#actfind">~find</a></td>
                                                    <td>GET</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/[transactions]</td>
                                                    <td><a href="/billing-api/v01/actions/318#actprocess">~process</a></td>
                                                    <td>POST</td>
                                                </tr>
                                            <tr><td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}} colspan="3">&nbsp;[version]/customers/[customer-id]/transactions/[transaction-id]&nbsp;&nbsp;<a href="/billing-api/v01/actions/319">view</a></td></tr>                                                <tr>
                                                    <td>[version]/customers/[customer-id]/transactions/[transaction-id]</td>
                                                    <td><a href="/billing-api/v01/actions/319#actload">~load</a></td>
                                                    <td>GET</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/customers/[customer-id]/transactions/[transaction-id]</td>
                                                    <td><a href="/billing-api/v01/actions/319#actreverse">~reverse</a></td>
                                                    <td>GET</td>
                                                </tr>
                                            <tr><td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}} colspan="3">&nbsp;[version]/customers/[customer-id]/transactions/[transaction-id]/[allocations]&nbsp;&nbsp;<a href="/billing-api/v01/actions/353">view</a></td></tr>                                                <tr>
                                                    <td>[version]/customers/[customer-id]/transactions/[transaction-id]/[allocations]</td>
                                                    <td><a href="/billing-api/v01/actions/353#actfind">~find</a></td>
                                                    <td>GET</td>
                                                </tr>
                                            <tr><td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}} colspan="3">&nbsp;[version]/plans&nbsp;&nbsp;<a href="/billing-api/v01/actions/316">view</a></td></tr>                                                <tr>
                                                    <td>[version]/plans</td>
                                                    <td><a href="/billing-api/v01/actions/316#actcreate">~create</a></td>
                                                    <td>PUT</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/plans</td>
                                                    <td><a href="/billing-api/v01/actions/316#actfind">~find</a></td>
                                                    <td>GET</td>
                                                </tr>
                                            <tr><td style={{padding: "0 0 0 0", height: "1px", backgroundColor: "#EBECE4", fontWeight: "bold"}} colspan="3">&nbsp;[version]/plans/[plan-id]&nbsp;&nbsp;<a href="/billing-api/v01/actions/317">view</a></td></tr>                                                <tr>
                                                    <td>[version]/plans/[plan-id]</td>
                                                    <td><a href="/billing-api/v01/actions/317#actload">~load</a></td>
                                                    <td>GET</td>
                                                </tr>
                                                                                            <tr>
                                                    <td>[version]/plans/[plan-id]</td>
                                                    <td><a href="/billing-api/v01/actions/317#actmodify">~modify</a></td>
                                                    <td>POST</td>
                                                </tr>
                                                                                        </tbody>
                                        </table>
                                    </div>
                                                            </div>
                        </div>
                        
                    </div>
    
                )

                case 4:
                    return(
                        <div className="row">
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Connectivity Information</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Subscription Management</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Billing API response</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >How to use Billing API</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Subscription Types and Statuses</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Testing</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Processing Priority</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Transaction Types</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Reference Entity</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Transaction-related Actions</a></a> </h3>
                                </div>
                            </div>
                            
                        </div>
        
                    )

                case 5:
                    return(
                        <div className="row">
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Create standard monthly and annual pricing Plans for service Subscriptions</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Freeze payments in an existing Subscription</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Reverse a previous sale</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Add a New Customer with a fixed term Subscription for 12 payments, using a credit card</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Cancel an entire Subscription</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Add a New Customer with a perpetual term Subscription, billed weekly, using ACH</a></a> </h3>
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ textAlign: "left", paddingLeft: "5px", paddingTop: "5px", paddingBottom: "5px" }}>
                                    <h3 className='lblencabezados'><a ><LuArrowRightCircle className='icoflecha' /><a >Sell physical goods to an existing Customer via a Credit Card</a></a> </h3>
                                </div>
                            </div>
                           
                            
                        </div>
        
                    )
       
        default:
            return null;
       }
    }

    
    renderizarContenidos(){
        switch (this.state.tabactivo) {
            case 1:
             return(
                 <Objects opcioncontenido={this.state.opcioncontenido} cambiarOpcionContenido={this.cambiarOpcionContenido}/>
             )
 
             case 2:
                 return(
                    <TypeEnumerations opcioncontenido={this.state.opcioncontenido}/>
                 )
 
                 case 3:
                 return(
                   
                    <div className="row">
                             
                    </div>
                 )
 
                 case 4:
                     return(
                        <IntegrationNotes opcioncontenido={this.state.opcioncontenido}/>
         
                     )
 
                 case 5:
                     return(
                         <div className="row">
                            
                            
                             
                         </div>
         
                     )
        
         default:
             return null;
        }
     }
 

    render() {
        return (
            <>
                <Navbar />

            <div className='anchoMaximo' style={{ paddingTop: '120px', display: 'block', margin: "auto", width: "95%" }}>
                <div class="row">
                    <div class="col-xs-12 col-sm-3">
                        <h1 style={{ fontSize: 'x-large' }}><b>{this.pasartexto(this.state.titulo)}</b></h1>
                    </div>
                </div>


                    <div class="row">
                        <div class="col-xs-12 col-sm-6">
                        
                          
                        </div>
                        <br />
                        
                        <div class="col-xs-12 col-sm-12">
                            <a name="introduction-note"></a>
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <b>
                                        Introduction                        </b>
                                </div>
                                <div class="panel-body">
                                    Billing API is a RESTful API that allows setting up customers, collecting payments and maintaining balances for these customers, as well as creating recurring billing subscriptions.
                                    <br/>

                                    <br/>
                                    Billing API documentation includes the following sections:
                                    <br/>

                                    <br/>
                                    <strong>Objects</strong> -  lists all recurring billing objects. The purpose of this section is to describe all object fields, their format and usage, provide the links to the corresponding database tables.
                                    <br/>

                                    <br/>
                                    <strong>Type Enumerations</strong> – lists possible values that can be used in the respective fields within Billing API requests and responses. The purpose of this section is to provide an explanation of all enumerations used for API calls.
                                    <br/>

                                    <br/>
                                    <strong>Actions</strong> - lists all actions that can be performed with key recurring billing objects. The purpose of this section is to describe the methods and format of API requests and the format of responses. 
                                    <br/>

                                    <br/>
                                    <strong>Integration Notes</strong> – lists integration notes associated with the Billing API objects and actions. The purpose of this section is to provide explicit information about the peculiarities of specific actions and object fields.
                                    <br/>
                                    
                                    <br/>
                                    <strong>Code samples</strong> - provides examples of recurring billing use cases (creating billing plans, subscriptions, freeing or canceling subscriptions, reversing transactions, etc) and their implementation in JSON, XML formats. The purpose of this section is to provide examples that can be used for testing of the integration with the gateway. 
                                    <br/>

                                    <br/>
                                    To learn how to work with the Billing API, we recommend reviewing the integration notes first. Key recurring billing module terms are available <a href="/terminology#billing_configurations">here</a>. 
                                    For more detailed information about the recurring billing configurations and management, check the <a href="https://unipaygateway.info/guide_content?id=6"><strong>Recurring Billing Guide</strong></a>. 
                                    <br/>

                                </div>
                            </div>
                        </div>
                       
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 mr-2">
                            <div id="main-nav-list" class="tabs">
                                <div className='row'>
                                        <div onClick={() => this.cambiarOptionTab(1)} className={this.state.tabactivo == 1 ? "tabactivo col-1 taboption" : "col-1 taboption"} >Objects</div>
                                        <div onClick={() => this.cambiarOptionTab(2)} className={this.state.tabactivo == 2 ? "tabactivo col-1 taboption" : "col-1 taboption"} >Type Enumerations</div>
                                        <div onClick={() => this.cambiarOptionTab(3)} className={this.state.tabactivo == 3 ? "tabactivo col-1 taboption" : "col-1 taboption"} > Actions</div>
                                        <div onClick={() => this.cambiarOptionTab(4)} className={this.state.tabactivo == 4 ? "tabactivo col-1 taboption" : "col-1 taboption"} > Integration Notes</div>
                                        <div onClick={() => this.cambiarOptionTab(5)} className={this.state.tabactivo == 5 ? "tabactivo col-1 taboption" : "col-1 taboption"} > Code Samples</div>
                                </div>
                            </div>
                        </div>

                        <div className='options-container'>
                            <div className="row pt-3">
                                <div className="col-12">
                                    {this.renderizarOptions()}
                                </div>
                            </div>
                        </div>

                       <div className=''>
                            <div className="row pt-3">
                                <div className="col-12">
                                    {this.renderizarContenidos()}
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

                <Footer />
            </>
        )
    }
}

