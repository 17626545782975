import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalAccountData extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Account Data</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                The field accountData contains one of the following items: 
<br/>
1) for payment cards:
<br/>
<ul type="circle" >
<li>track data (unencrypted/encrypted);
</li>
<li>EMV data (unencrypted/encrypted);
</li>
<li>manually keyed number;
</li>
</ul>
2) for Check21 transactions:
<br/>
<ul type="circle" >
<li>MICR data
</li>
</ul>

<br/>

<br/>
<h2>Track Data</h2> 
The system supports submission of track data in two ways - encrypted and unencrypted. Both are submitted within <em>accountData</em> field. 
When the <em>accountData</em> field contains <strong>unencrypted</strong> track data, it has to be encoded before it is sent to eliminate any issues with special characters. Use standard URL encoding procedure for that. 
<br/>
If track data is <strong>encrypted</strong>, generally, encoding is not required (but it may be needed if non-alpha-numeric characters are present). The system accepts track data encrypted by both external and internal encryption methods. If an internal encryption method is used, the system will decrypt track data with a key registered within the system and submit decrypted value for processing. If an external encryption method is used, the data will be passed to the processor as it was received by the gateway i.e. without modifications. To identify that submitted data is encrypted, its value has to be preceded by tilde sign (~). Otherwise, the system assumes that data is unencrypted. For example, <em>~021301000F3D0000%*4111.....</em>
<br/>

<br/>
Currently, three encryption algorithm are supported for track data encryption: 
<br/>
<ul type="circle" >
<li><em>TDES</em> - a symmetric algorithm that requires one key for both encryption and decryption. Used by ID Tech devices. 
</li>
<li><em>AES</em> - a symmetric algorithm that requires one key for both encryption and decryption. Used by ID Tech devices. 
</li>
<li><em>RSA</em> - an asymmetric algorithm that requires two different keys for encryption (public key) and decryption (private key). Used by a native terminal application.
</li>
</ul>

<br/>
Currently, the following ID Tech models are supported by the system: 
<br/>
<ul type="circle" >
<li><em>M100</em>
</li>
<li><em>M130</em>
</li>
</ul>

<br/>
The UniPay/UniBroker application receives audio track data (encrypted or decrypted) from USB card readers (audio-jack) in hexadecimal format and transforms them into ASCII format.
<br/>

<br/>

<br/>
<h2>EMV Data</h2> 
When the <em>accountData</em> field contains <strong>unencrypted</strong> EMV data, its value has to be preceded by exclamation mark (!).  For example, <em>!4F07A00.....</em>
<br/>
To identify that submitted EMV data is <strong>encrypted</strong>, its value has to be preceded by exclamation mark and tilde sign (!~ or ~!). For example, <em>!~021301000F3D0000%*4111.....</em>
<br/>

<br/>
EMV processing requires various tags to be included into receipt. These tags are also included in account data associated with a particular EMV card. Tags in transaction response come in random order, so the format can start from any available tag. Account data in transaction response includes both required and optional tags. The tag <strong>0x91</strong> is a required tag. It is always present in the transaction response. The tag <strong>0x8A</strong> allows to check the status of the transaction. It is optional and whether it is present or not in the response depends upon the processor. Full list of EMV tags is available <a href="http://www.emvlab.org/emvtags/all/">here</a>. 
<br/>

<br/>
Please see <a href="/processing-api/realtime/examples#example_realtime_Sale_Request__with_EMV_Data_">request</a> and <a href="/processing-api/realtime/examples#example_realtime_Sale_Response__with_EMV_Data_">response</a> examples of sale operation with EMV card for more details.                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

