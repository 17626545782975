import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINStoredCredentials extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Stored Credentials</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                There can be cases when a payment card is going to be used for recurring/installment transactions, which can be processed either in real-time or as batch files. Some card brands require identification of an original authorization when recurring transactions are processed. The idea of that is when a cardholder was accepting the agreement, an initial transaction was processed either as card-present or with CSC included, and subsequent recurring transactions, which are processed as card-not-present without CSC, are assumed to be a continuation of the initial or previous transaction. This is needed to manage risk and fraud, resulting in fewer chargebacks and an improved cardholder experience. 
                                <br/>

                                <br/>
                                To process a recurring/installment transaction, follow the steps below:
                                <br/>
                                <ol style={{marginLeft: "25px", color: "inherit !important"}}>
                                <li>Submit a processing request file. All transactions submitted within a processing request file are considered to be recurring/installment. 
                                </li>
                                <li>Once the response file is returned, every transaction has the <em>networkTransactionId</em> field value included. This value must be used in every transaction associated with the first one to indicate that a transaction is a continuation of a recurring sequence and follows a recurring/installment transaction that has already been processed by the system before. Note that each <em>networkTransactionId</em> value is associated with a certain sequence of recurring/installment transactions and not with an account number of a cardholder. 
                                </li>
                                <li>Submit the received <em>networkTransactionId</em> field value in the <em>originalNetworkTransactionId</em> field within the subsequent recurring/installment transactions.
                                </li>
                                <li>If you want to start a new sequence of recurring/installment transactions, you have to submit a new processing request file without the <em>originalNetworkTransactionId</em> field included in the transactions, as described in step 1.
                                </li>
                                </ol>

                                <br/>
                                In the table below you can see, which fields are required to submit in the request and which are returned in the response when using the stored credentials mechanism:
                                <br/>

                                <br/>
                                <div style={{display: "inline-block"}}>
                                <div class="table-responsive"><table class="newtable newtablenorowspan pull-left" style={{marginTop: "0px", tableLayout: "fixed"}}><tbody><tr>
                                <th style={{fontWeight: "bold", textAlign: "center"}}>Transaction Sequence
                                </th>
                                <th style={{fontWeight: "bold", textAlign: "center"}}>Network <br/> Transaction ID
                                </th>
                                <th style={{fontWeight: "bold", textAlign: "center"}}>Original Network <br/> Transaction ID
                                </th>
                                </tr><tr>
                                <td>First transaction (request)
                                </td>
                                <td> 
                                </td>
                                <td> 
                                </td>
                                </tr><tr>
                                <td>First transaction (response)
                                </td>
                                <td>x
                                </td>
                                <td>
                                </td>
                                </tr><tr>
                                <td>Subsequent transactions (request)
                                </td>
                                <td>
                                </td>
                                <td>x
                                </td>
                                </tr><tr>
                                <td>Subsequent transactions (response)
                                </td>
                                <td>x
                                </td>
                                <td>
                                </td>
                                </tr><tr>
                                </tr></tbody></table> 

                                <br/>
                                </div>

                                <br/>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

