import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalIdentityVerificationType extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Identity Verification Type</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "15%"}}></colgroup>
                                        <colgroup style={{width: "85%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>Value</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td width="100px">ssn_4</td>
                                                <td>The last four digits of a bank account holder’s social security number<br/></td>
                                            </tr>
                                                                <tr>
                                                <td width="100px">dob_y</td>
                                                <td>Birth year of a bank account holder<br/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

