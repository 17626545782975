import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalFallbackTransactions extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Fallback Transactions</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                Fallback is a mechanism, which allows to process an EMV card without using EMV technology in exceptional situations, when proper EMV processing is not possible. At the normal circumstances, an EMV card can be processed using an integrated circuit, or using contactless technology by tapping card. In situation, when none of these approaches can be used, a fallback routine is initiated. Currently, the fallback routine may be initiated because of the following reasons:
<br/>
<ul type="circle" >
<li>technical fallback - occurs when processing is not physically possible. For example, if the chip does not respond when the card is inserted. In this case, the terminal will allow to swipe this card. 
</li>
<li>no application match - the chip contains one or more applications, that are selected by the terminal to process the transaction. In order for transaction to occur, the terminal must be configured to deal with the apllications that are available on the chip. Situation might occur, when none of the applications, available on the chip, are supported by the terminal. For example, when current credit card is used on the terminal that only accepts US debit cards. In such cases, fallback will be allowed and the merchant can swipe the card if transaction is desirable.  </li>
</ul>                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

