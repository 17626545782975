import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINDynamicDescriptor extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Dynamic Descriptor</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                            An important feature that many merchants require, - is the support for a custom-defined soft descriptor. Depending on the processor, this feature can be supported as a static value, associated with an underlying merchant ID, or as a dynamic value, that can be specified for each transaction processed.
                            <br/>

                            <br/>
                            For credit cards, the descriptor is a single value length of 25 characters; for direct debit, the descriptor is comprised of two fields: Merchant Name (13) and Item Descriptor (10), the values should be separated with two colons (:), e.g:
                            <br/>

                            <br/>
                            [descriptor]::[merchantName]
                            <br/>

                            <br/>
                            When transactions are processed through PSP or an aggregator, it is common to include an identifier of the underlying payment processor or aggregator through a prefix, which is, usually, 3 or 6 characters in length. The prefix is added to the name of the merchant and is generally separated from it by an asterisk (*).
                            <br/>

                            <br/>
                            For each transaction processed through a processor, which supports dynamic descriptor feature, submitter can supply the value of the descriptor in the following format:
                            <br/>

                            <br/>
                            [item-descriptor]:[prefix]*[merchant-name]
                            <br/>

                            <br/>
                            Note that a processor can require a specific set of symbols to be present or absent in the descriptor. <em>To avoid any issues, one of such symbols, asterisk (*), is replaced with a space ( ) in batch API requests.</em> 

                            <br/>
                            Each part of the descriptor is optional; if respective value is not supplied at the transaction level (as part of the request), the default value will automatically be taken from the profile (if it is configured). When deciding on which value to supply, please make sure that maximum allowed length for each component is respected.
                            <br/>

                            <br/>
                            If the descriptor is not formatted correctly, the system is going to make the best attempt to properly truncate the values and process the transaction. A special warning will be issued and returned to the submitter (as warningCode field) explaining how descriptor should be adjusted in the future submissions.
                            <br/>

                            <br/>
                            Different payment card associations (Visa/MC/Discover) have different processing rules and timelines. While a Visa transaction is funded within 24h and cardholders may see the transactions in their online account almost instantly after an authorization happens (depending on their issuing bank), Amex takes 3 days to fund and sometimes it may take up to 3 days to see the transaction settled into the account and to have the transactions show up in online cardholder’s account. Please note that this delay depends on Amex processing rules only and can't be changed in the settings of the gateway. 
                            <br/>
                            
                            <br/>
                            Depending on the processor, dynamic descriptor mechanism can be supported at the authorization time or at the settlement time. In cases when the mechanism is supported at the settlement time, the descriptor gets supplied only after the transaction is settled. This means that a transaction will show up on cardholder's bank account with a generic descriptor (in pending authorizations section), while in a day (or 2-3 days later in case of Amex) it will be displayed with the proper descriptor.
                            <br/>
                            
                            <br/>
                            While all issuing banks will show at least the descriptor characters when the transaction is presented to the card holder, some can show more information (such as merchant name, phone, merchant state). It doesn't depend on the settings of the gateway and is specific to an online banking system of a particular issuing bank.
                            <br/>

                            <br/>
                            For all processors that support descriptor, processors configuration profile contains fields allowing to specify the default value that is used in a transaction if descriptor was not explicitly provided within the request. For those cases when you don't want to supply descriptor and you don't want any default values from the profile to be used (basically, you don't want to supply any descriptor information to the processor), you can send the value "-::-" in the descriptor field. This will indicate to the system that no descriptor information should be supplied to the processor and whatever descriptor is associated with the respective MID at the processor's end will be used.<br/>                
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

