import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalLevelIIIItemSubrecord extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Level III Item Subrecord</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "5%"}}></colgroup>
                                        <colgroup style={{width: "10%"}}></colgroup>
                                        <colgroup style={{width: "10%"}}></colgroup>
                                        <colgroup style={{width: "20%"}}></colgroup>
                                        <colgroup style={{width: "5%"}}></colgroup>
                                        <colgroup style={{width: "50%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>№</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Possible values / Format</th>
                                                <th>Usage</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                                                <tr>
                                <td>1</td>
                                <td>code</td>
                                                                    <td>String(12)</td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Unique identifier of an item within a submitter's system or inventory catalog.&nbsp;                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>2</td>
                                <td>commodityCode</td>
                                                                    <td>String(12)</td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Standardized identifier of an item. Usually acquirer designated.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>3</td>
                                <td>itemNumber</td>
                                                                    <td>String(20)</td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="O">O</span></td>
                                <td>Sequential number of an item in the transaction.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>4</td>
                                <td>quantity</td>
                                                                    <td>Float</td>
                                                                <td>up to 4 decimal places</td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Number of item units purchased/refunded as a part of the transaction.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>5</td>
                                <td>totalAmount</td>
                                                                    <td>Integer</td>
                                                                <td>amount in cents</td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Total amount of an item that includes discount and tax.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>6</td>
                                <td>unitCostAmount</td>
                                                                    <td>Float</td>
                                                                <td>amount in cents; up to 4 decimal places</td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Cost of a single unit of an item.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>7</td>
                                <td>unitMeasure</td>
                                                                    <td>String(12)</td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Unit of measure used to quantify items purchased/refunded as a part of the transaction (for example, g, kg, lb, inch).                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>8</td>
                                <td>description</td>
                                                                    <td>String(35)</td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Description of an item.&nbsp;                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>9</td>
                                <td>taxRate</td>
                                                                    <td>Integer</td>
                                                                <td>implied 2 decimal places (e.g. 10% = 1000)</td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Tax rate charged on an item.&nbsp;                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>10</td>
                                <td>taxAmount</td>
                                                                    <td>Integer</td>
                                                                <td>amount in cents</td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Tax amount charged on an item.&nbsp;                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>11</td>
                                <td>taxIndicator</td>
                                                                    <td >
                                        <span data="36" type="enum" class="popup-link">Enum</span>
                                    </td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Defines how tax is handled for an item.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>12</td>
                                <td>taxCode</td>
                                                                    <td>String(4)</td>
                                                                <td></td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Acquirer designated identifier of a tax charged on an item.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>13</td>
                                <td>discountRate</td>
                                                                    <td>Integer</td>
                                                                <td>implied 2 decimal places (e.g. 150 = 1.5%; 1000 = 10%)</td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Discount rate applied to an item.&nbsp;                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>14</td>
                                <td>discountAmount</td>
                                                                    <td>Integer</td>
                                                                <td>amount in cents</td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Discount amount applied to an item.                                                                    </td>
                            </tr>
                                                                                <tr>
                                <td>15</td>
                                <td>isCredit</td>
                                                                    <td>Boolean</td>
                                                                <td>1 - item is returned, 0 - item is purchased; default - 0</td>
                                <td><span class="popup-usage" type="usage" page="components" data="R3">R3</span></td>
                                <td>Indicates whether an item is purchased or returned.&nbsp;                                                                    </td>
                            </tr>
                                                </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

