import React from 'react'
import './Footer.css';


const Footer = () => {
    return (
        <div class="footer copyright-GatewayAid">
            <div class="copyright-GatewayAid-margin ">
                <small class="small">
                    <span>Copyright ©
                        <script language="javascript" type="text/javascript">
                            var d = new Date();
                            document.write(d.getFullYear());
                        </script>2023
                        United Thinkers.
                        (<a href="http://www.Transxationsgateway.com" target="_blank" title="Transxations - Payment Gateway Software">Transxations - Payment Gateway Software</a>)
                    </span>
                </small>
                <div class="copyright-GatewayAid">
                    <small class="small"><span>All Logos and Trademarks used or mentioned on this page are copyrighted property of their respective owners and are used for display purposes only.</span></small>
                </div>
            </div>
        </div>
    )
}

export default Footer