import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINPartialSettlement extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Partial Settlement</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                                When supported by underlying processor, it is possible to perform a partial capture/settlement on a transaction. This functionality may be useful when original authorization was for an amount greater than the actual amount of the transaction (for example, customer placed an order, but some items were discovered as being out of stock at a later time). To accomplish this, it is sufficient to indicate desired settlement amount in the amount field of capture operation request.<br/>               
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

