import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalReturnURLPolicyType extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }



    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Return URL Policy Type</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>
                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "15%"}}></colgroup>
                                        <colgroup style={{width: "85%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>Value</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                            <td width="100px">page</td>
                            <td>Indicates that when a transaction is completed, a result (confirmation) page with the response is rendered, regardless of the transaction response (approval or decline). On the result (confirmation) page there is a Continue button to automatically redirect the user to the link indicated in returnURL (if it is included in the request).<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">page-approval</td>
                            <td>Indicates that when a transaction is completed, a result (confirmation) page with the response is rendered only if the transaction has been approved. The Continue button (to automatically redirect the user to the link indicated in returnURL) is present only if the transaction has been approved. If the transaction has been declined, response message is rendered on the main page (HPP session invalidated, no further action is allowed).<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">redirect</td>
                            <td>Indicates that when a transaction is completed, a user is automatically redirected to the link indicated in returnURL (bypassing a confirmation page) regardless of the transaction response (approval or decline).<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">redirect-approval</td>
                            <td>Indicates that when a transaction is completed and approved by the processor, a user is automatically redirected to the link indicated in returnURL (bypassing a confirmation page). If the transaction has been declined, the user is forwarded to the result page (HPP session closed, no further action allowed).<br/></td>
                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

