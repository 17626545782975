import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalINRecordID extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            Integration Notes
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Record ID</h2>

                        <div class="panel panel-default">
                            <div class="panel-body">
                            To identify an object within the system, the gateway assigns its own unique identifier when the object is created. In the context of transaction processing, identifiers of previously created objects, such as merchant (<em>accountId, merchantId</em>) and a terminal (<em>terminalId</em>), are submitted as a part of an API request. Identifiers of objects created as a result of the transaction processing, such as a transaction itself (<em>transactionId</em>), are returned within an API response. 
 
                            <br/>
                            When a <em>transactionId</em> is returned, depending on the context, values of these fields can be preceded by the prefixes listed in the table below:

                            <br/>
                            <div style={{display: "inline-block"}}><br/>
                            <div class="table-responsive"><table class="newtable pull-left" style={{marginTop: "0px"}}><tbody><tr>
                            <th style={{fontWeight: "bold"}}>Field
                            </th>
                            <th style={{fontWeight: "bold"}}>Prefix
                            </th>
                            <th style={{fontWeight: "bold"}}>Description
                            </th>
                            <th style={{fontWeight: "bold"}}>Example
                            </th>
                            </tr><tr>
                            <td>transactionId
                            </td>
                            <td>without prefix
                            </td>
                            <td>Reference to a sale, sale-auth or credit transaction.
                            </td>
                            <td>transactionId=123456
                            </td>
                            </tr><tr>
                            <td>transactionId
                            </td>
                            <td>with @ prefix
                            </td>
                            <td>Reference to an original sale-auth transaction that was captured. Returned only within a <a href="/processing-api/batch/operations#component_Capture_File_Format_Response">capture response file</a> in the following format: @transactionId=[<em>transactionId of the original sale-auth transaction</em>].
                            </td>
                            <td>@transactionId=123456
                            </td>
                            </tr></tbody></table>
                            </div><br/>                
                            </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

