import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalConTablas extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,

            tablaDatos: [
                {
                    titulo: "Account Type",
                    id: "ProcessignAccountTypeEnum",
                    headers:["Value", "Description"],
                    rows: [
                        {
                            value: "R",
                            descripcion: "Payment card (debit or credit)"
                        },
                        {
                            value: "C",
                            descripcion: "Checking"
                        },
                        {
                            value: "S",
                            descripcion: "Savings"
                        }
                    ]
                },
                {
                    titulo: "Subscription  Account Type",
                    id: "SubscriptionAccountTypeEnum",
                    headers:["Value", "Description"],
                    rows: [
                        {
                            value: "S",
                            descripcion: "Savings"
                        },
                        {
                            value: "C",
                            descripcion: "Checking"
                        }
                    ]
                },
                {
                    titulo: "Charge Type",
                    id: "modalChargeType",
                    headers:["Value", "Description"],
                    rows: [
                        {
                            value: "FF",
                            descripcion: "Franchising fee"
                        },
                    ]
                },
                {
                    titulo: "Holder Type",
                    id: "ProcessignHolderTypeEnum",
                    headers:["Value", "Description"],
                    rows: [
                        {
                            value: "P",
                            descripcion: "Person"
                        },
                        {
                            value: "O",
                            descripcion: "Organization (Corporation)"
                        },
                    ]
                },
                {
                    titulo: "Transaction Type",
                    id: "TransactionInformationTransactionTypeEnum",
                    headers:["Value", "Description"],
                    rows: [
                        {
                            value: "S",
                            descripcion: "Sale (operation used to withdraw specific amount of money from the supplied credit card or bank account)"
                        },
                        {
                            value: "C",
                            descripcion: "Credit (operation used to deposit (return) specific amount of money to the supplied credit card or bank account)"
                        },
                        {
                            value: "D",
                            descripcion: "Sale decline (sale transaction that got declined)"
                        },
                        {
                            value: "X",
                            descripcion: "Credit decline (credit transaction that got declined)"
                        },
                        {
                            value: "E",
                            descripcion: "Error (transaction that did not go through an internal validation of the gateway and was not sent for further processing)"
                        },
                        {
                            value: "B",
                            descripcion: "Blacklist (transaction, which has not been processed, as the account is in the blacklist due to the previous hard decline)"
                        },
                        {
                            value: "N",
                            descripcion: "Return (represents direct debit payment that is not honored by a bank)"
                        },
                        {
                            value: "G",
                            descripcion: "Chargeback (operation of reversal of a prior outbound transfer of funds from a customer's bank account or credit card)"
                        },
                        {
                            value: "R",
                            descripcion: "Reversal (operation where credit card chargeback is reversed - money is given back to the merchant or customer)"
                        },
                        {
                            value: "U",
                            descripcion: "Notice of change (represents direct debit transaction that is returned by the customer's bank as a notification that some details of the transaction were corrected)"
                        },
                        {
                            value: "J",
                            descripcion: "Reject (direct debit payment that is rejected by the bank due to insufficient funds or errors in payment order)"
                        },
                    ]
                },
                {
                    titulo: "Transaction Industry Type",
                    id: "TransactionInformationTransactionIndustryTypeEnum",
                    headers:["Value", "Description"],
                    rows: [
                        {
                            value: "DM",
                            descripcion: "Direct marketing"
                        },
                        {
                            value: "EC",
                            descripcion: "Ecommerce"
                        },
                        {
                            value: "PT",
                            descripcion: "Petroleum"
                        },
                        {
                            value: "RE",
                            descripcion: "Retail"
                        },
                        {
                            value: "RS",
                            descripcion: "Restaurant"
                        },
                        {
                            value: "LD",
                            descripcion: "Lodging"
                        },
                        {
                            value: "CCD",
                            descripcion: "Corporate credit or debit (direct debit only)"
                        },
                        {
                            value: "PPD",
                            descripcion: "Prearranged payment and deposit entry (direct debit only)"
                        },
                        {
                            value: "POP",
                            descripcion: "Point purchase entry (direct debit only)"
                        },
                        {
                            value: "TEL",
                            descripcion: "Telephone initiated entry (direct debit only)"
                        },
                        {
                            value: "WEB",
                            descripcion: "Internet initiated entry (direct debit only)"
                        },
                        {
                            value: "C21",
                            descripcion: "Check 21 (direct debit only)"
                        },
                    ]
                },
                {
                    titulo: "Message Formats",
                    id: "Message Formats",
                    headers:["Type", "Description"],
                    rows: [
                        {
                            value: "C",
                            descripcion: "conditional; conditions of the usage are defined below the corresponding section."
                        },
                        {
                            value: "E",
                            descripcion: "echo back from request; if present in request, it is present in response, if it is not present in request, it is not present in response."
                        },
                        {
                            value: "I",
                            descripcion: "for internal use only."
                        },
                        {
                            value: "O",
                            descripcion: "optional in request/not always present in response."
                        },
                        {
                            value: "R",
                            descripcion: "required in request/always present in response for direct debit transactions and credit card transactions of all levels (I, II, III)."
                        },
                        {
                            value: "R2",
                            descripcion: "required in request/always present in response for credit card transactions of level II and III only; optional for direct debit and level I credit card transactions."
                        },
                        {
                            value: "R3",
                            descripcion: "required in request/always present in response for credit card transactions of III only; optional for direct debit and level I, II credit card transactions."
                        },
                        {
                            value: "SR",
                            descripcion: "required in request/always present in response for split transactions only."
                        },
                    ]
                },
                {
                    titulo: "Message Formats",
                    id: "MessageFormats2",
                    headers:["Type", "Description"],
                    rows: [
                        {
                            value: "*",
                            descripcion: "required fields in these specific sections are only required if this specific feature is used."
                        },
                        {
                            value: "C",
                            descripcion: "conditional; conditions of the usage are defined below the corresponding section."
                        },
                        {
                            value: "E",
                            descripcion: "echo back from request; if present in request, it is present in response, if it is not present in request, it is not present in response."
                        },
                        {
                            value: "I",
                            descripcion: "for internal use only."
                        },
                        {
                            value: "N",
                            descripcion: "not used."
                        },
                        {
                            value: "O",
                            descripcion: "optional in request/not always present in response."
                        },
                        {
                            value: "R",
                            descripcion: "required in request/always present in response for direct debit transactions and credit card transactions of all levels (I, II, III)."
                        },
                        {
                            value: "R2",
                            descripcion: "required in request/always present in response for credit card transactions of level II and III only; optional for direct debit and level I credit card transactions."
                        },
                        {
                            value: "R3",
                            descripcion: "required in request/always present in response for credit card transactions of III only; optional for direct debit and level I, II credit card transactions."
                        },
                        {
                            value: "SR",
                            descripcion: "required in request/always present in response for split transactions only."
                        },
                    ]
                },
                {
                    titulo: "Transaction Type",
                    id: "ProcessingResponsetransactionTypeEnum",
                    headers:["Value", "Description"],
                    rows: [
                        {
                            value: "S",
                            descripcion: "Sale (operation used to withdraw specific amount of money from the supplied credit card or bank account)"
                        },
                        {
                            value: "C",
                            descripcion: "Credit (operation used to deposit (return) specific amount of money to the supplied credit card or bank account)"
                        },
                        {
                            value: "D",
                            descripcion: "Sale decline (sale transaction that got declined)"
                        },
                        {
                            value: "X",
                            descripcion: "Credit decline (credit transaction that got declined)"
                        },
                        {
                            value: "E",
                            descripcion: "Error (transaction that did not go through an internal validation of the gateway and was not sent for further processing)"
                        },
                        {
                            value: "B",
                            descripcion: "Blacklist (transaction, which has not been processed, as the account is in the blacklist due to the previous hard decline)"
                        },
                        {
                            value: "N",
                            descripcion: "Return (represents direct debit payment that is not honored by a bank)"
                        },
                        {
                            value: "G",
                            descripcion: "Chargeback (operation of reversal of a prior outbound transfer of funds from a customer's bank account or credit card)"
                        },
                        {
                            value: "R",
                            descripcion: "Reversal (operation where credit card chargeback is reversed - money is given back to the merchant or customer)"
                        },
                        {
                            value: "U",
                            descripcion: "Notice of change (represents direct debit transaction that is returned by the customer's bank as a notification that some details of the transaction were corrected)"
                        },
                        {
                            value: "J",
                            descripcion: "Reject (direct debit payment that is rejected by the bank due to insufficient funds or errors in payment order)"
                        },
                    ]
                },
                {
                    titulo: "Account Type",
                    id: "ReturnAccountType",
                    headers:["Value", "Description"],
                    rows: [
                        {
                            value: "R",
                            descripcion: "Branded credit card"
                        },
                        {
                            value: "E",
                            descripcion: "Branded debit checking card. This type is used for Canadian debit cards (Interac) only."
                        },
                        {
                            value: "V",
                            descripcion: "Branded debit savings card. This type is used for Canadian debit cards (Interac) only."
                        },
                        {
                            value: "D",
                            descripcion: "Unbranded debit checking card"
                        },
                        {
                            value: "I",
                            descripcion: "Unbranded debit savings card"
                        },
                        {
                            value: "S",
                            descripcion: "Bank saving account"
                        },
                        {
                            value: "C",
                            descripcion: "Bank checking account"
                        },
                        {
                            value: "F",
                            descripcion: "EBT food stamp"
                        },
                        {
                            value: "H",
                            descripcion: "EBT cash benefit"
                        },
                        {
                            value: "G",
                            descripcion: "Gift card"
                        },
                        {
                            value: "L",
                            descripcion: "Fleet"
                        },
                        {
                            value: "A",
                            descripcion: "Cash (used for sale-info, credit-info API calls)"
                        },
                        {
                            value: "K",
                            descripcion: "Check (used for sale-info, credit-info API calls)"
                        },

                    ]
                },
            ],
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>
                            {
                                this.state.tablaDatos.map((tabla) => (
                                    tabla.id==this.state.modal ? 
                                    tabla.titulo
                                    : null
                                ))
                            }
                            </h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <div style={{ alignContent: "center" }}>
                            <div class="table-responsive">
                            <table class="newtable newtablenorowspan">
                                <tbody>
                                    <tr>
                                        {
                                            this.state.tablaDatos.map((tabla) => (
                                                tabla.id==this.state.modal ? 
                                                tabla.headers.map((hd, ihd)=>(
                                                    <th style={{ fontWeight: "bold" }}>{hd}</th>
                                                ))
                                                : null
                                            ))
                                        }
                                    </tr>
                                    {
                                        this.state.tablaDatos.map((tabla) => (
                                            tabla.id==this.state.modal ? 
                                            tabla.rows.map((column)=>(
                                                <tr>
                                                    <td>{column.value}</td>
                                                    <td>{column.descripcion}</td>
                                                </tr>
                                            ))
                                            : null
                                        ))
                                    }
                                </tbody>
                            </table>
                            </div><br />         
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}
