import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';

export default class ModalProcessorRulesPaymentNetworkName extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
        }
    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}></h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>


                        <div class="white-popup">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12">
                                    <h2>Processor Rules</h2>
                                </div>
                            </div>
                            
                            <div class="panel panel-default">
                <div class="panel-heading"><span class="h3">FirstData Nashville (Card Processing)</span></div>
                <div class="panel-body">
                    <strong>paymentNetworkName</strong> field is always present within <em>Sale-Auth, Sale, Credit, Credit-Auth (responses)</em>                                    </div>
            </div>
                       </div>
                      

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

