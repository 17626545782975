import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalConTablas.css';

export default class ModalNoProcessorRules extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
            modal: props.modal,
        }
    }

    async componentDidMount() {

    }

    showModal = (accion) => {
        this.setState({ show: false });
        let json = {
            accion: accion,
            modal: this.state.modal
        }
        setTimeout(() => {
            this.props.cerrarModal(json);
        }, 200);
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <div class="col panel-body">
                                No Processor Rules
                            </div>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

