import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';
import parse from 'html-react-parser';


export default class ModalSubrecordFormat extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }


    pasartexto = (texto) => {
        try {
            return parse(texto);
        } catch (error) {
            return texto;
        }
    }


    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="xl"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Integration Notes</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2>Subrecord Format</h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="panel panel-default">
                                <div class="panel-body">
                Subrecord is an entry within a primary record. Usually, the primary record has a list of subrecords. The following subrecords are currently supported within the system:
<br/>
<ul type="circle" >
<li><em>items</em> (for transactions with items; may include a reference to a split schema or a split payment scenario);
</li>
<li><em>extraCharges</em> (for lodging and car rental transactions);
</li>
<li><em>petroleumItems</em> (for petroleum transactions);
</li>
<li><em>distributions</em> (for vendors distributions);
</li>
<li><em>splits</em> (for split payments).
</li>
</ul>

<br/>
The following formatting rules are applied to the fields with subrecords: 
<br/>
1. Each subrecord must be enclosed by brackets. 
<br/>
2. Equal sign (=) is used to separate field’s name and value, semicolon (;) - to separate fields. 
<br/>
3. To submit multiple subrecords, list them one after another. 
<br/>
 
<br/>
For example, if the following item information is needed to be submitted: 
<br/>
Item 1: 
<br/>
<ul type="circle" >
<li>code=001
</li>
<li>quantity=1
</li>
<li>description=t-shirt
</li>
<li>totalAmount=50000
</li>
</ul>
Item 2: 
<br/>
<ul type="circle" >
<li>code=002
</li>
<li>quantity=1
</li>
<li>description=jeans
</li>
<li>totalAmount=70000,
</li>
</ul>

<br/>
<em>items</em> field’s value must be formatted as shown below: 
<em>items=(code=001;quantity=1;description=t-shirt;totalAmount=50000)(code=002;quantity=1;description=jeans;totalAmount=70000);</em>

<br/>
If amounts of the items listed above should be split between two or more recipients, <em>items</em> field’s value must be formatted as follows: 
<em>items=(code=001;description=t-shirt;quantity=1;totalAmount=50000;splits=(accountId=2011;amount=7000)(accountId=*johnsmith123;amount=2000))(code=002;description=jeans;quantity=1;totalAmount=80000;splits=(accountId=2011;amount=9000)(accountId=*johnsmith123;amount=2000)(accountId=*my+Merchant+Account+code;amount=3000));</em>
                </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

