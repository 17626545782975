import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';
import { CgClose } from 'react-icons/cg';
import './ModalRealtime.css';


export default class ModalSpecialProgramType extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            show: props.show,
        }

    }

    async componentDidMount() {

    }



    showModal = (accion) => {

        this.setState({ show: false });
        setTimeout(() => {
            this.props.cerrarModal(accion);
        }, 200);

    }

    render() {
        return (

            <>


                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered>
                    <ModalBody>

                        <div class="row">
                            <h3 class="col" style={{color: "#0e2689"}}>Special Program Type</h3>
                            <div class="col-auto">
                                <CgClose className='icoCloseModal' onClick={() => this.showModal('0')} />
                            </div>
                        </div>

                        <div className="prettyline" ></div>

                        <h2></h2>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table my-table">
                                        <colgroup style={{width: "15%"}}></colgroup>
                                        <colgroup style={{width: "85%"}}></colgroup>
                                        <thead>
                                            <tr>
                                                <th>Value</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                            <td width="100px">AD</td>
                            <td>Advance deposit<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">AR</td>
                            <td>Assured reservation<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">DC</td>
                            <td>Delayed charge<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">ES</td>
                            <td>Express service<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">NC</td>
                            <td>Normal charge<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">NS</td>
                            <td>No show charge<br/></td>
                        </tr>
                                            <tr>
                            <td width="100px">FR </td>
                            <td>Frequent renter<br/></td>
                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )
    }
}

